import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import { TextField } from 'widgets-v6/standard-input';
import { SimpleSelect } from 'widgets-v6/select';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

import FileUpload from 'widgets-v5/file-upload';
import { default as creativeBulkUploadActions } from '../../actions';
import { processMacrosForCustomHtml } from '../../services/process-macros';
import { getValidSizes, getPlatformsAvailableForCampaign } from 'forms/creative-form/constants';

import { cleanStringSpaces } from 'utils/formatting';
import { securePartnerDomainsAndReplaceUrlMacros } from 'widgets-v5/url-standard-input';
import { getEnvironmentSettings } from 'services/environment';

import selector from './selector';

const useStyles = makeStyles(() => ({
    image: {
        maxHeight: '70px',
        maxWidth: '250px',
    },
    textArea: {
        padding: 0,
    },
}));

const validSizes = getValidSizes();
const sizeOptions = _.map(validSizes, size => ({ value: size, label: size }));

function BulkUploadTableRow({
    creativeDraft,
    onChange,
    creativeKey,
    creativeFormat,
    showErrors,
    errors,
    onImageChange,
    isAssetLoading,
    selectablePlatforms,
    dispatch,
    hasImageGenerationError,
    lineSizeError,
    campaignType,
}) {
    const classes = useStyles();
    const handleThirdPartyPixelsBlur = ({ value, creativeKey, index }) => {
        const processedValue = securePartnerDomainsAndReplaceUrlMacros(value);

        dispatch(
            creativeBulkUploadActions.changeThirdPartyPixel({
                value: processedValue,
                index,
                creativeKey,
            })
        );
    };

    const handleThirdPartyJavascriptPixelsBlur = ({ value, creativeKey, index }) => {
        const processedValue = securePartnerDomainsAndReplaceUrlMacros(value);

        dispatch(
            creativeBulkUploadActions.changeThirdPartyJavascript({
                value: processedValue,
                index,
                creativeKey,
            })
        );
    };

    const handleClickThroughUrlBlur = ({ value, creativeKey }) => {
        const processedValue = securePartnerDomainsAndReplaceUrlMacros(value);

        dispatch(
            creativeBulkUploadActions.changeClickThroughUrl({
                value: processedValue,
                creativeKey,
            })
        );
    };

    const debouncedFieldChange = _.debounce(
        ({ value, field }) => onChange({ creativeKey, field, value }),
        200
    );

    const handleContentHtmlBlur = ({ value }) => {
        const html = processMacrosForCustomHtml({ value });
        onChange({ creativeKey, field: 'content_html', value: html });
    };

    const platforms = getPlatformsAvailableForCampaign(campaignType);

    const environmentSettings = getEnvironmentSettings();
    const platformOptions = _.filter(platforms, platform => {
        return !_.includes(environmentSettings.getCreativePlatformRestrictions(), platform.value);
    });
    const useJobForBulkCreativeScreenshots = getEnvironmentSettings().useJobForBulkCreativeScreenshots();

    return (
        <TableRow>
            <TableCell>
                <TextField
                    defaultValue={creativeDraft.name}
                    onChange={e =>
                        debouncedFieldChange({
                            field: 'name',
                            value: cleanStringSpaces(e.target.value),
                        })
                    }
                    error={showErrors && errors.name ? true : false}
                    helperText={showErrors && errors.name}
                    multiline
                    maxRows={2}
                    className={classes.textArea}
                />
            </TableCell>
            <TableCell>
                {lineSizeError ? (
                    <React.Fragment>
                        <SimpleSelect
                            onChange={e =>
                                dispatch(
                                    creativeBulkUploadActions.changeSize({
                                        creativeKey,
                                        size: e.target.value,
                                    })
                                )
                            }
                            value={creativeDraft.size}
                            options={sizeOptions}
                        />
                        <FormHelperText error>Size is required</FormHelperText>
                    </React.Fragment>
                ) : (
                    <Typography>{creativeDraft.size}</Typography>
                )}
            </TableCell>
            {creativeFormat !== 'standard' && (
                <TableCell>
                    <TextField
                        key={`${creativeDraft.content_html}-${creativeKey}`}
                        defaultValue={creativeDraft.content_html}
                        error={showErrors && errors.content_html ? true : false}
                        helperText={showErrors && errors.content_html}
                        className={classes.textArea}
                        multiline
                        maxRows={5}
                        onBlur={e => handleContentHtmlBlur({ value: e.target.value })}
                    />
                </TableCell>
            )}

            {(!useJobForBulkCreativeScreenshots || creativeFormat === 'standard') && (
                <TableCell>
                    {creativeFormat === 'standard' ? (
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <img
                                    src={creativeDraft.image_preview_url}
                                    className={classes.image}
                                />
                            </Grid>
                            <Grid>
                                <FileUpload
                                    accept="image/*"
                                    onChange={formData => onImageChange(formData, creativeKey)}
                                    buttonText="Change Image"
                                    isLoading={isAssetLoading}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                {isAssetLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <img
                                        src={creativeDraft.reference_image_preview_url}
                                        className={classes.image}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() =>
                                        dispatch(
                                            creativeBulkUploadActions.generateReferenceImage({
                                                html: creativeDraft.content_html,
                                                creativeKey,
                                            })
                                        )
                                    }
                                    autoFocus
                                    variant="outlined"
                                    color="primary"
                                >
                                    Refresh Image
                                </Button>
                            </Grid>
                            <Grid item>
                                <FileUpload
                                    accept="image/*"
                                    onChange={formData => onImageChange(formData, creativeKey)}
                                    buttonText="Change Image"
                                />
                            </Grid>
                            <Grid item>
                                {lineSizeError && (
                                    <FormHelperText error>
                                        Must select size before generating image
                                    </FormHelperText>
                                )}
                                {hasImageGenerationError && (
                                    <FormHelperText error>Failed to Generate Image</FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </TableCell>
            )}

            <TableCell>
                <TextField
                    defaultValue={creativeDraft.landing_page}
                    onChange={e =>
                        debouncedFieldChange({ field: 'landing_page', value: e.target.value })
                    }
                    error={showErrors && errors.landing_page ? true : false}
                    helperText={showErrors && errors.landing_page}
                    multiline
                    maxRows={2}
                    className={classes.textArea}
                />
            </TableCell>
            <TableCell>
                <FormControlLabel
                    control={
                        <Switch
                            checked={creativeDraft.third_party_clickthrough}
                            onChange={e =>
                                dispatch(
                                    creativeBulkUploadActions.toggleClickThroughUrl({
                                        creativeKey,
                                        checked: e.target.checked,
                                    })
                                )
                            }
                            name="checkedA"
                            color="primary"
                        />
                    }
                    label="Enable"
                />
                {creativeDraft.third_party_clickthrough && (
                    <TextField
                        key={`${creativeKey}-${creativeDraft.clickthrough_url}`}
                        defaultValue={creativeDraft.clickthrough_url}
                        onBlur={e =>
                            handleClickThroughUrlBlur({ value: e.target.value, creativeKey })
                        }
                        error={showErrors && errors.clickthrough_url ? true : false}
                        helperText={showErrors && errors.clickthrough_url}
                        multiline
                        maxRows={2}
                        className={classes.textArea}
                    />
                )}
            </TableCell>
            <TableCell>
                <Grid container direction="column" spacing={1}>
                    {_.map(creativeDraft.third_party_pixels, (pixel, index) => (
                        <Grid item>
                            <TextField
                                key={`${creativeKey}-${pixel}-${index}`}
                                defaultValue={pixel}
                                disableMargin
                                error={
                                    showErrors && _.get(errors, `third_party_pixels.${index}`)
                                        ? true
                                        : false
                                }
                                helperText={_.get(errors, `third_party_pixels.${index}`)}
                                multiline
                                maxRows={2}
                                onBlur={e =>
                                    handleThirdPartyPixelsBlur({
                                        value: e.target.value,
                                        index,
                                        creativeKey,
                                    })
                                }
                                className={classes.textArea}
                            />
                            <IconButton
                                onClick={() =>
                                    dispatch(
                                        creativeBulkUploadActions.removeThirdPartyPixel({
                                            index,
                                            creativeKey,
                                        })
                                    )
                                }
                                size="large"
                            >
                                <RemoveCircleIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    ))}
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={() =>
                                dispatch(
                                    creativeBulkUploadActions.addThirdPartyPixelInput({
                                        creativeKey,
                                    })
                                )
                            }
                            color="primary"
                            size="small"
                        >
                            Add Pixel
                        </Button>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container direction="column" spacing={1}>
                    {_.map(creativeDraft.third_party_javascript_urls, (pixel, index) => (
                        <Grid item>
                            <TextField
                                key={`${creativeKey}-${pixel}-${index}`}
                                defaultValue={pixel}
                                disableMargin
                                error={
                                    showErrors &&
                                    _.get(errors, `third_party_javascript_urls.${index}`)
                                        ? true
                                        : false
                                }
                                helperText={_.get(errors, `third_party_javascript_urls.${index}`)}
                                multiline
                                maxRows={2}
                                onBlur={e =>
                                    handleThirdPartyJavascriptPixelsBlur({
                                        value: e.target.value,
                                        index,
                                        creativeKey,
                                    })
                                }
                                className={classes.textArea}
                            />
                            <IconButton
                                onClick={() =>
                                    dispatch(
                                        creativeBulkUploadActions.removeThirdPartyJavascript({
                                            index,
                                            creativeKey,
                                        })
                                    )
                                }
                                size="large"
                            >
                                <RemoveCircleIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    ))}
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={() =>
                                dispatch(
                                    creativeBulkUploadActions.addThirdPartyJavascriptInput({
                                        creativeKey,
                                    })
                                )
                            }
                            color="primary"
                            size="small"
                        >
                            Add Pixel
                        </Button>
                    </Grid>
                </Grid>
            </TableCell>
            {creativeFormat !== 'standard' && environmentSettings.canUseMraid() && (
                <TableCell>
                    <Checkbox
                        checked={creativeDraft.format === 'mraid'}
                        color="primary"
                        onChange={e =>
                            dispatch(
                                creativeBulkUploadActions.toggleMraid({
                                    checked: e.target.checked,
                                    creativeKey,
                                })
                            )
                        }
                    />
                </TableCell>
            )}
            <TableCell>
                <FormGroup>
                    {_.map(platformOptions, platform => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={_.includes(creativeDraft.platforms, platform.value)}
                                    onChange={e =>
                                        dispatch(
                                            creativeBulkUploadActions.changePlatforms({
                                                value: platform.value,
                                                checked: e.target.checked,
                                                creativeKey,
                                            })
                                        )
                                    }
                                    color="primary"
                                    disabled={!_.includes(selectablePlatforms, platform.value)}
                                />
                            }
                            label={platform.label}
                        />
                    ))}
                    {showErrors && errors.platforms && (
                        <FormHelperText error>{errors.platforms}</FormHelperText>
                    )}
                </FormGroup>
            </TableCell>
            <TableCell>
                <IconButton
                    onClick={() =>
                        dispatch(creativeBulkUploadActions.duplicateRow({ creativeKey }))
                    }
                    size="large"
                >
                    <FileCopyOutlinedIcon />
                </IconButton>
                <IconButton
                    onClick={() => dispatch(creativeBulkUploadActions.deleteRow({ creativeKey }))}
                    size="large"
                >
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

export default connect(selector)(BulkUploadTableRow);
