import { makeActions } from 'utils/redux-tools';

export const REDUCER_KEY = 'gisForm';
export const initialState = {
    isOpen: false,
};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        open: state => ({
            ...state,
            isOpen: true,
        }),
        close: state => ({
            ...state,
            isOpen: false,
        }),
    }
});
