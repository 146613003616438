import React, { useEffect, useReducer } from 'react';
import _ from 'lodash';
import uuid from 'uuid';
import numeral from 'numeral';

import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import TextFieldFormatter from 'widgets-v6/text-field-formatter';
import { PLATFORM_MAPPING } from 'common/constants/platforms';
import { SimpleSelect, GroupedSingleSelect } from 'widgets-v6/select';
import OverflowLayout from 'widgets-v5/overflow-layout';
import { CustomContentSelect } from 'widgets-v6/select';
import {
    VALID_WEATHER_CONDITION_MULTIPLE_OPTION_TYPE,
    isWeatherTypeTemperature,
    inputAdornmentMap,
} from 'common/constants/weather-condition-targeting';
import schema from './creative-rotation-by-weather/schema';
import { getEnvironmentSettings } from 'services/environment';
import {
    useWeatherConditions,
    getWeatherConditionQualifier,
    getWeatherConditionTimeframe,
    getWeatherConditionValue,
} from '../weather-conditions/hook';

const useStyles = makeStyles(theme => ({
    creativeInfoSelector: {
        whiteSpace: 'normal',
        overflow: 'auto',
    },
    previewImg: {
        width: '100%',
    },
    previewImgSelector: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    previewImgColumn: {
        marginRight: 10,
    },
    disabledSelectOption: {
        backgroundColor: '#f2f2f2',
        color: '#999999',
        cursor: 'not-allowed',
    },
    weatherTable: {
        [theme.breakpoints.down('lg')]: {
            '& .MuiTableCell-sizeSmall': {
                padding: '6px',
            },
        },
    },
}));

const handlers = {
    CREATIVE_ROTATION_BY_WEATHER__ADD_A_NEW_SETTING: state => {
        return {
            ...state,
            draft: [
                ...state.draft,
                {
                    id: uuid.v4(),
                    qualifier: '',
                    type: '',
                    timeframe: '',
                    amount: '',
                    markupId: null,
                    isDefault: false,
                },
            ],
        };
    },
    CREATIVE_ROTATION_BY_WEATHER__REMOVE_A_SETTING: (state, action) => {
        return {
            ...state,
            draft: _.filter(state.draft, setting => setting.id !== action.payload.id),
        };
    },
    CREATIVE_ROTATION_BY_WEATHER__UPDATE_A_SETTING: (state, action) => {
        const { id, field, value } = action.payload;

        return {
            ...state,
            draft: _.map(state.draft, setting => {
                if (setting.id === id) {
                    const updatedSetting = {
                        ...setting,
                        [field]: value,
                    };

                    if (field === 'type') {
                        updatedSetting.amount = '';
                        updatedSetting.timeframe = '';
                        updatedSetting.qualifier = !isWeatherTypeTemperature(value) ? 'equal' : '';
                        updatedSetting.amount = '';
                    }

                    return updatedSetting;
                }
                return setting;
            }),
        };
    },
};

const validateDraft = state => {
    const options = {
        allowUnknown: true,
        abortEarly: false,
    };

    const result = schema.validate(state.draft, options);

    if (!result.error) {
        return {
            ...state,
            errors: {},
        };
    }

    const errors = {};

    _.each(result.error.details, detail => {
        errors[`rotation_rules.weather.${detail.path.join('.')}`] = detail.message;
    });

    return {
        ...state,
        errors,
    };
};

const reducer = (state, action) => {
    let nextState = state;

    if (handlers[action.type]) {
        nextState = handlers[action.type](state, action);
        nextState = validateDraft(nextState);
        return nextState;
    }

    return state;
};

export const CreativeRotationByWeatherTable = ({
    errors,
    showErrors,
    creatives,
    weatherRotationSettings,
    onChange,
    rotationRules,
    isCrossPlatformCampaign,
    useAspectRatio,
}) => {
    const classes = useStyles();
    const initialState = {
        draft: weatherRotationSettings,
        errors: {},
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const nonCreativeRotationByWeatherErrors = _.omitBy(errors, (value, key) =>
            key.startsWith('rotation_rules.weather')
        );
        onChange({
            errors: {
                ...nonCreativeRotationByWeatherErrors,
                ...state.errors,
            },
            draft: {
                rotation_rules: {
                    ...rotationRules,
                    weather: state.draft,
                },
            },
        });
    }, [state]);

    const handleAddSettingClick = () => {
        dispatch({
            type: 'CREATIVE_ROTATION_BY_WEATHER__ADD_A_NEW_SETTING',
        });
    };

    const handleSettingChange = id => ({ field, value }) => {
        dispatch({
            type: 'CREATIVE_ROTATION_BY_WEATHER__UPDATE_A_SETTING',
            payload: {
                id,
                field,
                value,
            },
        });
    };

    const handleDeleteSetting = id => {
        dispatch({
            type: 'CREATIVE_ROTATION_BY_WEATHER__REMOVE_A_SETTING',
            payload: {
                id,
            },
        });
    };

    return (
        <React.Fragment>
            <Paper>
                <OverflowLayout>
                    <Table size="small" className={classes.weatherTable}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" colSpan={2}>
                                    Condition
                                </TableCell>
                                <TableCell align="left" colSpan={2}>
                                    Qualifier
                                </TableCell>
                                <TableCell align="left" colSpan={2}>
                                    Value
                                </TableCell>
                                <TableCell align="left" colSpan={2}>
                                    Time Frame
                                </TableCell>
                                <TableCell align="left" colSpan={2}>
                                    Creative
                                </TableCell>
                                <TableCell align="left" colSpan={2} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.filter(state.draft, setting => !setting.isDefault).map(
                                (setting, index) => (
                                    <RotationSettingNonDefaultRow
                                        setting={setting}
                                        key={setting.id}
                                        creatives={creatives}
                                        onChange={handleSettingChange(setting.id)}
                                        onDelete={handleDeleteSetting}
                                        index={index + 1}
                                        showErrors={showErrors}
                                        errors={errors}
                                        isCrossPlatformCampaign={isCrossPlatformCampaign}
                                        useAspectRatio={useAspectRatio}
                                    />
                                )
                            )}
                            {_.filter(state.draft, setting => setting.isDefault).map(setting => (
                                <RotationSettingDefaultRow
                                    setting={setting}
                                    key={setting.id}
                                    creatives={creatives}
                                    onChange={handleSettingChange(setting.id)}
                                    onDelete={handleDeleteSetting}
                                    index={0}
                                    showErrors={showErrors}
                                    errors={errors}
                                    isCrossPlatformCampaign={isCrossPlatformCampaign}
                                    useAspectRatio={useAspectRatio}
                                />
                            ))}
                            <TableRow>
                                <TableCell align="center" colSpan={12}>
                                    Rules are matched starting from top to bottom. The default rule
                                    is used when no other rules are matched.
                                </TableCell>
                            </TableRow>

                            <TableCell align="center" colSpan={12}>
                                <Button
                                    color="primary"
                                    onClick={handleAddSettingClick}
                                    startIcon={<AddIcon />}
                                >
                                    Add a rule
                                </Button>
                            </TableCell>
                        </TableBody>
                    </Table>
                </OverflowLayout>
            </Paper>
        </React.Fragment>
    );
};

const RotationSettingDefaultRow = ({
    setting,
    creatives,
    onChange,
    showErrors,
    errors,
    index,
    isCrossPlatformCampaign,
    useAspectRatio,
}) => {
    return (
        <TableRow>
            <TableCell align="left" colSpan={8}>
                <SimpleSelect disabled={true} placeholder="Default Rule" value={setting.type} />
            </TableCell>

            <TableCell align="left" colSpan={2}>
                <CreativeSelect
                    setting={setting}
                    index={index}
                    creatives={creatives}
                    onChange={onChange}
                    showErrors={showErrors}
                    errors={errors}
                    isCrossPlatformCampaign={isCrossPlatformCampaign}
                    useAspectRatio={useAspectRatio}
                />
            </TableCell>
        </TableRow>
    );
};

const RotationSettingNonDefaultRow = ({
    setting,
    creatives,
    onChange,
    onDelete,
    showErrors,
    errors,
    index,
    isCrossPlatformCampaign,
    useAspectRatio,
}) => {
    const environmentSettings = getEnvironmentSettings();

    const { conditionOptions } = useWeatherConditions({
        conditionsToUse: environmentSettings.getWeatherCondtionsToUse(),
    });

    return (
        <TableRow>
            <React.Fragment>
                <Hidden mdUp>
                    <TableCell align="left" colSpan={1}>
                        <IconButton size="small" onClick={() => onDelete(setting.id)}>
                            <DeleteIcon style={{ fontSize: 16 }} />
                        </IconButton>
                    </TableCell>
                    <TableCell align="left" colSpan={1}>
                        <GroupedSingleSelect
                            label="Condition"
                            value={setting.type}
                            options={conditionOptions}
                            onChange={value => {
                                onChange({ field: 'type', value });
                            }}
                            placeholder="Select condition"
                            error={
                                showErrors && errors[`weatherConditions.conditions.${index}.type`]
                            }
                        />
                    </TableCell>
                </Hidden>
                <Hidden mdDown>
                    <TableCell align="left" colSpan={2}>
                        <GroupedSingleSelect
                            label="Condition"
                            value={setting.type}
                            options={conditionOptions}
                            onChange={value => {
                                onChange({ field: 'type', value });
                            }}
                            placeholder="Select condition"
                            error={
                                showErrors && errors[`weatherConditions.conditions.${index}.type`]
                            }
                        />
                    </TableCell>
                </Hidden>
            </React.Fragment>
            <TableCell align="left" colSpan={2}>
                {!setting.type ||
                _.includes(VALID_WEATHER_CONDITION_MULTIPLE_OPTION_TYPE, setting.type) ? (
                    <SimpleSelect
                        value={setting.qualifier}
                        options={getWeatherConditionQualifier(conditionOptions, setting.type)}
                        placeholder="Select qualifier"
                        disabled={!setting.type}
                        onChange={event => {
                            onChange({ field: 'qualifier', value: event.target.value });
                        }}
                        error={showErrors && errors[`rotation_rules.weather.${index}.qualifier`]}
                    />
                ) : (
                    <React.Fragment>
                        <TextField fullWidth disabled={true} label="equals to" />
                    </React.Fragment>
                )}
            </TableCell>
            <TableCell align="left" colSpan={2}>
                {isWeatherTypeTemperature(setting.type) ? (
                    <TextFieldFormatter
                        initialValue={setting.amount}
                        onChange={v =>
                            onChange({
                                field: 'amount',
                                value: v,
                            })
                        }
                        shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                        formatIn={v => numeral(v).value()}
                        formatOut={v => numeral(v).value()}
                        renderInput={({ value, onChange, onBlur }) => (
                            <TextField
                                margin="dense"
                                variant="outlined"
                                style={{ 'min-width': '100px' }}
                                error={
                                    showErrors && errors[`rotation_rules.weather.${index}.amount`]
                                }
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {inputAdornmentMap[setting.type]}
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Input value"
                            />
                        )}
                    />
                ) : (
                    <SimpleSelect
                        value={setting.amount === -1 ? '' : setting.amount}
                        options={getWeatherConditionValue(conditionOptions, setting.type)}
                        disabled={!setting.type}
                        placeholder="Select Value"
                        onChange={event => {
                            onChange({ field: 'amount', value: event.target.value });
                        }}
                        error={showErrors && errors[`rotation_rules.weather.${index}.amount`]}
                    />
                )}
            </TableCell>
            <TableCell align="left" colSpan={2}>
                <SimpleSelect
                    value={setting.timeframe}
                    options={getWeatherConditionTimeframe(conditionOptions, setting.type)}
                    placeholder="Select Time Frame"
                    disabled={!setting.type}
                    onChange={event => {
                        onChange({ field: 'timeframe', value: event.target.value });
                    }}
                    error={showErrors && errors[`rotation_rules.weather.${index}.timeframe`]}
                />
            </TableCell>
            <TableCell align="left" colSpan={2}>
                <CreativeSelect
                    setting={setting}
                    creatives={creatives}
                    onChange={onChange}
                    showErrors={showErrors}
                    errors={errors}
                    isCrossPlatformCampaign={isCrossPlatformCampaign}
                    useAspectRatio={useAspectRatio}
                />
            </TableCell>
            <Hidden mdDown>
                <TableCell align="left" colSpan={2}>
                    <Button
                        color="primary"
                        startIcon={<DeleteIcon />}
                        onClick={() => onDelete(setting.id)}
                    />
                </TableCell>
            </Hidden>
        </TableRow>
    );
};

const CreativeSelect = ({
    creatives,
    setting,
    onChange,
    isCrossPlatformCampaign,
    useAspectRatio,
}) => {
    const classes = useStyles();
    return (
        <CustomContentSelect
            required={true}
            placeholder="Select a creative"
            value={setting.markupId}
            onChange={event => {
                const value = event.target.value;
                const creative = creatives.find(c => c.creativeId === value);
                onChange({ field: 'markupId', value: creative.disableSelection ? '' : value });
            }}
            options={_.map(creatives, creative => ({
                label: creative.name,
                value: creative.creativeId,
                content: (
                    <Grid
                        container
                        className={creative.disableSelection ? classes.disabledSelectOption : ''}
                    >
                        <Grid item xs={3} className={classes.previewImgColumn}>
                            <img className={classes.previewImgSelector} src={creative.previewImg} />
                        </Grid>
                        <Grid item xs={8} className={classes.creativeInfoSelector}>
                            <Typography gutterBottom>{creative.name}</Typography>
                            {isCrossPlatformCampaign && (
                                <Grid container direction="row" spacing={1}>
                                    {_.map(creative.platforms, platform => (
                                        <Grid item key={platform}>
                                            <Chip
                                                size="small"
                                                label={PLATFORM_MAPPING[platform].label}
                                                variant="outlined"
                                                color="primary"
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                            <div className="ef3-creativeRotationSelector_tags">
                                <span className="ef3-creativeRotationSelector_tag">
                                    {useAspectRatio ? creative.aspect_ratio : creative.size}
                                </span>
                                <span className="ef3-creativeRotationSelector_tag">
                                    {creative.type}
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                ),
            }))}
        />
    );
};
