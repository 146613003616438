import _ from 'lodash';

export const TORSTAR_ID = '60240676c48e547c2b3d114d';

export const AUDIENCE_CATEGORIES = [
    {
        value: 'all',
        label: 'all',
    },
    {
        label: 'Pelmorex Audiences',
        value: 'pelmorex_audiences',
        subCategories: [
            {
                label: 'Precise Geolocation',
                value: ['pds_brand', 'pds_standard', 'pds_behaviour'],
                category: 'pelmorex_audiences',
                shouldHide: ({ restrictedSegments }) => {
                    if (
                        _.intersection(restrictedSegments, ['pds_brand', 'pds_standard']).length > 0
                    ) {
                        return true;
                    }
                    return false;
                },
            },
            {
                label: 'Flex Geolocation',
                value: ['flex_standard_brand', 'flex_location_category'],
                category: 'pelmorex_audiences',
                shouldHide: ({
                    restrictedSegments,
                    shouldHideFlexSegments,
                    platform,
                    isCrossPlatformCampaign,
                }) => {
                    if (
                        shouldHideFlexSegments ||
                        _.intersection(restrictedSegments, [
                            'flex_standard_brand',
                            'flex_location_category',
                        ]).length > 0
                    ) {
                        return true;
                    }

                    if (isCrossPlatformCampaign) {
                        return false;
                    } else {
                        return platform !== 'inapp';
                    }
                },
            },
            {
                label: 'Online Behavioural',
                value: ['pds_intent'],
                category: 'pelmorex_audiences',
                shouldHide: ({ restrictedSegments }) => {
                    if (
                        _.intersection(restrictedSegments, ['pds_intent', 'pds_behaviour']).length >
                        0
                    ) {
                        return true;
                    }
                    return false;
                },
            },
            {
                label: 'CanaCode',
                value: ['pds_manifold'],
                category: 'pelmorex_audiences',
                shouldHide: ({ restrictedSegments }) => {
                    if (_.intersection(restrictedSegments, ['pds_manifold']).length > 0) {
                        return true;
                    }
                    return false;
                },
            },
            {
                label: 'Environics',
                value: ['pds_environics'],
                category: 'pelmorex_audiences',
                shouldHide: ({ restrictedSegments }) => {
                    if (_.intersection(restrictedSegments, ['pds_environics']).length > 0) {
                        return true;
                    }
                    return false;
                },
            },
            {
                label: 'Mastercard',
                value: ['pds_mastercard'],
                category: 'pelmorex_audiences',
                shouldHide: ({ restrictedSegments }) => {
                    if (_.intersection(restrictedSegments, ['pds_mastercard']).length > 0) {
                        return true;
                    }
                    return false;
                },
            },
            {
                label: 'Declared Age',
                value: ['pds_age'],
                category: 'pelmorex_audiences',
                shouldHide: ({ restrictedSegments }) => {
                    if (_.intersection(restrictedSegments, ['pds_age']).length > 0) {
                        return true;
                    }
                    return false;
                },
            },
        ],
    },
    {
        label: 'Custom Audiences',
        value: 'custom_audiences',
        subCategories: [
            {
                label: 'Pelmorex Data',
                value: ['pds_custom'],
                category: 'custom_audiences',
                shouldHide: ({ restrictedSegments }) => {
                    if (_.intersection(restrictedSegments, ['pds_custom']).length > 0) {
                        return true;
                    }
                    return false;
                },
            },
            {
                label: 'Custom Flex Geolocation',
                value: ['flex_custom_brand'],
                category: 'custom_audiences',
                shouldHide: ({
                    restrictedSegments,
                    platform,
                    shouldHideFlexSegments,
                    isCrossPlatformCampaign,
                }) => {
                    if (
                        shouldHideFlexSegments ||
                        _.intersection(restrictedSegments, ['flex_custom_brand']).length > 0
                    ) {
                        return true;
                    }

                    if (isCrossPlatformCampaign) {
                        return false;
                    } else {
                        return platform !== 'inapp';
                    }
                },
            },
            {
                label: 'Retargeting',
                value: ['ad', 'campaign', 'pixel'],
                category: 'custom_audiences',
                shouldHide: ({ restrictedSegments }) => {
                    if (
                        _.intersection(restrictedSegments, ['ad', 'campaign', 'pixel']).length > 0
                    ) {
                        return true;
                    }
                    return false;
                },
            },
            {
                label: 'Uploaded',
                value: ['upload'],
                category: 'custom_audiences',
                shouldHide: ({ restrictedSegments }) => {
                    if (_.intersection(restrictedSegments, ['upload']).length > 0) {
                        return true;
                    }
                    return false;
                },
            },
        ],
    },
    {
        label: 'DMP Integrations',
        value: 'dmp_integration',
        subCategories: [
            {
                label: 'Adobe',
                value: ['adobe'],
                category: 'dmp_integration',
                shouldHide: ({ restrictedSegments }) => {
                    if (_.intersection(restrictedSegments, ['adobe']).length > 0) {
                        return true;
                    }
                    return false;
                },
            },
            {
                label: 'Permutive',
                value: ['permutive'],
                category: 'dmp_integration',
                shouldHide: ({ restrictedSegments, orgId }) => {
                    return (
                        _.intersection(restrictedSegments, ['permutive']).length > 0 ||
                        orgId !== TORSTAR_ID
                    );
                },
            },
        ],
    },
];

export function getSegments({
    allSegments,
    sourceType,
    tab,
    restrictedSegments,
    shouldHideFlexSegments,
    platforms,
    platform,
    keywords,
    isCrossPlatformCampaign,
    orgId,
}) {
    const segmentTypes = _(AUDIENCE_CATEGORIES)
        .filter(category => category.value !== 'all')
        .filter(category => {
            if (tab === 'all') {
                return true;
            }

            return tab === category.value;
        })
        .map(category => category.subCategories)
        .flatten()
        .filter(subCategory => {
            if (sourceType === 'all') {
                return true;
            }

            return _.isEqual(sourceType, subCategory.value);
        })
        .filter(subCategory => {
            return !subCategory.shouldHide({
                restrictedSegments,
                shouldHideFlexSegments,
                platforms,
                platform,
                isCrossPlatformCampaign,
                orgId,
            });
        })
        .map(subCategory => subCategory.value)
        .flatten()
        .value();

    return _(allSegments)
        .filter(segment => !segment.deprecated)
        .filter(segment => _.includes(segmentTypes, segment.source_type))
        .filter(segment => {
            if (!keywords) {
                return true;
            }
            const compareKeywords = _.toLower(keywords);
            return (
                _.includes(_.toLower(segment.id), compareKeywords) ||
                _.includes(_.toLower(segment.name), compareKeywords) ||
                _.includes(_.toLower(segment.description), compareKeywords)
            );
        })
        .value();
}

export const SOURCES = {
    devices: 'uniqueDevices',
    cookies: 'uniqueCookies',
};

const getSourceFromSegmentSupport = ({ segment }) => {
    if (_.includes(segment.supports, 'inapp') && !_.includes(segment.supports, 'mweb')) {
        return SOURCES.devices;
    } else if (!_.includes(segment.supports, 'inapp') && _.includes(segment.supports, 'mweb')) {
        return SOURCES.cookies;
    } else if (_.includes(segment.supports, 'inapp') && _.includes(segment.supports, 'mweb')) {
        return null;
    }
};

export const SUPPORTED_AUDIENCES_PLATFORMS_PERCENTAGES = {
    pds_brand: {
        getDominantSource: () => {
            return SOURCES.devices;
        },
        getSupportedPlatforms: () => {
            return ['inapp', 'mweb', 'desktop'];
        },
    },
    pds_standard: {
        getDominantSource: () => {
            return SOURCES.devices;
        },
        getSupportedPlatforms: () => {
            return ['inapp', 'mweb', 'desktop'];
        },
    },
    pds_behaviour: {
        getDominantSource: () => {
            return SOURCES.devices;
        },
        getSupportedPlatforms: () => {
            return ['inapp', 'mweb', 'desktop'];
        },
    },
    flex_standard_brand: {
        getDominantSource: () => {
            return SOURCES.devices;
        },
        getSupportedPlatforms: () => {
            return ['inapp'];
        },
    },
    flex_location_category: {
        getDominantSource: () => {
            return SOURCES.devices;
        },
        getSupportedPlatforms: () => {
            return ['inapp'];
        },
    },
    pds_intent: {
        getDominantSource: () => {
            return SOURCES.cookies;
        },
        getSupportedPlatforms: () => {
            return ['inapp', 'mweb', 'desktop'];
        },
    },
    pds_manifold: {
        getDominantSource: () => {
            return SOURCES.devices;
        },
        getSupportedPlatforms: () => {
            return ['inapp', 'mweb', 'desktop'];
        },
    },
    pds_environics: {
        getDominantSource: () => {
            return SOURCES.devices;
        },
        getSupportedPlatforms: () => {
            return ['inapp', 'mweb', 'desktop'];
        },
    },
    pds_mastercard: {
        getDominantSource: () => {
            return SOURCES.devices;
        },
        getSupportedPlatforms: () => {
            return ['inapp', 'mweb', 'desktop'];
        },
    },
    pds_age: {
        getDominantSource: () => {
            return SOURCES.devices;
        },
        getSupportedPlatforms: () => {
            return ['inapp', 'mweb', 'desktop'];
        },
    },
    pds_custom: {
        getDominantSource: () => {
            return SOURCES.devices;
        },
        getSupportedPlatforms: () => {
            return ['inapp', 'mweb', 'desktop'];
        },
    },
    flex_custom_brand: {
        getDominantSource: () => {
            return SOURCES.devices;
        },
        getSupportedPlatforms: () => {
            return ['inapp'];
        },
    },
    ad: {
        getDominantSource: ({ segment }) => {
            return getSourceFromSegmentSupport({ segment });
        },
        getSupportedPlatforms: ({ segment }) => {
            return segment.supports;
        },
    },
    campaign: {
        getDominantSource: ({ segment }) => {
            return getSourceFromSegmentSupport({ segment });
        },
        getSupportedPlatforms: ({ segment }) => {
            return segment.supports;
        },
    },
    pixel: {
        getDominantSource: () => {
            return SOURCES.cookies;
        },
        getSupportedPlatforms: () => {
            return ['mweb'];
        },
    },
    upload: {
        getDominantSource: ({ segment, isInternalUser }) => {
            if (!isInternalUser) {
                return SOURCES.devices;
            }
            return getSourceFromSegmentSupport({ segment });
        },
        getSupportedPlatforms: ({ segment }) => {
            return segment.supports;
        },
    },
    adobe: {
        getDominantSource: ({ segment }) => {
            return getSourceFromSegmentSupport({ segment });
        },
        getSupportedPlatforms: ({ segment }) => {
            return segment.supports;
        },
    },
    permutive: {
        getDominantSource: ({ segment }) => {
            return getSourceFromSegmentSupport({ segment });
        },
        getSupportedPlatforms: ({ segment }) => {
            return segment.supports;
        },
    },
    pds_mweb: {
        getDominantSource: () => {
            return SOURCES.cookies;
        },
        getSupportedPlatforms: () => {
            return ['mweb'];
        },
    },
};
