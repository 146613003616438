import _ from 'lodash';

const initialState = {
    isLoading: true,
    audienceSegments: [],
    sourceType: 'all',
    keywords: '',
    filteredAudienceSegments: [],
    tab: 'all',
};

export const NAME = 'audienceSegmentPickerModal';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'PICKER_SELECT_TABLE__AUDIENCES__FETCH_START': {
            return {
                ...state,
                isLoading: false,
                sourceType: 'all',
                tab: 'all',
                keywords: '',
            };
        }

        case 'PICKER_SELECT_TABLE__AUDIENCES__FETCH_END': {
            return {
                ...state,
                isLoading: false,
                audienceSegments: _.get(action.payload, 'data'),
                filteredAudienceSegments: _.get(action.payload, 'data'),
            };
        }

        case 'PICKER_SELECT_TABLE__AUDIENCES__FILTER_START': {
            return {
                ...state,
                keywords: _.get(action.payload, 'keywords'),
            };
        }

        case 'PICKER_SELECT_TABLE__AUDIENCES__FILTER_END': {
            return {
                ...state,
                filteredAudienceSegments: _.get(action.payload, 'filteredAudienceSegments'),
            };
        }

        case 'PICKER_SELECT_TABLE__AUDIENCES__TAB_CHANGE': {
            return {
                ...state,
                tab: action.payload.tab,
                sourceType: 'all',
                keywords: '',
            };
        }

        case 'PICKER_SELECT_TABLE__AUDIENCES__SOURCE_TYPE_CHANGE': {
            return {
                ...state,
                sourceType: action.payload.sourceType,
                keywords: '',
            };
        }

        default:
            return state;
    }
}
