import _ from 'lodash';
import folder from 'fold-to-ascii';

import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import { CREATIVES_VENDOR_TAGS, TRACKING_VENDOR_TAGS } from 'common/constants/vendors';
import { defaultSetting } from './services';
import { getExporter, getFormats, getSizes } from 'forms/creative-form/constants';
import { reducer } from './v2/reducer';
import { validateCreativeDraft } from './services';
import c from 'common/constants/flux-events';
import {
    getAllViablePlatforms,
    getPlatformsAvailableForCampaign,
    getThirdPartyVendors,
    POSITIONS,
} from './constants';

const isVendorGwdOrAdobe = exporter => {
    return ['gwd', 'adobe', 'html5'].indexOf(exporter) > -1;
};

const initialState = {
    draft: null,
    isDraftValid: false,
    errors: [],
    showErrors: false,
    isLoading: true,
    isSubmitting: false,
    isDirty: false,
    isFormOpen: false,
    name: '',
    isReferenceImageLoading: false,
    isZipUploadLoading: false,
    isEditConfirmationModalOpen: false,
    confirmMessage: '',
    isEditConfirmSaveAndAudit: false,
    creativeTagsMatched: [],
    trackingTagsMatched: [],
    showHTMLblock: false,
};

const SERVER_TO_CLIENT_MAPPING = {
    mraid: {
        image_preview_url: 'reference_image_preview_url',
        image_asset_preview_url: 'reference_image_asset_preview_url',
    },
    custom_html: {
        image_preview_url: 'reference_image_preview_url',
        image_asset_preview_url: 'reference_image_asset_preview_url',
    },
};
export const NAME = 'creativeForm';

function getDraftForNewFormat({ state, format }) {
    const nextDraft = {
        format,
        // reset draft to default values
        ...defaultSetting.defaultValues[format],
        // retain these fields when format changes
        platforms: state.draft.platforms,
        type: state.draft.type,
        placements: state.draft.placements,
        positions: state.draft.positions,
        size: state.draft.size,
        exporter: state.draft.exporter,
        creative_vendors: state.draft.creative_vendors,
        isCreativeVendorAutoDetected: state.draft.isCreativeVendorAutoDetected,
        pending_variants: state.draft.platforms.includes(CampaignTypeMapping.DOOH),
        audio_required: null,
    };

    if (format === 'native') {
        nextDraft.size = '0x0';
        nextDraft.sponsoredBy = state.draft.sponsoredBy;
    }

    return nextDraft;
}

function reducerOld(state = {}, action) {
    switch (action.type) {
        case c.CREATIVE_FORM__INIT_STATE: {
            return initialState;
        }
        case c.CREATIVE_FORM__OPEN: {
            return {
                ...state,
                isFormOpen: true,
                isLoading: true,
            };
        }

        case 'CREATIVE_FORM__BACK': {
            const overwrite = {};
            const campaignType = action.payload.campaignType;

            // select "ctv" for ctv and "dooh" for dooh campaigns
            if (campaignType !== CampaignTypeMapping.Standard) {
                overwrite.platforms = getPlatformsAvailableForCampaign(campaignType).map(
                    platform => platform.value
                );
            }

            return {
                ...state,
                draft: {
                    ...defaultSetting.defaultValues.initial,
                    ...overwrite,
                },
            };
        }

        case c.CREATIVE_FORM__INIT: {
            const creativeId = _.get(action, 'payload.creativeId');
            const isNew = !creativeId;
            const sessionDraft_current = _.get(state, `draft`, null);

            let sessionDraft_next = sessionDraft_current;
            if (sessionDraft_current === null) {
                // Only inititialize session's draft if there isn't already one.
                // This is to prevent router reruning when user navigates to edit creative form.
                const payloadCreative = _.get(action, 'payload.creative');
                sessionDraft_next = { ...payloadCreative };

                const thirdPartyVendors = _.get(sessionDraft_next, 'third_party_vendors');
                // Legacy creatives uses only third_party_vendors.
                // so we split the vendors into two new fields(creaive/tracking)
                if (thirdPartyVendors) {
                    const { creative_vendors, tracking_vendors } = seperateVendors(
                        thirdPartyVendors
                    );
                    sessionDraft_next.creative_vendors = _.uniq([
                        ...sessionDraft_next.creative_vendors,
                        ...creative_vendors,
                    ]);
                    sessionDraft_next.tracking_vendors = _.uniq([
                        ...sessionDraft_next.tracking_vendors,
                        ...tracking_vendors,
                    ]);
                }
                const {
                    creativeCustomVendors,
                    trackingCustomVendors,
                } = filterCustomisedVendorToOptions(
                    sessionDraft_next.creative_vendors,
                    sessionDraft_next.tracking_vendors
                );

                sessionDraft_next.creativeVendorsOptions = creativeCustomVendors;
                sessionDraft_next.trackingVendorsOptions = trackingCustomVendors;
            }

            if (isNew) {
                const location = action.payload.location;
                let exporter =
                    location && location.query && location.query.exporter
                        ? location.query.exporter
                        : defaultSetting.defaultValues.initial.exporter;
                if (exporter === '') {
                    let searchParams = new URLSearchParams(location.search);
                    if (searchParams.has('exporter')) {
                        exporter = searchParams.get('exporter');
                    }
                }

                const isCreativeVendorAutoDetected = !isVendorGwdOrAdobe(exporter);
                const creative_vendors = isVendorGwdOrAdobe(exporter)
                    ? getExporter(exporter).vendors
                    : [];

                sessionDraft_next.exporter = exporter;
                sessionDraft_next.isCreativeVendorAutoDetected = isCreativeVendorAutoDetected;
                sessionDraft_next.creative_vendors = creative_vendors;

                const { advertiser } = action.payload;
                if (!sessionDraft_next.sponsoredBy || sessionDraft_next.sponsoredBy === '') {
                    sessionDraft_next.sponsoredBy = advertiser;
                }
            }

            const errors = validateCreativeDraft({ ...sessionDraft_next });

            return {
                ...state,
                creativeId: action.payload.creativeId,
                errors,
                isDraftValid: errors.length === 0,
                draft: sessionDraft_next,
                isLoading: false,
                isDirty: false,
                campaignType: action.payload.campaignType,
            };
        }

        case c.CREATIVE_FORM__UPDATE: {
            const { formData } = action.payload;
            let draft = {
                ...state.draft,
                ...formData,
            };

            const creativeTags = getSuggestedVendors({
                draft,
                selectedThirdPartyVendors: draft.creative_vendors,
                vendorTagsByType: CREATIVES_VENDOR_TAGS,
            });
            if (creativeTags.tagsSuggested.length > 0 && draft.isCreativeVendorAutoDetected) {
                const creativeVendorWithSuggestion = _.map(
                    creativeTags.tagsSuggested,
                    suggestion => {
                        return suggestion.value;
                    }
                );
                draft.creative_vendors = _.uniq(creativeVendorWithSuggestion);
            }

            const trackingTags = getSuggestedVendors({
                draft,
                selectedThirdPartyVendors: draft.third_party_vendors,
                vendorTagsByType: TRACKING_VENDOR_TAGS,
            });
            if (trackingTags.tagsSuggested.length > 0 && draft.isTrackingVendorsAutoDetected) {
                const trackingVendorsWithSuggestion = [...draft.tracking_vendors];
                _.forEach(trackingTags.tagsSuggested, suggestion => {
                    trackingVendorsWithSuggestion.push(suggestion.value);
                });
                draft.tracking_vendors = _.uniq(trackingVendorsWithSuggestion);
            }

            if (!draft.third_party_clickthrough) {
                draft.clickthrough_url = draft.landing_page;
            }

            if (draft.format === 'native') {
                draft.size = '0x0';
            }

            if (draft.name) {
                // Convert input into ascii equivalents
                draft.name = folder.fold(draft.name);
            }

            const errors = validateCreativeDraft(draft);

            return {
                ...state,
                creativeTagsMatched: creativeTags.tagsMatched,
                trackingTagsMatched: trackingTags.tagsMatched,
                draft,
                errors,
                isDraftValid: errors.length === 0,
            };
        }

        case 'CREATIVE_FORM__SHOW_ERRORS': {
            return {
                ...state,
                showErrors: true,
                isSubmitting: false,
                isLoading: false,
            };
        }

        case c.CREATIVE_FORM__SUBMIT_CANCEL: {
            return {
                ...state,
                isSubmitting: false,
            };
        }

        case c.CREATIVE_FORM__SUBMIT: {
            const errors = validateCreativeDraft(state.draft);
            return {
                ...state,
                isSubmitting: true,
                errors,
            };
        }
        case c.CREATIVE_FORM__SUBMIT_FAIL: {
            const errors = _.map(action.error, error => {
                const { format } = state.draft;
                const field = _.get(
                    SERVER_TO_CLIENT_MAPPING,
                    `${format}.${error.field}`,
                    error.field
                );

                return {
                    field,
                    message: error.message,
                };
            });

            return {
                ...state,
                errors,
                showErrors: true,
                isSubmitting: false,
            };
        }

        case c.CREATIVE_FORM__SUBMIT_SUCCESS: {
            return {
                ...state,
                isSubmitting: false,
            };
        }

        case c.CREATIVE_FORM__CLOSE: {
            return {
                ...initialState,
            };
        }

        case 'CREATIVE_FORM__NEXT_STEP': {
            let nextState = { ...state };

            const { creativePlatformRestrictions } = action.payload;

            const allViablePlatforms = getAllViablePlatforms({
                type: state.draft.type,
                size: state.draft.size,
                format: state.draft.format,
                placements: state.draft.placements,
                currentPlatforms: state.draft.platforms,
                campaignType: state.campaignType,
            }).filter(platform => {
                return !_.includes(creativePlatformRestrictions, platform);
            });

            const thirdPartyVendors = getThirdPartyVendors({
                exporter: state.draft.exporter,
                existingThirdPartyVendors: state.draft.third_party_vendors,
            });

            nextState = updateDraft(nextState, {
                platforms: allViablePlatforms,
                third_party_vendors: thirdPartyVendors,
            });

            return {
                ...nextState,
                showErrors: false,
            };
        }

        case 'CREATIVE_FORM__TOGGLE_PLATFORMS': {
            const { platform } = action.payload;
            const hasPlatform = _.includes(state.draft.platforms, platform);

            const lastPlatform = state.draft.platforms.length === 1;
            // always keep at least one platform selected
            if (hasPlatform && lastPlatform) {
                return state;
            }

            let platforms;
            if (hasPlatform) {
                platforms = _.filter(state.draft.platforms, p => p !== platform);
            } else {
                platforms = state.draft.platforms.concat(platform);
            }

            return updateDraft(state, {
                platforms,
                type: '',
                placements: [],
                positions: [],
                size: '',
                format: '',
            });
        }

        case 'CREATIVE_FORM__CHANGE_NAME': {
            const { name } = action.payload;

            return updateDraft(state, {
                name,
            });
        }

        case 'CREATIVE_FORM__CHANGE_TYPE': {
            const { type } = action.payload;

            const sizes = getSizes(state.draft.platforms, type, state.draft.placements);
            const size = sizes.length === 1 ? sizes[0].value : '';

            const formats = getFormats({
                platforms: state.draft.platforms,
                type,
                exporter: state.draft.exporter,
            });

            // If only 1 format is going to be available for this type, pre-select it
            if (formats.length === 1) {
                const format = formats[0].value;
                const draftForNewFormat = getDraftForNewFormat({ state, format });

                return updateDraft(state, {
                    ...draftForNewFormat,
                    type,
                    placements: [],
                    positions: [],
                    size,
                    format,
                });
            }

            return updateDraft(state, {
                type,
                placements: [],
                positions: [],
                size,
                format: '',
            });
        }

        case 'CREATIVE_FORM__TOGGLE_PLACEMENTS': {
            const { placement } = action.payload;
            const hasPlacement = _.includes(state.draft.placements, placement);

            let placements;
            if (hasPlacement) {
                placements = _.filter(state.draft.placements, p => p !== placement);
            } else {
                placements = state.draft.placements.concat(placement);
            }

            // Positions should be selected by default when Placement is In Stream
            let positions;
            if (placement === 'in_stream' && !hasPlacement) {
                positions = _.map(POSITIONS, p => p.value);
            } else if (!_.includes(placements, 'in_stream')) {
                positions = [];
            } else {
                positions = state.draft.positions;
            }

            // At least one placement must be selected, don't allow removing
            if (placements.length === 0) {
                return state;
            }

            const sizes = getSizes(state.draft.platforms, state.draft.type, placements);

            // If only 1 size is going to be available for the new placement, pre-select it
            if (sizes.length === 1) {
                return updateDraft(state, {
                    placements,
                    positions,
                    size: sizes[0].value,
                });
            }

            return updateDraft(state, {
                placements,
                positions,
            });
        }

        case 'CREATIVE_FORM__TOGGLE_POSITIONS': {
            const { position } = action.payload;
            const hasPosition = _.includes(state.draft.positions, position);

            let positions;
            if (hasPosition) {
                positions = _.filter(state.draft.positions, p => p !== position);
            } else {
                positions = state.draft.positions.concat(position);
            }

            return updateDraft(state, {
                positions,
            });
        }

        case 'CREATIVE_FORM__CHANGE_SIZE': {
            return updateDraft(state, {
                size: action.payload.size,
            });
        }

        case 'CREATIVE_FORM__CHANGE_ASPECT_RATIO': {
            return updateDraft(state, {
                aspect_ratio: action.payload.aspect_ratio,
            });
        }

        case 'CREATIVE_FORM__CHANGE_AUDIO': {
            return updateDraft(state, {
                audio_required: action.payload.audio_required,
                size: '',
            });
        }

        case 'CREATIVE_FORM__CHANGE_FORMAT': {
            const { format } = action.payload;

            const nextDraft = getDraftForNewFormat({ state, format });

            return updateDraft(state, nextDraft);
        }

        case 'CREATIVE_FORM__GET_REFERENCE_IMAGE_START': {
            return {
                ...state,
                isReferenceImageLoading: true,
            };
        }

        case 'CREATIVE_FORM__GET_REFERENCE_IMAGE_SUCCESS': {
            const { file } = action.payload;
            const newState = updateDraft(state, {
                reference_image_asset: file.id,
                reference_image_preview_url: file.preview_url,
                reference_image_url: file.url,
            });

            return {
                ...newState,
                isReferenceImageLoading: false,
            };
        }

        case 'CREATIVE_FORM__GET_REFERENCE_IMAGE_ERROR': {
            return {
                ...state,
                isReferenceImageLoading: false,
            };
        }

        case 'CREATIVE_FORM__UPLOAD_CREATIVE_ZIP_START': {
            return {
                ...state,
                isZipUploadLoading: true,
                showHTMLblock: true,
            };
        }

        case 'CREATIVE_FORM__CHANGE_CONTENT_HTML': {
            return updateDraft(state, {
                content_html: action.payload.markup,
                showHTMLblock: true,
            });
        }

        case 'CREATIVE_FORM__UPLOAD_CREATIVE_ZIP_SUCCESS': {
            return {
                ...state,
                isZipUploadLoading: false,
                showHTMLblock: true,
            };
        }

        case 'CREATIVE_FORM__UPLOAD_CREATIVE_ZIP_ERROR': {
            return {
                ...state,
                isZipUploadLoading: false,
            };
        }

        case 'CREATIVE_FORM__OPEN_CREATIVE_CONFIRMATION_MODAL': {
            return {
                ...state,
                isEditConfirmationModalOpen: true,
                confirmModalMessage: action.payload.confirmMessage,
                isEditConfirmSaveAndAudit: action.payload.isSaveAudit,
            };
        }

        case 'CREATIVE_FORM__CLOSE_CREATIVE_CONFIRMATION_MODAL': {
            return {
                ...state,
                isEditConfirmationModalOpen: false,
            };
        }

        case 'CREATIVE_FORM__CHANGE_ASSET_SELECTION_METHOD': {
            return updateDraft(state, {
                assetSelectionMethod: action.payload.method,
                reference_image_asset: '',
                reference_image_preview_url: '',
                reference_image_url: '',
            });
        }

        case 'CREATIVE_FORM__TOGGLE_CLICKTRACKER': {
            const { clicktracker } = action.payload;

            return updateDraft(state, {
                clickthrough_url: clicktracker === 'enabled' ? '' : state.draft.landing_page,
                third_party_clickthrough: clicktracker === 'enabled' ? true : false,
            });
        }

        default: {
            return state;
        }
    }
}

export default function reducerWrapper(state, action) {
    let nextState = reducerOld(state, action);
    nextState = reducer(nextState, action);
    return nextState;
}

function updateDraft(state, changes) {
    const nextDraft = {
        ...state.draft,
        ...changes,
    };

    const errors = validateCreativeDraft(nextDraft);

    return {
        ...state,
        draft: nextDraft,
        errors,
        isDraftValid: errors.length === 0,
    };
}

function getTrackingValues(draft) {
    return []
        .concat(
            draft.image_url,
            draft.video_url,
            draft.companion_url,
            draft.clickthrough_url,
            draft.content_html,
            draft.hosted_vast_url,
            draft.third_party_pixels,
            draft.third_party_javascript_urls,
            draft.icon_image_url,
            draft.main_image_url,
            draft.landing_page
        )
        .filter(x => x);
}

export function getSuggestedVendors({ draft, selectedThirdPartyVendors, vendorTagsByType }) {
    const trackingValues = getTrackingValues(draft);
    const tagsMatched = _(vendorTagsByType)
        // check if tag is present
        .filter(tag => _.some(trackingValues, content => tag.test(content)))
        .value();

    const tagsSuggested = _(tagsMatched)
        //remove vendors already selected
        .filter(tag => !_.includes(selectedThirdPartyVendors, tag.value))
        // check if tag is present
        .filter(tag => _.some(trackingValues, content => tag.test(content)))
        .value();

    return {
        tagsMatched,
        tagsSuggested,
    };
}

function seperateVendors(vendors) {
    const creative_vendors = [];
    const tracking_vendors = [];

    _.forEach(vendors, vendor => {
        _.forEach(CREATIVES_VENDOR_TAGS, creativeTag => {
            if (creativeTag.value === vendor) {
                creative_vendors.push(vendor);
            }
        });
    });

    _.forEach(vendors, vendor => {
        _.forEach(TRACKING_VENDOR_TAGS, trackingTag => {
            if (trackingTag.value === vendor) {
                tracking_vendors.push(vendor);
            }
        });
    });

    return { creative_vendors, tracking_vendors };
}

function filterCustomisedVendorToOptions(creativeVendors, trackingVendors) {
    const creativeBaseVendors = _.map(CREATIVES_VENDOR_TAGS, x => x.value);
    const creativeCustomVendors = _.difference(creativeVendors, creativeBaseVendors);

    const trackingBaseVendors = _.map(TRACKING_VENDOR_TAGS, x => x.value);
    const trackingCustomVendors = _.difference(trackingVendors, trackingBaseVendors);

    return { creativeCustomVendors, trackingCustomVendors };
}
