import _ from 'lodash';
import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';
import flags from 'containers/flags/service';
import moment from 'moment-timezone';
import { RevenueModelMapping } from 'states/resources/campaigns/business-logic';
import { getEnvironmentSettings } from 'services/environment';

const METRIC_CONFIG_WITH_SPEND_BREAKDOWN = [
    {
        metricType: 'impressions',
        style: 'impressions',
        category: 'basic',
        formatType: 'thousands',
        presentationName: 'Impressions',
        isCurrencyType: false,
        inceptionDate: null,
    },
    {
        metricType: 'clicks',
        style: 'clicks',
        category: 'basic',
        formatType: 'thousands',
        presentationName: 'Clicks',
        isCurrencyType: false,
        inceptionDate: null,
    },
    {
        metricType: 'ctr',
        style: 'ctr',
        category: 'basic',
        formatType: 'percentage',
        presentationName: 'CTR',
        isCurrencyType: false,
        inceptionDate: null,
    },
    {
        metricType: 'unique_users',
        style: 'unique_users',
        category: 'basic',
        formatType: 'thousands',
        presentationName: 'Unique Users',
        isCurrencyType: false,
        disableAsPrimaryMetric: true,
        inceptionDate: null,
    },
    {
        metricType: 'daily_uniq',
        style: 'daily_uniq',
        category: 'basic',
        formatType: 'thousands',
        presentationName: 'Average Daily Uniques',
        isCurrencyType: false,
        inceptionDate: null,
    },
    {
        metricType: 'average_freq',
        style: 'average_freq',
        category: 'basic',
        formatType: 'whole-fixed',
        presentationName: 'Average Daily Frequency',
        isCurrencyType: false,
        inceptionDate: null,
    },
    {
        metricType: 'owner_total_media_cost_local',
        style: 'owner_total_media_cost_local',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Total Cost',
        isCurrencyType: true,
        inceptionDate: null,
    },
    {
        metricType: 'owner_media_cost_2_local',
        style: 'owner_media_cost_2_local',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Media Cost',
        isCurrencyType: true,
        isSubCategory: true,
        inceptionDate: '2018-10-23T00:00:00.000Z',
    },
    {
        metricType: 'data_cost',
        style: 'data_cost',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Data Cost',
        isCurrencyType: true,
        isSubCategory: true,
        inceptionDate: '2018-10-23T00:00:00.000Z',
    },
    {
        metricType: 'third_party_fees',
        style: 'third_party_fees',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Third Party Fees',
        isCurrencyType: true,
        isSubCategory: true,
        inceptionDate: '2018-10-23T00:00:00.000Z',
    },
    {
        metricType: 'owner_total_media_cost_local_ecpm',
        style: 'owner_total_media_cost_local_ecpm',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Total Cost eCPM',
        isCurrencyType: true,
        inceptionDate: null,
    },
    {
        metricType: 'owner_total_media_cost_local_ecpc',
        style: 'owner_total_media_cost_local_ecpc',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Total Cost eCPC',
        isCurrencyType: true,
        inceptionDate: null,
    },
    {
        metricType: 'win_rate',
        style: 'win_rate',
        category: 'basic',
        formatType: 'percentage',
        presentationName: 'Win Rate',
        isCurrencyType: false,
        inceptionDate: null,
    },
    {
        metricType: 'revenue',
        style: 'revenue',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Billings',
        isCurrencyType: true,
        inceptionDate: null,
    },
    {
        metricType: 'erpm',
        style: 'erpm',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Billings eCPM',
        isCurrencyType: true,
        inceptionDate: null,
    },
    {
        metricType: 'erpc',
        style: 'erpc',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Billings eCPC',
        isCurrencyType: true,
        inceptionDate: null,
    },
];

const initialState = {
    currentSelectedMetricType: 'impressions',
    metricComponentsConfig: [],
    selectedMetrics: ['impressions', 'clicks', 'ctr', 'owner_total_media_cost_local', 'revenue'],
};

const getMetricConfig = ({ isValidDate }) => {
    const environmentSettings = getEnvironmentSettings();
    const canUseNewDimensions = environmentSettings.useNewReportingDimensions();
    let metricConfig = [...METRIC_CONFIG_WITH_SPEND_BREAKDOWN];

    if (canUseNewDimensions && isValidDate) {
        metricConfig.push({
            metricType: 'vcr',
            status: 'hidden',
            style: 'vcr',
            category: 'basic',
            formatType: 'percentage',
            presentationName: 'VCR',
            isCurrencyType: false,
            inceptionDate: null,
        });
        metricConfig.push({
            metricType: 'owner_total_media_cost_local_ecpcv',
            status: 'hidden',
            style: 'owner_total_media_cost_local_ecpcv',
            category: 'basic',
            formatType: 'dollar',
            presentationName: 'Total Cost eCPCV',
            isCurrencyType: true,
            inceptionDate: null,
        });
        metricConfig.push({
            metricType: 'revenue_ecpcv',
            status: 'hidden',
            style: 'revenue_ecpcv',
            category: 'basic',
            formatType: 'dollar',
            presentationName: 'Revenue eCPCV',
            isCurrencyType: true,
            inceptionDate: null,
        });
    }

    return metricConfig;
};

export const Transformer = {
    createConversionComponents(conversions, advertiserConversions, campaign) {
        if ([...conversions, ...advertiserConversions].length === 0) {
            return [];
        }
        let components = [];
        const isDisabledRevenueModel = campaign.revenueModel === RevenueModelMapping.Disabled;

        components = components.concat(
            [
                {
                    conversionName: 'overall',
                    isHeader: true,
                    metricType: 'conv_overall',
                    category: 'conversion',
                    presentationName: 'Overall Conversions',
                    isCurrencyType: false,
                    formatType: 'thousands',
                    status: 'hidden',
                    style: 'conv_overall',
                },
            ],
            flags.isEnabled('en_4598_new_report_conversion_columns')
                ? [
                      {
                          conversionName: 'overall',
                          metricType: 'conv_overall',
                          category: 'conversion',
                          presentationName: 'Total',
                          isCurrencyType: false,
                          isSubCategory: true,
                          formatType: 'thousands',
                          status: 'hidden',
                          style: 'conv_overall',
                      },
                  ]
                : [],
            [
                {
                    conversionName: 'overall',
                    metricType: 'conv_overall_imp',
                    category: 'conversion',
                    presentationName: 'View-through',
                    isCurrencyType: false,
                    isSubCategory: true,
                    formatType: 'thousands',
                    status: 'hidden',
                    style: 'conv_overall_imp',
                },
                {
                    conversionName: 'overall',
                    metricType: 'conv_overall_click',
                    category: 'conversion',
                    presentationName: 'Click-through',
                    isCurrencyType: false,
                    isSubCategory: true,
                    formatType: 'thousands',
                    status: 'hidden',
                    style: 'conv_overall_click',
                },
                {
                    conversionName: 'overall',
                    metricType: 'conv_overall_total_cost_ecpa',
                    category: 'conversion',
                    presentationName: 'Total Cost eCPA',
                    isCurrencyType: true,
                    isSubCategory: true,
                    formatType: 'dollar',
                    status: 'hidden',
                    style: 'conv_overall_total_cost_ecpa',
                },
            ]
        );

        if (!isDisabledRevenueModel) {
            components.push({
                conversionName: 'overall',
                metricType: 'conv_overall_revenue_ecpa',
                category: 'conversion',
                presentationName: 'Revenue eCPA',
                isCurrencyType: true,
                isSubCategory: true,
                formatType: 'dollar',
                status: 'hidden',
                style: 'conv_overall_revenue_ecpa',
            });
        }

        _.each(advertiserConversions, conversion => {
            components = components.concat(
                [
                    {
                        conversionName: conversion.event_name,
                        metricType: `conv_total_adv_${conversion.event_name}`,
                        category: 'advertiser_conversion',
                        isHeader: true,
                        presentationName: conversion.reporting_name,
                        isCurrencyType: false,
                        formatType: 'thousands',
                        status: 'hidden',
                        style: `conv_total_adv_${conversion.event_name}`,
                    },
                ],
                flags.isEnabled('en_4598_new_report_conversion_columns')
                    ? [
                          {
                              conversionName: conversion.event_name,
                              metricType: `conv_total_adv_${conversion.event_name}`,
                              category: 'advertiser_conversion',
                              presentationName: 'Total',
                              isCurrencyType: false,
                              isSubCategory: true,
                              formatType: 'thousands',
                              status: 'hidden',
                              style: `conv_total_adv_${conversion.event_name}`,
                          },
                      ]
                    : [],
                [
                    {
                        conversionName: conversion.event_name,
                        metricType: `conv_total_imp_adv_${conversion.event_name}`,
                        category: 'advertiser_conversion',
                        presentationName: 'View-through',
                        isCurrencyType: false,
                        isSubCategory: true,
                        formatType: 'thousands',
                        status: 'hidden',
                        style: `conv_total_imp_adv_${conversion.event_name}`,
                    },
                    {
                        conversionName: conversion.event_name,
                        metricType: `conv_total_click_adv_${conversion.event_name}`,
                        category: 'advertiser_conversion',
                        presentationName: 'Click-through',
                        isCurrencyType: false,
                        isSubCategory: true,
                        formatType: 'thousands',
                        status: 'hidden',
                        style: `conv_total_click_adv_${conversion.event_name}`,
                    },
                    {
                        conversionName: conversion.event_name,
                        metricType: `conv_total_cost_ecpa_adv_${conversion.event_name}`,
                        category: 'advertiser_conversion',
                        presentationName: 'Total Cost eCPA',
                        isCurrencyType: true,
                        isSubCategory: true,
                        formatType: 'dollar',
                        status: 'hidden',
                        style: `conv_total_cost_ecpa_adv_${conversion.event_name}`,
                    },
                ]
            );

            if (!isDisabledRevenueModel) {
                components.push({
                    conversionName: conversion.event_name,
                    metricType: `conv_revenue_ecpa_adv_${conversion.event_name}`,
                    category: 'advertiser_conversion',
                    presentationName: 'Revenue eCPA',
                    isCurrencyType: true,
                    isSubCategory: true,
                    formatType: 'dollar',
                    status: 'hidden',
                    style: `conv_revenue_ecpa_adv_${conversion.event_name}`,
                });
            }

            if (conversion.dynamic_data) {
                // For the dynamic data we have the count and the event value.
                // We should remove the event value or we would get duplicate options.
                const nonEventValueDynamicData = conversion.dynamic_data
                    .filter(dynamicData => dynamicData.indexOf('conv_value') !== 0)
                    .sort();
                const dynamicData = {
                    conversionName: conversion.event_name,
                    category: 'advertiser_conversion',
                    presentationName: 'Event Parameters',
                    isCurrencyType: false,
                    isMultiSelect: true,
                    isSubCategory: true,
                    status: 'hidden',
                    style: `conv_${conversion.event_name}`,
                    options: nonEventValueDynamicData.map(dynamicData => ({
                        value: dynamicData,
                        label: dynamicData.split('|')[1] || 'Unknown',
                    })),
                };
                components = components.concat(
                    [
                        {
                            conversionName: conversion.event_name,
                            metricType: `conv_total_value_adv_${conversion.event_name}`,
                            category: 'advertiser_conversion',
                            presentationName: `Event Value`,
                            isCurrencyType: false,
                            isSubCategory: true,
                            formatType: 'dollar',
                            status: 'hidden',
                            style: `conv_${conversion.event_name}`,
                        },
                    ],
                    flags.isEnabled('en_4598_new_report_conversion_columns') ? dynamicData : []
                );
            }
        });

        _.each(conversions, conversion => {
            components = components.concat(
                [
                    {
                        conversionName: conversion.event_name,
                        isHeader: true,
                        metricType: `conv_total_${conversion.event_name}`,
                        category: 'conversion',
                        presentationName: conversion.reporting_name,
                        isCurrencyType: false,
                        formatType: 'thousands',
                        status: 'hidden',
                        style: `conv_total_${conversion.event_name}`,
                    },
                ],
                flags.isEnabled('en_4598_new_report_conversion_columns')
                    ? [
                          {
                              conversionName: conversion.event_name,
                              metricType: `conv_total_${conversion.event_name}`,
                              category: 'conversion',
                              presentationName: 'Total',
                              isCurrencyType: false,
                              isSubCategory: true,
                              formatType: 'thousands',
                              status: 'hidden',
                              style: `conv_total_${conversion.event_name}`,
                          },
                      ]
                    : [],
                [
                    {
                        conversionName: conversion.event_name,
                        metricType: `conv_total_imp_${conversion.event_name}`,
                        category: 'conversion',
                        presentationName: 'View-through',
                        isCurrencyType: false,
                        isSubCategory: true,
                        formatType: 'thousands',
                        status: 'hidden',
                        style: `conv_total_imp_${conversion.event_name}`,
                    },
                    {
                        conversionName: conversion.event_name,
                        metricType: `conv_total_click_${conversion.event_name}`,
                        category: 'conversion',
                        presentationName: 'Click-through',
                        isCurrencyType: false,
                        isSubCategory: true,
                        formatType: 'thousands',
                        status: 'hidden',
                        style: `conv_total_click_${conversion.event_name}`,
                    },
                    {
                        conversionName: conversion.event_name,
                        metricType: `conv_total_cost_ecpa_${conversion.event_name}`,
                        category: 'conversion',
                        presentationName: 'Total Cost eCPA',
                        isCurrencyType: true,
                        isSubCategory: true,
                        formatType: 'dollar',
                        status: 'hidden',
                        style: `conv_total_cost_ecpa_${conversion.event_name}`,
                    },
                ]
            );

            if (!isDisabledRevenueModel) {
                components.push({
                    conversionName: conversion.event_name,
                    metricType: `conv_revenue_ecpa_${conversion.event_name}`,
                    category: 'conversion',
                    presentationName: 'Revenue eCPA',
                    isCurrencyType: true,
                    isSubCategory: true,
                    formatType: 'dollar',
                    status: 'hidden',
                    style: `conv_revenue_ecpa_${conversion.event_name}`,
                });
            }

            if (conversion.dynamic_data) {
                // For the dynamic data we have the count and the event value.
                // We should remove the event value or we would get duplicate options.
                const nonEventValueDynamicData = conversion.dynamic_data
                    .filter(dynamicData => dynamicData.indexOf('conv_value') !== 0)
                    .sort();
                const dynamicData = {
                    conversionName: conversion.event_name,
                    metricType: `conv_${conversion.event_name}`,
                    category: 'conversion',
                    presentationName: 'Event Parameters',
                    isCurrencyType: false,
                    isMultiSelect: true,
                    isSubCategory: true,
                    status: 'hidden',
                    style: `conv_${conversion.event_name}`,
                    options: nonEventValueDynamicData.map(dynamicData => ({
                        value: dynamicData,
                        label: dynamicData.split('|')[1] || 'Unknown',
                    })),
                };

                components = components.concat(
                    [
                        {
                            conversionName: conversion.event_name,
                            metricType: `conv_total_value_${conversion.event_name}`,
                            category: 'conversion',
                            presentationName: `Event Value`,
                            isCurrencyType: false,
                            isSubCategory: true,
                            formatType: 'dollar',
                            status: 'hidden',
                            style: `conv_${conversion.event_name}`,
                        },
                    ],
                    flags.isEnabled('en_4598_new_report_conversion_columns') ? dynamicData : []
                );
            }
        });

        return components;
    },
    createBeaconComponents(beacons) {
        const filteredBeacons = _.filter(beacons, beacon => !beacon.hide);
        if (filteredBeacons.length < 2) {
            return _(filteredBeacons)
                .map(beacon => {
                    return {
                        metricType: 'event_' + beacon.name,
                        status: 'hidden',
                        category: 'beacon',
                        presentationName: beacon.label || beacon.name,
                        isCurrencyType: false,
                        formatType: 'thousands',
                        style: 'beacon1',
                    };
                })
                .value();
        }

        return _(filteredBeacons)
            .map(beacon => {
                return {
                    metricType: 'event_' + beacon.name,
                    status: 'hidden',
                    category: 'beacon',
                    presentationName: beacon.label || beacon.name,
                    isCurrencyType: false,
                    formatType: 'thousands',
                    style: 'beacon1',
                    defaultCellValue: 0,
                };
            })
            .reverse()
            .push({
                metricType: flags.isEnabled('efb_82_separate_video_metrics')
                    ? 'overall_engagements_without_video'
                    : 'overall_engagements',
                status: 'hidden',
                category: 'beacon',
                presentationName: 'Overall Engagements',
                isCurrencyType: false,
                formatType: 'thousands',
                style: 'beacon1',
            })
            .reverse()
            .value();
    },
};

export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            if (state.initialized) {
                return state;
            }

            const { campaignId } = action.payload;

            return findCacheState(campaignId, state, initialState);
        }
        case c.CAMPAIGN_REPORT__INITIALIZE: {
            if (state.initialized) {
                return state;
            }

            const { campaign, isClient, isClientReportPage } = action.payload;

            const beacons = _.get(action, 'payload.beacons', []);

            const beaconMetricComponents = Transformer.createBeaconComponents(beacons);

            const sharingSettings = campaign.sharing_settings.metrics;

            const isStartDateBeforeApril1st2023 = moment(campaign.start)
                .tz(campaign.default_timezone)
                .isBefore(moment('2023-04-01').tz(campaign.default_timezone));

            const metricConfig = getMetricConfig({ isValidDate: !isStartDateBeforeApril1st2023 });

            let metricComponentsConfigWithBeacons = metricConfig
                .map(renameBillingsToRevenueOrSpend(isClientReportPage))
                .filter(shouldShowMediaCost(campaign.isMediaCostMetricEnabled))
                .filter(config => {
                    if (config.metricType === 'unique_users') {
                        const isUniqueUsersEnabled = campaign.isUniqueUsersDisabled !== true;

                        return isUniqueUsersEnabled;
                    }

                    return true;
                })
                .filter(config => {
                    if (_.includes(['revenue', 'erpm', 'erpc'], config.metricType)) {
                        return campaign.billing_enabled;
                    }

                    return true;
                })
                .filter(config => {
                    if (!(isClient || isClientReportPage)) {
                        return true;
                    }

                    // This is where we enforce campaign sharing settings on metrics.
                    // We do the same for dimensions in the dimension selector
                    const sharingConfig = _.find(sharingSettings, setting => {
                        return setting.name === config.metricType;
                    });

                    if (!sharingConfig) {
                        return false;
                    }

                    return sharingConfig.shared;
                })
                .concat(beaconMetricComponents);

            const conversions = _.get(action, 'payload.conversions', []);
            const advertiserConversions = _.get(action, 'payload.advertiserConversions', []);

            const conversionMetricComponents = Transformer.createConversionComponents(
                conversions,
                advertiserConversions,
                campaign
            );

            const metricComponentsConfig = metricComponentsConfigWithBeacons.concat(
                conversionMetricComponents
            );

            return {
                ...initialState,
                metricComponentsConfig,
            };
        }

        case c.CAMPAIGN_REPORT__METRIC_SELECTOR__TOGGLE_VISIBILITY: {
            const { selectedMetrics } = state;
            const { metric } = action.payload;

            const metricIndex = selectedMetrics.indexOf(metric.metricType);
            const isMetricSelected = metricIndex !== -1;

            let newSelectedMetrics = selectedMetrics.slice(0);
            if (isMetricSelected) {
                newSelectedMetrics.splice(metricIndex, 1);
            } else {
                newSelectedMetrics.push(metric.metricType);
            }

            if (newSelectedMetrics.length === 0) {
                // Don't do anything as this is the last selected metric.
                // We need at least one metric showing.
                return state;
            }

            return {
                ...state,
                selectedMetrics: newSelectedMetrics,
            };
        }

        case c.CAMPAIGN_REPORT__METRIC_SELECTOR__TOGGLE_SELECTION: {
            const { metricComponentsConfig, selectedMetrics } = state;
            const { metric } = action.payload;

            const targetMetric = _.find(metricComponentsConfig, {
                metricType: metric.metricType,
                presentationName: metric.presentationName,
            });
            const metricIndex = selectedMetrics.indexOf(metric.metricType);
            const isMetricSelected = metricIndex !== -1;

            let newSelectedMetrics = selectedMetrics.slice(0);
            if (!isMetricSelected) {
                newSelectedMetrics.push(metric.metricType);
            }

            return {
                ...state,
                selectedMetrics: newSelectedMetrics,
                currentSelectedMetricType: targetMetric.metricType,
            };
        }

        default:
            return state;
    }
}

function shouldShowMediaCost(isMediaCostMetricEnabled) {
    return config => {
        if (config.metricType !== 'owner_media_cost_2_local') {
            return true;
        }

        if (isMediaCostMetricEnabled) {
            return true;
        } else {
            return false;
        }
    };
}

function renameBillingsToRevenueOrSpend(isClient) {
    return metricConfig => {
        const clientChanges = {
            revenue: {
                presentationName: 'Spend',
            },
            erpm: {
                presentationName: 'Spend eCPM',
            },
            erpc: {
                presentationName: 'Spend eCPC',
            },
        };

        const ownerChanges = {
            revenue: {
                presentationName: 'Revenue',
            },
            erpm: {
                presentationName: 'Revenue eCPM',
            },
            erpc: {
                presentationName: 'Revenue eCPC',
            },
        };

        // ignore non-billings metrics
        if (!clientChanges[metricConfig.metricType]) {
            return metricConfig;
        }

        // apply changes
        if (isClient) {
            return {
                ...metricConfig,
                ...clientChanges[metricConfig.metricType],
            };
        } else {
            return {
                ...metricConfig,
                ...ownerChanges[metricConfig.metricType],
            };
        }
    };
}
