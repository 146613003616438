import _ from 'lodash';
import React, { useState } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import VirtualList from 'react-tiny-virtual-list';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';
import numeral from 'numeral';
import { getEnvironmentSettings } from 'services/environment';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import LoadGroup from 'widgets-v5/load-group';
import Card from 'widgets-v5/card';
import AccordionItem from 'widgets-v5/expansion-panel-item';
import { SimpleSelect, MultiSelect, SingleSelect } from 'widgets-v6/select';
import { TextField, SearchInput } from 'widgets-v6/standard-input';

import flags from 'containers/flags/service';

function Layout(props) {
    const [expandedPanel, setExpand] = useState('');
    const [searchAds, setSearchAds] = useState('');
    const [searchCampaigns, setSearchCampaigns] = useState('');
    const [showDoohMixedCampaignsMsg, setshowDoohMixedCampaignsMsg] = useState(false);

    const selections = {};
    _.each(props.draft.source_id, itemValue => (selections[itemValue] = true));

    const selectedAds = {};
    _.forEach(props.draft.source_id, selectedId => {
        const foundOption = _.find(props.adOptions, option => option.value === selectedId);
        selectedAds[selectedId] = foundOption;
    });

    const selectedCampaigns = {};
    _.forEach(props.draft.source_id, selectedId => {
        const foundOption = _.find(props.campaignOptions, option => option.value === selectedId);
        selectedCampaigns[selectedId] = foundOption;
    });

    /**
     * Checks if the selected campaigns are incompatible
     * DOOH campaigns cannot be combined with other campaigns
     * @param {Object} param
     * @param {String} param.source_type - the source_type of the selected item
     * @param {Array} param.newSource - the array of already selected items
     * @returns {Boolean} - true if the selected campaigns are incompatible
     */
    const checkIncompatibleCampaigns = ({ source_type, newSource }) => {
        if (!flags.isEnabled('efb_195_warn_dooh_retargeting')) {
            return;
        }

        let showDoohMessage = false;
        let checkForDooh = false;
        const campaignAdOptionsKeys = {
            ad: 'adOptions',
            campaign: 'campaignOptions',
        };
        if (source_type === 'campaign' || source_type === 'ad') {
            checkForDooh = true;
        }

        if (newSource.length > 1 && checkForDooh) {
            const campaignAdOptions = props[campaignAdOptionsKeys[source_type]];

            let campaignType = '';
            const doohCampaingType = CampaignTypeMapping.DOOH;

            _.forEach(newSource, selectedId => {
                const foundOption = _.find(
                    campaignAdOptions,
                    option => option.value === selectedId
                );

                if (campaignType === '') {
                    campaignType = foundOption.campaign_type;
                } else if (
                    campaignType !== foundOption.campaign_type &&
                    (foundOption.campaign_type === doohCampaingType ||
                        campaignType === doohCampaingType)
                ) {
                    showDoohMessage = true;
                    return false;
                }
            });
        }
        setshowDoohMixedCampaignsMsg(showDoohMessage);
    };
    /**
     *
     * @param {String} sourceId can be either campaign or ad id
     */
    const handleChange = sourceId => {
        const { draft } = props;
        const { source_type } = draft;

        const { source_id } = draft;
        let newSource = [];

        const isAlreadySelected = _.includes(source_id, sourceId);

        if (isAlreadySelected) {
            newSource = _.filter(source_id, value => value !== sourceId);
        } else {
            newSource = source_id.concat(sourceId);
        }

        checkIncompatibleCampaigns({ source_type, newSource });

        props.onSourceChange(newSource);
    };

    const handleRemove = campaignId => {
        const { draft } = props;
        const { source_id } = draft;

        let newSource = _.filter(source_id, value => value !== campaignId);
        setshowDoohMixedCampaignsMsg(false);
        props.onSourceChange(newSource);
    };

    const expandPanel = panel => {
        expandedPanel === panel ? setExpand('') : setExpand(panel);
    };

    const handleSourceChange = value => {
        props.onSourceTypeChange(value);
        if (value !== 'pixel') {
            props.onAdvertiserChange('');
            props.onEventNameListChange([]);
            setExpand('');
            setSearchAds('');
            setSearchCampaigns('');
        }
        setshowDoohMixedCampaignsMsg(false);
    };

    const handleRetargetingChange = value => {
        props.onRetargetingChange(value);

        if (value === 'pixel') {
            handleSourceChange('pixel');
        } else {
            handleSourceChange('campaign');
        }
    };

    const handleAdvertiserChange = value => {
        props.onAdvertiserChange(value);
        props.onEventNameListChange([]);
    };

    const handleSearchAds = _.debounce(text => {
        setSearchAds(_.trim(text));
    }, 200);

    const handleSearchCampaigns = _.debounce(text => {
        setSearchCampaigns(_.trim(text));
    }, 200);

    const adsFiltered = _.filter(props.adOptions, ad => {
        if (!searchAds) {
            return true;
        }

        if (ad.label.toLowerCase().indexOf(searchAds.toLowerCase()) > -1) {
            return true;
        }

        return false;
    });

    const campaignsFiltered = _.filter(props.campaignOptions, campaign => {
        if (!searchCampaigns) {
            return true;
        }

        if (campaign.label.toLowerCase().indexOf(searchCampaigns.toLowerCase()) > -1) {
            return true;
        }

        return false;
    });

    return (
        <LoadGroup isLoading={props.isLoading}>
            <Grid container direction="column">
                <Grid item xs={6}>
                    <Box mb={2}>
                        <TextField
                            required
                            error={props.showErrors && props.errors.name}
                            label="Name"
                            value={props.draft.name}
                            onChange={e => props.onNameChange(e.target.value)}
                            disabled={props.isEditing && props.draft.upload_origin === 'environics'}
                            fullWidth
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <Box mb={2}>
                        <FormControl
                            component="fieldset"
                            error={props.showErrors && props.errors.retargeting_type}
                            disabled={props.isEditing}
                        >
                            <FormLabel component="legend">Event</FormLabel>
                            <RadioGroup
                                aria-label="gender"
                                name="gender1"
                                value={props.draft.retargeting_type}
                                onChange={e => handleRetargetingChange(e.target.value)}
                            >
                                {_.map(props.retargetingOptions, option => (
                                    <FormControlLabel
                                        value={option.value}
                                        control={<Radio color="primary" />}
                                        label={option.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item>
                    <Box mb={2}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Source</FormLabel>
                            <RadioGroup
                                aria-label="source"
                                name="source"
                                value={props.draft.source_type}
                                onChange={e => {
                                    handleSourceChange(e.target.value);
                                }}
                            >
                                {props.draft.retargeting_type === 'pixel' ? (
                                    <FormControlLabel
                                        disabled={props.isEditing}
                                        value="pixel"
                                        control={<Radio color="primary" />}
                                        label="Advertiser Pixel"
                                    />
                                ) : (
                                    _.map(props.sourceTypeOptions, option => (
                                        <FormControlLabel
                                            disabled={props.isEditing}
                                            value={option.value}
                                            control={<Radio color="primary" />}
                                            label={option.label}
                                        />
                                    ))
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Grid>
                {props.draft.source_type === 'ad' && (
                    <Grid item xs={12}>
                        <Box mb={2}>
                            <AccordionItem
                                readOnly={props.isEditing}
                                onClick={() => expandPanel('ad')}
                                expanded={expandedPanel === 'ad'}
                                title="Ads"
                                required
                                error={props.showErrors && props.errors.source_id}
                                details={
                                    <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                            <Box mx={1.5} mb={1}>
                                                <SearchInput
                                                    placeholder="Filter ads"
                                                    onChange={e => handleSearchAds(e.target.value)}
                                                    defaultValue={searchAds}
                                                />
                                            </Box>
                                            <VirtualList
                                                width="100%"
                                                height={300}
                                                itemCount={adsFiltered.length}
                                                itemSize={50}
                                                renderItem={({ index, style }) => {
                                                    const ad = adsFiltered[index];
                                                    return (
                                                        <div
                                                            style={style}
                                                            className="ef6-alignment__space-between reports-editor__campaign-list-item"
                                                            key={ad.value}
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={
                                                                            !!selections[ad.value]
                                                                        }
                                                                        onChange={() =>
                                                                            handleChange(ad.value)
                                                                        }
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={`#${ad.label}`}
                                                            />
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} mb={1}>
                                            <Typography variant="subtitle1">Selected</Typography>
                                            {showDoohMixedCampaignsMsg && (
                                                <Alert severity="info" variant="filled">
                                                    Due to technical limitations, retargeting
                                                    audiences created from DOOH campaigns cannot be
                                                    combined audiences from other platforms. Once
                                                    processed, two audiences will be created: -
                                                    "Name (DOOH)" - "Name (other platforms)"
                                                </Alert>
                                            )}
                                            <Box maxHeight={300} overflow="auto">
                                                {_.map(props.draft.source_id, ad => (
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        width="100%"
                                                        pb={1}
                                                        alignItems="center"
                                                    >
                                                        <Typography key={selectedAds[ad].value}>
                                                            {`#${selectedAds[ad].label}`}
                                                        </Typography>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleRemove(selectedAds[ad].value)
                                                            }
                                                            size="large"
                                                        >
                                                            <CancelIcon />
                                                        </IconButton>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                summary={
                                    props.draft.source_id.length > 0 ? (
                                        _.map(props.draft.source_id, ad => (
                                            <Typography key={selectedAds[ad].value}>
                                                {`#${selectedAds[ad].label}`}
                                            </Typography>
                                        ))
                                    ) : (
                                        <Typography color="textSecondary">Select ad(s)</Typography>
                                    )
                                }
                            />
                        </Box>
                    </Grid>
                )}
                {props.draft.source_type === 'campaign' && (
                    <Grid item xs={12}>
                        <Box mb={2}>
                            <AccordionItem
                                readOnly={props.isEditing}
                                onClick={() => expandPanel('campaign')}
                                expanded={expandedPanel === 'campaign'}
                                title="Campaigns"
                                required
                                error={props.showErrors && props.errors.source_id}
                                details={
                                    <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                            <Box mx={1.5} mb={1}>
                                                <SearchInput
                                                    placeholder="Filter campaigns"
                                                    onChange={e =>
                                                        handleSearchCampaigns(e.target.value)
                                                    }
                                                    defaultValue={searchCampaigns}
                                                />
                                            </Box>
                                            <VirtualList
                                                width="100%"
                                                height={300}
                                                itemCount={campaignsFiltered.length}
                                                itemSize={50}
                                                renderItem={({ index, style }) => {
                                                    const campaign = campaignsFiltered[index];
                                                    return (
                                                        <div
                                                            style={style}
                                                            className="ef6-alignment__space-between reports-editor__campaign-list-item"
                                                            key={campaign.value}
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={
                                                                            !!selections[
                                                                                campaign.value
                                                                            ]
                                                                        }
                                                                        onChange={() =>
                                                                            handleChange(
                                                                                campaign.value
                                                                            )
                                                                        }
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={`#${campaign.label}`}
                                                            />
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box mb={1}>
                                                <Typography variant="subtitle1">
                                                    Selected
                                                </Typography>
                                                {showDoohMixedCampaignsMsg && (
                                                    <Alert severity="info">
                                                        Due to technical limitations, retargeting
                                                        audiences created from DOOH campaigns cannot
                                                        be combined audiences from other platforms.
                                                        Once processed, two audiences will be
                                                        created:
                                                        <br />
                                                        - "Name (DOOH)" <br />- "Name (other
                                                        platforms)"
                                                    </Alert>
                                                )}
                                            </Box>
                                            <Box maxHeight={300} overflow="auto">
                                                {_.map(props.draft.source_id, campaign => (
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        width="100%"
                                                        pb={1}
                                                        alignItems="center"
                                                    >
                                                        <Typography
                                                            key={selectedCampaigns[campaign].value}
                                                        >
                                                            {`#${
                                                                selectedCampaigns[campaign].label
                                                            }`}
                                                        </Typography>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleRemove(
                                                                    selectedCampaigns[campaign]
                                                                        .value
                                                                )
                                                            }
                                                            size="large"
                                                        >
                                                            <CancelIcon />
                                                        </IconButton>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                summary={
                                    props.draft.source_id.length > 0 ? (
                                        _.map(props.draft.source_id, campaign => (
                                            <Typography key={selectedCampaigns[campaign].value}>
                                                {`#${selectedCampaigns[campaign].label}`}
                                            </Typography>
                                        ))
                                    ) : (
                                        <Typography color="textSecondary">
                                            Select campaign(s)
                                        </Typography>
                                    )
                                }
                            />
                        </Box>
                    </Grid>
                )}
                {props.draft.source_type === 'pixel' && (
                    <React.Fragment>
                        <Grid item xs={6}>
                            <Box mb={2}>
                                <SingleSelect
                                    required
                                    placeholder="Select Advertiser"
                                    label="Advertiser"
                                    value={props.draft.advertiser_id}
                                    onChange={value => {
                                        handleAdvertiserChange(value);
                                    }}
                                    options={props.advertiserOptions}
                                    disabled={props.isEditing}
                                    error={props.showErrors && props.errors.advertiser_id}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box mb={2}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    error={props.showErrors && props.errors.event_name_list}
                                >
                                    <FormLabel required component="legend">
                                        {' '}
                                        Target Pixels{' '}
                                    </FormLabel>
                                    <MultiSelect
                                        placeholder={
                                            props.draft.event_name_list.length === 0 ||
                                            !props.draft.event_name_list
                                                ? 'Select target pixel'
                                                : ''
                                        }
                                        clearable
                                        error={props.showErrors && props.errors.event_name_list}
                                        value={props.draft.event_name_list}
                                        options={props.conversionOptions}
                                        onChange={props.onEventNameListChange}
                                        disabled={props.isEditing}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                    </React.Fragment>
                )}
                {props.draft.retargeting_type === 'conversion' && (
                    <Grid item xs={5}>
                        <Box mb={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                error={props.showErrors && props.errors.event_name_list}
                            >
                                <FormLabel required component="legend">
                                    {' '}
                                    Target Engagements{' '}
                                </FormLabel>
                                <MultiSelect
                                    placeholder={
                                        props.draft.event_name_list.length === 0 ||
                                        !props.draft.event_name_list
                                            ? 'Select engagements'
                                            : ''
                                    }
                                    clearable
                                    error={props.showErrors && props.errors.event_name_list}
                                    value={props.draft.event_name_list}
                                    options={props.eventOptions}
                                    onChange={props.onEventNameListChange}
                                    disabled={props.isEditing}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                )}
                {props.draft.retargeting_type === 'attribution' && (
                    <Grid item xs={5}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            error={props.showErrors && props.errors.event_name_list}
                        >
                            <FormLabel required component="legend">
                                {' '}
                                Target Pixels{' '}
                            </FormLabel>
                            <MultiSelect
                                placeholder={
                                    props.draft.event_name_list.length === 0 ||
                                    !props.draft.event_name_list
                                        ? 'Select attribution'
                                        : ''
                                }
                                clearable
                                error={props.showErrors && props.errors.event_name_list}
                                value={props.draft.event_name_list}
                                options={props.attributionOptions}
                                onChange={props.onEventNameListChange}
                                disabled={props.isEditing}
                            />
                        </FormControl>
                    </Grid>
                )}
                {props.draft.source_type === 'pixel' && (
                    <Grid item xs={2}>
                        {getEnvironmentSettings().canUseRetargetingFrequencyOption() && (
                            <Box mb={2}>
                                <TextFieldFormatter
                                    initialValue={props.draft.frequency}
                                    onChange={props.onFrequencyChange}
                                    shouldAllowChange={v => /^[0-9]*$/.test(v)}
                                    formatIn={v => numeral(v).value()}
                                    formatOut={v => numeral(v).value()}
                                    renderInput={({ value, onChange, onBlur }) => (
                                        <TextField
                                            error={props.showErrors && props.errors.frequency}
                                            value={value}
                                            label="Frequency"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            disabled={props.isEditing}
                                            placeholder="Enter frequency number"
                                            required
                                        />
                                    )}
                                />
                            </Box>
                        )}
                        <Box mb={2}>
                            <SimpleSelect
                                label="Lookback Window"
                                required
                                placeholder="Select Lookback window"
                                disabled={props.isEditing}
                                value={props.draft.lookback}
                                onChange={e => props.onLookBackChange(e.target.value)}
                                error={props.showErrors && props.errors.lookback}
                                options={[
                                    { label: '1 day', value: 1 },
                                    { label: '7 days', value: 7 },
                                    { label: '14 days', value: 14 },
                                    { label: '28 days', value: 28 },
                                ]}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
            {props.invalidLines.length > 0 && (
                <Card>
                    <div className="audience-editor__server-error">Invalid lines:</div>
                    {_.map(props.invalidLines, line => (
                        <div className="audience-editor__server-error">{line}</div>
                    ))}
                </Card>
            )}
            <FormHelperText>{props.serverError}</FormHelperText>
        </LoadGroup>
    );
}

export default Layout;
