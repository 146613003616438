import _ from 'lodash';

import { getEnvironmentSettings } from 'services/environment';
import { uploadOriginTypes } from '../../editor/services/constants';

export default function selector(storeState) {
    const audienceEditorState = _.get(storeState, 'retargetingModal');
    const isModalOpen = _.get(storeState, 'retargetingModal.isModalOpen');
    const audienceId = _.get(storeState, 'retargetingModal.audienceId');
    const resourcesLoading = _.get(storeState, 'reportCampaignsLoader.isLoading');
    const environmentSettings = getEnvironmentSettings();

    const uploadOriginOptions = _.filter(uploadOriginTypes, type =>
        _.includes(environmentSettings.getDataProviderOptions(), type.value)
    );

    const campaignOptions = getCampaignOptions(storeState);

    const adOptions = getAdOptions(storeState);

    const eventOptions = getEventOptions(storeState);

    const conversionOptions = getConversionOptions(storeState);

    const attributionOptions = getAttributionOptions(storeState);

    const advertiserOptions = getAdvertiserOptions(storeState);

    return {
        audienceEditorState,
        resourcesLoading,
        campaignOptions,
        adOptions,
        eventOptions,
        attributionOptions,
        uploadOriginOptions,
        advertiserOptions,
        conversionOptions,
        isModalOpen,
        audienceId,
    };
}

function getAttributionOptions(storeState) {
    const targetCampaigns = getTargetCampaigns(storeState);

    const options = _(targetCampaigns)
        .filter(campaign => campaign)
        .map(campaign => campaign.conversions || [])
        .flatten()
        .uniqBy('event_name')
        .orderBy('reporting_name')
        .map(conversion => ({ value: conversion.event_name, label: conversion.reporting_name }))
        .value();

    return options;
}

function getTargetCampaigns(storeState) {
    const campaigns = _.get(storeState, 'retargetingModal.campaigns', []);
    const sourceIds = _.get(storeState, 'retargetingModal.draft.source_id', []);
    const campaignMap = {};
    const sourceIdToCampaignIdMap = {};

    _.each(campaigns, campaign => {
        campaignMap[campaign.id] = campaign;

        sourceIdToCampaignIdMap[campaign.id] = campaign.id;

        _.each(campaign.ads, ad => {
            sourceIdToCampaignIdMap[ad.id] = campaign.id;
        });
    });

    let targetCampaigns = _.map(sourceIds, sourceId => {
        // sourceId can be an Ad or Campaign Id, convert it to a campaignId
        const campaignId = sourceIdToCampaignIdMap[sourceId];

        // get the campaign
        const campaign = campaignMap[campaignId];

        return campaign;
    });

    // if no sources are selected, it should select all events
    if (targetCampaigns.length === 0) {
        targetCampaigns = campaigns;
    }

    return targetCampaigns;
}

function getEventOptions(storeState) {
    const targetCampaigns = getTargetCampaigns(storeState);

    const options = _(targetCampaigns)
        .filter(campaign => campaign)
        .map(campaign => campaign.beacons || [])
        .flatten()
        .uniqBy('name')
        .orderBy('label')
        .map(beacon => ({ value: beacon.name, label: beacon.label }))
        .value();

    return options;
}

function getCampaignOptions(storeState) {
    const { campaigns } = storeState.retargetingModal;

    if (!campaigns) {
        return [];
    }

    return _(campaigns)
        .orderBy('id', 'desc')
        .map(campaign => {
            return {
                label: `${campaign.id} ${campaign.name}`,
                value: campaign.id,
                campaign_type: campaign.campaign_type,
                children: _.map(campaign.ads, ad => {
                    return {
                        label: `${ad.id} ${ad.name}`,
                        value: ad.id,
                        unselectable: true,
                    };
                }),
            };
        })
        .value();
}

function getAdOptions(storeState) {
    const { campaigns } = storeState.retargetingModal;

    if (!campaigns) {
        return [];
    }

    return _(campaigns)
        .orderBy('id', 'desc')
        .map(campaign => {
            return _.map(campaign.ads, ad => {
                return (ad = {
                    ...ad,
                    campaign_id: campaign.id,
                    campaign_type: campaign.campaign_type,
                });
            });
        })
        .flatten()
        .map(ad => {
            return {
                label: `${ad.id} ${ad.name}`,
                value: Number(ad.id),
                campaign_id: Number(ad.campaign_id),
                campaign_type: ad.campaign_type,
            };
        })
        .value();
}

function getAdvertiserOptions(storeState) {
    const { organizations } = storeState.retargetingModal;

    if (!organizations) {
        return [];
    }

    return _(organizations)
        .orderBy('name', 'asc')
        .map(org => {
            return {
                label: `${org.name}`,
                value: `${org.id}`,
            };
        })
        .value();
}

function getConversionOptions(storeState) {
    const { ownOrganization } = storeState.retargetingModal;
    const advertiserId = _.get(storeState, 'retargetingModal.draft.advertiser_id', []);

    if (!ownOrganization) {
        return [];
    }
    const options = [];

    _.each(ownOrganization.conversions, org => {
        if (org.advertiser.id === advertiserId) {
            options.push({
                label: `${org.reporting_name}`,
                value: `${org.event_name}`,
            });
        }
    });

    return options;
}
