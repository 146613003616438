import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import numeral from 'numeral';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';

import { SearchInput } from 'widgets-v6/standard-input';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import { MultiSelect } from 'widgets-v6/select';

import { DEAL_TYPE_MAPPING } from 'common/constants/deals';
import flags from 'containers/flags/service';

import DealModal from './deal-modal';
import DeleteDealModal from './delete-deal-modal';
import inventoryDealModalActions from './deal-modal/actions';
import deleteDealModalActions from './delete-deal-modal/actions';
import dealsInventoryActions from './actions';
import selector from './selector';
import { getCurrencySignforCurrency, formatDate_dateWithYear } from 'utils/formatting';

const useStyles = makeStyles(theme => ({
    padded: {
        padding: `0 ${theme.spacing(3)}`,
    },
    tableContainer: {
        padding: `0 ${theme.spacing(3)}`,
        margin: `${theme.spacing(3)} 0`,
    },
    textContainer: {
        marginBottom: theme.spacing(1.5),
    },
}));

const tableStyles = makeStyles(() => ({
    overflowTable: {
        overflowX: 'auto',
    },
}));

const DealsInventory = ({
    dispatch,
    deals,
    loading,
    keywords,
    sort,
    timezone,
    advertiserOptions,
    filters,
    isCurated,
    disableEditingDeal,
}) => {
    const classes = useStyles();
    useEffect(() => {
        dispatch(dealsInventoryActions.fetchAllDeals());
        dispatch(dealsInventoryActions.applyFilters({ advertiserIds: [] }));
    }, [isCurated]);

    const openDealsModal = () => {
        dispatch(inventoryDealModalActions.openInventoryDealsForm(isCurated));
    };

    const onChange = e => {
        const { value } = e.target;
        dispatch(dealsInventoryActions.fetchAllDeals(value, sort));
    };

    const onSort = column => {
        const isAscending = sort.field === column && sort.order === 1;
        dispatch(
            dealsInventoryActions.fetchAllDeals(keywords, {
                field: column,
                order: isAscending ? -1 : 1,
            })
        );
    };

    return (
        <div>
            <Toolbar>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Typography variant="h4">
                        {isCurated ? 'Curated Deals' : 'Private Deals'}
                    </Typography>
                </Grid>
            </Toolbar>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.padded}
            >
                <Grid item xs={3}>
                    <SearchInput placeholder="Search" value={keywords} onChange={onChange} />
                </Grid>
                <Grid item xs="auto">
                    {!disableEditingDeal && (
                        <Button
                            startIcon={<AddIcon />}
                            variant="contained"
                            onClick={openDealsModal}
                            color="primary"
                        >
                            New Deal
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Container disableGutters className={classes.tableContainer} maxWidth={false}>
                <BlockLoadGroup isLoading={loading}>
                    <div className={classes.textContainer}>
                        <Typography variant="body2">{deals.length} Records found.</Typography>
                    </div>
                    <div>
                        <DealsTable
                            deals={deals}
                            dispatch={dispatch}
                            onSort={onSort}
                            sortColumn={sort.field}
                            sortOrder={sort.order === -1 ? 'desc' : 'asc'}
                            timezone={timezone}
                            advertiserOptions={advertiserOptions}
                            filters={filters}
                            keywords={keywords}
                            sort={sort}
                            isCurated={isCurated}
                            disableEditingDeal={disableEditingDeal}
                        />
                    </div>
                </BlockLoadGroup>
            </Container>
            <DealModal advertiserOptions={advertiserOptions} isCurated={isCurated} />
            <DeleteDealModal />
        </div>
    );
};

const DealsTable = ({
    deals,
    dispatch,
    sortColumn,
    onSort,
    sortOrder,
    timezone,
    advertiserOptions,
    keywords,
    sort,
    filters,
    isCurated,
    disableEditingDeal,
}) => {
    const classes = tableStyles();

    const openDealModalWithDetails = dealId => {
        dispatch(inventoryDealModalActions.showDealDetails(dealId));
    };

    const openDeleteDealModal = (dealId, appName) => {
        dispatch(deleteDealModalActions.openDeleteModal(dealId, appName));
    };

    const fields = [
        {
            label: flags.isEnabled('efb_35_curated_deals') ? 'Deal Name' : 'App/Site Name',
            id: 'app_name',
            align: 'left',
            getValue: ({ deal }) => {
                return deal.app_name;
            },
        },
        {
            label: 'Deal ID',
            id: 'id',
            align: 'left',
            getValue: ({ deal }) => {
                return deal.id;
            },
        },
        {
            label: 'Type',
            id: 'type',
            align: 'left',
            getValue: ({ deal }) => {
                return DEAL_TYPE_MAPPING[deal.type]?.label;
            },
        },
        {
            label: isCurated ? 'Description' : 'Publisher Name',
            id: 'pub_name',
            align: 'left',
            getValue: ({ deal }) => {
                return deal.pub_name;
            },
        },
        {
            label: 'Advertiser',
            id: 'advertiser',
            align: 'left',
            getValue: ({ deal, advertiserOptions }) => {
                const advertiserId = deal.advertiserId;
                const matchedAdvertiser = _.find(
                    advertiserOptions,
                    advertiser => advertiser.value === advertiserId
                );

                if (matchedAdvertiser) {
                    return matchedAdvertiser.label;
                }
                return null;
            },
        },
        {
            label: flags.isEnabled('efb_35_curated_deals') ? 'Exchange' : 'Supply Source',
            id: 'supply_source',
            align: 'left',
            getValue: ({ deal }) => {
                return deal.supply_source;
            },
        },
        {
            label: 'Price',
            id: 'price',
            align: 'left',
            getValue: ({ deal }) => {
                const currencyString = numeral(deal.price_local).format('0,0.00');
                const currencySign = getCurrencySignforCurrency(deal.currency);
                return `${deal.currency} ${currencySign}${currencyString} `;
            },
        },
        {
            label: 'Start Date',
            id: 'start_date',
            align: 'left',
            getValue: ({ deal, timezone }) => {
                if (deal.start_date) {
                    return formatDate_dateWithYear(deal.start_date, timezone);
                }
            },
        },
        {
            label: 'End Date',
            id: 'end_date',
            align: 'left',
            getValue: ({ deal, timezone }) => {
                if (deal.end_date) {
                    return formatDate_dateWithYear(deal.end_date, timezone);
                }
            },
        },
        {
            label: 'Created On',
            id: '_created',
            align: 'left',
            getValue: ({ deal, timezone }) => {
                if (deal._created) {
                    return formatDate_dateWithYear(deal._created, timezone);
                }
            },
        },
        {
            label: 'Details',
            id: 'details_link',
            align: 'left',
            getValue: ({ deal }) => {
                const detailsLink = deal?.details_link;
                if (detailsLink) {
                    return (
                        <a
                            href={detailsLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none' }}
                        >
                            <IconButton size="small">
                                <LaunchIcon />
                            </IconButton>
                        </a>
                    );
                }
                return null;
            },
        },
        {
            label: 'Actions',
            id: 'actions',
            align: 'left',
            getValue: ({ deal, openDealModalWithDetails, openDeleteDealModal }) => {
                return (
                    <div>
                        {' '}
                        <IconButton onClick={() => openDealModalWithDetails(deal.id)} size="small">
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => openDeleteDealModal(deal.id, deal.app_name)}
                            size="small"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    const filterFields = fields.filter(field => {
        const filterFields = [];
        if (!flags.isEnabled('efb_93_deal_start_end_dates')) {
            filterFields.push('start_date', 'end_date');
        } // Hide start and end date columns if feature flag is disabled
        if (isCurated) {
            filterFields.push('advertiser');
        } // Hide advertiser column for curated deals
        if (!isCurated || !flags.isEnabled('efb_129_details_link_curated_deals')) {
            filterFields.push('details_link');
        }
        if (disableEditingDeal) {
            filterFields.push('actions');
        }
        return !filterFields.includes(field.id);
    });

    return (
        <Paper>
            <Toolbar>
                <Grid container alignItems="center" spacing={1} justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">All Deals</Typography>
                    </Grid>
                    {!isCurated && (
                        <Grid item xs={6}>
                            <Filters
                                advertiserOptions={advertiserOptions}
                                filters={filters}
                                keywords={keywords}
                                sort={sort}
                            />
                        </Grid>
                    )}
                </Grid>
            </Toolbar>
            <div className={classes.overflowTable}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {_.map(filterFields, field => (
                                <TableCell
                                    sortDirection={sortColumn === field.id ? sortOrder : false}
                                    align={field.align}
                                    key={field.id}
                                >
                                    <TableSortLabel
                                        active={sortColumn === field.id}
                                        direction={sortColumn === field.id ? sortOrder : 'asc'}
                                        onClick={() => onSort(field.id)}
                                    >
                                        {field.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(deals, deal => (
                            <TableRow key={deal.id}>
                                {_.map(filterFields, field => (
                                    <TableCell align={field.align} key={field.id}>
                                        {field.getValue({
                                            deal,
                                            timezone,
                                            openDealModalWithDetails,
                                            openDeleteDealModal,
                                            advertiserOptions,
                                        })}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    );
};

const filterOptions = [
    {
        label: 'Advertiser',
        component: ({ filters, handleChange, advertiserOptions }) => (
            <MultiSelect
                placeholder="Filter by advertiser"
                value={filters.advertiserIds}
                onChange={value => handleChange({ advertiserIds: value })}
                options={advertiserOptions}
                fullWidth={true}
            />
        ),
    },
];

const Filters = ({ filters, advertiserOptions }) => {
    const dispatch = useDispatch();

    const handleChange = selectedFilters => {
        dispatch(dealsInventoryActions.applyFilters(selectedFilters));
    };
    return (
        <React.Fragment>
            {_.map(filterOptions, (filter, index) => (
                <FormControl margin="dense" key={index} fullWidth>
                    {filter.component({
                        filters,
                        handleChange,
                        advertiserOptions,
                    })}
                </FormControl>
            ))}
        </React.Fragment>
    );
};

export default connect(selector)(DealsInventory);
