import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import shallowCompare from 'utils/shallow-compare';
import toastr from 'toastr';
/** import third-party libraries in the section above, keep the ascending order */

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
/** import mui components in the section above, keep the ascending order */

import { BlockLoadGroup } from 'widgets-v5/load-group';
import { DialogTitle } from 'widgets-v6/modal';
import { LinearProgressWithLabel } from 'widgets-v6/progress';
import { SingleSelect } from 'widgets-v6/select';
import FormField from 'widgets-v5/form-field';
/** import widgets components in the section above, keep the ascending order */

import {
    CTV_DEVICE_OS_OPTIONS,
    DESKTOP_DEVICE_OS_OPTIONS,
    DOOH_DEVICES_OS_OPTIONS,
    INAPP_DEVICES_OS_OPTIONS,
    payload_formData as adDefaults,
} from 'forms/ad-form/services/default-values';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import { MuiFormLabel } from 'widgets-v6/standard-input';
import actions from 'forms/media-plan-form/actions';
import AdMenu from '../ad-menu';
import PartialAdForm from '../partial-ad-form';
import selector from './selector';
/** import classes and others in the section above, keep the ascending order */

const TOASTR_OPTIONS = {
    tapToDismiss: false,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
    closeButton: true,
    allowHtml: true,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Select Ads', 'Override Settings'];
}

function FormSteps(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const { ads } = props;

    useEffect(() => {
        if (ads.length > 0) {
            setActiveStep(1);
        } else {
            setActiveStep(0);
        }
    }, [ads.length]);

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep}>
                {steps.map(label => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>{props.children}</div>
        </div>
    );
}

const BaseColumnForm = connect(selector)(
    class extends React.Component {
        constructor(props) {
            super(props);
            const { ad } = props;
            const adIdsToSave = [];
            if (ad) {
                adIdsToSave.push(ad.id);
            }

            this.state = {
                adIdsToSave,
                error: null,
                selectedAdSettingsToCopy: '',
            };
        }

        shouldComponentUpdate(nextProps, nextState) {
            const shouldUpdate =
                !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
            return shouldUpdate;
        }

        getDraft = () => {
            const { ad, ads, campaign } = this.props;

            let draft;
            if (ad) {
                draft = ad;
            } else {
                const uniquePlatforms = _(ads)
                    .map(ad => ad.platform)
                    .uniq()
                    .value();
                const defaultStartAndEnd = {
                    start: moment().format('YYYY-MM-DDTHH:mm'),
                    end: moment().format('YYYY-MM-DDTHH:mm'),
                };

                const isCTVCampaign = campaign.type === CampaignTypeMapping.CTV;
                const isDOOHCampaign = campaign.type === CampaignTypeMapping.DOOH;
                const isStandardCampaign = campaign.type === CampaignTypeMapping.Standard;

                const desktopDeviceOsValues = DESKTOP_DEVICE_OS_OPTIONS.map(os => os.value);
                const inappDeviceOsValues = INAPP_DEVICES_OS_OPTIONS.map(os => os.value);
                const ctvDeviceOsValues = CTV_DEVICE_OS_OPTIONS.map(os => os.value);
                const doohDeviceOsValues = DOOH_DEVICES_OS_OPTIONS.map(os => os.value);
                const isDesktopPlatformOnly =
                    uniquePlatforms.length === 1 && uniquePlatforms[0] === 'desktop';

                let defaultTargetDeviceOs;
                if (isStandardCampaign && campaign.isCrossPlatformCampaign) {
                    defaultTargetDeviceOs = _.concat(inappDeviceOsValues, desktopDeviceOsValues);
                } else if (isStandardCampaign && isDesktopPlatformOnly) {
                    defaultTargetDeviceOs = desktopDeviceOsValues;
                } else if (isCTVCampaign) {
                    defaultTargetDeviceOs = ctvDeviceOsValues;
                } else if (isDOOHCampaign) {
                    defaultTargetDeviceOs = doohDeviceOsValues;
                } else {
                    defaultTargetDeviceOs = adDefaults.target_device_os;
                }

                if (uniquePlatforms.length === 1) {
                    draft = {
                        ...adDefaults,
                        target_device_os: defaultTargetDeviceOs,
                        platform: uniquePlatforms[0],
                        ...defaultStartAndEnd,
                        timezone: campaign.default_timezone,
                    };
                } else {
                    draft = {
                        ...adDefaults,
                        ...defaultStartAndEnd,
                        timezone: campaign.default_timezone,
                        target_device_os: defaultTargetDeviceOs,
                    };
                }
            }

            return draft;
        };

        submit = (save, errors, showErrors) => {
            const { shouldRefreshPredictionsOnSave, externalErrors } = this.props;

            const { adIdsToSave } = this.state;

            showErrors();

            if (_.keys(externalErrors).length > 0) {
                console.log({ externalErrors });
                return;
            }

            if (_.keys(errors).length > 0) {
                return;
            }

            if (adIdsToSave.length === 0) {
                return;
            }

            const { onSave, campaign, dispatch, columnName } = this.props;

            dispatch(actions.submitForm(save, adIdsToSave, columnName, campaign)).then(
                ({ savedAdIds, errorAdIds, errorMessages }) => {
                    if (shouldRefreshPredictionsOnSave) {
                        dispatch(actions.fetchPrediction(savedAdIds, campaign));
                    }
                    if (errorAdIds.length !== 0) {
                        const errorString = _.map(
                            errorAdIds,
                            (adId, i) =>
                                `#${adId} ${errorMessages[i] ? ` - ${errorMessages[i]}` : ``}`
                        ).join('<br />');
                        toastr.warning(
                            `${errorString}`,
                            `Invalid ${columnName} for ads:`,
                            TOASTR_OPTIONS
                        );
                    }
                    if (savedAdIds.length !== 0) {
                        onSave();
                    }
                }
            );
        };

        copyAd = (updateDraft, ad) => {
            updateDraft(ad);
            if (this.props.onCopyAd) {
                this.props.onCopyAd(ad);
            }
        };

        getOverallError = errors => {
            if (this.state.adIdsToSave.length === 0) {
                return 'Select at least one Ad before saving';
            }

            const fields = _.keys(errors);

            const allErrors = _(fields)
                .map(field => errors[field])
                .flatten()
                .filter(x => x)
                .value();

            return _.first(allErrors);
        };

        addAdToList = adIdsToSave => {
            this.setState({ adIdsToSave });
        };

        handleAdsSettingsToCopy = (value, updateDraft) => {
            if (value === 'reset') {
                this.setState({ selectedAdSettingsToCopy: 'reset' });
                updateDraft(this.getDraft());
                return;
            } else if (value === null) {
                this.setState({ selectedAdSettingsToCopy: '' });
                updateDraft(this.getDraft());
                return;
            } else {
                this.setState({ selectedAdSettingsToCopy: value });
            }

            const selectedAdToCopy = _.find(
                this.props.ads,
                ad => _.toString(ad.id) === _.toString(value)
            );
            this.copyAd(updateDraft, selectedAdToCopy);
        };

        render() {
            const {
                ad,
                ads,
                campaign,
                processedAds,
                activePlatformFilter,
                isCopyDraftEnabled,
            } = this.props;

            const adsMapping = _.map(ads, ad => ({
                value: ad.id.toString(),
                label: `#${ad.id.toString()} ${ad.name}`,
            }));

            const adsSettingsToCopyList = _.concat(
                { label: 'Reset Settings', value: 'reset' },
                adsMapping
            );

            return (
                <PartialAdForm
                    initialDraft={this.getDraft()}
                    campaign={campaign}
                    ad={ad}
                    ads={ads}
                    fields={this.props.fields}
                >
                    {({
                        draft,
                        errors,
                        shouldShowErrors,
                        updateDraft,
                        isSaving,
                        showErrors,
                        save,
                    }) => (
                        <React.Fragment>
                            <DialogTitle onClose={this.props.onCancel}>
                                {ad ? this.props.headline : `Bulk Edit - ${this.props.headline}`}
                            </DialogTitle>
                            <FormSteps ads={this.state.adIdsToSave}>
                                <DialogContent dividers>
                                    <BlockLoadGroup isLoading={isSaving}>
                                        <Grid container spacing={2}>
                                            {ads && (
                                                <Grid item xs={4}>
                                                    <Box mb={2}>
                                                        <Typography variant="body1" gutterBottom>
                                                            1. Select Ads
                                                        </Typography>
                                                    </Box>
                                                    <Box maxHeight="400" overflow="auto">
                                                        <AdMenu
                                                            ads={ads}
                                                            isCopyDraftEnabled={isCopyDraftEnabled}
                                                            onChange={this.addAdToList}
                                                            onCopyAd={ad =>
                                                                this.copyAd(updateDraft, ad)
                                                            }
                                                            onResetForm={() =>
                                                                updateDraft(this.getDraft())
                                                            }
                                                            activePlatformFilter={
                                                                activePlatformFilter
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                            )}
                                            <Grid item xs={ads ? 8 : 12}>
                                                {this.state.adIdsToSave.length > 0 && (
                                                    <React.Fragment>
                                                        <Box mb={2}>
                                                            <Box mb={2}>
                                                                <Typography
                                                                    variant="body1"
                                                                    gutterBottom
                                                                >
                                                                    2. Override Settings
                                                                </Typography>
                                                            </Box>
                                                            <MuiFormLabel>
                                                                Copy Settings From (Optional)
                                                            </MuiFormLabel>
                                                            <SingleSelect
                                                                options={adsSettingsToCopyList}
                                                                onChange={value => {
                                                                    this.handleAdsSettingsToCopy(
                                                                        value,
                                                                        updateDraft
                                                                    );
                                                                }}
                                                                value={_.toString(
                                                                    this.state
                                                                        .selectedAdSettingsToCopy
                                                                )}
                                                                placeholder="Select ads to copy"
                                                            />
                                                            {this.state.selectedAdSettingsToCopy !==
                                                                '' && (
                                                                <Box my={1}>
                                                                    <Alert severity="success">
                                                                        Settings have been copied.
                                                                    </Alert>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                        <Box
                                                            maxHeight="525"
                                                            overflow="auto"
                                                            height="100%"
                                                            width="100%"
                                                        >
                                                            {this.props.children(
                                                                updateDraft,
                                                                shouldShowErrors,
                                                                errors,
                                                                draft
                                                            )}
                                                        </Box>
                                                    </React.Fragment>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </BlockLoadGroup>
                                </DialogContent>
                                <DialogActions>
                                    {isSaving && (
                                        <Box width="100%">
                                            <Typography>
                                                {`Processed ${processedAds} out of ${
                                                    this.state.adIdsToSave.length
                                                }`}
                                            </Typography>
                                            <LinearProgressWithLabel
                                                value={
                                                    (processedAds / this.state.adIdsToSave.length) *
                                                    100
                                                }
                                            />
                                        </Box>
                                    )}
                                    {this.getOverallError(errors) && (
                                        <FormField
                                            showErrors={shouldShowErrors}
                                            errors={[this.getOverallError(errors)]}
                                        />
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.submit(save, errors, showErrors)}
                                        disabled={isSaving || this.state.adIdsToSave.length === 0}
                                    >
                                        Save
                                    </Button>
                                </DialogActions>
                            </FormSteps>
                        </React.Fragment>
                    )}
                </PartialAdForm>
            );
        }
    }
);

export default BaseColumnForm;
