import _ from 'lodash';
import { ADAPTIVE_SIZE } from 'common/constants/creatives';
import { TAGS } from 'common/constants/vendors';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import { getEnvironmentSettings } from 'services/environment';
import flags from 'containers/flags/service';

export const CONVERSIO_MARKETING_ORG_ID = '5ecd256d8d7c137037c11d75';

export const ASSET_SELECTION_METHODS = [
    {
        label: 'Auto-generated screenshot',
        value: 'autoGeneratedScreenshot',
    },
    {
        label: 'Select from assets',
        value: 'selectFromAssets',
    },
    {
        label: 'Input by URL',
        value: 'inputByUrl',
    },
];

export const DEFAULT_RENDER_DELAY = 2;

export const POSITIONS = [
    { value: 'pre_roll', label: 'Pre-roll' },
    { value: 'mid_roll', label: 'Mid-roll' },
    { value: 'post_roll', label: 'Post-roll' },
    { value: 'unknown', label: 'Unknown' },
];

export const EXPORTERS = [
    {
        name: 'gwd',
        vendors: ['gwd__tag__js'],
        label: 'Google Web Designer - Published Creative',
        title: 'Google Web Designer',
    },
    {
        name: 'conversio',
        vendors: ['conversio__tag__js'],
        label: 'Conversio - Published Creative',
        title: 'Conversio',
    },
    {
        name: 'adobe',
        vendors: ['adobe__tag__js'],
        label: 'Adobe Animate - Published Creative',
        title: 'Adobe Animate',
    },
    {
        name: 'html5',
        vendors: ['html5__tag__js'],
        label: 'HTML5 - Published Creative',
        title: 'HTML5',
    },
];

export function getExporter(name) {
    return _.find(EXPORTERS, { name });
}

export function getSupportedExporters() {
    return _.map(EXPORTERS, ({ name }) => name);
}

export function isSupportedExporter(exporter) {
    return _.includes(getSupportedExporters(), exporter);
}

export function getPlatforms() {
    return _(getCreativeTable())
        .filter(platform => {
            // always allow
            if (platform.internalOnly === false) {
                return true;
            }

            // filter out
            return false;
        })
        .value();
}

export function getTypes(platforms, exporter, campaignType) {
    return _(platforms)
        .map(platformName => _.find(getCreativeTable(), { value: platformName }))
        .map(platform => {
            if (
                !flags.isEnabled('en_3360_add_mweb_interstitial_creative_format') &&
                _.includes(['desktop', 'mweb', 'dooh'], platform.value)
            ) {
                return _.filter(platform.types, type => type.value !== 'display_interstitial');
            }
            return platform.types;
        })
        .flatten()
        .filter(typeObject => {
            const supportedCreativeTypes = getEnvironmentSettings().getInventoryForecastCreativeTypes();
            return _.includes(supportedCreativeTypes, typeObject.value);
        })
        .groupBy(typeObject => typeObject.value)
        .filter(type => {
            if (platforms.length !== type.length) {
                return false;
            }
            return true;
        })
        .filter(types => {
            let out;
            if (types[0].value === 'video' && campaignType !== CampaignTypeMapping.DOOH) {
                out = _.intersectionBy(
                    ..._.map(types, type => type.placements),
                    placement => placement.value
                );
            } else {
                out = _.intersectionBy(..._.map(types, type => type.sizes), size => size.value);
            }
            return out.length > 0;
        })
        .map(types => types[0])
        .filter(({ value }) => {
            if (isSupportedExporter(exporter) && _.includes(['native', 'video'], value)) {
                return false;
            }
            return true;
        })
        .value();
}

export function getTypeSizes() {
    const output = [];
    _.each(getCreativeTable(), row => {
        _.each(row.types, ({ value: type, sizes }) => {
            _.each(sizes, ({ value: size }) => {
                output.push({
                    size,
                    type,
                });
            });
        });
    });
    return output;
}

export function getCreativeTable(displayDOOHAspectRatioSizes = false) {
    const CREATIVE_TABLE_VERSION_2 = [
        {
            value: 'inapp',
            label: 'Mobile In-App',
            icon: 'inapp',
            internalOnly: false,
            allowedOnCampaignType: CampaignTypeMapping.Standard,
            types: [
                {
                    value: 'display_banner',
                    label: 'Banner',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'custom_html',
                            label: 'Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve JavaScript tags, including tags from third-party ad servers.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'mraid',
                            label: 'MRAID Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve Rich Media tags that require MRAID 2.0 support.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '320x50',
                            label: '320x50',
                            content: [{ message: 'Mobile Banner' }],
                        },
                        {
                            value: '300x50',
                            label: '300x50',
                            content: [
                                { message: 'Mobile Banner' },
                                {
                                    icon: 'info',
                                    message: 'Ads may appear in 320x50 ad spaces with black bars',
                                },
                            ],
                        },
                        {
                            value: '728x90',
                            label: '728x90',
                            content: [{ message: 'Tablet Banner' }],
                        },
                    ],
                    icon: 'inapp_banner',
                    category: 'display',
                },
                {
                    value: 'display_box',
                    label: 'Box',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'custom_html',
                            label: 'Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve JavaScript tags, including tags from third-party ad servers',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'mraid',
                            label: 'MRAID Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve Rich Media tags that require MRAID 2.0 support.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '300x250',
                            label: '300x250',
                            content: [{ message: 'Medium Rectangle' }],
                        },
                    ],
                    icon: 'inapp_box',
                    category: 'display',
                },
                {
                    value: 'display_vertical',
                    label: 'Vertical',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'custom_html',
                            label: 'Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve JavaScript tags, including tags from third-party ad servers',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'mraid',
                            label: 'MRAID Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve Rich Media tags that require MRAID 2.0 support.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '300x600',
                            label: '300x600',
                        },
                    ],
                    icon: 'inapp_vertical',
                    category: 'display',
                },
                {
                    value: 'display_interstitial',
                    label: 'Interstitial',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'custom_html',
                            label: 'Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve JavaScript tags, including tags from third-party ad servers',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'mraid',
                            label: 'MRAID Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve Rich Media tags that require MRAID 2.0 support.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '320x480',
                            label: '320x480',
                            content: [{ message: 'Mobile Interstitial' }],
                        },
                        {
                            value: '480x320',
                            label: '480x320',
                            content: [
                                { message: 'Mobile Interstitial (Landscape)' },
                                { icon: 'warning', message: 'Limited inventory' },
                            ],
                        },
                        {
                            value: '768x1024',
                            label: '768x1024',
                            content: [{ message: 'Tablet Interstitial' }],
                        },
                        {
                            value: '1024x768',
                            label: '1024x768',
                            content: [
                                { message: 'Tablet Interstitial (Landscape)' },
                                { icon: 'warning', message: 'Limited inventory' },
                            ],
                        },
                    ],
                    icon: 'inapp_interstitial',
                    category: 'display',
                },
                {
                    value: 'display_expandable',
                    label: 'Expandable',
                    formats: [
                        {
                            value: 'mraid',
                            label: 'MRAID Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve Rich Media tags that require MRAID 2.0 support.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '320x50',
                            label: '320x50',
                            content: [
                                { message: 'Mobile Banner' },
                                { icon: 'expand', message: 'Expands into 320x480' },
                            ],
                        },
                        {
                            value: '300x250',
                            label: '300x250',
                            content: [
                                { message: 'Mobile Banner' },
                                { icon: 'expand', message: 'Expands into 320x480' },
                            ],
                        },
                        {
                            value: '728x90',
                            label: '728x90',
                            content: [
                                { message: 'Tablet Banner' },
                                { icon: 'expand', message: 'Expands into 768x1024' },
                            ],
                        },
                    ],
                    icon: 'inapp_expandable',
                    category: 'display',
                },
                {
                    value: 'video',
                    label: 'Video',
                    formats: [
                        {
                            value: 'video',
                            label: 'Hosted',
                            content: [
                                {
                                    message:
                                        'Serve video files from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Video start, complete and quartile events are automatically tracked.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'third_party_vast',
                            label: 'Third-Party VAST',
                            content: [
                                { message: 'Serve VAST tags hosted on third-party ad servers.' },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [],
                    icon: 'inapp_video',
                    category: 'video',
                    placements: [
                        {
                            value: 'interstitial',
                            label: 'Interstitial',
                            sizes: [
                                {
                                    value: '320x480',
                                    label: '320x480',
                                    content: [{ message: 'Mobile Interstitial' }],
                                },
                                {
                                    value: '480x320',
                                    label: '480x320',
                                    content: [
                                        { message: 'Mobile Interstitial (Landscape)' },
                                        { icon: 'warning', message: 'Limited inventory' },
                                    ],
                                },
                                {
                                    value: '768x1024',
                                    label: '768x1024',
                                    content: [{ message: 'Tablet Interstitial' }],
                                },
                                {
                                    value: '1024x768',
                                    label: '1024x768',
                                    content: [
                                        { message: 'Tablet Interstitial (Landscape)' },
                                        { icon: 'warning', message: 'Limited inventory' },
                                    ],
                                },
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'inapp_video_interstitial',
                        },
                        {
                            value: 'in_stream',
                            label: 'In-stream',
                            sizes: [
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'inapp_video_in_stream',
                        },
                        {
                            value: 'out_stream',
                            label: 'Out-stream',
                            sizes: [
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'inapp_video_out_stream',
                        },
                        {
                            value: 'unknown',
                            label: 'Unknown',
                            sizes: [
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'inapp_video_unknown',
                        },
                    ],
                },
                {
                    value: 'native',
                    label: 'In-Content',
                    formats: [
                        {
                            value: 'native',
                            label: 'Native',
                            content: [
                                {
                                    message:
                                        "Serve less intrusive ads that fit the look and feel of the publisher's page.",
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '0x0',
                            label: 'Automatic',
                            content: [
                                { message: 'Main Image: 1200x627; Icon: 80x80' },
                                {
                                    icon: 'check',
                                    message:
                                        'Styled by publisher to match formatting of surrounding content',
                                },
                            ],
                        },
                    ],
                    icon: 'inapp_native',
                    category: 'native',
                },
            ],
        },
        {
            value: 'mweb',
            label: 'Mobile Web',
            icon: 'mweb',
            internalOnly: false,
            allowedOnCampaignType: CampaignTypeMapping.Standard,
            types: [
                {
                    value: 'display_banner',
                    label: 'Banner',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'custom_html',
                            label: 'Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve JavaScript tags, including tags from third-party ad servers',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '320x50',
                            label: '320x50',
                            content: [{ message: 'Mobile Banner' }],
                        },
                        {
                            value: '300x50',
                            label: '300x50',
                            content: [
                                { message: 'Mobile Banner' },
                                {
                                    icon: 'info',
                                    message: 'Ads may appear in 320x50 ad spaces with black bars',
                                },
                            ],
                        },
                        {
                            value: '320x100',
                            label: '320x100',
                            content: [{ message: 'Large Mobile Banner' }],
                        },
                        {
                            value: '728x90',
                            label: '728x90',
                            content: [{ message: 'Tablet Banner' }],
                        },
                        {
                            value: '970x90',
                            label: '970x90',
                            content: [{ message: 'Large Leaderboard' }],
                        },
                        {
                            value: '970x250',
                            label: '970x250',
                            content: [{ message: 'Billboard' }],
                        },
                    ],
                    icon: 'inapp_banner',
                    category: 'display',
                },
                {
                    value: 'display_box',
                    label: 'Box',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'custom_html',
                            label: 'Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve JavaScript tags, including tags from third-party ad servers',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '300x250',
                            label: '300x250',
                            content: [{ message: 'Medium Rectangle' }],
                        },
                    ],
                    icon: 'inapp_box',
                    category: 'display',
                },
                {
                    value: 'display_vertical',
                    label: 'Vertical',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'custom_html',
                            label: 'Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve JavaScript tags, including tags from third-party ad servers',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '300x600',
                            label: '300x600',
                        },
                        {
                            value: '160x600',
                            label: '160x600',
                            content: [{ message: 'Wide Skyscraper' }],
                        },
                    ],
                    icon: 'inapp_vertical',
                    category: 'display',
                },
                {
                    value: 'display_interstitial',
                    label: 'Interstitial',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'custom_html',
                            label: 'Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve JavaScript tags, including tags from third-party ad servers',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'mraid',
                            label: 'MRAID Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve Rich Media tags that require MRAID 2.0 support.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '320x480',
                            label: '320x480',
                            content: [{ message: 'Mobile Interstitial' }],
                        },
                    ],
                    icon: 'inapp_interstitial',
                    category: 'display',
                },
                {
                    value: 'video',
                    label: 'Video',
                    formats: [
                        {
                            value: 'video',
                            label: 'Hosted',
                            content: [
                                {
                                    message:
                                        'Serve video files from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Video start, complete and quartile events are automatically tracked.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'third_party_vast',
                            label: 'Third-Party VAST',
                            content: [
                                { message: 'Serve VAST tags hosted on third-party ad servers.' },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [],
                    icon: 'inapp_video',
                    category: 'video',
                    placements: [
                        {
                            value: 'in_stream',
                            label: 'In-stream',
                            sizes: [
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'inapp_video_in_stream',
                        },
                        {
                            value: 'out_stream',
                            label: 'Out-stream',
                            sizes: [
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'inapp_video_out_stream',
                        },
                        {
                            value: 'unknown',
                            label: 'Unknown',
                            sizes: [
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'inapp_video_unknown',
                        },
                    ],
                },
                {
                    value: 'native',
                    label: 'In-Content',
                    formats: [
                        {
                            value: 'native',
                            label: 'Native',
                            content: [
                                {
                                    message:
                                        "Serve less intrusive ads that fit the look and feel of the publisher's page.",
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '0x0',
                            label: 'Automatic',
                            content: [
                                { message: 'Main Image: 1200x627; Icon: 80x80' },
                                {
                                    icon: 'check',
                                    message:
                                        'Styled by publisher to match formatting of surrounding content',
                                },
                            ],
                        },
                    ],
                    icon: 'inapp_native',
                    category: 'native',
                },
            ],
        },
        {
            value: 'desktop',
            label: 'Desktop',
            icon: 'desktop',
            internalOnly: false,
            allowedOnCampaignType: CampaignTypeMapping.Standard,
            types: [
                {
                    value: 'display_banner',
                    label: 'Banner',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'custom_html',
                            label: 'Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve JavaScript tags, including tags from third-party ad servers',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '320x50',
                            label: '320x50',
                            content: [{ message: 'Mobile Banner' }],
                        },
                        {
                            value: '300x50',
                            label: '300x50',
                            content: [{ message: 'Mobile Banner' }],
                        },
                        {
                            value: '728x90',
                            label: '728x90',
                            content: [{ message: 'Leaderboard (Tablet Banner)' }],
                        },
                        {
                            value: '970x90',
                            label: '970x90',
                            content: [{ message: 'Large Leaderboard' }],
                        },
                        {
                            value: '970x250',
                            label: '970x250',
                            content: [{ message: 'Billboard' }],
                        },
                    ],
                    icon: 'desktop_banner',
                    category: 'display',
                },
                {
                    value: 'display_vertical',
                    label: 'Vertical',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'custom_html',
                            label: 'Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve JavaScript tags, including tags from third-party ad servers',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '120x600',
                            label: '120x600',
                            content: [{ message: 'Skyscraper' }],
                        },
                        {
                            value: '160x600',
                            label: '160x600',
                            content: [{ message: 'Wide Skyscraper' }],
                        },
                        {
                            value: '300x600',
                            label: '300x600',
                            content: [{ message: 'Half Page' }],
                        },
                    ],
                    icon: 'desktop_vertical_banner',
                    category: 'display',
                },
                {
                    value: 'display_box',
                    label: 'Box',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'custom_html',
                            label: 'Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve JavaScript tags, including tags from third-party ad servers',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '200x200',
                            label: '200x200',
                            content: [{ message: 'Small Square' }],
                        },
                        {
                            value: '250x250',
                            label: '250x250',
                            content: [{ message: 'Square' }],
                        },
                        {
                            value: '300x250',
                            label: '300x250',
                            content: [{ message: 'Medium Rectangle' }],
                        },
                        {
                            value: '336x280',
                            label: '336x280',
                            content: [{ message: 'Large Rectangle' }],
                        },
                    ],
                    icon: 'desktop_box',
                    category: 'display',
                },
                {
                    value: 'display_interstitial',
                    label: 'Interstitial',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'custom_html',
                            label: 'Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve JavaScript tags, including tags from third-party ad servers',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'mraid',
                            label: 'MRAID Rich Media',
                            content: [
                                {
                                    message:
                                        'Serve Rich Media tags that require MRAID 2.0 support.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '320x480',
                            label: '320x480',
                            content: [{ message: 'Descktop Interstitial' }],
                        },
                    ],
                    icon: 'inapp_interstitial',
                    category: 'display',
                },
                {
                    value: 'video',
                    label: 'Video',
                    formats: [
                        {
                            value: 'video',
                            label: 'Hosted',
                            content: [
                                {
                                    message:
                                        'Serve video files from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Video start, complete and quartile events are automatically tracked.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'third_party_vast',
                            label: 'Third-Party VAST',
                            content: [
                                { message: 'Serve VAST tags hosted on third-party ad servers.' },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [],
                    icon: 'desktop_video',
                    category: 'video',
                    placements: [
                        {
                            value: 'in_stream',
                            label: 'In-stream',
                            sizes: [
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'desktop_video_in_stream',
                        },
                        {
                            value: 'out_stream',
                            label: 'Out-stream',
                            sizes: [
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'desktop_video_out_stream',
                        },
                        {
                            value: 'unknown',
                            label: 'Unknown',
                            sizes: [
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'desktop_video_unknown',
                        },
                    ],
                },
                {
                    value: 'native',
                    label: 'In-Content',
                    formats: [
                        {
                            value: 'native',
                            label: 'Native',
                            content: [
                                {
                                    message:
                                        "Serve less intrusive ads that fit the look and feel of the publisher's page.",
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '0x0',
                            label: 'Automatic',
                            content: [
                                { message: 'Main Image: 1200x627; Icon: 80x80' },
                                {
                                    icon: 'check',
                                    message:
                                        'Styled by publisher to match formatting of surrounding content',
                                },
                            ],
                        },
                    ],
                    icon: 'inapp_native',
                    category: 'native',
                },
            ],
        },
        {
            value: 'ctv',
            label: 'Connected TV',
            icon: 'connected_tv',
            internalOnly: false,
            allowedOnCampaignType: CampaignTypeMapping.CTV,
            types: [
                {
                    value: 'video',
                    label: 'Video',
                    formats: [
                        {
                            value: 'video',
                            label: 'Hosted',
                            content: [
                                {
                                    message:
                                        'Serve video files from EngageFront. No tags required.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Video start, complete and quartile events are automatically tracked.',
                                },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                        {
                            value: 'third_party_vast',
                            label: 'Third-Party VAST',
                            content: [
                                { message: 'Serve VAST tags hosted on third-party ad servers.' },
                                {
                                    icon: 'check',
                                    message:
                                        'Third-party impression pixels and click trackers are supported.',
                                },
                            ],
                        },
                    ],
                    sizes: [],
                    icon: 'desktop_video',
                    category: 'video',
                    placements: [
                        {
                            value: 'in_stream',
                            label: 'In-stream',
                            sizes: [
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'desktop_video_in_stream',
                        },
                        {
                            value: 'out_stream',
                            label: 'Out-stream',
                            sizes: [
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'desktop_video_out_stream',
                        },
                        {
                            value: 'unknown',
                            label: 'Unknown',
                            sizes: [
                                {
                                    value: ADAPTIVE_SIZE,
                                    label: 'Responsive',
                                    content: [
                                        { message: 'Upload videos of any size and aspect ratio' },
                                        {
                                            icon: 'check',
                                            message:
                                                'Video players automatically scale video to fit',
                                        },
                                    ],
                                },
                            ],
                            icon: 'desktop_video_unknown',
                        },
                    ],
                },
            ],
        },
        {
            value: 'dooh',
            label: 'Digital Out-of-Home',
            // @TODO: add icon.
            icon: 'digital_out_of_home',
            internalOnly: false,
            allowedOnCampaignType: CampaignTypeMapping.DOOH,
            // @TODO: define the types and add icons.
            // For now, it is just a copy from inapp(removing the native option)
            types: [
                {
                    value: 'display_banner',
                    label: 'Banner',
                    formats: [
                        {
                            value: 'standard',
                            label: 'Standard',
                            content: [
                                {
                                    message:
                                        'Serve static or animated images from EngageFront. No tags required.',
                                },
                            ],
                        },
                    ],
                    sizes: [
                        {
                            value: '5120x720',
                            label: displayDOOHAspectRatioSizes ? '64:9' : '5120x720',
                            aspectRatio: '64:9',
                            ...(displayDOOHAspectRatioSizes
                                ? { content: [{ message: '(Recommended: 5120x720)' }] }
                                : {}),
                            supportedSizes: ['5120x720'],
                        },
                        {
                            value: '1920x1080',
                            label: displayDOOHAspectRatioSizes ? '16:9' : '1920x1080',
                            aspectRatio: '16:9',
                            ...(displayDOOHAspectRatioSizes
                                ? { content: [{ message: '(Recommended: 1920x1080)' }] }
                                : {}),
                            supportedSizes: [
                                '1920x1080',
                                '1350x759',
                                '1200x675',
                                '1024x576',
                                '740x416',
                                '448x252',
                            ],
                        },
                        {
                            value: '1920x540',
                            label: displayDOOHAspectRatioSizes ? '7:2' : '1920x540',
                            aspectRatio: '7:2',
                            ...(displayDOOHAspectRatioSizes
                                ? { content: [{ message: '(Recommended: 1920x540)' }] }
                                : {}),
                            supportedSizes: ['1920x540', '1400x400', '1088x320', '1056x288'],
                        },
                        {
                            value: '1664x832',
                            label: displayDOOHAspectRatioSizes ? '2:1' : '1664x832',
                            aspectRatio: '2:1',
                            ...(displayDOOHAspectRatioSizes
                                ? { content: [{ message: '(Recommended: 1664x832)' }] }
                                : {}),
                            supportedSizes: [
                                '1664x832',
                                '1152x576',
                                '840x400',
                                '624x288',
                                '368x176',
                            ],
                        },
                        ...(displayDOOHAspectRatioSizes
                            ? []
                            : [
                                  {
                                      value: '1400x400',
                                      label: '1400x400',
                                      aspectRatio: '7:2',
                                  },
                              ]),
                        {
                            value: '1280x960',
                            label: displayDOOHAspectRatioSizes ? '4:3' : '1280x960',
                            aspectRatio: '4:3',
                            ...(displayDOOHAspectRatioSizes
                                ? { content: [{ message: '(Recommended: 1280x960)' }] }
                                : {}),
                            supportedSizes: ['1280x960', '960x700'],
                        },
                        ...(displayDOOHAspectRatioSizes
                            ? []
                            : [
                                  {
                                      value: '1080x1920',
                                      label: '1080x1920',
                                      aspectRatio: '9:16 Vertical',
                                  },
                                  {
                                      value: '840x400',
                                      label: '840x400',
                                      aspectRatio: '2:1',
                                  },
                                  {
                                      value: '624x288',
                                      label: '624x288',
                                      aspectRatio: '2:1',
                                  },
                              ]),
                        {
                            value: '2160x3840',
                            label: displayDOOHAspectRatioSizes ? '9:16 Vertical' : '2160x3840',
                            aspectRatio: '9:16 Vertical',
                            ...(displayDOOHAspectRatioSizes
                                ? { content: [{ message: '(Recommended: 2160x3840)' }] }
                                : {}),
                            supportedSizes: ['2160x3840', '1080x1920'],
                        },
                    ],
                    icon: 'dooh_banner',
                    category: 'display',
                },
                {
                    value: 'video',
                    label: 'Video',
                    formats: [
                        {
                            value: 'video',
                            label: 'Hosted',
                            content: [
                                {
                                    message:
                                        'Serve video files from EngageFront. No tags required.',
                                },
                            ],
                        },
                    ],
                    audio_required: [
                        {
                            value: true,
                            label: 'Required',
                            sizes: [
                                {
                                    value: '1920x1080',
                                    aspectRatio: '16:9',
                                    label: displayDOOHAspectRatioSizes ? '16:9' : '1920x1080',
                                    ...(displayDOOHAspectRatioSizes
                                        ? { content: [{ message: '(Recommended: 1920x1080)' }] }
                                        : {}),
                                    supportedSizes: ['1920x1080'],
                                },
                                {
                                    value: '1400x400',
                                    label: displayDOOHAspectRatioSizes ? '7:2' : '1400x400',
                                    aspectRatio: '7:2',
                                    ...(displayDOOHAspectRatioSizes
                                        ? { content: [{ message: '(Recommended: 1400x400)' }] }
                                        : {}),
                                    supportedSizes: ['1400x400'],
                                },
                                {
                                    value: '840x400',
                                    label: displayDOOHAspectRatioSizes ? '2:1' : '840x400',
                                    aspectRatio: '2:1',
                                    ...(displayDOOHAspectRatioSizes
                                        ? { content: [{ message: '(Recommended: 840x400)' }] }
                                        : {}),
                                    supportedSizes: ['840x400'],
                                },
                                {
                                    value: '1080x1920',
                                    label: displayDOOHAspectRatioSizes
                                        ? '9:16 Vertical'
                                        : '1080x1920',
                                    aspectRatio: '9:16 Vertical',
                                    ...(displayDOOHAspectRatioSizes
                                        ? { content: [{ message: '(Recommended: 1080x1920)' }] }
                                        : {}),
                                    supportedSizes: ['1080x1920'],
                                },
                            ],
                        },
                        {
                            value: false,
                            label: 'Not Required',
                            // This looks like it is not being used anywhere.
                            sizes: [
                                {
                                    value: '5120x720',
                                    label: '5120x720',
                                },
                                {
                                    value: '1920x1080',
                                    label: '1920x1080',
                                },
                                {
                                    value: '1920x540',
                                    label: '1920x540',
                                },
                                {
                                    value: '1664x832',
                                    label: '1664x832',
                                },
                                {
                                    value: '1280x960',
                                    label: '1280x960',
                                },
                                {
                                    value: '1080x1920',
                                    label: '1080x1920',
                                },
                            ],
                        },
                    ],
                    // These are video without audio sizes
                    sizes: [
                        {
                            value: '5120x720',
                            label: displayDOOHAspectRatioSizes ? '64:9' : '5120x720',
                            aspectRatio: '64:9',
                            ...(displayDOOHAspectRatioSizes
                                ? { content: [{ message: '(Recommended: 5120x720)' }] }
                                : {}),
                            supportedSizes: ['5120x720'],
                        },
                        ...(displayDOOHAspectRatioSizes
                            ? []
                            : [
                                  {
                                      value: '2160x3840',
                                      label: '2160x3840',
                                      aspectRatio: '9:16 Vertical',
                                  },
                              ]),
                        {
                            value: '1920x1080',
                            label: displayDOOHAspectRatioSizes ? '16:9' : '1920x1080',
                            aspectRatio: '16:9',
                            ...(displayDOOHAspectRatioSizes
                                ? { content: [{ message: '(Recommended: 1920x1080)' }] }
                                : {}),
                            supportedSizes: [
                                '1920x1080',
                                '1350x759',
                                '1200x675',
                                '1024x576',
                                '740x416',
                                '448x252',
                            ],
                        },
                        {
                            value: '1920x540',
                            label: displayDOOHAspectRatioSizes ? '7:2' : '1920x540',
                            aspectRatio: '7:2',
                            ...(displayDOOHAspectRatioSizes
                                ? { content: [{ message: '(Recommended: 1920x540)' }] }
                                : {}),
                            supportedSizes: ['1920x540', '1400x400', '1088x320', '1056x288'],
                        },
                        {
                            value: '1664x832',
                            label: displayDOOHAspectRatioSizes ? '2:1' : '1664x832',
                            aspectRatio: '2:1',
                            ...(displayDOOHAspectRatioSizes
                                ? { content: [{ message: '(Recommended: 1664x832)' }] }
                                : {}),
                            supportedSizes: [
                                '1664x832',
                                '1152x576',
                                '840x400',
                                '624x288',
                                '368x176',
                            ],
                        },
                        ...(displayDOOHAspectRatioSizes
                            ? []
                            : [
                                  {
                                      value: '1400x400',
                                      label: '1400x400',
                                      aspectRatio: '7:2',
                                  },
                              ]),
                        {
                            value: '1280x960',
                            label: displayDOOHAspectRatioSizes ? '4:3' : '1280x960',
                            aspectRatio: '4:3',
                            ...(displayDOOHAspectRatioSizes
                                ? { content: [{ message: '(Recommended: 1280x960)' }] }
                                : {}),
                            supportedSizes: ['1280x960', '960x700'],
                        },
                        ...(displayDOOHAspectRatioSizes
                            ? [
                                  {
                                      value: '1080x1920',
                                      label: displayDOOHAspectRatioSizes
                                          ? '9:16 Vertical'
                                          : '1080x1920',
                                      aspectRatio: '9:16 Vertical',
                                      ...(displayDOOHAspectRatioSizes
                                          ? { content: [{ message: '(Recommended: 1080x1920)' }] }
                                          : {}),
                                      supportedSizes: ['1080x1920'],
                                  },
                              ]
                            : [
                                  {
                                      value: '1080x1920',
                                      label: '1080x1920',
                                      aspectRatio: '9:16 Vertical',
                                  },
                                  {
                                      value: '840x400',
                                      label: '840x400',
                                      aspectRatio: '2:1',
                                  },
                                  {
                                      value: '624x288',
                                      label: '624x288',
                                      aspectRatio: '2:1',
                                  },
                              ]),
                    ],
                    icon: 'dooh_video',
                    category: 'video',
                    placements: [],
                },
            ],
        },
    ];

    return CREATIVE_TABLE_VERSION_2;
}

export const getDOOHSizeVariants = (type, audio_required) => {
    const doohCreatives = getCreativeTable(true).find(({ value }) => value === 'dooh');
    const getSizesMap = sizes =>
        sizes.reduce((acc, { value, supportedSizes, label }) => {
            acc[value] = { supportedSizes, aspectRatio: label };
            return acc;
        }, {});
    let sizes = [];
    if (type === 'display_banner') {
        sizes = doohCreatives.types.find(({ value }) => value === 'display_banner').sizes;
        return getSizesMap(sizes);
    }
    if (type === 'video' && audio_required === true) {
        sizes = doohCreatives.types.find(({ value }) => value === 'video').audio_required[0].sizes;
        return getSizesMap(sizes);
    }
    sizes = doohCreatives.types.find(({ value }) => value === 'video').sizes;
    return getSizesMap(sizes);
};

export function getFormats({ platforms, type, exporter }) {
    if (!type) {
        return [];
    }

    const typesByPlatformFormat = _(platforms)
        .map(platformName => _.find(getCreativeTable(), { value: platformName }))
        .map(platform => {
            const targetType = _.find(platform.types, { value: type });

            if (!targetType) {
                return [];
            }
            if (
                !flags.isEnabled('en_3360_add_mweb_interstitial_creative_format') &&
                _.includes(['desktop', 'mweb'], platform.value) &&
                type === 'display_interstitial'
            ) {
                return [];
            }

            return targetType.formats;
        })
        .value();

    let filteredFormats = _.intersectionBy(...typesByPlatformFormat, f => f.value);

    filteredFormats = _.filter(filteredFormats, ({ value }) => {
        if (isSupportedExporter(exporter) && value === 'standard') {
            return false;
        }
        return true;
    });

    return filteredFormats;
}

export function getSizes(platforms, type, placements) {
    const sizesByPlatform = _(platforms)
        .map(platformName =>
            _.find(getCreativeTable(flags.isEnabled('efb_63_dooh_creative_resizing')), {
                value: platformName,
            })
        )
        .map(platform => {
            const targetType = _.find(platform.types, { value: type });

            if (!targetType) {
                return [];
            }

            // For Video type, we get sizes from their placements
            if (targetType.value === 'video' && !platforms.includes(CampaignTypeMapping.DOOH)) {
                const placementSizes = _(targetType.placements)
                    .filter(p => _.includes(placements, p.value))
                    .map(p => p.sizes)
                    .value();

                return _.intersectionBy(...placementSizes, s => s.value);
            } else {
                return targetType.sizes;
            }
        })
        .value();

    return _.intersectionBy(...sizesByPlatform, s => s.value);
}

export function getAudios(platforms, type) {
    const sizesByPlatform = _(platforms)
        .map(platformName => _.find(getCreativeTable(), { value: platformName }))
        .map(platform => {
            const targetType = _.find(platform.types, { value: type });

            if (!targetType) {
                return [];
            }

            return targetType.audio_required;
        })
        .value();

    return _.intersectionBy(...sizesByPlatform, s => s.value);
}

export function getAudioSizes(platforms, type, audio_required) {
    const sizesByPlatform = _(platforms)
        .map(platformName =>
            _.find(getCreativeTable(flags.isEnabled('efb_63_dooh_creative_resizing')), {
                value: platformName,
            })
        )
        .map(platform => {
            const targetType = _.find(platform.types, { value: type });

            if (!targetType) {
                return [];
            }

            // For Video type, we get sizes from their placements
            if (targetType.audio_required) {
                const placementSizes = _(targetType.audio_required)
                    .filter(a => a.value === audio_required)
                    .map(a => a.sizes)
                    .value();

                return _.intersectionBy(...placementSizes, s => s.value);
            } else {
                return targetType.sizes;
            }
        })
        .value();

    return _.intersectionBy(...sizesByPlatform, s => s.value);
}

export function getPlacements(platforms, campaignType) {
    if (campaignType === CampaignTypeMapping.DOOH) {
        // DOOH creatives don't have placements.
        return [];
    }

    const placementsByPlatform = _(platforms)
        .map(platformName => _.find(getCreativeTable(), { value: platformName }))
        .map(platform => {
            const targetType = _.find(platform.types, { value: 'video' });

            if (!targetType) {
                return [];
            }

            return targetType.placements;
        })
        .value();

    return _.intersectionBy(...placementsByPlatform, p => p.value);
}

export function getPositions(campaignType) {
    if (campaignType === CampaignTypeMapping.DOOH) {
        // DOOH creatives don't have any positions.
        return [];
    }

    return POSITIONS;
}

export const platformsMapping = {};
_.forEach(getCreativeTable(), platform => (platformsMapping[platform.value] = platform.label));

export const typesMapping = {};
_.forEach(getCreativeTable(), ({ types }) => {
    _.forEach(types, type => (typesMapping[type.value] = type.label));
});

export const formatsMapping = {};
_.forEach(getCreativeTable(), ({ types }) => {
    _.forEach(types, ({ formats }) => {
        _.forEach(formats, format => (formatsMapping[format.value] = format.label));
    });
});

export const placementsMapping = {};
_.forEach(getCreativeTable(), ({ types }) => {
    _.forEach(types, ({ placements }) => {
        _.forEach(placements, placement => (placementsMapping[placement.value] = placement.label));
    });
});

export const positionsMapping = {};
_.forEach(POSITIONS, position => (positionsMapping[position.value] = position.label));

export const sizesMapping = {};
_.forEach(getCreativeTable(), ({ types }) => {
    _.forEach(types, ({ sizes }) => {
        _.forEach(sizes, size => (sizesMapping[size.value] = size.label));
    });
    _.forEach(types, ({ placements }) => {
        _.forEach(placements, ({ sizes }) => {
            _.forEach(sizes, size => (sizesMapping[size.value] = size.label));
        });
    });
});

export function getAllViablePlatforms({
    type,
    size,
    format,
    placements,
    currentPlatforms,
    campaignType,
}) {
    return _(getCreativeTable())
        .filter(item => item.allowedOnCampaignType === campaignType)
        .filter(removeUnsupportedPlatforms({ type, size, format, placements, currentPlatforms }))
        .map(getPlatformValue)
        .value();
}

export function getThirdPartyVendors({ exporter, existingThirdPartyVendors }) {
    return isSupportedExporter(exporter)
        ? getExporter(exporter).vendors
        : existingThirdPartyVendors;
}

export function getPlatformsAvailableForCampaign(campaignType) {
    return getCreativeTable()
        .filter(item => item.allowedOnCampaignType === campaignType)
        .map(item => {
            return { label: item.label, value: item.value };
        });
}

function removeUnsupportedPlatforms({ type, size, format, placements, currentPlatforms }) {
    return platform => {
        // If this is a Connected TV creative then it only supports CTV.
        if (currentPlatforms && currentPlatforms.includes('ctv') && platform.value !== 'ctv') {
            return false;
        }

        // If this is a DOOH creative then it only supports DOOH.
        if (currentPlatforms && currentPlatforms.includes('dooh') && platform.value !== 'dooh') {
            return false;
        }

        const targetType = _.find(platform.types, { value: type });
        if (!targetType) {
            return false;
        }

        const targetFormat = _.find(targetType.formats, { value: format });
        if (!targetFormat) {
            return false;
        }

        if (type === 'video' && !currentPlatforms.includes('dooh')) {
            const hasAllPlacements = _.every(placements, placement =>
                _.find(targetType.placements, { value: placement })
            );
            if (!hasAllPlacements) {
                return false;
            }

            const hasTargetSize = _(placements)
                .map(placement => _.find(targetType.placements, { value: placement }))
                .filter(placement => placement)
                .map(placement => placement.sizes)
                .flatten()
                .filter(placementSize => placementSize.value === size)
                .some(Boolean);

            if (!hasTargetSize) {
                return false;
            }
        } else {
            const targetSize = _.find(targetType.sizes, { value: size });
            if (!targetSize) {
                return false;
            }
        }

        return true;
    };
}

export function getPlatformValue(platform) {
    return platform.value;
}

export function getValidSizes() {
    const output = [];

    _.each(getCreativeTable(), row => {
        const availableTypes = _.filter(
            row.types,
            type =>
                type.value !== 'video' &&
                type.value !== 'native' &&
                type.value !== 'display_expandable'
        );
        _.each(availableTypes, ({ sizes }) => {
            _.each(sizes, ({ value: size }) => {
                if (size !== '0x0') {
                    output.push(size);
                }
            });
        });
    });

    const sortedOutput = _.sortBy(output, size => {
        const width = _.split(size, 'x')[0];
        return _.toNumber(width);
    });

    return _.uniq(sortedOutput);
}

export function getValidImageSizeMapping(acceptableRatioCount) {
    const output = {};

    _.each(getValidSizes(), size => {
        const [width, height] = size.split('x');
        const iterations = _.range(1, acceptableRatioCount ? acceptableRatioCount : 10);

        _.each(iterations, iteration => {
            const key = `${parseInt(width) * iteration}x${parseInt(height) * iteration}`;
            output[key] = size;
        });
    });

    return output;
}

export function getValidImageSize(inputSize) {
    const mapping = getValidImageSizeMapping();
    return mapping[inputSize] ? mapping[inputSize] : null;
}

const getCreativeTrackingVendorsMapping = () => {
    let mapping = {};

    _.forEach(TAGS, tag => {
        mapping[tag.value] = tag.label;
    });
    return mapping;
};

export const creativeTrackingVendorsMapping = getCreativeTrackingVendorsMapping();
