import React from 'react';

import BaseColumnForm from '../base-column-form';

import shallowCompare from 'utils/shallow-compare';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

export const PublicInventoryForm = props => {
    const { ad, ads, campaign, onSave } = props;

    const setDealApplicationType = (e, updateDraft) => {
        const dealApplicationType = e.target.checked ? 'deal_augmented' : 'deal_only';
        updateDraft({ dealApplicationType });
    };

    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline="Public Inventory"
            fields={['dealApplicationType']}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSave}
            onCancel={onSave}
            columnName="Public Inventory"
        >
            {(updateDraft, shouldShowErrors, errors, draft) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={draft.dealApplicationType === 'deal_augmented'}
                            onChange={e => setDealApplicationType(e, updateDraft)}
                            value="dealApplicationType"
                            color="primary"
                        />
                    }
                    label="Bid on open auctions"
                />
            )}
        </BaseColumnForm>
    );
};

export class PublicInventorySummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad } = this.props;

        return (
            <div>
                <Typography>
                    {ad.dealApplicationType === 'deal_augmented'
                        ? 'Bid on open auctions'
                        : 'Do not bid on open auctions'}
                </Typography>
            </div>
        );
    }
}
