import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _ from 'lodash';

import ArchiveIcon from '@mui/icons-material/Archive';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Simulator from 'widgets/simulator';
import {
    canEditCreative,
    canDuplicateCreative,
    canArchiveCreative,
} from 'states/resources/creatives/business-logic';

const getActions = ({
    creative,
    campaign,
    editCreative,
    campaignId,
    duplicateCreative,
    creativeId,
    openCreativeHistory,
    setPermalink,
    creativeFormat,
    openArchiveConfirmModal,
}) => {
    const options = [
        {
            label: 'Edit',
            link: null,
            canView: canEditCreative(campaign),
            onClick: () => editCreative(creativeId),
            icon: <i className="fa fa-fw fa-pencil" />,
        },
        {
            label: 'History',
            link: null,
            canView: () => true,
            onClick: () => openCreativeHistory(creativeId),
            icon: <i className="fa fa-fw fa-clock-o" />,
        },
        {
            label: 'Duplicate',
            link: null,
            canView: canDuplicateCreative(campaign),
            onClick: () => duplicateCreative(campaignId, creativeId),
            icon: <i className="fa fa-fw fa-files-o" />,
        },
        {
            label: 'Archive',
            link: null,
            canView: canArchiveCreative(campaign),
            onClick: () => openArchiveConfirmModal(creativeId),
            icon: <ArchiveIcon />,
        },
        {
            label: 'Permalink',
            link: `/campaigns/${campaignId}/setup/creatives/#${creativeId}`,
            canView: () => true,
            onClick: event => {
                setPermalink(creativeId, event.target.href);
            },
            icon: <i className="fa fa-link" />,
        },
        {
            label: 'Simulate',
            link: null,
            canView: () => {
                return creativeFormat === 'third_party_vast' ? false : true;
            },
            onClick: () => Simulator.openInNewWindow(creative),
            icon: <i className="fa fa-mobile" />,
        },
    ];

    return _.filter(options, option => option.canView);
};

const CreativeActionsMenu = ({
    campaign,
    campaignId,
    creative,
    creativeId,
    editCreative,
    creativeFormat,
    openCreativeHistory,
    duplicateCreative,
    setPermalink,
    openArchiveConfirmModal,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const creativeActions = getActions({
        creative,
        campaign,
        editCreative,
        campaignId,
        duplicateCreative,
        creativeId,
        openCreativeHistory,
        setPermalink,
        creativeFormat,
        openArchiveConfirmModal,
    });
    return (
        <React.Fragment>
            <IconButton onClick={handleClick} size="large">
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} elevation={1}>
                {_.map(creativeActions, action => (
                    <MenuItem key={action.label} onClick={action.onClick}>
                        <Link to={action.link} color="inherit" underline="none" component={MuiLink}>
                            {action.label}
                        </Link>
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
};

export default connect()(CreativeActionsMenu);
