import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';

import env from '../../../../config';

/*
    Example usage:

    creativeJSON = {
        format
        size
        content_html
        mraid
    }

    Use this for the simulator button on creative
    Simulator.openInNewWindow(creativeJSON);

    Use this for the form version
    <Simulator creative={creativeJSON} />
*/

const Simulator = createReactClass({
    state: { mounted: false },

    displayName: 'Simulator',

    statics: {
        openInNewWindow: openInNewWindow,
    },

    reloadIframe(creative) {
        if (this.state.mounted) {
            generateForm(creative).submit();
        }
    },

    openInNewWindow() {
        openInNewWindow(this.props.creative);
    },

    UNSAFE_componentWillMount() {
        this.debouncedReloadIframe = _.debounce(this.reloadIframe, 0);
    },

    componentDidMount() {
        this.setState({
            mounted: true,
        });
        this.debouncedReloadIframe(this.props.creative);
    },

    componentDidUpdate() {
        this.debouncedReloadIframe(this.props.creative);
    },

    shouldComponentUpdate(nextProps) {
        if (nextProps.className !== this.props.className) {
            return true;
        }

        return !_.isEqual(this.props.creative, nextProps.creative);
    },

    isVideoOrVast() {
        return (
            this.props.creative.format === 'video' ||
            this.props.creative.format === 'third_party_vast'
        );
    },

    render() {
        return (
            <div className="ef3-creativeSimulator">
                <div className="ef3-creativeSimulator_container">
                    <iframe className="ef3-creativeSimulator_simulator" name="creative-simulator" />
                </div>
                <div className="ef3-creativeSimulator__message">
                    <p>
                        <strong>Creative simulator is shown for reference only.</strong>
                    </p>
                    <br />
                    {this.isVideoOrVast() ? (
                        <p>
                            Some features and functionality (e.g. Skip Ad button) may depend on its
                            actual live environment.
                        </p>
                    ) : (
                        <p>
                            Some features and functionality may depend on its actual live
                            environment.
                        </p>
                    )}
                    <p>
                        If the creative or clickthrough loads from an insecure source (does not
                        begin with 'https://'), it may not render properly in this window. Try
                        previewing in a{' '}
                        <a href="#" onClick={this.openInNewWindow}>
                            new window
                        </a>{' '}
                        instead.
                    </p>
                </div>
            </div>
        );
    },
});

function openInNewWindow(creative) {
    var form = generateForm(creative, 'popup');

    window.open(
        '',
        'simulator-window',
        'width=700,height=850,status=yes,resizable=yes,scrollbars=yes'
    );

    form.submit();

    document.body.removeChild(form);
}

function generateForm(creative, mode = 'embedded') {
    let _creative = creative;

    let prevForm = document.querySelector('#creative-simulator-form');
    if (prevForm) {
        document.body.removeChild(prevForm);
    }

    var form = document.createElement('form');

    document.body.appendChild(form);

    let endpoint;
    switch (mode) {
        case 'embedded':
            endpoint = `${env.SIMULATOR_ENDPOINT}?mode=embedded`;

            switch (_creative.format) {
                case 'standard':
                    _creative = {
                        ..._creative,
                        image_url: _creative.image_preview_url,
                    };
                    break;
                case 'video':
                    _creative = {
                        ..._creative,
                        video_url: _creative.video_preview_url,
                        companion_url: _creative.companion_preview_url,
                    };
                    break;
                case 'native':
                    _creative = {
                        ..._creative,
                        icon_image_url: _creative.icon_image_preview_url,
                        main_image_url: _creative.main_image_preview_url,
                    };
                    break;
            }
            break;
        case 'popup':
            endpoint = env.SIMULATOR_ENDPOINT;
            break;
    }

    form.method = 'POST';
    form.id = 'creative-simulator-form';
    form.target = mode === 'embedded' ? 'creative-simulator' : 'simulator-window';
    form.action = endpoint;
    form.style = 'display:none;';

    Object.keys(_creative)
        .filter(field => _creative[field] !== undefined && _creative[field] !== '')
        .map(generateInputFieldsFrom(_creative))
        .forEach(function appendToForm(field) {
            form.appendChild(field);
        });

    return form;
}

function generateInputFieldsFrom(creative) {
    return function(fieldName) {
        return createField(fieldName, creative[fieldName]);
    };
}

function createField(name, value) {
    var field = document.createElement('input');

    field.type = 'text';
    field.name = name;
    field.value = _.isNil(value)
        ? ''
        : value
              .toString()
              .split('\n')
              .join('##LINEHACK##');

    return field;
}

export default Simulator;
