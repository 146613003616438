import _ from 'lodash';

export default function selector(storeState, props) {
    const dealsToAdMapping = _.get(storeState, 'mediaPlanForm.dealsToAdMapping');
    const { ad } = props;
    const dealsInAd = _.get(dealsToAdMapping, ad.id);
    const dealsByOrganization = _.get(storeState, 'mediaPlanForm.dealsByOrganization');

    return {
        dealsInAd,
        dealsByOrganization,
        ad: { start: ad.start, end: ad.end },
    };
}

export function formSelector(storeState) {
    const dealsByOrganization = _.get(storeState, 'mediaPlanForm.dealsByOrganization');

    return {
        dealsByOrganization,
    };
}
