import React from 'react';

import Grid from '@mui/material/Grid';

import actions from '../actions';

import { TextField } from 'widgets-v6/standard-input';
import { getEnvironmentSettings } from 'services/environment';

class UserInput extends React.Component {
    constructor(props) {
        super(props);
        this._cache = {};
    }

    state = {
        activeTab: 'editor',
    };

    onFieldChange = fieldName => {
        const { dispatch, appListId } = this.props;

        return fieldValue => {
            const newFormData = { [fieldName]: fieldValue };
            dispatch(actions.updateDraft(newFormData, appListId));
        };
    };

    handleCancel = () => {
        const { dispatch, appListId } = this.props;

        dispatch(actions.cancel(appListId));
    };

    syncUserInputWithBackendFields = () => {
        const { appListId } = this.props;

        const data = {
            bundleIdInput: this._cache.bundleIdInput,
            domainInput: this._cache.domainInput,
            connectedTVIdsInput: this._cache.connectedTVIdsInput,
            doohBoardIdsInput: this._cache.doohBoardIdsInput,
        };

        this.props.dispatch(actions.sync({ appListId, data }));

        this.setState({
            activeTab: this.state.activeTab === 'editor' ? 'app' : 'editor',
        });
    };

    handleTextAreaChange = field => event => {
        this._cache[field] = event.target.value.toLowerCase();
    };

    handleTextAreaChange2 = field => event => {
        this.onFieldChange(field)(event.target.value);
    };

    handleTextAreaBlur = field => event => {
        this.onFieldChange(field)(event.target.value);
    };

    render() {
        const { draft, showErrors, errorMapping } = this.props;

        const { bundleIdInput, domainInput, connectedTVIdsInput, doohBoardIdsInput } = draft;

        this._cache.bundleIdInput = bundleIdInput;
        this._cache.domainInput = domainInput;
        this._cache.connectedTVIdsInput = connectedTVIdsInput;
        this._cache.doohBoardIdsInput = doohBoardIdsInput;

        return (
            <div className="userInput">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Mobile Apps"
                            onChange={this.handleTextAreaChange2('bundleIdInput')}
                            value={bundleIdInput}
                            multiline
                            helperText='Enter Bundle IDs from Apple App Store (e.g. "529479190") or Application IDs from Google Play Store (e.g. "com.supercell.clashofclans").'
                            fullWidth={true}
                            rowsMax={15}
                            error={
                                showErrors &&
                                (errorMapping['__appsAndSites'] || errorMapping['__bundleId'])
                            }
                            placeholder="529479190&#10;com.supercell.clashofclans"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Sites"
                            onChange={this.handleTextAreaChange2('domainInput')}
                            value={domainInput}
                            multiline
                            helperText='Enter domains without the leading "http://", "https://", or "www" (e.g. "google.com", "yahoo.co.uk").'
                            fullWidth={true}
                            rowsMax={15}
                            error={
                                showErrors &&
                                (errorMapping['__appsAndSites'] || errorMapping['__domain'])
                            }
                            placeholder="google.com&#10;yahoo.co.uk"
                        />
                    </Grid>
                    {getEnvironmentSettings().canUseCTVOrDOOH() && (
                        <Grid item xs={6}>
                            <TextField
                                label="Connected TV Apps"
                                onChange={this.handleTextAreaChange2('connectedTVIdsInput')}
                                value={connectedTVIdsInput}
                                multiline
                                helperText="Enter Connected TV IDs"
                                fullWidth={true}
                                rowsMax={15}
                                error={showErrors && errorMapping['__appsAndSites']}
                                placeholder="net.relaxio.relaxio&#10;molotov.tv"
                            />
                        </Grid>
                    )}
                    {getEnvironmentSettings().canUseCTVOrDOOH() && (
                        <Grid item xs={6}>
                            <TextField
                                label="Digital Out-of-Home Board IDs"
                                onChange={this.handleTextAreaChange2('doohBoardIdsInput')}
                                value={doohBoardIdsInput}
                                multiline
                                helperText="Enter Board IDs"
                                fullWidth={true}
                                rowsMax={15}
                                error={showErrors && errorMapping['__appsAndSites']}
                                placeholder="0vr3i:QCD4008A&#10;7Z_t_:E62x863410049839167"
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }
}

export default UserInput;
