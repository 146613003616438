import _ from 'lodash';

import { DeviceOSMapping, PlatformMapping } from 'states/resources/ads/business-logic';
import { INVENTORY_FORECAST_CACHE_ALLOW } from 'pages/prediction-editor/constants';

export const MIN_VALID_DRAFT = (overrides = {}) => ({
    daysRunning: 1,

    platforms: [PlatformMapping.INAPP],
    geotargets: [{ country: 'CA', include: [], exclude: [] }],
    creativeSize: null,
    creativeFormat: null,
    creativeType: null,

    target_age_groups: [],
    target_genders: [],
    target_video_skippable: '',
    target_device_os: [DeviceOSMapping.IOS, DeviceOSMapping.Android],
    target_device_language: [],
    target_device_language_exclude: [],
    placements: [],
    positions: [],

    fcaps: {
        imp: 5,
        interval_count: 1,
        interval_unit: 'days',
        start: Date.now(),
    },

    geo_targeting_settings: {
        categories: [],
        custom_layers: [],
        category_layers: [],
        radius: 500,
        retarget: true,
        target: true,
        days_to_retarget: 21,
    },

    target_app_store_cat: [],
    target_app_store_cat_exclude: [],

    audiences: [],
    audience_exclude: [],

    // FTA
    ftaEnabled: false,

    // Total spend
    max_cpm_rate_local: 0,

    // Third Party Fees
    totalThirdPartyFees: 0,

    // Bid Price
    bidRate: 0,

    pattisonBillboards: [],
    tactics_generators: [],

    // Apps and sites
    whitelistedAppsAndSites: [],
    blacklistedAppsAndSites: [],

    // Dooh
    target_boards: [],
    target_boards_exclude: [],
    target_dooh_venues: [],
    target_dooh_venues_exclude: [],

    // V4
    cache_strategy: INVENTORY_FORECAST_CACHE_ALLOW,

    ...overrides,
});

//-- validation rules --//
export function getDraftValidators({ audiencesMapping }) {
    return {
        daysRunning(value) {
            if (!value) {
                return 'Inventory Prediction requires an ad to run for at least one day';
            }
        },

        creativeSize(value, source) {
            if (!value && !source.platforms.includes(PlatformMapping.DOOH)) {
                return 'Inventory Prediction requires a creative size';
            }
        },
        creativeFormat(value, source) {
            if (!value && !source.platforms.includes(PlatformMapping.DOOH)) {
                return 'Inventory Prediction requires a creative format';
            }
        },
        creativeType(value, source) {
            if (!value && !source.platforms.includes(PlatformMapping.DOOH)) {
                return 'Inventory Prediction requires a creative type';
            }
        },
        max_cpm_rate_local(value) {
            if (!value) {
                return 'Inventory Prediction requires a bid rate';
            }
        },

        fcaps(value) {
            if (!value.imp) {
                return 'Inventory Prediction requires at least a frequency cap of 1 impression per user.';
            }
        },

        audiences(value, source) {
            const isUsingNonPdsIncludeSegments = _(source.audiences)
                .map(audienceId => audiencesMapping[audienceId])
                .filter(x => x)
                .some(audience => audience.source_type.indexOf('pds_') === -1);

            if (isUsingNonPdsIncludeSegments) {
                return 'Inventory Prediction is only supported for PDS Audience Segments';
            }

            const isUsingNonPdsExcludeSegments = _(source.audience_exclude)
                .map(audienceId => audiencesMapping[audienceId])
                .some(audience => audience.source_type.indexOf('pds_') === -1);

            if (isUsingNonPdsExcludeSegments) {
                return 'Inventory Prediction is only supported for PDS Audience Segments';
            }
        },
    };
}
