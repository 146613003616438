import _ from 'lodash';
import toastr from 'toastr';

import http, { createHttp } from 'utils/http';
import notify from 'utils/notify';
import CampaignActions from 'states/resources/campaigns/actions';
import AdActions from 'states/resources/ads/actions';
import AudienceActions from 'containers/audience-segment-picker/actions';
import { getDaysRunning, calculateBidPriceFromAd } from 'states/resources/ads/business-logic';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const actions = {
    init(campaignId) {
        return async (dispatch, getState) => {
            dispatch({
                type: 'MEDIA_PLAN_FORM__INIT',
                payload: {},
            });

            const orgId = _.get(getState(), 'profile.organizationId');

            const ads = await dispatch(AdActions.getAll(campaignId));

            const dealsToAdMapping = {};

            const campaign = await dispatch(CampaignActions.get(campaignId));
            const [pageData] = await Promise.all([
                fetchMediaPlanFormData(orgId),
                ..._.map(ads, async ad => {
                    const deals = await dispatch(actions.fetchAdDeals(ad.id));
                    dealsToAdMapping[ad.id] = deals;
                }),
                dispatch(
                    AudienceActions.fetchAudiencesWithGraphQl(campaign.shouldHideFlexSegments)
                ),
            ]);

            dispatch({
                type: 'MEDIA_PLAN_FORM__INIT_END',
                payload: {
                    campaign,
                    ads,
                    dealsToAdMapping,
                    ...pageData,
                },
            });
        };
    },
    cleanup() {
        return { type: 'MEDIA_PLAN_FORM__CLEANUP' };
    },
    fetchAdDeals(adId) {
        return async dispatch => {
            const deals = await fetchDealForAd(adId);
            dispatch({ type: 'MEDIA_PLAN_FORM__FETCH_DEAL_END', payload: { deals, adId } });
            return deals;
        };
    },
    fetchPrediction(adIds, campaign) {
        return (dispatch, getState) => {
            async function saveNext(_adIds) {
                if (_adIds.length === 0) {
                    return;
                }

                const adId = _adIds[0];
                const rest = _adIds.slice(1);
                const ad = _.get(getState(), `resources.ads.${adId}.attr`);

                dispatch({
                    type: 'MEDIA_PLAN_FORM__FETCH_PREDICTION_START',
                    payload: { adId: ad.id },
                });

                const creativeResources = _.get(getState(), 'resources.creatives', {});
                const audiences = _.get(getState(), 'resources.audiences', {});

                const orgId = _.get(getState(), 'profile.organizationId');

                const techFee = await fetchOrganizationTechFee(orgId);

                const bidRate = calculateBidPriceFromAd(ad, techFee);

                const isUsingNonPdsIncludeSegments = _(ad.audiences)
                    .map(audienceId => audiences[audienceId] && audiences[audienceId].attr)
                    .some(audience => audience.source_type.indexOf('pds_') === -1);

                const isUsingNonPdsExcludeSegments = _(ad.audience_exclude)
                    .map(audienceId => audiences[audienceId] && audiences[audienceId].attr)
                    .some(audience => audience.source_type.indexOf('pds_') === -1);

                let inventoryPredictionError;

                if (!ad.start || !ad.end) {
                    inventoryPredictionError = 'Set start and end dates to see forecast.';
                } else if (!ad.max_cpm_rate_local) {
                    inventoryPredictionError = 'Set Total Bid Price to see forecast.';
                } else if (ad.fcaps.interval_unit === 'hours') {
                    inventoryPredictionError = 'Forecast not supported for hourly frequency cap.';
                } else if (ad.fcaps.interval_unit === 'weeks') {
                    inventoryPredictionError = 'Forecast not supported for weekly frequency cap.';
                } else if (ad.fcaps.interval_unit === 'ad') {
                    inventoryPredictionError =
                        'Forecast not supported for frequency caps based on ad lifetime.';
                } else if (ad.rotation_rules.mode !== CreativeRotationMethodMapping.Single) {
                    inventoryPredictionError = 'Forecast not supported for rotated creatives.';
                } else if (ad.creative.length === 0) {
                    inventoryPredictionError = 'Select a creative to see forecast';
                } else if (ad.geotargets.length === 0) {
                    inventoryPredictionError = 'Select a country to see forecast';
                } else if (ad.geotargets.length > 1 || ad.geotargets[0].country !== 'CA') {
                    inventoryPredictionError = 'Forecast not supported fore regions outside Canada';
                } else if (isUsingNonPdsIncludeSegments || isUsingNonPdsExcludeSegments) {
                    inventoryPredictionError = 'Forecast not supported for custom segments';
                }

                if (inventoryPredictionError) {
                    dispatch({
                        type: 'MEDIA_PLAN_FORM__FETCH_PREDICTION_ERROR',
                        payload: { adId: ad.id },
                        error: inventoryPredictionError,
                    });
                    return;
                }

                let creativeSize = _.get(creativeResources, `${ad.creative[0]}.attr.size`);
                let creativeFormat = _.get(creativeResources, `${ad.creative[0]}.attr.format`);

                const query = {
                    geo_cregion_include: _(ad.geotargets)
                        .map(target => target.include)
                        .flatten()
                        .filter(includedTarget => !includedTarget.city)
                        .map(includedTarget => includedTarget.region)
                        .value(),
                    geo_cregion_exclude: _(ad.geotargets)
                        .map(target => target.exclude)
                        .flatten()
                        .filter(includedTarget => !includedTarget.city)
                        .map(includedTarget => includedTarget.region)
                        .value(),
                    geo_city_include: _(ad.geotargets)
                        .map(target => target.include)
                        .flatten()
                        .filter(includedTarget => includedTarget.city)
                        .map(includedTarget => includedTarget.city)
                        .value(),
                    geo_city_exclude: _(ad.geotargets)
                        .map(target => target.exclude)
                        .flatten()
                        .filter(includedTarget => includedTarget.city)
                        .map(includedTarget => includedTarget.city)
                        .value(),
                    creative_size: creativeSize,
                    creative_format: creativeFormat,
                    age_groups: ad.target_age_groups,
                    genders: ad.target_genders,
                    device_os: ad.target_device_os,
                    bid_rate: ad.max_cpm_rate_local,
                    frequency_cap: ad.fcaps.imp,
                };

                query.bid_rate = bidRate;

                query.tactics_generators = ad.tactics_generators;

                try {
                    const requestMin = await http().post('inventory-predictions', {
                        ...query,
                        ctr: [1],
                    });

                    const requestMax = await http().post('inventory-predictions', {
                        ...query,
                        ctr: [0, 1],
                    });

                    const daysRunning = getDaysRunning(ad);

                    dispatch({
                        type: 'MEDIA_PLAN_FORM__FETCH_PREDICTION_END__EF_51',
                        payload: {
                            adId: ad.id,
                        },
                    });

                    // save the prediction
                    await dispatch(
                        AdActions.update(campaign.id, ad.id, {
                            predicted_total_impressions_min:
                                requestMin.dailyAvailableImpressions * daysRunning,
                            predicted_total_uniques_min: requestMin.dailyUniqueUsers,

                            predicted_total_impressions_max:
                                requestMax.dailyAvailableImpressions * daysRunning,
                            predicted_total_uniques_max: requestMax.dailyUniqueUsers,
                        })
                    );

                    saveNext(rest);
                } catch (err) {
                    dispatch({
                        type: 'MEDIA_PLAN_FORM__FETCH_PREDICTION_ERROR',
                        payload: { adId: ad.id },
                        error: err,
                    });
                }
            }

            saveNext(adIds);
        };
    },
    saveStart(adId) {
        return {
            type: 'MEDIA_PLAN_FORM__SAVE_AD_START',
            payload: { adId },
        };
    },
    saveEnd(column, adId) {
        return {
            type: 'MEDIA_PLAN_FORM__SAVE_AD_END',
            payload: { adId, column },
        };
    },
    saveError(column, adId) {
        return {
            type: 'MEDIA_PLAN_FORM__SAVE_AD_ERROR',
            payload: { adId, column },
            error: 'Failed to save. Please try again.',
        };
    },
    submitForm(save, adIdsToSave, column, campaign) {
        return dispatch => {
            return new Promise(resolve => {
                const savedAdIds = [];
                const errorAdIds = [];
                const errorMessages = [];

                // Save ads sequentially to avoid etag conflicts from saving the same resource too quickly
                function saveNext(_adIds) {
                    if (_adIds.length === 0) {
                        dispatch({
                            type: 'MEDIA_PLAN_FORM__SAVE_ALL_COLUMN_ADS_END',
                        });
                        resolve({ savedAdIds, errorAdIds, errorMessages });
                        return;
                    }

                    const adId = _adIds[0];
                    const rest = _adIds.slice(1);

                    dispatch(actions.saveStart(adId));

                    save(adId)
                        .then(
                            ad => {
                                if (campaign.budget_allocation_method === 'auto') {
                                    dispatch({
                                        type: 'SYSTEM__CAMPAIGN_ADS__AUTO_BUDGET_AD__EDIT',
                                        payload: {
                                            etag: ad._etag,
                                            adIds: campaign.ads,
                                        },
                                    });
                                }
                                dispatch(actions.saveEnd(column, adId));
                                savedAdIds.push(ad.id);
                            },
                            err => {
                                dispatch(actions.saveError(column, adId, err));
                                const error = err?.body
                                    ?.map(e => e.message)
                                    .filter(e => !_.isEmpty(e))
                                    .join(', '); // Sanitize errors
                                errorMessages.push(error);
                                errorAdIds.push(adId);
                            }
                        )
                        .then(() => {
                            saveNext(rest);
                        });
                }

                saveNext(adIdsToSave);
            });
        };
    },
    toggleColumn(column) {
        return {
            type: 'MEDIA_PLAN_FORM__TOGGLE_COLUMN',
            payload: { column },
        };
    },
    setPlatformFilter(filter) {
        return {
            type: 'MEDIA_PLAN_FORM__SET_ACTIVE_PLATFORM_FILTER',
            payload: { filter },
        };
    },
};

async function fetchOrganizationTechFee(orgId) {
    const http = createHttp();
    const query = `
        query getTechFeeDataForMediaPlanForm($id: String) {
            organization(id: $id) {
                tech_fee
            }
        }
    `;
    const variables = {
        id: orgId,
    };
    try {
        const { organization } = await http.graphql(query, variables);
        return organization.tech_fee;
    } catch (error) {
        if (error.response.status === 401) {
            return;
        }
        toastr.warning(
            '',
            '<p>Something went wrong, please try again later. The EngageFront team has been notified</p>',
            TOASTR_OPTIONS
        );
        if (window.bugsnagClient) {
            window.bugsnagClient.notify(
                `Failed to fetch organization tech fee data in Media Plan Form`,
                {
                    metaData: {
                        orgId: `${orgId}`,
                    },
                }
            );
        }
    }
}

async function fetchMediaPlanFormData(id) {
    const http = createHttp();
    const query = `
        query getOrganizationForMediaPlanForm ($id: String) {
            organization(id: $id) {
                id
                name
                fta_partner_id
            }
            ftaLocationLists {
                id
                name
            }
            applists(filters: {}) {
                id
                name
            }
            deals {
                id
                app_name
                category
                start_date
                end_date
            }
        }
    `;
    const variables = {
        id,
    };
    try {
        const data = await http.graphql(query, variables);
        return data;
    } catch (error) {
        notify({ error, metaData: { organizationId: id } });
    }
}

async function fetchDealForAd(adId) {
    const http = createHttp();
    const query = `
        query getOrganizationForMediaPlanForm ($adIds: [Int]) {
            deals(filter: { adIds: $adIds }) {
                    id
                    app_name
                    category
                    start_date
                    end_date
            }
        }
    `;
    const variables = {
        adIds: [Number(adId)],
    };
    try {
        const { deals } = await http.graphql(query, variables);
        return deals;
    } catch (error) {
        notify({ error, metaData: { adId } });
    }
}

export default actions;
