import _ from 'lodash';
import { createHttp } from 'utils/http';
import toastr from 'toastr';
import { metricConfig } from 'services/metrics';

const http = createHttp();

const BEACON_PREFIX = 'event_';
const CONVERSION_PREFIX = 'conv_';
const OVERALL_ENGAGEMENTS_PREFIX = 'overall_engagements';
const STANDARD_METRICS = [
    'billings_local',
    'clicks',
    'daily_uniq',
    'unique_campaign',
    'unique_ad',
    'unique_creative',
    'unique_ad_creative',
    'impressions',
    'video_impressions',
    'owner_inventory_cost_local',
    'owner_total_media_cost_local',
    'placed_bid',
    'revenue',
    'spend',
    'owner_media_cost_2_local',
    'data_cost',
    'third_party_fees',
    'owner_total_media_cost_local_ecpm',
    'owner_total_media_cost_local_ecpc',
];

const DERIVED_METRICS = [
    'average_freq',
    'ecpc',
    'ecpm',
    'erpc',
    'erpm',
    'win_rate',
    'vcr',
    'owner_total_media_cost_local_ecpcv',
    'revenue_ecpcv',
];

const calcCtr = (clicks = 0, impressions = 0) => {
    return impressions === 0 ? 0 : clicks / impressions;
};
const calcEcpm = (impressions, spend) => {
    return impressions === 0 ? 0 : (spend * 1000) / impressions;
};
const calcEcpc = (clicks, spend) => {
    return clicks === 0 ? 0 : spend / clicks;
};
const calcErpm = (impressions, revenue) => {
    return impressions === 0 ? 0 : (revenue * 1000) / impressions;
};
const calcErpc = (clicks, revenue) => {
    return clicks === 0 ? 0 : revenue / clicks;
};
const calcAverageFrequency = (impressions, dailyUniq) => {
    return dailyUniq === 0 ? 0 : impressions / dailyUniq;
};
const calcWinRate = (impressions, placedBid) => {
    return placedBid === 0 ? 0 : impressions / placedBid;
};
const calcVCR = (event_video_complete = 0, video_impressions = 0) => {
    return video_impressions === 0 ? 0 : event_video_complete / video_impressions;
};
const calcOwnerTotalMediaCostLocalECPCV = (
    owner_total_media_cost_local = 0,
    event_video_complete = 0
) => {
    return event_video_complete === 0 ? 0 : owner_total_media_cost_local / event_video_complete;
};
const calcRevenueECPCV = (revenue = 0, event_video_complete = 0) => {
    return event_video_complete === 0 ? 0 : revenue / event_video_complete;
};
export const getCtr = stat => calcCtr(stat.clicks, stat.impressions);
export const getEcpm = stat => calcEcpm(stat.impressions, stat.spend);
export const getEcpc = stat => calcEcpc(stat.clicks, stat.spend);
export const getErpm = stat => calcErpm(stat.impressions, stat.revenue);
export const getErpc = stat => calcErpc(stat.clicks, stat.revenue);
export const getAverageFrequency = stat => calcAverageFrequency(stat.impressions, stat.daily_uniq);
export const getWinRate = stat => calcWinRate(stat.impressions, stat.placed_bid);
export const getVCR = stat => calcVCR(stat.event_video_complete, stat.video_impressions);
export const getOwnerTotalMediaCostLocalECPCV = stat =>
    calcOwnerTotalMediaCostLocalECPCV(stat.owner_total_media_cost_local, stat.event_video_complete);
export const getRevenueECPCV = stat => calcRevenueECPCV(stat.revenue, stat.event_video_complete);

export const withCtr = stat => ({ ...stat, ctr: getCtr(stat) });
export const withEcpm = stat => ({ ...stat, ecpm: getEcpm(stat) });
export const withEcpc = stat => ({ ...stat, ecpc: getEcpc(stat) });
export const withErpm = stat => ({ ...stat, erpm: getErpm(stat) });
export const withErpc = stat => ({ ...stat, erpc: getErpc(stat) });
export const withAverageFrequency = stat => ({ ...stat, average_freq: getAverageFrequency(stat) });
export const withWinRate = stat => ({ ...stat, win_rate: getWinRate(stat) });
export const withTotalSpendEcpm = stat => ({
    ...stat,
    owner_total_media_cost_local_ecpm:
        (stat.owner_total_media_cost_local / stat.impressions) * 1000,
});
export const withTotalSpendEcpc = stat => ({
    ...stat,
    owner_total_media_cost_local_ecpc: stat.owner_total_media_cost_local / stat.clicks,
});
export const withVCR = stat => ({ ...stat, vcr: getVCR(stat) });
export const withOwnerTotalMediaCostLocalECPCV = stat => ({
    ...stat,
    owner_total_media_cost_local_ecpcv: getOwnerTotalMediaCostLocalECPCV(stat),
});
export const withRevenueECPCV = stat => ({ ...stat, revenue_ecpcv: getRevenueECPCV(stat) });

// Get values for derived metrics
export const getAsAbsolute = stat => {
    const statWithDefaults = setMetricDefaults(stat);
    // Push stat through each function (top-down), which returns a copy of stat
    return _.flow(
        withCtr,
        withEcpc,
        withEcpm,
        withErpc,
        withErpm,
        withAverageFrequency,
        withWinRate,
        withTotalSpendEcpm,
        withTotalSpendEcpc,
        withVCR,
        withOwnerTotalMediaCostLocalECPCV,
        withRevenueECPCV
    )(statWithDefaults);
};

export function getIsZeroStat(stat, visibleMetricKeys) {
    if (!stat) {
        return true;
    }
    return _.every(visibleMetricKeys, metric => {
        if (metricConfig[metric]) {
            return metricConfig[metric].isZeroValue({ statRecord: stat });
        }

        return !stat[metric];
    });
}

function calcPercentage(absoluteValue, totalValue, field) {
    return totalValue[field] === 0 ? 0 : (absoluteValue[field] / totalValue[field]) * 100;
}

function getBaseMetrics(stat) {
    return _(stat)
        .map((value, fieldName) => fieldName)
        .filter(fieldName => {
            if (_.startsWith(fieldName, BEACON_PREFIX)) {
                return true;
            } else if (_.startsWith(fieldName, CONVERSION_PREFIX)) {
                return true;
            } else if (_.startsWith(fieldName, OVERALL_ENGAGEMENTS_PREFIX)) {
                return true;
            } else {
                return _.includes(STANDARD_METRICS, fieldName);
            }
        })
        .value();
}

export function getAsPercentage(statAsAbsolute, statsTotal) {
    const baseMetrics = getBaseMetrics(statAsAbsolute);

    return _(DERIVED_METRICS)
        .concat(baseMetrics)
        .filter(x => x)
        .reduce(
            (stat, metricName) => ({
                ...stat,
                [metricName]:
                    metricName === 'ctr'
                        ? statAsAbsolute.ctr
                        : calcPercentage(statAsAbsolute, statsTotal, metricName),
            }),
            statAsAbsolute
        );
}

export function calcTotalStats(stats) {
    if (!_.isArray(stats)) {
        return {};
    }

    if (stats.length === 0) {
        return {};
    }

    const baseMetrics = getBaseMetrics(stats[0]);

    const total = _.reduce(
        stats,
        (acc, stat) => {
            return _.reduce(
                baseMetrics,
                (sum, metricName) => ({
                    ...sum,
                    [metricName]: (sum[metricName] || 0) + (stat[metricName] || 0),
                }),
                acc
            );
        },
        {}
    );

    const totalDaily = _.reduce(
        stats,
        (acc, stat) => {
            if (stat.daily) {
                return _.reduce(
                    baseMetrics,
                    (sum, metricName) => ({
                        ...sum,
                        [metricName]: (sum[metricName] || 0) + (stat.daily[metricName] || 0),
                    }),
                    acc
                );
            }
        },
        {}
    );

    const output = {
        ...total,
        daily: totalDaily,
    };

    return getAsAbsolute(output);
}

// For all standard metrics set the default to 0 if it doesn't exist in the stat.
function setMetricDefaults(stat) {
    const statWithDefaults = { ...stat };

    _.each(STANDARD_METRICS, metricName => {
        if (statWithDefaults[metricName] !== undefined) {
            return;
        }
        statWithDefaults[metricName] = 0;
    });

    return statWithDefaults;
}

export function getMetricValue({ metricName, statRecord }) {
    if (metricConfig[metricName]) {
        return metricConfig[metricName].getValueFromRecord({ statRecord });
    }
    return statRecord[metricName];
}

function warnStatsProblemRetryLater() {
    toastr.warning(
        'Please try again later',
        'We are currently experiencing issues with report stats.',
        {
            closeButton: true,
            tapToDismiss: false,
            showDuration: 300,
            showMethod: 'slideDown',
            timeOut: 0,
            extendedTimeOut: 0,
            preventDuplicates: true,
            positionClass: 'toast-bottom-left',
        }
    );
}

export const mockResponse = {
    event_page_loaded: 0,
    event_overall_engagements: 0,
    event_crea_analytic_banner_impression: 0,
    preview_event_crea_analytic_banner_impression: 0,
    preview_event_page_loaded: 0,
    clicks: 0,
    impressions: 0,
    daily_uniq: 0,
    revenue: 0,
    inventory_cost: 0,
    platform_cost_local: 0,
    media_cost_local: 0,
    billable_media_cost_local: 0,
    total_media_cost_local: 0,
    net_revenue_local: 0,
    spend: 0,
    addictive_cost_local: 0,
    owner_cost_local: 0,
    billings_local: 0,
    owner_total_media_cost_local: 0,
    owner_inventory_cost_local: 0,
    ctr: 0,
    ecpm: 0,
    ecpc: 0,
    erpm: 0,
    erpc: 0,
    average_freq: 0,
    owner_media_cost_2_local: 0,
    split: [],
    stats: [],
    attributes: {},
};
export function fetchReportStats(
    body,
    options = { mockResponseOnError: true, displayWarnStatsProblemRetryLater: true }
) {
    return http.post('report', body).then(null, error => {
        if (options.displayWarnStatsProblemRetryLater) {
            warnStatsProblemRetryLater();
        }

        if (!options.mockResponseOnError) {
            throw error;
        }

        return mockResponse;
    });
}

export function fetchReportStatsV2(
    body,
    options = { mockResponseOnError: true, displayWarnStatsProblemRetryLater: true }
) {
    return http.post('report/stats', body).then(null, error => {
        if (options.displayWarnStatsProblemRetryLater) {
            warnStatsProblemRetryLater();
        }

        if (!options.mockResponseOnError) {
            throw error;
        }

        return mockResponse;
    });
}

export function translateClientToServerMetrics(metrics) {
    let _metrics = [...metrics];

    // Translate unique_users to unique_campaign, unique_ad, unique_creative, unique_ad_creative
    if (_.includes(_metrics, 'unique_users')) {
        _metrics = _.filter(_metrics, m => m !== 'unique_users').concat([
            'unique_campaign',
            'unique_ad',
            'unique_creative',
            'unique_ad_creative',
        ]);
    }

    return _metrics;
}
