import _ from 'lodash';
import numeral from 'numeral';
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import flags from 'containers/flags/service';

import AccordionItem from 'widgets-v5/expansion-panel-item';
import Spacer from 'widgets-v5/spacer';
import { Label } from 'widgets-v5/typography';
import { TextField } from 'widgets-v6/standard-input';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';

import { formatNumber_currency } from 'utils/formatting';

import { getEnvironmentSettings } from 'services/environment';
import {
    calculateThirdPartyFees as calculateAdThirdPartyFees,
    BiddingStrategyMode,
} from 'states/resources/ads/business-logic';
import { RevenueModelMapping } from 'states/resources/campaigns/business-logic';
import { BillingsList } from '../../modules/optimization';
import { useAdForm } from '../../v2/hook';
import { WarningText } from '../cross-platform-setup-warning';
import { getTotalBidPriceWarning } from '../cross-platform-setup-warning/helpers';

export const processBidStrategyValue = ({ value }) => {
    let newAutomaticBidPrice;
    if (value === 'fixed_bid_price') {
        newAutomaticBidPrice = {
            max_ecpm_local: 0,
            apply_max_ecpm: true,
            optimizing_strategy: {
                mode: BiddingStrategyMode.SpendFullBudget,
                max_ecpc_local: 0,
                max_ecpcv_local: 0,
            },
        };
    } else {
        newAutomaticBidPrice = {
            max_ecpm_local: 0,
            apply_max_ecpm: true,
            optimizing_strategy: {
                mode: BiddingStrategyMode.SpendFullBudget,
                max_ecpc_local: 0,
                max_ecpcv_local: 0,
            },
        };
    }
    return newAutomaticBidPrice;
};

export const processOptimizingStrategyMode = ({ draft, value }) => {
    const preAutomaticBidPrice = {
        automatic_bid_price: draft.automatic_bid_price,
        bid_strategy_mode: draft.bid_strategy_mode,
    };

    const newAutomaticBidPrice = {
        ...preAutomaticBidPrice.automatic_bid_price,
        optimizing_strategy: {
            ...preAutomaticBidPrice.optimizing_strategy,
            max_ecpc_local: 0,
            max_ecpcv_local: 0,
            mode: value,
        },
    };
    return newAutomaticBidPrice;
};

export const processOptimizingStrategyMaxEcpcLocal = ({ draft, value }) => {
    const preAutomaticBidPrice = {
        automatic_bid_price: draft.automatic_bid_price,
        bid_strategy_mode: draft.bid_strategy_mode,
    };

    const newAutomaticBidPrice = {
        ...preAutomaticBidPrice.automatic_bid_price,
        optimizing_strategy: {
            ...preAutomaticBidPrice.automatic_bid_price.optimizing_strategy,
            max_ecpc_local: value,
        },
    };
    return newAutomaticBidPrice;
};

export const processOptimizingStrategyMaxEcpcvLocal = ({ draft, value }) => {
    const preAutomaticBidPrice = {
        automatic_bid_price: draft.automatic_bid_price,
        bid_strategy_mode: draft.bid_strategy_mode,
    };

    const newAutomaticBidPrice = {
        ...preAutomaticBidPrice.automatic_bid_price,
        optimizing_strategy: {
            ...preAutomaticBidPrice.automatic_bid_price.optimizing_strategy,
            max_ecpcv_local: value,
        },
    };
    return newAutomaticBidPrice;
};

export const processMaxEcpm = ({ draft, value }) => {
    const preAutomaticBidPrice = draft;

    const newAutomaticBidPrice = {
        ...preAutomaticBidPrice.automatic_bid_price,
        max_ecpm_local: value,
    };
    return newAutomaticBidPrice;
};

class BidStrategy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            automatic_bid_price: props.formData.automatic_bid_price,
            // {
            //     max_ecpm_local: 0,
            //     apply_max_ecpm: true,
            //     optimizing_strategy: {
            //         mode: 'spending_full_budget',
            //         max_ecpc_local: 0,
            //     },
            // }
            bid_strategy_mode: props.formData.bid_strategy_mode,
        };
    }

    isBidOptimizationComplete = () => {
        const { automatic_bid_price, bid_strategy_mode } = this.state;

        if (
            bid_strategy_mode === 'automatic_bid_price' &&
            automatic_bid_price &&
            automatic_bid_price.optimizing_strategy &&
            automatic_bid_price.optimizing_strategy.mode !== BiddingStrategyMode.SpendFullBudget
        ) {
            return this.props.isCTVCampaign
                ? automatic_bid_price.optimizing_strategy.max_ecpcv_local === 0
                : automatic_bid_price.optimizing_strategy.max_ecpc_local === 0;
        }
    };

    isTotalBidPriceComplete = () => {
        const { automatic_bid_price, bid_strategy_mode } = this.state;

        const { formData } = this.props;
        const { max_cpm_rate_local } = formData;

        if (bid_strategy_mode === 'fixed_bid_price' && max_cpm_rate_local === 0) {
            return true;
        } else {
            if (
                bid_strategy_mode === 'automatic_bid_price' &&
                automatic_bid_price &&
                automatic_bid_price.max_ecpm_local === 0
            ) {
                return true;
            }
        }
    };

    updateBidStrategyMode = event => {
        const { onFieldChange } = this.props;

        const mode = event.target.value;
        const newAutomaticBidPrice = processBidStrategyValue({ value: mode });

        this.setState({
            bid_strategy_mode: mode,
            automatic_bid_price: newAutomaticBidPrice,
        });

        onFieldChange('bid_strategy_mode')(mode);
        onFieldChange('automatic_bid_price')(newAutomaticBidPrice);

        if (mode === 'automatic_bid_price') {
            onFieldChange('max_cpm_rate_local')(0);
        }
    };

    updateUseDealPriceToBid = value => {
        const { onFieldChange } = this.props;

        onFieldChange('use_deal_price_to_bid')(value);
    };

    updateMaxEcpm = _.debounce(value => {
        const { onFieldChange } = this.props;

        const newAutomaticBidPrice = processMaxEcpm({ value, draft: this.state });

        this.setState({ automatic_bid_price: newAutomaticBidPrice });
        onFieldChange('automatic_bid_price')(newAutomaticBidPrice);
    }, 700);

    updateOptimizingStrategyMaxEcpcLocal = _.debounce(value => {
        const { onFieldChange } = this.props;
        const newAutomaticBidPrice = processOptimizingStrategyMaxEcpcLocal({
            value,
            draft: this.state,
        });

        this.setState({ automatic_bid_price: newAutomaticBidPrice });
        onFieldChange('automatic_bid_price')(newAutomaticBidPrice);
    }, 700);

    updateOptimizingStrategyMaxEcpcvLocal = _.debounce(value => {
        const { onFieldChange } = this.props;
        const newAutomaticBidPrice = processOptimizingStrategyMaxEcpcvLocal({
            value,
            draft: this.state,
        });

        this.setState({ automatic_bid_price: newAutomaticBidPrice });
        onFieldChange('automatic_bid_price')(newAutomaticBidPrice);
    }, 700);

    updateOptimizingStrategyMode = value => {
        const { onFieldChange } = this.props;
        const newAutomaticBidPrice = processOptimizingStrategyMode({ value, draft: this.state });

        this.setState({ automatic_bid_price: newAutomaticBidPrice });
        onFieldChange('automatic_bid_price')(newAutomaticBidPrice);
    };

    render() {
        const {
            expandPanel,
            classes,
            formData,
            showErrors,
            errors,
            currency,
            techFee,
            campaignId,
            campaignRevenueModel,
            onFieldChange,
            expandedPanel,
            totalFirstPartyDataFee,
            organizationName,
            overrideDealPriceWarning,
            isCTVCampaign,
            isDOOHCampaign,
        } = this.props;

        const { automatic_bid_price } = this.state;
        const environmentSettings = getEnvironmentSettings();
        const currencyLabel = environmentSettings.showCurrencyLabel() ? currency : '';
        const totalBidPriceWarning = getTotalBidPriceWarning(this.state.bid_strategy_mode);

        const { dealApplicationType } = formData;

        let useDealPriceToBidValue = _.toString(formData.use_deal_price_to_bid);

        return (
            <React.Fragment>
                {environmentSettings.canEditBidStrategy() && (
                    <AccordionItem
                        onClick={expandPanel('bidStrategy')}
                        title="Bid Strategy"
                        expanded={expandedPanel === 'bidStrategy'}
                        error={showErrors && errors.bid_strategy_mode}
                        details={
                            <RadioGroup
                                aria-label="gender"
                                name="bid_strategy_mode"
                                value={this.state.bid_strategy_mode}
                                onChange={event => this.updateBidStrategyMode(event)}
                            >
                                <FormControlLabel
                                    value="automatic_bid_price"
                                    control={<Radio color="primary" />}
                                    label="Automated bid price"
                                    disabled={isDOOHCampaign}
                                />
                                <FormControlLabel
                                    value="fixed_bid_price"
                                    control={<Radio color="primary" />}
                                    label="Fixed bid price"
                                    disabled={isDOOHCampaign}
                                />
                            </RadioGroup>
                        }
                        summary={
                            <Label>
                                {this.state.bid_strategy_mode === 'fixed_bid_price'
                                    ? 'Fixed bid price'
                                    : 'Automated bid price'}
                            </Label>
                        }
                        helper={isDOOHCampaign && 'DOOH ads only support fixed bid price.'}
                    />
                )}
                {this.state.bid_strategy_mode === 'automatic_bid_price' && (
                    <AccordionItem
                        onClick={expandPanel('bidOptimization')}
                        title="Bid Optimization"
                        expanded={expandedPanel === 'bidOptimization'}
                        incomplete={this.isBidOptimizationComplete()}
                        error={
                            showErrors &&
                            (errors.bid_strategy_mode ||
                                errors['automatic_bid_price.optimizing_strategy'])
                        }
                        details={
                            <div className={classes.bidStrategyBody}>
                                <RadioGroup
                                    aria-label="gender"
                                    name="gender1"
                                    value={this.state.automatic_bid_price.optimizing_strategy.mode}
                                    onChange={event =>
                                        this.updateOptimizingStrategyMode(event.target.value)
                                    }
                                >
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        value={BiddingStrategyMode.SpendFullBudget}
                                        label="Optimize for spending full budget"
                                    />
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        className={classes.bidStrategyDescription}
                                    >
                                        {`Spend full budget while maximizing ${
                                            isCTVCampaign ? 'VCR' : 'CTR'
                                        }`}
                                    </Typography>
                                    {!isCTVCampaign && (
                                        <Grid container spacing={2} direction="column">
                                            <Grid item>
                                                <FormControlLabel
                                                    value="performance"
                                                    control={<Radio color="primary" />}
                                                    label="Optimize for performance"
                                                />
                                                <Typography
                                                    variant="body2"
                                                    gutterBottom
                                                    className={classes.bidStrategyDescription}
                                                >
                                                    Budget may not be fully spent if goal is too
                                                    aggressive
                                                </Typography>
                                                {this.state.automatic_bid_price.optimizing_strategy
                                                    .mode === BiddingStrategyMode.Performance && (
                                                    <BidStrategyField
                                                        classes={classes}
                                                        initialValue={
                                                            !isCTVCampaign
                                                                ? this.state.automatic_bid_price
                                                                      .optimizing_strategy
                                                                      .max_ecpc_local
                                                                : this.state.automatic_bid_price
                                                                      .optimizing_strategy
                                                                      .max_ecpcv_local
                                                        }
                                                        onUpdate={
                                                            isCTVCampaign
                                                                ? this
                                                                      .updateOptimizingStrategyMaxEcpcvLocal
                                                                : this
                                                                      .updateOptimizingStrategyMaxEcpcLocal
                                                        }
                                                        showErrors={showErrors}
                                                        errors={errors}
                                                        currency={currency}
                                                        fieldName={isCTVCampaign ? 'eCPCV' : 'eCPC'}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                </RadioGroup>
                            </div>
                        }
                        summary={
                            <BidStrategyFieldSummary
                                strategyMode={automatic_bid_price.optimizing_strategy.mode}
                                maxLocalValue={
                                    isCTVCampaign
                                        ? automatic_bid_price.optimizing_strategy.max_ecpcv_local
                                        : automatic_bid_price.optimizing_strategy.max_ecpc_local
                                }
                                currency={currency}
                                fieldName={isCTVCampaign ? 'eCPCV' : 'eCPC'}
                            />
                        }
                        helper={
                            !isCTVCampaign
                                ? 'Specify the maximum CPM amount you are willing spend to reach a user with this ad. The final bid price will be automatically calculated.'
                                : 'Connected TV campaigns can only be optimized towards spending the full budget.'
                        }
                    />
                )}
                <AccordionItem
                    onClick={expandPanel('totalBidPrice')}
                    title={
                        this.state.bid_strategy_mode === 'fixed_bid_price'
                            ? 'Total Bid Price'
                            : 'Total Bid Price Limit'
                    }
                    expanded={expandedPanel === 'totalBidPrice'}
                    incomplete={this.isTotalBidPriceComplete()}
                    required
                    error={
                        (showErrors && errors['max_cpm_rate_local']) ||
                        errors['automatic_bid_price.max_ecpm_local']
                    }
                    details={
                        <React.Fragment>
                            <Grid container alignItems="center">
                                {this.state.bid_strategy_mode === 'automatic_bid_price' && (
                                    <React.Fragment>
                                        <Label>Do not exceed total bid price of</Label>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    className={classes.bidStrategyDescription}
                                                >
                                                    <Grid item>
                                                        <TextFieldFormatter
                                                            key="automatic_bid_price.max_ecpm_local"
                                                            initialValue={
                                                                this.state.automatic_bid_price
                                                                    .max_ecpm_local
                                                            }
                                                            onChange={v => this.updateMaxEcpm(v)}
                                                            shouldAllowChange={v =>
                                                                !/[a-zA-Z]/.test(v)
                                                            }
                                                            formatIn={v => {
                                                                if (v === 0) {
                                                                    return '';
                                                                } else {
                                                                    return numeral(v).format(
                                                                        '0,0.00'
                                                                    );
                                                                }
                                                            }}
                                                            formatOut={v => numeral(v).value()}
                                                            renderInput={({
                                                                value,
                                                                onChange,
                                                                onBlur,
                                                            }) => (
                                                                <TextField
                                                                    disableMargin
                                                                    error={
                                                                        showErrors &&
                                                                        errors[
                                                                            'automatic_bid_price.max_ecpm_local'
                                                                        ]
                                                                    }
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    onBlur={onBlur}
                                                                    startAdornment={
                                                                        <div
                                                                            className={
                                                                                classes.bidStrategyTextField
                                                                            }
                                                                        >
                                                                            {currencyLabel}{' '}
                                                                            {environmentSettings.getCurrencySign()}
                                                                        </div>
                                                                    }
                                                                    required={
                                                                        this.state
                                                                            .bid_strategy_mode ===
                                                                        'automatic_bid_price'
                                                                    }
                                                                    endAdornment={
                                                                        <Box width={50}>
                                                                            <Typography>
                                                                                CPM
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                    placeholder="0.00"
                                                                    helperText="Some fees may be deducted from your Total Bid Price."
                                                                />
                                                            )}
                                                        />
                                                        {flags.isEnabled(
                                                            'en_3224_warning_and_alert_when_CPM_rate_less_than_totalBidPrice'
                                                        ) && (
                                                            <>
                                                                {campaignRevenueModel ===
                                                                    RevenueModelMapping.CPMCPC &&
                                                                    formData.automatic_bid_price
                                                                        .max_ecpm_local >
                                                                        formData.billing_rate && (
                                                                        <WarningText
                                                                            message={`Total Bid Price Limit is higher than the Billing Rate of ${formatNumber_currency(
                                                                                formData.billing_rate
                                                                            )}. This may result in a negative margin.`}
                                                                        />
                                                                    )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                    {showErrors &&
                                                        errors[
                                                            'automatic_bid_price.max_ecpm_local'
                                                        ] && (
                                                            <Grid item>
                                                                <FormControl error>
                                                                    <FormHelperText>
                                                                        {
                                                                            errors[
                                                                                'automatic_bid_price.max_ecpm_local'
                                                                            ]
                                                                        }
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                        )}
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Breakdown>
                                                    <BillingsList
                                                        formData={formData}
                                                        currency={currency}
                                                        errors={errors}
                                                        showErrors={showErrors}
                                                        thirdPartyFees={calculateAdThirdPartyFees(
                                                            formData
                                                        )}
                                                        techFee={techFee}
                                                        campaignId={campaignId}
                                                        totalFirstPartyDataFee={
                                                            totalFirstPartyDataFee
                                                        }
                                                        organization={organizationName}
                                                    />
                                                </Breakdown>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </Grid>
                            <Spacer />
                            <Grid container alignItems="center">
                                {this.state.bid_strategy_mode === 'fixed_bid_price' && (
                                    <React.Fragment>
                                        <Grid item>
                                            <TextFieldFormatter
                                                initialValue={formData.max_cpm_rate_local}
                                                onChange={_.debounce(
                                                    onFieldChange('max_cpm_rate_local'),
                                                    200
                                                )}
                                                shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                                formatIn={v => {
                                                    if (v === 0) {
                                                        return '';
                                                    } else {
                                                        return numeral(v).format('0,0.00');
                                                    }
                                                }}
                                                formatOut={v => numeral(v).value()}
                                                renderInput={({ value, onChange, onBlur }) => (
                                                    <React.Fragment>
                                                        <TextField
                                                            disabled={
                                                                dealApplicationType ===
                                                                    'deal_only' &&
                                                                formData.use_deal_price_to_bid ===
                                                                    true
                                                                    ? true
                                                                    : false
                                                            }
                                                            disableMargin
                                                            error={
                                                                showErrors &&
                                                                errors['max_cpm_rate_local']
                                                            }
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            startAdornment={
                                                                <div
                                                                    className={
                                                                        classes.bidStrategyTextField
                                                                    }
                                                                >
                                                                    {currencyLabel}{' '}
                                                                    {environmentSettings.getCurrencySign()}
                                                                </div>
                                                            }
                                                            required
                                                            endAdornment={
                                                                <Box width={50}>
                                                                    <Typography>CPM</Typography>
                                                                </Box>
                                                            }
                                                            placeholder="0.00"
                                                            helperText="Some fees may be deducted from your Total Bid Price."
                                                            errorText={
                                                                showErrors &&
                                                                errors['max_cpm_rate_local']
                                                            }
                                                        />
                                                        {flags.isEnabled(
                                                            'en_3224_warning_and_alert_when_CPM_rate_less_than_totalBidPrice'
                                                        ) && (
                                                            <>
                                                                {campaignRevenueModel ===
                                                                    RevenueModelMapping.CPMCPC &&
                                                                    formData.max_cpm_rate_local >
                                                                        formData.billing_rate && (
                                                                        <WarningText
                                                                            message={`Total Bid Price Limit is higher than the Billing Rate of ${formatNumber_currency(
                                                                                formData.billing_rate
                                                                            )}. This may result in a negative margin.`}
                                                                        />
                                                                    )}
                                                            </>
                                                        )}
                                                        {!formData.use_deal_price_to_bid &&
                                                            overrideDealPriceWarning.hasWarning && (
                                                                <Box marginTop={1} marginLeft={4}>
                                                                    <WarningText
                                                                        message={
                                                                            overrideDealPriceWarning.message
                                                                        }
                                                                        severity={
                                                                            overrideDealPriceWarning.severity
                                                                        }
                                                                    />
                                                                </Box>
                                                            )}
                                                    </React.Fragment>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Breakdown>
                                                <BillingsList
                                                    formData={formData}
                                                    currency={currency}
                                                    errors={errors}
                                                    showErrors={showErrors}
                                                    thirdPartyFees={calculateAdThirdPartyFees(
                                                        formData
                                                    )}
                                                    techFee={techFee}
                                                    campaignId={campaignId}
                                                    totalFirstPartyDataFee={totalFirstPartyDataFee}
                                                    organization={organizationName}
                                                />
                                            </Breakdown>
                                        </Grid>
                                        <RadioGroup
                                            aria-label="use_deal_price_to_bid"
                                            name="use_deal_price_to_bid"
                                            value={useDealPriceToBidValue}
                                            onChange={event => {
                                                const value = event.target.value === 'true';
                                                this.updateUseDealPriceToBid(value);
                                            }}
                                        >
                                            <FormControlLabel
                                                disabled={false}
                                                value="false"
                                                control={<Radio color="primary" />}
                                                label="Use Total Bid Price for targeted deals"
                                            />
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                className={classes.bidStrategyDescription}
                                            >
                                                Select this to prioritize a strict bid price limit
                                            </Typography>
                                            <FormControlLabel
                                                disabled={false}
                                                value="true"
                                                control={<Radio color="primary" />}
                                                label={
                                                    flags.isEnabled('efb_35_curated_deals')
                                                        ? "Use targeted deals' specified prices"
                                                        : "Use Private Deal's specified price for targeted deals"
                                                }
                                            />
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                className={classes.bidStrategyDescription}
                                            >
                                                {flags.isEnabled('efb_35_curated_deals')
                                                    ? "Select this to ensure that targeted deals' floor prices are met"
                                                    : 'Select this to prioritize buying private inventory'}
                                            </Typography>
                                        </RadioGroup>
                                        {useDealPriceToBidValue === 'true' &&
                                            totalBidPriceWarning.hasWarning && (
                                                <Box marginTop={1} marginLeft={4}>
                                                    <WarningText
                                                        message={totalBidPriceWarning.message}
                                                        severity={totalBidPriceWarning.severity}
                                                    />
                                                </Box>
                                            )}
                                    </React.Fragment>
                                )}
                            </Grid>
                        </React.Fragment>
                    }
                    summary={
                        <React.Fragment>
                            {this.state.bid_strategy_mode === 'fixed_bid_price' &&
                                (formData.max_cpm_rate_local === 0 ? (
                                    <Label placeholder>
                                        {currencyLabel} {environmentSettings.getCurrencySign()}
                                        0.00 CPM
                                    </Label>
                                ) : (
                                    <Label>
                                        {currencyLabel} {environmentSettings.getCurrencySign()}{' '}
                                        {numeral(formData.max_cpm_rate_local).format('0,0.00')} CPM
                                    </Label>
                                ))}
                            {this.state.bid_strategy_mode === 'automatic_bid_price' &&
                                (automatic_bid_price.max_ecpm_local === 0 ? (
                                    <Label>No limit</Label>
                                ) : (
                                    <Label>
                                        Do not exceed {currencyLabel}{' '}
                                        {environmentSettings.getCurrencySign()}{' '}
                                        {numeral(automatic_bid_price.max_ecpm_local).format(
                                            '0,0.00'
                                        )}{' '}
                                        eCPM
                                    </Label>
                                ))}
                        </React.Fragment>
                    }
                    helper="Total Bid Price is the maximum amount you are willing to pay to reach a user, after all fees. EngageFront will deduct any non-media fees to calculate the Net Bid Price used for auctions."
                />
            </React.Fragment>
        );
    }
}

const useStyles = makeStyles({
    title: {
        textDecoration: 'underline',
        marginLeft: 10,
        marginBottom: 24,
    },
});

function Breakdown(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div>
            <p className={classes.title} onClick={handleClick}>
                (view breakdown)
            </p>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {props.children}
            </Popover>
        </div>
    );
}

const BidStrategyField = ({
    classes,
    initialValue,
    onUpdate,
    showErrors,
    errors,
    currency,
    fieldName,
}) => {
    const environmentSettings = getEnvironmentSettings();
    const currencyLabel = environmentSettings.showCurrencyLabel() ? currency : '';

    return (
        <Grid container direction="column" className={classes.bidStrategyDescription}>
            <Grid item>
                <TextFieldFormatter
                    initialValue={initialValue}
                    onChange={onUpdate}
                    shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                    formatIn={v => {
                        if (v === 0) {
                            return '';
                        } else {
                            return numeral(v).format('0,0.00');
                        }
                    }}
                    formatOut={v => numeral(v).value()}
                    renderInput={({ value, onChange, onBlur }) => (
                        <TextField
                            error={showErrors && errors['automatic_bid_price.optimizing_strategy']}
                            startAdornment={
                                <div className={classes.bidStrategyTextField}>
                                    {currencyLabel} {environmentSettings.getCurrencySign()}
                                </div>
                            }
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            endAdornment={
                                <div className={classes.bidStrategyTextField}>
                                    {fieldName} or less
                                </div>
                            }
                            placeholder="0.00"
                        />
                    )}
                />
            </Grid>
            {showErrors && errors['automatic_bid_price.optimizing_strategy'] && (
                <Grid item>
                    <FormControl error>
                        <FormHelperText>
                            {errors['automatic_bid_price.optimizing_strategy']}
                        </FormHelperText>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
};

const BidStrategyFieldSummary = ({ maxLocalValue, strategyMode, currency, fieldName }) => {
    const environmentSettings = getEnvironmentSettings();
    const currencyLabel = environmentSettings.showCurrencyLabel() ? currency : '';

    return (
        <React.Fragment>
            {strategyMode === BiddingStrategyMode.SpendFullBudget && (
                <Label>Optimize for spending full budget</Label>
            )}
            {strategyMode === BiddingStrategyMode.Performance &&
                (maxLocalValue === 0 ? (
                    <Typography color="error">Incomplete fields</Typography>
                ) : (
                    <div>
                        <Label>Optimize for performance</Label>
                        <Label>
                            {currencyLabel} {environmentSettings.getCurrencySign()}{' '}
                            {numeral(maxLocalValue).format('0,0.00')} {fieldName} or less
                        </Label>
                    </div>
                ))}
        </React.Fragment>
    );
};

function BidStrategyWrapper(props) {
    const { overrideDealPriceWarning } = useAdForm();

    return <BidStrategy {...props} overrideDealPriceWarning={overrideDealPriceWarning} />;
}

export default BidStrategyWrapper;
