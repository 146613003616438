import React, { useState, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { map, includes } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Icon from '@mdi/react';
import { mdiDatabase, mdiMapSearch, mdiBullseye, mdiFileUpload } from '@mdi/js';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router';
import Map from '@mui/icons-material/Map';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import { MoreVert } from '@mui/icons-material';
import VirtualizedTable from 'packages/widgets-v6/virtualized-table';

import { SearchInput } from 'widgets-v6/standard-input';
import { DialogTitle } from 'widgets-v6/modal';
import FlexSegmentsForm from 'forms/flex-segments-form';
import RetargetingModal from 'pages/audiences/retargeting-dashboard/modules';
import { SidebarAudienceItems } from '../modules/audience-sidebar';
import { AUDIENCE_STATUS_MAPPING } from 'pages/presets/poi-editor/services/constants';
import { ConfirmDialog } from 'widgets-v6/modal';

import {
    formatNumber_whole,
    formatNumber_currency,
    formatDate_dateWithYear,
} from 'utils/formatting';

import selector from './selector';
import { getEnv } from 'services/environment';
import { isInternalUser } from 'states/profile/business-rules';
import {
    getAudienceDaysToExpire,
    getAudienceStatus,
    getAudienceStatusClass,
} from 'states/resources/audiences/business-logic';
import { useOverallTableDashboardData } from '../overall-dashboard/v2/hook';

import FlexSegmentsFormActions from 'forms/flex-segments-form/actions';
import audienceEditorActions from 'pages/audiences/retargeting-dashboard/modules/actions';
import { default as uploadedEditorActions } from 'pages/audiences/editor/actions';
import { default as UploadedAudiencesModal } from 'pages/audiences/editor';

const useStyles = makeStyles(theme => ({
    mainContainer: {
        paddingBottom: 0,
    },
    drawer: {
        width: 694,
        padding: theme.spacing(2),
    },
    filterLabel: {
        color: '#000000',
        fontSize: 20,
    },
    overlay: {
        visibility: 'hidden',
        position: 'absolute',
        height: '100%',
        width: '100%',
        opacity: '0.95',
        backgroundColor: '#000',
        color: '#fff',
        padding: '20px 15px 0 15px',
    },
    warningOverlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        opacity: '0.85',
        backgroundColor: '#EEE',
        padding: '130px 0 0 15px',
    },
    root: {
        '&:hover > div': {
            visibility: 'visible',
        },
        position: 'relative',
        height: '100%',
        cursor: 'pointer',
    },
    icon: {
        width: '3.5em',
        height: '3.5em',
    },
    iconColor: {
        color: '#098679',
    },
    cardContent: {
        height: '100%',
    },
    disabled: {
        cursor: 'not-allowed',
    },
    warningLabel: {
        color: '#E65100',
        fontSize: '12px',
    },
    pendingOrSuspendedAudience: {
        opacity: '0.4',
    },
    activeAudience: {
        color: '#0eafa0',
    },
    statusWarningLabel: {
        fontSize: '9px',
        marginLeft: '5px',
        opacity: '0.4'
    },
}));

const generateAudiencesConfigV2 = (
    onMoreInfoColumnClick,
    openAudienceEditorForm,
    canEditAudiences,
    reactivateAudience,
) => {
    const classes = useStyles();
    return {
        columns: [
            {
                Header: 'Status',
                id: 'status',
                accessor: 'status',
                Cell: ({ row: { original } }) => {
                    return (
                        <>
                            <Typography
                                className={getAudienceStatusClass(
                                    original.status,
                                    classes
                                )}
                            >
                                {getAudienceStatus(original)}
                            </Typography>
                            {original.status ===
                                AUDIENCE_STATUS_MAPPING.suspending && (
                                <Box display="flex" alignItems="center">
                                    <WarningAmberIcon
                                        style={{
                                            color: 'red',
                                        }}
                                    />{' '}
                                    <Typography
                                        className={classes.statusWarningLabel}
                                        variant="body2"
                                    >
                                        Suspending in {getAudienceDaysToExpire(original)} days.
                                    </Typography>
                                </Box>
                            )}
                        </>
                    );
                },
                maxWidth: 200,
                sticky: 'left',
            },
            {
                Header: 'Name',
                id: 'name',
                accessor: 'name',
                Cell: ({ row: { original } }) => {
                    return isPendingOrSuspendedAudience(original) ? (
                        <Typography
                            className={classes.pendingOrSuspendedAudience}
                        >
                            {original.name}
                        </Typography>
                    ) : (
                        <Typography>{original.name}</Typography>
                    );
                },
                minWidth: 400,
                sticky: 'left',
            },
            {
                Header: 'Uniques',
                id: 'uniques',
                accessor: 'uniques',
                Cell: ({ row: { original } }) => {
                    return isPendingOrSuspendedAudience(original) ? (
                        <Typography
                            className={classes.pendingOrSuspendedAudience}
                        >
                            {formatNumber_whole(original.uniques)}
                        </Typography>
                    ) : (
                        <Typography>{original.uniques}</Typography>
                    );
                },
                maxWidth: 100,
            },
            {
                Header: 'Type',
                id: 'type',
                accessor: 'type',
                Cell: ({ row: { original } }) => {
                    return isPendingOrSuspendedAudience(original) ? (
                        <Typography
                            className={classes.pendingOrSuspendedAudience}
                        >
                            {original.type}
                        </Typography>
                    ) : (
                        <Typography>{original.type}</Typography>
                    );
                },
                maxWidth: 180,
            },
            {
                Header: 'Category',
                id: 'category',
                accessor: 'category',
                Cell: ({ row: { original } }) => {
                    return isPendingOrSuspendedAudience(original) ? (
                        <Typography
                            className={classes.pendingOrSuspendedAudience}
                        >
                            {original.category}
                        </Typography>
                    ) : (
                        <Typography>{original.category}</Typography>
                    );
                },
                maxWidth: 120,
            },
            {
                Header: 'Data Cost',
                id: 'dataCost',
                accessor: 'dataCost',
                Cell: ({ row: { original } }) => {
                    return isPendingOrSuspendedAudience(original) ? (
                        <Typography
                            className={classes.pendingOrSuspendedAudience}
                        >
                            {original.dataCost
                                ? formatNumber_currency(original.dataCost)
                                : 'N/A'}
                        </Typography>
                    ) : (
                        <Typography>
                            {original.dataCost
                                ? formatNumber_currency(original.dataCost)
                                : 'N/A'}
                        </Typography>
                    );
                },
                maxWidth: 76,
            },
            {
                Header: 'Created',
                id: 'created',
                accessor: '_created',
                Cell: ({ row: { original } }) => {
                    return isPendingOrSuspendedAudience(original) ? (
                        <Typography
                            className={classes.pendingOrSuspendedAudience}
                        >
                            {original._created
                                ? formatDate_dateWithYear(original._created)
                                : 'N/A'}
                        </Typography>
                    ) : (
                        <Typography>
                            {original._created
                                ? formatDate_dateWithYear(original._created)
                                : 'N/A'}
                        </Typography>
                    );
                },
                maxWidth: 120,
            },
            {
                Header: 'More Info',
                id: 'moreInfo',
                Cell: ({ row: { original } }) => {
                    return original.source_type !== 'adobe' &&
                        original.source_type !== 'permutive'
                        ? 'View Info'
                        : null;
                },
                maxWidth: 85,
                onClick: (e, original) => {
                    if (
                        original.source_type !== 'adobe' &&
                        original.source_type !== 'permutive'
                    ) {
                        onMoreInfoColumnClick(e, original);
                    }
                },
            },
            {
                Header: 'Actions',
                id: 'actions',
                Cell: ({ row: { original } }) => {
                    return (
                        <ActionMenu
                            original={original}
                            reactivateAudience={reactivateAudience}
                            openAudienceEditorForm={openAudienceEditorForm}
                            canEditAudiences={canEditAudiences}
                        />
                    )
                },
            },
        ],
        sortBy: [
            {
                id: 'created',
                desc: true,
            },
        ],
        hiddenColumns: [],
    }
};
    

function getNewAudienceCategories({
    classes,
    openFlexSegmentsFormModal,
    openAudienceFormModal,
    goToSegmentBuilder,
    openUploadedEditorActions,
    isInternalUser,
}) {
    const availableNewAudienceCategories = [
        {
            value: 'flex-brand',
            title: 'Flex Geolocation (Pelmorex Audience)',
            link: '/audiences/flex-brand',
            source: 'flex_standard_brand',
            icon: <Map className={classes.icon} />,
            tooltip:
                'Segments combining bidstream data and virtual beacons — composed of location radiuses and points of interest from Cleanlist.',
            onClick: function() {
                return openFlexSegmentsFormModal();
            },
            isInternal: false,
        },
        {
            value: 'pds-custom',
            title: 'Pelmorex Data',
            link: '/audiences/pds-custom',
            source: 'pds_custom',
            tooltip: isInternalUser
                ? "Pelmorex Data is a group of Geolocation segments, based on first-party SDK location data and precise polygons, and tailor-made for your campaigns. Plan and create audience segments through our Pelmorex Data's Audience Builder."
                : 'Please reach out to your dedicated Customer Engagement Specialist or Client Partner to create a Custom Audience!',
            icon: <Icon path={mdiDatabase} size={3} />,
            onClick: function() {
                return isInternalUser ? goToSegmentBuilder() : null;
            },
            warningText:
                'Please reach out to your dedicated Customer Engagement Specialist or Client Partner to create a Custom Audience!',
            isEnabled: isInternalUser,
            isInternal: true,
        },
        {
            value: 'flex-custom',
            title: 'Flex Geolocation',
            link: '/audiences/flex-custom',
            source: 'flex_custom_brand',
            icon: <Icon path={mdiMapSearch} size={3} />,
            tooltip:
                "Create your own Flex Geolocation segments by searching through Cleanlist's points of interest database, or by uploading your own store list.",
            onClick: function() {
                return openFlexSegmentsFormModal();
            },
            isInternal: true,
        },
        {
            value: 'retargeting',
            title: 'Retargeting',
            link: '/audiences/retargeting',
            source: ['ad', 'campaign', 'pixel'],
            icon: <Icon path={mdiBullseye} size={3} />,
            tooltip:
                'Target users who have seen or interacted with an earlier ad, or performed an action that fired a conversion pixel.',
            onClick: function() {
                return openAudienceFormModal(null);
            },
            isInternal: true,
        },
        {
            value: 'uploaded',
            title: 'Uploaded',
            link: '/audiences/uploaded',
            source: 'upload',
            icon: <Icon path={mdiFileUpload} size={3} />,
            tooltip:
                'Upload your own segments from any source like third-party DMPs and CRM platforms, based on mobile Device IDs.',
            onClick: function() {
                return openUploadedEditorActions(null, 'uploaded');
            },
            isInternal: true,
        },
    ];

    return availableNewAudienceCategories;
}

function isPendingOrSuspendedAudience(audience) {
    return (
        audience.status === AUDIENCE_STATUS_MAPPING.suspended ||
        audience.status === AUDIENCE_STATUS_MAPPING.pending
    );
}

const AudiencesTableFilter = () => {
    const classes = useStyles();
    const { toggleCategory, filters, audienceFiltersByType } = useOverallTableDashboardData();

    return (
        <React.Fragment>
            <FormLabel className={classes.filterLabel}>Filter By</FormLabel>
            <Box m={4} />
            {audienceFiltersByType &&
                map(audienceFiltersByType, type => {
                    return (
                        <div>
                            <FormLabel key={type.value}>{type.label}</FormLabel>
                            <div>
                                {type.categories.map(category => {
                                    return (
                                        <div style={{ paddingLeft: 15 }} key={category.id}>
                                            <Grid>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={includes(
                                                                filters,
                                                                category.value
                                                            )}
                                                            onChange={() =>
                                                                toggleCategory({
                                                                    value: category.value,
                                                                })
                                                            }
                                                        />
                                                    }
                                                    label={category.label}
                                                />
                                            </Grid>
                                        </div>
                                    );
                                })}
                            </div>
                            <Box m={2} />
                        </div>
                    );
                })}
        </React.Fragment>
    );
};

const OverallTableDashboard = () => {
    const classes = useStyles();
    const {
        initAudiencesOverallTable,
        fetchSidebarAudience,
        audienceSidebar,
        filteredAudiences,
        isLoading,
        toggleSidebar,
        isModalOpen,
        openModal,
        closeModal,
        canEditAudiences,
        editAudience,
    } = useOverallTableDashboardData();
    const [tableRef, setTableRef] = useState(null);
    const [isReactivateConfirmOpen, setIsReactivateConfirmOpen] = useState(false);
    const [audienceValue, setAudienceValue] = useState(null);
    const dispatch = useDispatch();
    const { hasEnvironics, organizationId, userOrganizationName } = useSelector(selector);

    const segmentBuilderUrl = `http://segment${
        getEnv() === 'production' ? '' : '-dev'
    }.data.pelmorex.com/?eftoken=789&orgId=${organizationId}&orgName=${userOrganizationName}&environics=${hasEnvironics}`;

    const openFlexSegmentsFormModal = selectedFlexBrandId => {
        dispatch(FlexSegmentsFormActions.open(selectedFlexBrandId));
    };

    const openAudienceFormModal = audienceId => {
        dispatch(audienceEditorActions.openModal(audienceId));
    };

    const openUploadedEditorActions = (audienceId, target) => {
        dispatch(uploadedEditorActions.initialize(audienceId, target));
        dispatch(uploadedEditorActions.openModal(audienceId));
    };

    const openAudienceEditorForm = audience => {
        setAudienceValue(audience);

        if (audience.category === 'Retargeting') {
            openAudienceFormModal(audience.id);
        }
        if (audience.category === 'Flex Geolocation') {
            openFlexSegmentsFormModal(audience.id);
        }
        if (audience.category === 'Uploaded') {
            openUploadedEditorActions(audience.id, 'uploaded');
        }
    };

    const goToSegmentBuilder = () => {
        window.open(segmentBuilderUrl, '_blank');
    };

    const openAudienceForm = () => {
        openModal();
    };

    const closeAudienceForm = () => {
        closeModal();
    };

    const onMoreInfoColumnClick = (event, original) => {
        fetchSidebarAudience({
            id: original.id,
            name: original.name,
            type: original.type,
        });
        toggleSidebar();
    };

    const reactivateAudience = (original) => {
        setAudienceValue(original);
        setIsReactivateConfirmOpen(true);
    }

    const confirmReactivateAudience = () => {
        // As most of the audiences are kept alive by PA team and we keep updating its metadata thru pds-syncer,
        // it’s safe to move from Suspended to Active (EN-4801)
        editAudience({
            audienceId: audienceValue.id,
            changes: {
                name: audienceValue.name,
                status: AUDIENCE_STATUS_MAPPING.active,
                expire_at: moment().add(3, 'months').endOf('day').toDate(),
            },
        });
        setIsReactivateConfirmOpen(false);
    }

    const closeReactivateConfirm = () => {
        setIsReactivateConfirmOpen(false);
    }

    const audiencesConfig = generateAudiencesConfigV2(
        onMoreInfoColumnClick,
        openAudienceEditorForm,
        canEditAudiences,
        reactivateAudience,
    );

    useEffect(() => {
        initAudiencesOverallTable({ searchString: '' });
    }, []);

    const onEditFlexGeolocationAudienceSave = () => {
        initAudiencesOverallTable({ searchString: '' });
    };

    const calculateMinHeight = () => {
        if (isLoading || !tableRef) {
            return 0;
        }

        // We calculate the distance between the table container to the bottom of the window.
        // This is so we can show as much audiences as possible in the window.
        // If there are a small number of audiences then we'll just calculate
        // the height based on that.
        return filteredAudiences.length
            ? Math.min(
                  window.innerHeight - tableRef.getBoundingClientRect().top - 70,
                  filteredAudiences.length * 60 + 40
              )
            : 400;
    };

    const debouncedSearch = useMemo(() => {
        return debounce(value => initAudiencesOverallTable({ searchString: value }), 200);
    }, []);

    const handleSearch = e => {
        const searchString = e.target.value;
        debouncedSearch(searchString);
    };

    const newAudienceCategories = getNewAudienceCategories({
        classes,
        openFlexSegmentsFormModal,
        openAudienceFormModal,
        goToSegmentBuilder,
        openUploadedEditorActions,
        isInternalUser: isInternalUser(),
    });

    const globalAudiences = _.filter(
        newAudienceCategories,
        audience => audience.isInternal === true
    );

    const internalAudiences = _.filter(
        newAudienceCategories,
        audience => audience.isInternal === false
    );

    return (
        <Container maxWidth="xl" maxHeight="100%" className={classes.mainContainer}>
            <Drawer
                anchor="right"
                open={audienceSidebar.isOpen}
                onClose={toggleSidebar}
                classes={{ paper: classes.drawer }}
            >
                <DialogTitle onClose={toggleSidebar} textVariant="h4">
                    {audienceSidebar.selectedAudience.name}
                </DialogTitle>
                {audienceSidebar.isLoading ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress isloading="true" />
                    </Box>
                ) : (
                    <SidebarAudienceItems organizationId={organizationId} {...audienceSidebar} />
                )}
            </Drawer>
            <Dialog open={isModalOpen} onClose={closeAudienceForm} maxWidth="md" fullWidth>
                <DialogTitle onClose={closeAudienceForm} textVariant="h6">
                    Add New Audience
                </DialogTitle>
                <DialogContent dividers>
                    <Box my={2}>
                        <Typography variant="body1">
                            Select the type of Audience Segment that you’d like to create.
                        </Typography>
                    </Box>
                    <Box my={2}>
                        {isInternalUser() && (
                            <Box
                                bgcolor={grey[100]}
                                p={2}
                                mb={3}
                                border={1}
                                borderRadius="5px"
                                borderColor={grey[200]}
                            >
                                <Typography variant="h6">Internal Only</Typography>
                                <Box pt={2}>
                                    <CardGrid
                                        categories={internalAudiences}
                                        onEditFlexGeolocationAudienceSave={
                                            onEditFlexGeolocationAudienceSave
                                        }
                                    />
                                </Box>
                            </Box>
                        )}
                        <CardGrid categories={globalAudiences} />
                    </Box>
                </DialogContent>
            </Dialog>
            <ReactivateConfirm
                isOpen={isReactivateConfirmOpen}
                onCancel={closeReactivateConfirm}
                onConfirm={confirmReactivateAudience}
                audienceValue={audienceValue || {}}
            />
            <Grid container>
                <Grid item xs="2">
                    <AudiencesTableFilter />
                </Grid>
                <Grid item xs="10">
                    <Box display="flex" justifyContent="space-between" mb={4}>
                        <Box maxWidth="550">
                            <SearchInput placeholder="Search by Name" onChange={handleSearch} />
                        </Box>
                        {canEditAudiences && (
                            <Button
                                startIcon={<AddIcon />}
                                variant="contained"
                                color="primary"
                                onClick={openAudienceForm}
                            >
                                New Audience
                            </Button>
                        )}
                    </Box>
                    <Box maxWidth="100%" ref={innerRef => setTableRef(innerRef)}>
                        <VirtualizedTable
                            data={filteredAudiences}
                            minHeight={calculateMinHeight()}
                            itemHeight={66}
                            isLoading={isLoading}
                            noResultsText="No Audiences found."
                            onSortChange={() => {}}
                            columns={audiencesConfig.columns}
                            sortBy={audiencesConfig.sortBy}
                            hiddenColumns={audiencesConfig.hiddenColumns}
                        />
                    </Box>
                </Grid>
            </Grid>
            {audienceValue && audienceValue.source_type === 'flex_location_category' && (
                <FlexSegmentsForm
                    type="standard"
                    title="Edit Flex Geolocation Audience (Pelmorex Audiences)"
                    onSave={onEditFlexGeolocationAudienceSave}
                    selectedFlexBrandId={audienceValue.id}
                />
            )}
            {audienceValue && audienceValue.source_type === 'flex_custom_brand' && (
                <FlexSegmentsForm
                    type="custom"
                    title="Edit Flex Geolocation Audience"
                    onSave={onEditFlexGeolocationAudienceSave}
                    selectedFlexBrandId={audienceValue.id}
                />
            )}
            <RetargetingModal open={isModalOpen} />
            {audienceValue && <UploadedAudiencesModal audienceId={audienceValue.id} />}
        </Container>
    );
};

const CardGrid = ({ categories, onEditFlexGeolocationAudienceSave }) => {
    const classes = useStyles();
    const [audienceCat, setAudienceCat] = React.useState('');

    const handleOnClick = category => {
        const { value, onClick } = category;
        setAudienceCat(value);
        onClick();
    };

    return (
        <Grid container spacing={2}>
            {_.map(categories, category => (
                <Grid item xs={4} key={category.title}>
                    <RouterLink
                        color="inherit"
                        component={Link}
                        underline="none"
                        onClick={() => handleOnClick(category)}
                    >
                        <Card classes={{ root: classes.root }}>
                            {category.tooltip && <CardOverlay category={category} />}
                            <CardContent classes={{ root: classes.cardContent }}>
                                <Box
                                    textAlign="center"
                                    className={`${category.isEnabled === false &&
                                        classes.disabled}`}
                                >
                                    <Box justifyContent="center" display="flex" fontWeight="bold">
                                        <Typography variant="h5" color="textPrimary" gutterBottom>
                                            {category.title}
                                        </Typography>
                                    </Box>
                                    <Box
                                        height="100"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        mb={1}
                                        className={classes.iconColor}
                                    >
                                        {category.icon}
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </RouterLink>
                </Grid>
            ))}
            {audienceCat === 'flex-brand' && (
                <FlexSegmentsForm
                    type="standard"
                    onSave={onEditFlexGeolocationAudienceSave}
                    title="New Flex Geolocation Audience (Pelmorex Audiences)"
                />
            )}
            {audienceCat === 'flex-custom' && (
                <FlexSegmentsForm
                    type="custom"
                    title="New Flex Geolocation Audience"
                    onSave={onEditFlexGeolocationAudienceSave}
                />
            )}
            {audienceCat === 'retargeting' && <RetargetingModal open={true} />}
            {audienceCat === 'uploaded' && <UploadedAudiencesModal />}
        </Grid>
    );
};

const CardOverlay = ({ category }) => {
    const classes = useStyles();
    return category.isEnabled === false ? (
        <Box className={`${classes.warningOverlay} ${classes.warningLabel} ${classes.disabled}`}>
            {category.warningText}
        </Box>
    ) : (
        <Box className={`${classes.overlay}`}>
            <Typography variant="h5" gutterBottom>
                {category.title}
            </Typography>
            {category.tooltip}
        </Box>
    );
};

const ActionMenu = ({
    original,
    openAudienceEditorForm,
    reactivateAudience,
    canEditAudiences,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const showReactivate = canEditAudiences && original.source_type === 'pds_custom' && original.status === AUDIENCE_STATUS_MAPPING.suspended;
    const showEdit = (canEditAudiences &&
        original.type === 'Custom Audiences' &&
        original.category !== 'Pelmorex Data') ||
    (canEditAudiences && original.source_type === 'flex_location_category');

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return showEdit || showReactivate ? (
        <div>
            <IconButton
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {showEdit && <MenuItem onClick={() => openAudienceEditorForm(original)}>
                    Edit
                </MenuItem>}
                {showReactivate && <MenuItem onClick={() => reactivateAudience(original)}>
                    Reactivate
                </MenuItem>}
            </Menu>
        </div>
    ) : null;
}

const ReactivateConfirm = ({ isOpen, onCancel, onConfirm, audienceValue }) => {
    const shouldContactCES = audienceValue.contact_ces;
    return (
        <ConfirmDialog
            isOpen={isOpen}
            onCancel={onCancel}
            onConfirm={onConfirm}
            isConfirmDisabled={shouldContactCES}
            title={shouldContactCES ? 'Notice' : 'Confirm Reactivation'}
            content={shouldContactCES ? 'Contact your CES.' : `Reactivate Audience ${audienceValue.name}?`}
        />
    )
}

export default OverallTableDashboard;
