import React from 'react';
import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import 'moment-timezone';

import Box from '@mui/material/Box';

import { hasTopLevelFieldsBeenModified } from 'widgets/history-table';
import LANGUAGE_CODES from 'common/constants/language-codes';
import CARRIER_TARGETING from 'common/constants/carrier-targeting';
import PLATFORM from 'common/constants/platforms';
import { IAS_FRAUD_PREVENTION_RISK_MAPPING } from 'common/constants/ias-fraud-prevention';
import { IAS_VIEWABILITY_OPTIONS } from 'common/constants/ias-viewability';
import { IAS_GROUPM_VIEWABILITY_MAPPING } from 'common/constants/ias-groupm-viewability';
import { IAS_PUBLICIS_VIEWABILITY_MAPPING } from 'common/constants/ias-publicis-viewability';
import { IAS_BRAND_SAFETY_MAPPING } from 'common/constants/ias-brand-safety';
import {
    getTargetCarriersInclude,
    getTargetCarriersExclude,
} from 'common/constants/carrier-targeting-v2';
import {
    WeatherConditionSummary,
    getConversionsForCreativeOptimization,
    labelWeatherConditionQualifier,
    labelWeatherConditionValue,
    labelWeatherConditionTimeframe,
} from 'containers/ad-summary';
import {
    hasWeatherConditions,
    getConditionLabel,
} from 'common/constants/weather-condition-targeting';
import IABCATEGORY_TARGETING from 'common/constants/iab-categories';
import { DOOH_VENUE_TYPES_NO_PARENT } from 'common/constants/dooh-venue-types';
import formatGeoCountry from 'services/gis/geo-country-format';
import { IAS_CONTEXTUAL_TARGETING_MAPPING } from 'common/constants/ias-contextual-targeting';
import { convertDaypartsByTimezone, mergeTimeranges } from 'common/fns';
import { labelExchange } from 'utils/formatting';
import {
    isTacticsEnabled,
    calculateThirdPartyFees,
    checkIsNewScheduledWeightedRotationMode,
    formatBillableVolume,
} from 'states/resources/ads/business-logic';
import {
    checkOnlyCreativeWeightChanges,
    hasChangedBillableImpressions,
    hasChangedNonBillableImpressions,
} from './helpers';

import {
    formatNumber_whole,
    formatNumber_currency,
    formatNumber_percentage,
} from 'utils/formatting';

import Separator from 'widgets-v5/separator';
import Spacer from 'widgets-v5/spacer';

import { IncludeExcludeLayout } from 'widgets-v6/layout';

import { getEnvironmentSettings } from 'services/environment';
import { formatDate_long } from 'utils/formatting';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

import flags from 'containers/flags/service';

const BILLING_RATES = {
    CPM: 'cpm_rate',
    CPC: 'cpc_rate',
    billable_media_cost_markup: 'billable_media_cost_markup_rate',
    billable_media_cost_margin: 'billable_media_cost_margin_rate',
};

const REVENUE_MODEL = {
    cpmcpc: 'CPM/CPC',
    cpm: 'CPM',
    agencyMargin: 'Agency Margin',
    totalSpendMarkup: 'Total Cost Markup',
    disabled: 'Disabled',
};

const formatApplistEntries = ({ listIds, appLists }) => {
    const formattedLists = _.map(listIds, value => {
        const appList = _.find(appLists, { id: value });
        if (appList) {
            return appList.name;
        }
        return '';
    }).join(', ');
    return formattedLists;
};

const getTargetCarriers = obj => {
    return _.isArray(obj)
        ? _.map(obj, function(i) {
              return CARRIER_TARGETING[i];
          }).join(', ')
        : '';
};

function getBillingRate(snapshot) {
    return snapshot[BILLING_RATES[snapshot.ef_billing_terms]];
}

const rotationMethodMapping = mode => {
    switch (mode) {
        case CreativeRotationMethodMapping.Single:
            return 'Single Creative';
        case CreativeRotationMethodMapping.Even:
            return 'Even Rotation';
        case CreativeRotationMethodMapping.Weighted:
            return 'Weighted Rotation';
        case CreativeRotationMethodMapping.Scheduled:
            return 'Scheduled Rotation';
        case CreativeRotationMethodMapping.OptimizedForClicks:
            return 'Optimize For Clicks';
        case CreativeRotationMethodMapping.OptimizedForConversions:
            return 'Optimize For Conversions';
        case CreativeRotationMethodMapping.OptimizedForVideoCompletion:
            return 'Optimize For Video Completion';
        case CreativeRotationMethodMapping.Weather:
            return 'Weather Condition';
        default:
            return '';
    }
};
export default function selector(storeState, props) {
    const { adId, campaignId } = props.params;
    const historyData = _.get(storeState, `resources.history.ads.${adId}`, []);
    const adName = _.get(storeState, `resources.ads.${adId}.attr.name`, '');
    const ad = _.get(storeState, `resources.ads.${adId}.attr`);
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    const organizationTimezone = _.get(storeState, 'profile.organizationTimezone');
    const ftaLocationLists = _.get(storeState, 'adHistory.ftaLocationLists');
    const organization = _.get(storeState, 'adHistory.organization');

    const ftaLocationListsMapping = {};
    _.each(ftaLocationLists, locationList => {
        ftaLocationListsMapping[locationList.id] = locationList.name;
    });

    const fields = getFields({
        storeState,
        ad,
        campaign,
        ftaLocationListsMapping,
        organization,
    });

    const filteredHistoryData = _(historyData)
        .filter((entry, index) => {
            if (index === 0) {
                return true;
            }

            const currenyHistoryValue = JSON.stringify(_.omit(entry.snapshot, ['_etag']));
            const prevHistoryValue = JSON.stringify(
                _.omit(historyData[index - 1].snapshot, ['_etag'])
            );

            return currenyHistoryValue !== prevHistoryValue;
        })
        .filter(entry => {
            const allChanges = _.map(fields, field => {
                return !!field.hasChange(entry);
            });

            const hasNoChanges = _.every(allChanges, change => change === false);

            return !hasNoChanges;
        })
        .value();

    const out = {
        adName,
        historyEntries: filteredHistoryData,
        fields,
        organizationTimezone,
        organizationId: _.get(storeState, 'profile.organizationId'),
        isLoading: _.get(storeState, 'adHistory.isLoading', false),
    };
    return out;
}

function getFields({ storeState, ad, campaign, ftaLocationListsMapping, organization }) {
    const environmentSettings = getEnvironmentSettings();
    if (!ad || !campaign) {
        return [];
    }

    const fields = [].concat(
        [
            {
                label: 'Basic',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'platform',
                        'name',
                        'pased',
                        'reference_id',
                        'notes',
                    ]);
                },
                format() {
                    return '';
                },
            },
            {
                label: 'Platform',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['platform']);
                },
                format(entry) {
                    let platform = _.find(PLATFORM, item => {
                        if (entry.snapshot.platform === item.value) {
                            return item;
                        }
                    });
                    return platform.label;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Name',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['name']);
                },
                format(entry) {
                    return entry.snapshot.name;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Status',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['paused']);
                },
                format(entry) {
                    return entry.snapshot.paused ? 'Off' : 'On';
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Reference ID',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['reference_id']);
                },
                format(entry) {
                    return entry.snapshot.reference_id;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
        ],
        _.map(ad.custom_fields, (adCustomField, customFieldIndex) => {
            return {
                label: adCustomField.name,
                isCategory: false,
                hasChange(entry) {
                    let hasChange;
                    const isAdd = _.get(entry, 'patch.custom_fields.op') === 'add';
                    if (isAdd) {
                        hasChange = true;
                    } else {
                        const indexesOfChangedCustomFields = _(entry.patch)
                            .map((value, key) => key)
                            .filter(key => {
                                if (key.indexOf('custom_fields') > -1) {
                                    return true;
                                } else {
                                    return false;
                                }
                            })
                            .map(key => String(key.split('.')[1]))
                            .value();

                        hasChange = _.includes(
                            indexesOfChangedCustomFields,
                            String(customFieldIndex)
                        );
                    }
                    return hasChange;
                },
                format(entry) {
                    const customField = _.find(entry.snapshot.custom_fields, {
                        name: adCustomField.name,
                    });

                    return customField ? customField.value : '';
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            };
        }),
        [
            {
                label: 'Notes',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['notes']);
                },
                format(entry) {
                    return entry.snapshot.notes;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Schedule',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'timezone',
                        'start',
                        'end',
                        'dayparts',
                        'dayparts_local',
                        'weekparts',
                        'weekparts_local',
                    ]);
                },
                format() {
                    return '';
                },
            },
            {
                label: 'Timezone',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['timezone']);
                },
                format(entry) {
                    return entry.snapshot.timezone;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Start Date',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['start']);
                },
                format(entry) {
                    const { start, timezone } = entry.snapshot;
                    return formatDate_long(start, timezone);
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'End Date',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['end']);
                },
                format(entry) {
                    const { end, timezone } = entry.snapshot;
                    return formatDate_long(end, timezone);
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Dayparts',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'dayparts',
                        'dayparts_local',
                    ]);
                },
                format(entry) {
                    const { dayparts_local, timezone, start } = entry.snapshot;

                    return formatDayparts(
                        entry.snapshot,
                        _.isArray(dayparts_local) ? 'UTC' : timezone,
                        start
                    );
                },
            },
            {
                label: 'Weekparts',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'weekparts',
                        'weekparts_local',
                    ]);
                },
                format(entry) {
                    const { weekparts, weekparts_local } = entry.snapshot;

                    // use weekparts_local when weepparts is null
                    const _weekparts = weekparts || weekparts_local;

                    return formatWeekparts(_weekparts, weekparts ? '(UTC) ' : '(Local Time) ');
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Revenue',
                isCategory: true,
                hasChange(entry) {
                    if (
                        hasChangedBillableImpressions(entry) ||
                        hasChangedNonBillableImpressions(entry)
                    ) {
                        return true;
                    }

                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'billing_rate',
                        'billing_term',
                        'billing_enabled',
                        'campaign_budget_enabled',
                        'campaign_max_total_spend_local',
                    ]);
                },
                format() {
                    return '';
                },
            },
            campaign.revenueModel !== 'agencyMargin' && {
                label: 'Bonus Ad',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['billing_enabled']);
                },
                format(entry) {
                    return entry.snapshot.billing_enabled ? 'Yes' : 'No';
                },
            },
            {
                label: 'Revenue Model',
                isCategory: false,
                hasChange(entry) {
                    const intersection = _.intersection(_.keys(entry.patch), [
                        'billing_rate',
                        'billing_term',
                        'billing_enabled',
                        'campaign_budget_enabled',
                        'campaign_max_total_spend_local',
                    ]);

                    return intersection.length > 0;
                },
                format() {
                    return REVENUE_MODEL[campaign.revenueModel];
                },
            },
            {
                label: campaign.revenueModel === 'agencyMargin' ? 'Agency Margin' : 'Billing Rate',
                isCategory: false,
                hasChange(entry) {
                    const intersection = _.intersection(_.keys(entry.patch), [
                        'billing_rate',
                        'billing_term',
                        'billing_enabled',
                        'campaign_budget_enabled',
                        'campaign_max_total_spend_local',
                    ]);

                    return intersection.length > 0;
                },
                format(entry) {
                    const { snapshot } = entry;
                    const billingRate = getBillingRate(snapshot);

                    let value = '';
                    if (campaign.revenueModel === 'agencyMargin') {
                        value = `${billingRate * 100}%`;
                        return value;
                    }
                    if (campaign.revenueModel !== 'disabled') {
                        switch (snapshot.ef_billing_terms) {
                            case 'CPM': {
                                value = `${formatNumber_currency(billingRate)} CPM`;
                                break;
                            }
                            case 'CPC': {
                                value = `${formatNumber_currency(billingRate)} CPC`;
                                break;
                            }
                        }
                    }
                    return value;
                },
            },
            campaign.revenueModel !== 'agencyMargin' && {
                label: 'Billable Impressions',
                isCategory: false,
                hasChange(entry) {
                    if (hasChangedBillableImpressions(entry)) {
                        return true;
                    }

                    const intersection = _.intersection(_.keys(entry.patch), [
                        'billing_rate',
                        'billing_term',
                        'billing_enabled',
                        'campaign_budget_enabled',
                        'campaign_max_total_spend_local',
                    ]);

                    return intersection.length > 0;
                },
                format(entry) {
                    const { snapshot } = entry;
                    const isUnlimited = snapshot.billable_volume === -1;

                    if (isUnlimited) {
                        return 'Unlimited';
                    }

                    return formatBillableVolume(
                        snapshot.billable_volume,
                        snapshot.ef_billing_terms
                    );
                },
            },
            campaign.revenueModel !== 'agencyMargin' && {
                label:
                    campaign.revenueModel === 'disabled'
                        ? 'Total Impressions'
                        : 'Non-billable Impressions',
                isCategory: false,
                hasChange(entry) {
                    if (hasChangedNonBillableImpressions(entry)) {
                        return true;
                    }

                    const intersection = _.intersection(_.keys(entry.patch), [
                        'billing_rate',
                        'billing_term',
                        'billing_enabled',
                        'campaign_budget_enabled',
                        'campaign_max_total_spend_local',
                    ]);

                    return intersection.length > 0;
                },
                format(entry) {
                    const { snapshot } = entry;

                    return formatBillableVolume(
                        snapshot.non_billable_volume,
                        snapshot.ef_billing_terms
                    );
                },
            },
            {
                label: 'Budget',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'billing_rate',
                        'billing_term',
                        'billing_enabled',
                        'campaign_budget_enabled',
                        'campaign_max_total_spend_local',
                    ]);
                },
                format() {
                    return '';
                },
            },
            campaign.revenueModel !== 'agencyMargin' && {
                label: 'Total Impressions',
                isCategory: false,
                hasChange(entry) {
                    const intersection = _.intersection(_.keys(entry.patch), [
                        'billing_rate',
                        'billing_term',
                        'billing_enabled',
                        'campaign_budget_enabled',
                        'campaign_max_total_spend_local',
                    ]);

                    return intersection.length > 0;
                },
                format(entry) {
                    const { snapshot } = entry;
                    const isUnlimited = snapshot.billable_volume === -1;
                    let value = '';

                    if (campaign.revenueModel !== 'disabled') {
                        value = `${
                            isUnlimited
                                ? 'Unlimited'
                                : formatNumber_whole(
                                      snapshot.billable_volume + snapshot.non_billable_volume
                                  )
                        }`;
                    }
                    return value;
                },
            },
            {
                label:
                    campaign.revenueModel !== 'agencyMargin' ? 'Total Revenue' : 'Revenue Budget',
                isCategory: false,
                hasChange(entry) {
                    const intersection = _.intersection(_.keys(entry.patch), [
                        'billing_rate',
                        'billing_term',
                        'billing_enabled',
                        'campaign_budget_enabled',
                        'campaign_max_total_spend_local',
                    ]);

                    return intersection.length > 0;
                },
                format(entry) {
                    const { snapshot } = entry;
                    const billingRate = getBillingRate(snapshot);
                    const isUnlimited = snapshot.billable_volume === -1;

                    let value = '';

                    switch (snapshot.ef_billing_terms) {
                        case 'CPM': {
                            value = `${
                                isUnlimited
                                    ? 'Unlimited'
                                    : formatNumber_currency(
                                          (snapshot.billable_volume / 1000) * billingRate
                                      )
                            }`;
                            break;
                        }
                        case 'CPC': {
                            value = `Total ${'Revenue'} : ${
                                isUnlimited
                                    ? 'Unlimited'
                                    : formatNumber_currency(snapshot.billable_volume * billingRate)
                            }`;
                            break;
                        }
                        case 'billable_media_cost_margin': {
                            const totalBillings = snapshot.billable_volume;

                            value = `${
                                isUnlimited ? 'Unlimited' : formatNumber_currency(totalBillings)
                            }`;
                            break;
                        }
                    }

                    return value;
                },
            },
            campaign.revenueModel === 'agencyMargin' && {
                label: 'Total Cost Budget',
                isCategory: false,
                hasChange(entry) {
                    const intersection = _.intersection(_.keys(entry.patch), [
                        'billing_rate',
                        'billing_term',
                        'billing_enabled',
                        'campaign_budget_enabled',
                        'campaign_max_total_spend_local',
                    ]);

                    return intersection.length > 0;
                },
                format(entry) {
                    const { snapshot } = entry;
                    const billingRate = getBillingRate(snapshot);
                    const isUnlimited = snapshot.billable_volume === -1;

                    let value = '';

                    if (snapshot.ef_billing_terms === 'billable_media_cost_margin') {
                        const billableMediaSpend = snapshot.billable_volume * (1 - billingRate);

                        value = `${
                            isUnlimited ? 'Unlimited' : formatNumber_currency(billableMediaSpend)
                        }`;
                    }
                    return value;
                },
            },
            {
                label: 'Third Party Fees',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['thirdPartyFees']);
                },
                format(entry) {
                    const { snapshot } = entry;
                    let value = {
                        label: ` ${formatNumber_currency(calculateThirdPartyFees(snapshot))} CPM`,
                    };
                    return value.label;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Pacing',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['use_front_load_pacing']);
                },
                format() {
                    return '';
                },
            },
            {
                label: 'Pacing Strategy',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['use_front_load_pacing']);
                },
                format(entry) {
                    const { use_front_load_pacing } = entry.snapshot;
                    if (!use_front_load_pacing) {
                        return 'Even Pacing: Impressions';
                    }
                    return 'Front-loaded Pacing: Impressions';
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },

            {
                label: 'Daily Pacing',
                isCategory: false,
                hasChange(entry) {
                    return (
                        hasTopLevelFieldsBeenModified(entry.patch, ['use_front_load_pacing']) ||
                        hasTopLevelFieldsBeenModified(entry.patch, [
                            'max_daily_impressions',
                            'max_daily_clicks',
                            'max_daily_spend_local',
                            'max_daily_billings',
                        ])
                    );
                },
                prepData(entry) {
                    const { snapshot } = entry;

                    let impressionsLabel;
                    if (snapshot.max_daily_impressions) {
                        impressionsLabel = formatNumber_whole(snapshot.max_daily_impressions);
                    } else {
                        impressionsLabel = 'Auto';
                    }

                    let clicksLabel;
                    if (snapshot.max_daily_clicks) {
                        clicksLabel = formatNumber_whole(snapshot.max_daily_clicks);
                    } else {
                        clicksLabel = 'Auto';
                    }

                    let spendLabel;
                    if (snapshot.max_daily_spend_local) {
                        spendLabel = formatNumber_currency(snapshot.max_daily_spend_local);
                    } else {
                        spendLabel = 'Auto';
                    }

                    const labels = [
                        `Impressions: ${impressionsLabel}`,
                        `Clicks: ${clicksLabel}`,
                        `Total Cost : ${spendLabel}`,
                    ];

                    return snapshot.use_front_load_pacing ? ['N/A'] : labels;
                },
                format(entry) {
                    const data = this.prepData(entry);

                    return (
                        <div>
                            {_.map(data, label => (
                                <div key={label}>{label}</div>
                            ))}
                        </div>
                    );
                },
                getClipboardText(entry) {
                    return this.prepData(entry).join(', ');
                },
            },
            environmentSettings.canUseFta() && {
                label: 'FTA',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['fta']);
                },
                format() {
                    return '';
                },
            },
            environmentSettings.canUseFta() && {
                label: 'Foot Traffic Attribution',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['fta']);
                },
                format(entry) {
                    const { fta } = entry.snapshot;
                    if (!fta || fta.enabled === null) {
                        return 'N/A';
                    }

                    return (
                        <table>
                            <tbody>
                                <tr>
                                    <td>{fta.enabled ? 'Enabled' : 'Disabled'}</td>
                                </tr>
                                <tr>
                                    <td>Location List: </td>
                                    <td>{ftaLocationListsMapping[fta.location_list] || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Partner ID: </td>
                                    <td>{fta.partner_id || 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                },
                getClipboardText(entry) {
                    return JSON.stringify(entry.snapshot.fta);
                },
            },
            {
                label: 'Creative',
                isCategory: true,
                hasChange(entry) {
                    const hasChange = hasTopLevelFieldsBeenModified(entry.patch, [
                        'creative',
                        'rotation_rules',
                    ]);

                    const onlyWeightCreativeChanges = checkOnlyCreativeWeightChanges(
                        entry.patch,
                        entry.snapshot
                    );
                    return hasChange && !onlyWeightCreativeChanges;
                },
                format() {
                    return '';
                },
            },
            {
                label: 'Creative',
                isCategory: false,
                hasChange(entry) {
                    const hasChange = hasTopLevelFieldsBeenModified(entry.patch, [
                        'creative',
                        'rotation_rules',
                    ]);

                    const onlyWeightCreativeChanges = checkOnlyCreativeWeightChanges(
                        entry.patch,
                        entry.snapshot
                    );
                    return hasChange && !onlyWeightCreativeChanges;
                },
                format(entry) {
                    const creatives = _.get(storeState, 'resources.creatives', {});
                    const { creative } = entry.snapshot;
                    const mode = _.get(
                        entry.snapshot,
                        'rotation_rules.mode',
                        CreativeRotationMethodMapping.Single
                    );
                    const rotationRules = _.get(entry, 'snapshot.rotation_rules');

                    const renderRangeAndScheduledCreatives = schedule => {
                        return (
                            <div key={schedule.id}>
                                <Box fontWeight="fontWeightBold">
                                    {formatDate_long(schedule.start, entry.snapshot.timezone)} -{' '}
                                    {formatDate_long(schedule.end, entry.snapshot.timezone)}
                                </Box>
                                {checkIsNewScheduledWeightedRotationMode(rotationRules)
                                    ? _.map(schedule.weighted, weighted => (
                                          <div key={weighted.id}>
                                              #{weighted.markup_id}{' '}
                                              {_.get(creatives, `${weighted.markup_id}.attr.name`)}{' '}
                                              {`(${formatNumber_percentage(
                                                  weighted.weight / 100
                                              )})`}
                                          </div>
                                      ))
                                    : _.get(creatives, `${schedule.markup_id}.attr.name`)}
                                <br />
                            </div>
                        );
                    };

                    if (
                        _.includes(
                            [
                                CreativeRotationMethodMapping.Weighted,
                                CreativeRotationMethodMapping.Even,
                                CreativeRotationMethodMapping.OptimizedForClicks,
                                CreativeRotationMethodMapping.OptimizedForConversions,
                                CreativeRotationMethodMapping.OptimizedForVideoCompletion,
                            ],
                            mode
                        )
                    ) {
                        return (
                            <div>
                                <div>
                                    {`Rotation Method: ${rotationMethodMapping(mode)}`}
                                    <br />
                                    <br />
                                </div>
                                {_.map(entry.snapshot.rotation_rules.weighted, rule => {
                                    let name = _.get(
                                        creatives,
                                        `${rule.markup_id}.attr.name`,
                                        creative
                                    );
                                    if (
                                        _.includes(
                                            [
                                                CreativeRotationMethodMapping.OptimizedForClicks,
                                                CreativeRotationMethodMapping.OptimizedForConversions,
                                                CreativeRotationMethodMapping.OptimizedForVideoCompletion,
                                            ],
                                            mode
                                        )
                                    ) {
                                        return (
                                            <div key={rule.markup_id}>
                                                {`${name} (#${rule.markup_id})`}
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div key={rule.markup_id}>
                                                {`${name} (#${
                                                    rule.markup_id
                                                }): ${formatNumber_percentage(rule.weight)}`}
                                            </div>
                                        );
                                    }
                                })}
                                {_.map(rotationRules.scheduled, renderRangeAndScheduledCreatives)}
                            </div>
                        );
                    } else if (_.includes([CreativeRotationMethodMapping.Weather], mode)) {
                        const weatherRotationSettings = _.get(
                            entry.snapshot,
                            'rotation_rules.weather'
                        );
                        return (
                            <div>
                                <div>
                                    {`Rotation Method: ${rotationMethodMapping(mode)}`}
                                    <br />
                                    <br />
                                </div>
                                {_.map(weatherRotationSettings, (setting, index) => {
                                    let name = _.get(
                                        creatives,
                                        `${setting.markupId}.attr.name`,
                                        creative
                                    );
                                    return (
                                        <div key={setting.id}>
                                            {setting.isDefault ? 'Default rule:' : `Rule ${index}:`}
                                            <br />
                                            {!setting.isDefault && (
                                                <React.Fragment>
                                                    Type: {getConditionLabel(setting.type)}
                                                    <br />
                                                    Qualifier:{' '}
                                                    {labelWeatherConditionQualifier(
                                                        setting.qualifier
                                                    )}
                                                    <br />
                                                    Time Frame:{' '}
                                                    {labelWeatherConditionTimeframe(
                                                        setting.timeframe
                                                    )}
                                                    <br />
                                                    Amount:{' '}
                                                    {labelWeatherConditionValue(
                                                        setting.amount,
                                                        setting.type
                                                    )}
                                                    <br />
                                                </React.Fragment>
                                            )}
                                            {`Creative: ${name} (#${setting.markupId})`}
                                            <br />
                                            {index < weatherRotationSettings.length - 1 && <br />}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    } else if (
                        _.includes(
                            [
                                CreativeRotationMethodMapping.Single,
                                CreativeRotationMethodMapping.Scheduled,
                            ],
                            mode
                        )
                    ) {
                        return (
                            <div>
                                <div>
                                    {`Rotation Method: ${rotationMethodMapping(mode)}`}
                                    <br />
                                    <br />
                                </div>
                                {!rotationRules.scheduled.length
                                    ? _.map(creative, creativeId => {
                                          let name = _.get(
                                              creatives,
                                              `${creativeId}.attr.name`,
                                              creative
                                          );
                                          return (
                                              <div
                                                  key={creativeId}
                                              >{`${name} (#${creativeId})`}</div>
                                          );
                                      })
                                    : _.map(
                                          rotationRules.scheduled,
                                          renderRangeAndScheduledCreatives
                                      )}
                            </div>
                        );
                    }
                },
            },
            {
                label: 'Conversion Optimization',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'conversionsForCreativeOptimization',
                    ]);
                },
                format() {
                    return '';
                },
            },
            {
                label: 'Optimized Conversion Events',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'conversionsForCreativeOptimization',
                    ]);
                },
                format(entry) {
                    const conversionsInCampaign = _.concat(
                        campaign.conversions,
                        campaign.advertiserConversions
                    );

                    return getConversionsForCreativeOptimization(
                        entry.snapshot.conversionsForCreativeOptimization,
                        conversionsInCampaign
                    ).toString();
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            environmentSettings.canUseIas() && {
                label: 'Third Party Verification',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['forensiq_risk_cap']);
                },
                format() {
                    return '';
                },
            },
            environmentSettings.canUseIas() && {
                label: 'Fraud Prevention',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['ias']);
                },
                prepData(entry) {
                    const { snapshot } = entry;

                    let ipLabel = snapshot.ias.fraudPrevention.ip.value === 1 ? 'On' : 'Off';
                    let unratedLabel =
                        snapshot.ias.fraudPrevention.unrateable.value === 1 ? 'On' : 'Off';

                    const labels = [
                        `Content-based: ${
                            IAS_FRAUD_PREVENTION_RISK_MAPPING[
                                snapshot.ias.fraudPrevention.risk.value
                            ]
                        }`,
                        `Exclude Unrated content: ${ipLabel}`,
                        `Excloud fraudulent IP Addresses: ${unratedLabel}`,
                    ];

                    return snapshot.use_front_load_pacing ? ['N/A'] : labels;
                },
                format(entry) {
                    const data = this.prepData(entry);

                    return (
                        <div>
                            {_.map(data, label => (
                                <div key={label}>{label}</div>
                            ))}
                        </div>
                    );
                },

                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            environmentSettings.canUseIas() && {
                label: 'Viewability',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['ias']);
                },
                prepData(entry) {
                    const { snapshot } = entry;

                    const IAS_VIEWABILITY_OPTIONS_MAPPING = {};
                    _.each(IAS_VIEWABILITY_OPTIONS, option => {
                        IAS_VIEWABILITY_OPTIONS_MAPPING[option.value] = option;
                    });

                    const labels = [
                        `Viewability: ${
                            IAS_VIEWABILITY_OPTIONS_MAPPING[snapshot.ias.viewability.value].label
                        }`,
                        `GroupM Performance Matching: ${
                            IAS_GROUPM_VIEWABILITY_MAPPING[snapshot.ias.groupmViewability.value]
                        }`,
                        `Publicis Performance Matching: ${
                            IAS_PUBLICIS_VIEWABILITY_MAPPING[snapshot.ias.publicisViewability.value]
                        }`,
                    ];

                    return snapshot.use_front_load_pacing ? ['N/A'] : labels;
                },
                format(entry) {
                    const data = this.prepData(entry);

                    return (
                        <div>
                            {_.map(data, label => (
                                <div key={label}>{label}</div>
                            ))}
                        </div>
                    );
                },

                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            environmentSettings.canUseIas() && {
                label: 'Brand Safety',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['ias']);
                },
                prepData(entry) {
                    const { snapshot } = entry;

                    const labels = [
                        `Adult: ${IAS_BRAND_SAFETY_MAPPING[snapshot.ias.brandSafety.adult.value]}`,
                        `Alcohol: ${
                            IAS_BRAND_SAFETY_MAPPING[snapshot.ias.brandSafety.alcohol.value]
                        }`,
                        `Gambling: ${
                            IAS_BRAND_SAFETY_MAPPING[snapshot.ias.brandSafety.gambling.value]
                        }`,
                        `Hate Speech: ${
                            IAS_BRAND_SAFETY_MAPPING[snapshot.ias.brandSafety.hateSpeech.value]
                        }`,
                        `Illegal Downloads: ${
                            IAS_BRAND_SAFETY_MAPPING[
                                snapshot.ias.brandSafety.illegalDownloads.value
                            ]
                        }`,
                        `Illegal Drugs: ${
                            IAS_BRAND_SAFETY_MAPPING[snapshot.ias.brandSafety.illegalDrugs.value]
                        }`,
                        `Offensive Language: ${
                            IAS_BRAND_SAFETY_MAPPING[
                                snapshot.ias.brandSafety.offensiveLanguage.value
                            ]
                        }`,
                        `Violence: ${
                            IAS_BRAND_SAFETY_MAPPING[snapshot.ias.brandSafety.violence.value]
                        }`,
                        `Exclude unrated content: ${
                            snapshot.ias.brandSafety.illegalDrugs.value === 1 ? 'On' : 'Off'
                        }`,
                    ];

                    return snapshot.use_front_load_pacing ? ['N/A'] : labels;
                },
                format(entry) {
                    const data = this.prepData(entry);

                    return (
                        <div>
                            {_.map(data, label => (
                                <div key={label}>{label}</div>
                            ))}
                        </div>
                    );
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            environmentSettings.canUseIas() &&
                organization?.isIasPostBidVerificationEnabled && {
                    label: 'Post-bid Verification',
                    isCategory: false,
                    hasChange(entry) {
                        return hasTopLevelFieldsBeenModified(entry.patch, [
                            'isIasPostBidVerificationEnabled',
                        ]);
                    },
                    format(entry) {
                        return entry.snapshot.isIasPostBidVerificationEnabled ? 'On' : 'Off';
                    },
                    getClipboardText(entry) {
                        return this.format(entry);
                    },
                },
            {
                label: 'Targeting',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'geotargets',
                        'target_device_os',
                        'target_carriers',
                        'carriers_exclude',
                        'target_device_language',
                        'target_app_store_cat',
                        'target_device_language_exclude',
                        'target_iab_categories',
                        'target_iab_categories_exclude',
                        'audiences',
                        'audience_exclude',
                        'target_genders',
                        'target_age_groups',
                        'geoboxes',
                        'tactics_generators',
                        'weatherConditions',
                        'target_dooh_venues',
                        'target_dooh_venues_exclude',
                    ]);
                },
                format() {
                    return '';
                },
            },
            {
                label: 'Geotargeting',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['geotargets']);
                },
                format(entry) {
                    return (
                        <div className="ef3-historyTable_bodyCell_geoTarget">
                            <ul>{_.map(entry.snapshot.geotargets, formatGeoCountry)}</ul>
                        </div>
                    );
                },
            },
            {
                label: 'User Language',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['target_device_language']);
                },
                format(entry) {
                    return formatDeviceLanguage(entry.snapshot.target_device_language);
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'User Language (Exclude)',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'target_device_language_exclude',
                    ]);
                },
                format(entry) {
                    return formatDeviceLanguage(entry.snapshot.target_device_language_exclude);
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Device OS',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['target_device_os']);
                },
                format(entry) {
                    return [].concat(entry.snapshot.target_device_os).join(', ');
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Venue Type Include',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['target_dooh_venues']);
                },
                format(entry) {
                    return formatDoohVenues(entry.snapshot.target_dooh_venues);
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Venue Type Exclude',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'target_dooh_venues_exclude',
                    ]);
                },
                format(entry) {
                    return formatDoohVenues(entry.snapshot.target_dooh_venues_exclude);
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
        ],
        !environmentSettings.canUseCarrierIsp()
            ? []
            : campaign.carriersISPsVersion === 1
            ? [
                  {
                      label: 'Target Carrier',
                      isCategory: false,
                      hasChange(entry) {
                          return hasTopLevelFieldsBeenModified(entry.patch, ['target_carriers']);
                      },
                      format(entry) {
                          return getTargetCarriers(entry.snapshot.target_carriers);
                      },
                      getClipboardText(entry) {
                          return this.format(entry);
                      },
                  },
              ]
            : [
                  {
                      label: 'Carrier / ISP',
                      isCategory: false,
                      hasChange(entry) {
                          return hasTopLevelFieldsBeenModified(entry.patch, ['target_carriers']);
                      },
                      format(entry) {
                          return getTargetCarriersInclude(
                              entry.snapshot.target_carriers,
                              entry.snapshot.platform
                          );
                      },
                      getClipboardText(entry) {
                          return this.format(entry);
                      },
                  },
                  {
                      label: 'Carrier / ISP (Exclude)',
                      isCategory: false,
                      hasChange(entry) {
                          return hasTopLevelFieldsBeenModified(entry.patch, ['carriers_exclude']);
                      },
                      format(entry) {
                          return getTargetCarriersExclude(
                              entry.snapshot.carriers_exclude,
                              entry.snapshot.platform
                          );
                      },
                      getClipboardText(entry) {
                          return this.format(entry);
                      },
                  },
              ],

        isTacticsEnabled(ad.tactics_enabled, campaign.id)
            ? []
            : campaign.isAppStoreCatEnabled
            ? [
                  {
                      label: 'App Categories (Include)',
                      isCategory: false,
                      hasChange(entry) {
                          return hasTopLevelFieldsBeenModified(entry.patch, [
                              'target_app_store_cat',
                          ]);
                      },
                      format(entry) {
                          if (!entry.snapshot.target_app_store_cat) {
                              return '';
                          }
                          return entry.snapshot.target_app_store_cat.join(', ');
                      },
                      getClipboardText(entry) {
                          return this.format(entry);
                      },
                  },
                  {
                      label: 'App Categories (Exclude)',
                      isCategory: false,
                      hasChange(entry) {
                          return hasTopLevelFieldsBeenModified(entry.patch, [
                              'target_app_store_cat_exclude',
                          ]);
                      },
                      format(entry) {
                          if (!entry.snapshot.target_app_store_cat_exclude) {
                              return '';
                          }
                          return entry.snapshot.target_app_store_cat_exclude.join(', ');
                      },
                      getClipboardText(entry) {
                          return this.format(entry);
                      },
                  },
              ]
            : [
                  {
                      label: 'IAB App Categories',
                      isCategory: false,
                      hasChange(entry) {
                          return hasTopLevelFieldsBeenModified(entry.patch, [
                              'target_iab_categories',
                          ]);
                      },
                      format(entry) {
                          return formatTargetIabCats(entry.snapshot.target_iab_categories);
                      },
                      getClipboardText(entry) {
                          return this.format(entry);
                      },
                  },
                  {
                      label: 'IAB App Categories (Exclude)',
                      isCategory: false,
                      hasChange(entry) {
                          return hasTopLevelFieldsBeenModified(entry.patch, [
                              'target_iab_categories_exclude',
                          ]);
                      },
                      format(entry) {
                          return formatTargetIabCats(entry.snapshot.target_iab_categories_exclude);
                      },
                      getClipboardText(entry) {
                          return this.format(entry);
                      },
                  },
                  {
                      label: 'Audience Segments Include',
                      isCategory: false,
                      hasChange(entry) {
                          return hasTopLevelFieldsBeenModified(entry.patch, ['audiences']);
                      },
                      format(entry) {
                          const audienceResources = _.get(storeState, 'resources.audiences', {});

                          return _.map(entry.snapshot.audiences, v => {
                              return _.get(audienceResources, `${v}.attr.name`);
                          }).join(', ');
                      },
                      getClipboardText(entry) {
                          return this.format(entry);
                      },
                  },
                  {
                      label: 'Audience Segments Exclude',
                      isCategory: false,
                      hasChange(entry) {
                          return hasTopLevelFieldsBeenModified(entry.patch, ['audience_exclude']);
                      },
                      format(entry) {
                          const audienceResources = _.get(storeState, 'resources.audiences', {});

                          return _.map(entry.snapshot.audience_exclude, v => {
                              return _.get(audienceResources, `${v}.attr.name`);
                          }).join(', ');
                      },
                      getClipboardText(entry) {
                          return this.format(entry);
                      },
                  },
              ],
        [
            environmentSettings.canUseGenderTargeting() && {
                label: 'Gender',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['target_genders']);
                },
                format(entry) {
                    return formatTargetGenders(entry.snapshot.target_genders);
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            environmentSettings.canUseAgeTargeting() && {
                label: 'Age',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['target_age_groups']);
                },
                format(entry) {
                    return formatAgeGroup(entry.snapshot.target_age_groups);
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            environmentSettings.canUseGeoFencing() && {
                label: 'Geofencing',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['geoboxes']);
                },
                format(entry) {
                    return (
                        <div className="ef3-historyTable_bodyCellGeoBox">
                            {_.map(entry.snapshot.geoboxes, (box, i) => {
                                const shorter = {};
                                _.each(box, function(val, key) {
                                    shorter[key] = val.toFixed(2);
                                });
                                return (
                                    <div key={i}>
                                        E: {shorter.E}, W: {shorter.W}, S: {shorter.S}, N:{' '}
                                        {shorter.N}
                                    </div>
                                );
                            })}
                        </div>
                    );
                },
            },
            isTacticsEnabled(ad.tactics_enabled, campaign.id) &&
                environmentSettings.canUseTactics() && {
                    label: 'Tactics',
                    isCategory: false,
                    hasChange(entry) {
                        return hasTopLevelFieldsBeenModified(entry.patch, ['tactics_generators']);
                    },
                    format(entry) {
                        return (
                            <React.Fragment>
                                {_.map(entry.snapshot.tactics_generators, tactic => {
                                    return (
                                        <React.Fragment key={tactic.id}>
                                            <Spacer type="small" />
                                            <Separator label={tactic.title} />
                                            <Spacer type="small" />

                                            {_.map(tactic.targeting, target => {
                                                switch (target.module) {
                                                    case 'categories': {
                                                        const exclude = _.get(
                                                            target,
                                                            'draft.ias.contextualTargeting.exclude',
                                                            []
                                                        );
                                                        const includeExcludedLabel = exclude
                                                            ? 'Included'
                                                            : 'Excluded';

                                                        const values = _.get(
                                                            target,
                                                            'draft.ias.contextualTargeting.value',
                                                            []
                                                        );
                                                        const labels = _.map(
                                                            values,
                                                            value =>
                                                                IAS_CONTEXTUAL_TARGETING_MAPPING[
                                                                    value
                                                                ]
                                                        );

                                                        return (
                                                            <div key={target.id}>
                                                                <div>App Categories (Include):</div>
                                                                <div>
                                                                    {_.get(
                                                                        target,
                                                                        'draft.target_app_store_cat',
                                                                        []
                                                                    ).join(', ')}
                                                                </div>
                                                                <div>App Categories (Exclude)</div>
                                                                <br />
                                                                <div>
                                                                    {_.get(
                                                                        target,
                                                                        'draft.target_app_store_cat_exclude',
                                                                        []
                                                                    ).join(', ')}
                                                                </div>
                                                                <div>Contextual Targeting:</div>
                                                                <div>{includeExcludedLabel}</div>
                                                                {labels.join(', ')}
                                                            </div>
                                                        );
                                                    }
                                                    case 'ias_contextual_targeting': {
                                                        const exclude = _.get(
                                                            target,
                                                            'draft.ias.contextualTargeting.exclude',
                                                            []
                                                        );
                                                        const includeExcludedLabel = exclude
                                                            ? 'Included'
                                                            : 'Excluded';

                                                        const values = _.get(
                                                            target,
                                                            'draft.ias.contextualTargeting.value',
                                                            []
                                                        );
                                                        const labels = _.map(
                                                            values,
                                                            value =>
                                                                IAS_CONTEXTUAL_TARGETING_MAPPING[
                                                                    value
                                                                ]
                                                        );

                                                        return (
                                                            <div key={target.id}>
                                                                <div>Contextual Targeting:</div>
                                                                <div>{includeExcludedLabel}</div>
                                                                {labels.join(', ')}
                                                            </div>
                                                        );
                                                    }
                                                    case 'app_categories': {
                                                        return (
                                                            <div key={target.id}>
                                                                <div>App Categories (Include):</div>
                                                                <div>
                                                                    {_.get(
                                                                        target,
                                                                        'draft.target_app_store_cat',
                                                                        []
                                                                    ).join(', ')}
                                                                </div>
                                                                <div>App Categories (Exclude)</div>
                                                                <div>
                                                                    {_.get(
                                                                        target,
                                                                        'draft.target_app_store_cat_exclude',
                                                                        []
                                                                    ).join(', ')}
                                                                </div>
                                                            </div>
                                                        );
                                                    }

                                                    case 'audience_segments': {
                                                        const audienceResources = _.get(
                                                            storeState,
                                                            'audienceSegmentPicker.audienceSegments',
                                                            {}
                                                        );
                                                        const audiencesInclude = _.map(
                                                            _.get(target, 'draft.audiences', []),
                                                            v => {
                                                                return _.result(
                                                                    _.find(audienceResources, {
                                                                        id: v,
                                                                    }),
                                                                    'name'
                                                                );
                                                            }
                                                        ).join(', ');

                                                        const audiencesExclude = _.map(
                                                            _.get(
                                                                target,
                                                                'draft.audience_exclude',
                                                                []
                                                            ),
                                                            v => {
                                                                return _.result(
                                                                    _.find(audienceResources, {
                                                                        id: v,
                                                                    }),
                                                                    'name'
                                                                );
                                                            }
                                                        ).join(', ');

                                                        let geoFencingResult = null;
                                                        if (
                                                            target.draft &&
                                                            target.draft.geo_targeting_settings
                                                        ) {
                                                            const geoFencingOutput = getGeofencing(
                                                                target.draft.geo_targeting_settings
                                                            );

                                                            geoFencingResult = (
                                                                <div
                                                                    className="ef3-historyTable_bodyCell_geoTargeting"
                                                                    key={target.id}
                                                                >
                                                                    <div>POI Segments:</div>
                                                                    {_.map(
                                                                        geoFencingOutput,
                                                                        (v, i) => (
                                                                            <p key={i}>{v}</p>
                                                                        )
                                                                    )}
                                                                </div>
                                                            );
                                                        }

                                                        return (
                                                            <React.Fragment>
                                                                <div key={target.id}>
                                                                    <div>
                                                                        Audience Segments Include:
                                                                    </div>
                                                                    <div>{audiencesInclude}</div>
                                                                    <div>
                                                                        Audience Segments Exclude:
                                                                    </div>
                                                                    <div>{audiencesExclude}</div>
                                                                </div>
                                                                <Spacer />
                                                                {geoFencingResult}
                                                            </React.Fragment>
                                                        );
                                                    }

                                                    case 'pois': {
                                                        const geoFencingOutput = getGeofencing(
                                                            target.draft.geo_targeting_settings
                                                        );

                                                        return (
                                                            <div
                                                                className="ef3-historyTable_bodyCell_geoTargeting"
                                                                key={target.id}
                                                            >
                                                                <div>POI Segments:</div>
                                                                {_.map(geoFencingOutput, (v, i) => (
                                                                    <p key={i}>{v}</p>
                                                                ))}
                                                            </div>
                                                        );
                                                    }

                                                    default:
                                                        return null;
                                                }
                                            })}

                                            <Spacer />
                                        </React.Fragment>
                                    );
                                })}
                            </React.Fragment>
                        );
                    },
                },
            environmentSettings.canUseWeatherConditions() && {
                label: 'Weather Conditions',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['weatherConditions']);
                },
                format(entry) {
                    if (campaign.isWeatherConditionEnabled) {
                        return hasWeatherConditions(entry.snapshot.weatherConditions) ? (
                            <React.Fragment>
                                {_.map(
                                    entry.snapshot.weatherConditions.conditions,
                                    (condition, index) => (
                                        <WeatherConditionSummary
                                            condition={condition}
                                            title={`Condition ${index + 1}`}
                                            showDivider={
                                                index !==
                                                entry.snapshot.weatherConditions.conditions.length -
                                                    1
                                            }
                                            logicRelationship={
                                                entry.snapshot.weatherConditions.logicRelationship
                                            }
                                        />
                                    )
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>None</React.Fragment>
                        );
                    }
                },
            },
        ]
    );
    fields.push(
        {
            label: 'Inventory',
            isCategory: true,
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, [
                    'dealApplicationType',
                    'applistSettings',
                    'exchanges_blocked_ui',
                ]);
            },
            format() {
                return '';
            },
        },
        {
            label: 'Public Inventory',
            isCategory: false,
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['dealApplicationType']);
            },
            format(entry) {
                if (entry.snapshot.dealApplicationType === 'deal_only') {
                    return 'Do not bid on open auctions';
                }
                return 'Bid on open auctions';
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: flags.isEnabled('efb_35_curated_deals') ? 'Deals' : 'Private Deals',
            isCategory: false,
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['referenced_deals']);
            },
            prepData(entry) {
                const { referenced_deals } = entry.snapshot;
                return referenced_deals?.map(deal => deal?.app_name);
            },
            format(entry) {
                const appNames = this.prepData(entry);
                return (
                    <div>
                        {appNames?.map((app_name, index) => <div key={index}>{app_name}, </div>)}
                    </div>
                );
            },
            getClipboardText(entry) {
                return this.prepData(entry).join(', ');
            },
        }
    );
    if (campaign.isExchangeTargetingEnabled && environmentSettings.canUseExchanges()) {
        fields.push({
            label: 'Exchanges(Exclude)',
            isCategory: false,
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['exchanges_blocked_ui']);
            },
            format(entry) {
                const values = entry.snapshot.exchanges_blocked_ui;

                if (!values || values.length === 0) {
                    return 'None';
                }

                return values
                    .map(value => labelExchange(value))
                    .sort()
                    .join(', ');
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        });
    }
    fields.push({
        label: environmentSettings.getAppsAndSitesLabel(),
        isCategory: false,
        hasChange(entry) {
            return hasTopLevelFieldsBeenModified(entry.patch, [
                'applistSettings',
                'whitelistedAppsAndSites',
                'blacklistedAppsAndSites',
            ]);
        },
        format(entry) {
            const appLists = _(_.get(storeState, `resources.appLists`, {}))
                .map(list => list.attr)
                .value();
            if (appLists.length === 0) {
                return '';
            }
            let whiteList = [];
            let blackList = [];

            const isUsingSeparateAppsAndSitesField = entry.snapshot.usingSeparateApplistFields;

            if (isUsingSeparateAppsAndSitesField) {
                whiteList = entry.snapshot.whitelistedAppsAndSites;
                blackList = entry.snapshot.blacklistedAppsAndSites;
            } else {
                if (entry.snapshot.applistSettings.mode === 'whitelist') {
                    whiteList = entry.snapshot.applistSettings.listIds;
                } else {
                    blackList = entry.snapshot.applistSettings.listIds;
                }
            }

            return (
                <React.Fragment>
                    <IncludeExcludeLayout type="Include">
                        {formatApplistEntries({ listIds: whiteList, appLists })}
                    </IncludeExcludeLayout>
                    <IncludeExcludeLayout type="Exclude">
                        {formatApplistEntries({ listIds: blackList, appLists })}
                    </IncludeExcludeLayout>
                </React.Fragment>
            );
        },
    });
    fields.push(
        {
            label: 'Bid Optimization',
            isCategory: true,
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, [
                    'max_cpm_rate_local',
                    'max_user_frequency',
                    'fcaps',
                    'use_deal_price_to_bid',
                ]);
            },
            format() {
                return '';
            },
        },
        {
            label: 'Bid Stategy',
            isCategory: false,
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, [
                    'bid_strategy_mode',
                    'automatic_bid_price',
                ]);
            },
            format(entry) {
                if (entry.snapshot.bid_strategy_mode === 'fixed_bid_price') {
                    return 'Fixed bid price';
                } else {
                    return `Automated bid price. Do not exceed ${formatNumber_currency(
                        entry.snapshot.automatic_bid_price.max_ecpm_local
                    )}  eCPM`;
                }
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Total Bid Price',
            isCategory: false,
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['max_cpm_rate_local']);
            },
            format(entry) {
                const { max_cpm_rate_local, automatic_bid_price } = entry.snapshot;

                const isAutoBidMode = entry.snapshot.bid_strategy_mode === 'automatic_bid_price';

                if (max_cpm_rate_local > 0) {
                    return formatNumber_currency(max_cpm_rate_local);
                }

                return isAutoBidMode
                    ? formatNumber_currency(automatic_bid_price.max_ecpm_local)
                    : 'Unlimited';
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Use Deal Price To Bid',
            isCategory: false,
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['use_deal_price_to_bid']);
            },
            format(entry) {
                const { use_deal_price_to_bid } = entry.snapshot;

                if (_.isNil(use_deal_price_to_bid)) {
                    return 'N/A';
                }

                return use_deal_price_to_bid
                    ? 'Use Total Bid Price for targeted deals'
                    : "Use Private Deal's specified price for targeted deals";
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Frequency Cap',
            isCategory: false,
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['max_user_frequency', 'fcaps']);
            },
            format(entry) {
                const intervalUnitsForDisplay = {
                    days: 'Day',
                    hours: 'Hour',
                    weeks: 'Week',
                };

                let config = entry.snapshot.fcaps;

                if (config.imp === null) {
                    return 'Unlimited';
                }

                if (config.interval_unit === 'ad') {
                    return `${config.imp} Over the Lifetime of the Ad`;
                }

                return `${config.imp} Per ${config.interval_count} ${
                    intervalUnitsForDisplay[config.interval_unit]
                }(s)`;
            },
            getClipboardText(entry) {
                return this.format(entry).toString();
            },
        }
    );
    return _(fields)
        .filter(field => field)
        .value();
}

function getGeofencing(settings) {
    if (!settings) return [];

    const { categories, custom_layers, category_layers } = settings;

    // Explicitly handle the case where targeting is effectively disabled:
    if (categories.length === 0 && custom_layers.length === 0 && category_layers.length === 0) {
        return [];
    }

    const adGeoCategoryLayers = createSummaryString(
        [].concat(categories),
        'POI Category',
        'POI Categories'
    );
    const adGeoLayers = createSummaryString(
        [].concat(category_layers),
        'Standard POI Segment',
        'Standard POI Segments'
    );
    const adCustomLayers = createSummaryString(
        custom_layers,
        'Custom POI Segment',
        'Custom POI Segments'
    );

    return [].concat(
        adGeoCategoryLayers,
        adGeoLayers,
        adCustomLayers,
        `Retarget - ${settings.retarget}`,
        `Target - ${settings.target}`
    );

    function createSummaryString(list, name, pluralName) {
        if (!list.length) return [];
        return `${list.length} ${list.length > 1 ? pluralName : name}`;
    }
}

function formatAgeGroup(val) {
    if (val.length === 0 || val.length === 7) {
        return 'Everyone';
    } else {
        var displayTextArray = [];
        _.forEach(val, function(item) {
            switch (item) {
                case 2:
                    displayTextArray.push('19-24');
                    break;
                case 3:
                    displayTextArray.push('25-34');
                    break;
                case 4:
                    displayTextArray.push('35+');
                    break;
                case 5:
                    displayTextArray.push('35-44');
                    break;
                case 6:
                    displayTextArray.push('45-54');
                    break;
                case 7:
                    displayTextArray.push('55-64');
                    break;
                case 8:
                    displayTextArray.push('65+');
                    break;
                case -1:
                    displayTextArray.push('Unknown');
                    break;
            }
        });
        return displayTextArray.join(', ');
    } // End else
}

function formatDeviceLanguage(obj) {
    return _.isArray(obj) ? _.map(obj, item => LANGUAGE_CODES[item]).join(', ') : '';
}

function formatTargetGenders(val) {
    if (_.isArray(val)) {
        if (val.length === 0) {
            return 'Everyone';
        } else {
            var displayTextArray = [];
            _.forEach(val, function(item) {
                if (item === 'M') {
                    displayTextArray.push('Male');
                } else if (item === 'F') {
                    displayTextArray.push('Female');
                } else if (item === 'D') {
                    displayTextArray.push('Unknown');
                } else {
                    throw new Error('Gender array is not empthy and contains unregonize value');
                }
            });
            return displayTextArray.join(', ');
        } // End else
    }
}

function formatTargetIabCats(obj) {
    return _.isArray(obj)
        ? _.map(obj, function(i) {
              return IABCATEGORY_TARGETING[i];
          }).join(', ')
        : '';
}

function formatDayparts(snapshot, timezone, startDate) {
    const dayparts = snapshot.dayparts ? snapshot.dayparts : snapshot.dayparts_local;
    if (_.isEmpty(dayparts)) {
        return <span>Full day</span>;
    } else {
        const formattedTimezone = snapshot.dayparts
            ? moment(startDate)
                  .tz(timezone)
                  .format('z')
            : 'Local Time';
        // since database converts autamatically, there is no need for convertion
        const daypartsByTimezone = convertDaypartsByTimezone(dayparts, 'UTC', timezone, startDate);
        // const daypartsByTimezone = _.map(dayparts, part => {
        //     return {
        //         start: moment(part.start, 'hhmmss').format('HH:mm'),
        //         end: moment(part.end, 'hhmmss').format('HH:mm')
        //     }
        // });
        const mergedTimeranges = mergeTimeranges(daypartsByTimezone);

        const out = _.map(mergedTimeranges, part => {
            return (
                <li key={_.uniqueId()}>
                    {part.start}
                    <span> {formattedTimezone}</span>
                    &nbsp;~ {part.end}
                    <span> {formattedTimezone}</span>
                </li>
            );
        });
        return <ul className="list-unstyled">{out}</ul>;
    }
}

function formatWeekparts(weekparts, suffix) {
    var names = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    return (
        suffix +
        _.compact(
            _.map(weekparts, function(bool, idx) {
                return bool ? names[idx] : '';
            })
        ).join(', ')
    );
}

function formatDoohVenues(obj) {
    return _.isArray(obj)
        ? _.map(obj, function(i) {
              return DOOH_VENUE_TYPES_NO_PARENT[i];
          }).join(', ')
        : '';
}
