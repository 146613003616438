import { connect } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
/** import third-party libraries in the section above, keep the ascending order */

import { Popper, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
/** import mui components in the section above, keep the ascending order */

import { InlineBlockLoadGroup } from 'widgets-v5/load-group';
import { VenueItem, VenueItems, OUT_FIELDS } from 'widgets-v6/inventory-indicator/map-indicator';
/** import widgets components in the section above, keep the ascending order */

import predictionActions from 'states/resources/inventory-predictions/actions';
import selector from './selectors';
/** import classes and others in the section above, keep the ascending order */

// This dynamic import is to comply with mocha commonjs standard
async function loadArcGISModules() {
    const [
        { default: Graphic },
        { default: IdentityManager },
        { default: GraphicsLayer },
        { default: FeatureLayer },
        { default: Map },
        { default: SimpleRenderer },
        { default: Query },
        { default: PictureMarkerSymbol },
        { default: MapView },
        { default: Sketch },
        { default: Search },
        geometryEngine,
    ] = await Promise.all([
        import('@arcgis/core/Graphic'),
        import('@arcgis/core/identity/IdentityManager'),
        import('@arcgis/core/layers/GraphicsLayer'),
        import('@arcgis/core/layers/FeatureLayer'),
        import('@arcgis/core/Map'),
        import('@arcgis/core/renderers/SimpleRenderer'),
        import('@arcgis/core/rest/support/Query'),
        import('@arcgis/core/symbols/PictureMarkerSymbol'),
        import('@arcgis/core/views/MapView'),
        import('@arcgis/core/widgets/Sketch'),
        import('@arcgis/core/widgets/Search'),
        import('@arcgis/core/geometry/geometryEngine'),
    ]);

    // Return all the modules
    return {
        Graphic,
        IdentityManager,
        GraphicsLayer,
        FeatureLayer,
        Map,
        SimpleRenderer,
        Query,
        PictureMarkerSymbol,
        MapView,
        Sketch,
        Search,
        geometryEngine,
    };
}

// Use the styled-components library to style the component
// Avoid the external css file to be applied in the whole application
const StyledGisForm = styled.div`
    .gis-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        height: 500px;
    }

    .map {
        width: 630px;
        background-color: rgb(168, 168, 168);
    }

    .selectionBox {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .selectionHeader {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #e0e0e0;
            padding: 10 0px;
        }

        .selectionList {
            overflow-y: auto;
            font-size: 14px;
        }
    }

    .popUpButton {
        font-size: 13px;
        display: flex;
        align-items: start;
        justify-content: center;
    }

    .esri-sketch__tool-section:nth-child(1) {
        display: none;
    }

    .esri-sketch__tool-section:nth-child(3) {
        display: none;
    }

    .esri-sketch__section:nth-child(4) {
        display: none;
    }

    // Include the remote CSS file
    > style {
        content: '';
    }
`;

const GISForm = ({ onFieldChange, gisDoohMapToken, getGisToken, cacheStrategy }) => {
    const mapRef = useRef(null);
    const selectionLayerRef = useRef(null); // Reference to the Selection GraphicsLayer
    const drawLayerRef = useRef(null); // Reference to the Draw GraphicsLayer
    const viewRef = useRef(null);
    const featureLayerRef = useRef(null);
    const customPopupRef = useRef(null);
    const fileInputRef = useRef(null);
    const [popupData, setPopupData] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [modules, setModules] = useState(null);
    const [mapLoading, setMapLoading] = useState(false);

    const [selectedFeatures, setSelectedFeatures] = useState([]); // State variable to store selected features
    const [includeExcludeSelection, setIncludeExcludeSelection] = React.useState('include');

    useEffect(() => {
        if (includeExcludeSelection === 'include') {
            onFieldChange(
                'target_boards',
                selectedFeatures.map(feature => feature.attributes.public_id)
            );
            onFieldChange('target_boards_exclude', []);
        } else {
            onFieldChange(
                'target_boards_exclude',
                selectedFeatures.map(feature => feature.attributes.public_id)
            );
            onFieldChange('target_boards', []);
        }
    }, [selectedFeatures, includeExcludeSelection]);

    useEffect(() => {
        const loadModulesAndMapToken = async () => {
            try {
                setMapLoading(true);
                const m = await loadArcGISModules();
                setModules(m);
                await getGisToken(cacheStrategy);
            } catch (error) {
                setMapLoading(false);
            } finally {
                setMapLoading(false);
            }
        };
        loadModulesAndMapToken();
    }, []);

    useEffect(() => {
        if (!mapRef.current || !gisDoohMapToken || !modules) {
            return;
        }
        const {
            Graphic,
            IdentityManager,
            GraphicsLayer,
            FeatureLayer,
            Map,
            SimpleRenderer,
            Query,
            PictureMarkerSymbol,
            MapView,
            Sketch,
            Search,
            geometryEngine,
        } = modules;

        const map = new Map({
            basemap: 'streets-vector',
        });
        IdentityManager.registerToken({
            server: 'https://gis.pelmorex.com/portal/sharing/rest',
            ...gisDoohMapToken,
        });

        const pictureMarkerSymbol = new PictureMarkerSymbol({
            angle: 0,
            height: 25,
            url: '/images/dooh-inventory-prediction/blueMarker.svg',
            width: 17,
            xoffset: 0,
            yoffset: 0,
        });

        const renderer = new SimpleRenderer({
            symbol: pictureMarkerSymbol,
        });

        const featureLayer = new FeatureLayer({
            url:
                'https://gis.pelmorex.com/server/rest/services/PDS/Vistarmedia_DOOH/FeatureServer/4',
            outFields: OUT_FIELDS,
            featureReduction: {
                type: 'cluster',
                clusterRadius: '50px',
                maxSingleFlareCount: 1,
                labelingInfo: [
                    {
                        symbol: {
                            type: 'text', // Label as text
                            color: 'white',
                            font: {
                                size: 12,
                                weight: 'bold',
                            },
                        },
                        labelPlacement: 'center-center', // Place label in the center
                        labelExpressionInfo: {
                            expression: '$feature.cluster_count', // Display the cluster count
                        },
                    },
                ],
                clusterMinSize: 20, // Minimum size of cluster symbols
                clusterMaxSize: 50, // Maximum size of cluster symbols
                returnExtent: true,
                symbol: {
                    type: 'simple-marker',
                    style: 'circle',
                    color: '#03A9F4',
                    outline: {
                        color: '#03A9F4',
                        width: 5,
                    },
                },
            },
            renderer: renderer,
        });

        featureLayerRef.current = featureLayer;
        const selectionLayer = new GraphicsLayer(); // For selected features
        const drawLayer = new GraphicsLayer(); // For drawn shapes
        selectionLayerRef.current = selectionLayer;
        drawLayerRef.current = drawLayer;

        map.addMany([featureLayer, selectionLayer, drawLayer]);

        const view = new MapView({
            container: mapRef.current,
            map: map,
            center: [-98, 50],
            zoom: 2,
        });

        viewRef.current = view; // Store reference to the map view

        // Disable the default popup
        view.popup.autoOpenEnabled = false;

        view.on('pointer-down', () => {
            setPopupData(null);
            // Hide the popup if no feature was clicked
            customPopupRef.current.style.display = 'none';
        });

        // Handle hover effect to change cursor when over a feature
        view.on('pointer-move', async event => {
            const response = await view.hitTest(event);
            const results = response.results.filter(
                result => result.graphic?.layer === featureLayer
            );

            if (results.length > 0) {
                view.container.style.cursor = 'pointer'; // Change cursor to pointer
            } else {
                view.container.style.cursor = 'default'; // Reset cursor
            }
        });

        // Create a listener for clicks on the layer
        view.on('click', async event => {
            const response = await view.hitTest(event);
            // Check if a feature was clicked
            const results = response.results.filter(
                result => result.graphic?.layer === featureLayer
            );
            if (results.length > 0) {
                const graphic = results[0].graphic;
                const attributes = graphic.attributes;
                if (attributes.cluster_count > 1) {
                    const bufferDistance = 20 * view.resolution;
                    const bufferGeometry = geometryEngine.buffer(graphic.geometry, bufferDistance);

                    const query = featureLayerRef.current.createQuery();
                    query.geometry = bufferGeometry;
                    query.spatialRelationship = 'intersects'; // Ensure it finds only close matches

                    query.returnGeometry = true;
                    query.outFields = OUT_FIELDS;

                    const { features } = await featureLayerRef.current.queryFeatures(query);

                    // Store all venues in state
                    setPopupData({
                        venues: features.map(feature => ({
                            attributes: feature.attributes,
                            id: feature.attributes.id,
                            geometry: feature.geometry,
                        })),
                        screenPoint: event.screenPoint,
                    });
                }

                if (attributes.cluster_count === 1) {
                    // Set data for the React popup
                    setPopupData({
                        venues: [
                            {
                                attributes,
                                id: attributes.id,
                                geometry: graphic.geometry,
                            },
                        ],
                        screenPoint: event.screenPoint,
                    });
                }
                // Get the screen point for positioning
                const screenPoint = event.screenPoint;
                // Populate and show the custom popup
                const popup = customPopupRef.current;
                popup.style.left = `${screenPoint.x}px`;
                popup.style.top = `${screenPoint.y}px`;
                popup.style.display = 'block';
            } else {
                setPopupData(null);
                // Hide the popup if no feature was clicked
                customPopupRef.current.style.display = 'none';
            }
        });

        const sketch = new Sketch({
            layer: drawLayer,
            view: view,
            availableCreateTools: ['rectangle', 'circle', 'polygon'], // Allow Rectangle, Circle, and Polyline
            creationMode: 'single', // Only one geometry at a time
        });

        view.ui.add(sketch, {
            position: 'top-left',
            index: 0,
        });

        sketch.on('create', async event => {
            if (event.state === 'complete') {
                const geometry = event.graphic.geometry;

                const query = new Query({
                    geometry: geometry,
                    spatialRelationship: 'intersects',
                    returnGeometry: true,
                    outFields: OUT_FIELDS,
                });

                const results = await featureLayer.queryFeatures(query);

                const newFeatures = results.features.map(feature => ({
                    id: feature.attributes.id,
                    geometry: feature.geometry,
                    attributes: feature.attributes,
                }));

                // Update the selected features in state
                setSelectedFeatures(prevSelected => {
                    // Add only unique features (avoid duplicates)
                    const updatedFeatures = [...prevSelected];
                    newFeatures.forEach(newFeature => {
                        if (!updatedFeatures.find(f => f.id === newFeature.id)) {
                            updatedFeatures.push(newFeature);
                        }
                    });
                    return updatedFeatures;
                });

                const newGraphics = results.features.map(feature => {
                    // Use SimpleMarkerSymbol to highlight points
                    return new Graphic({
                        geometry: feature.geometry,
                        attributes: {
                            id: feature.attributes.id,
                        },
                        symbol: new PictureMarkerSymbol({
                            angle: 0,
                            height: 25,
                            url: '/images/dooh-inventory-prediction/darkBlueMarker.svg',
                            width: 17,
                            xoffset: 0,
                            yoffset: 0,
                        }),
                    });
                });

                // Add new graphics to the Selection Layer
                selectionLayer.addMany(newGraphics);

                // Remove the drawn geometry
                drawLayer.removeAll();
            }
        });
        // Add the Search widget
        const searchWidget = new Search({
            view: view,
            popupEnabled: false,
            goToOverride: function(view, goToParams) {
                goToParams.options = {
                    duration: '2000',
                };
                return view.goTo(goToParams.target, goToParams.options);
            },
        });
        view.ui.add(searchWidget, 'top-right');

        // Move the zoom control to the bottom left
        view.ui.move('zoom', 'bottom-left');

        return () => {
            if (view) {
                view.container = null;
            }
        };
    }, [gisDoohMapToken, modules]);

    const handleIncludeExcludeToggleChange = (event, selectedValue) => {
        setIncludeExcludeSelection(selectedValue);
    };

    const clearSelection = () => {
        if (selectionLayerRef.current) {
            selectionLayerRef.current.removeAll(); // Clear selected features
            setSelectedFeatures([]); // Clear tracking
        }
    };

    const handleDelete = objectId => {
        setSelectedFeatures(prev => prev.filter(item => item.attributes.id !== objectId));

        // Remove the graphic from the highlight layer
        const graphicsToRemove = selectionLayerRef.current.graphics.items.filter(
            graphic => graphic.attributes?.id === objectId
        );
        graphicsToRemove.forEach(graphic => selectionLayerRef.current.remove(graphic));
    };

    // Handler for zooming to a selected point
    const zoomToFeature = feature => {
        const geometry = feature.geometry;

        // Zoom to the selected feature (point)
        viewRef.current.goTo({
            target: geometry,
            zoom: 15, // Set a desired zoom level
        });
    };

    // Add a point to the selections and highlight it on the map
    const handleAddVenue = venue => {
        if (!venue || !selectionLayerRef.current) return;
        const { Graphic, PictureMarkerSymbol } = modules;

        // Add to the selections list
        setSelectedFeatures(prev => [...prev, venue]);

        // Highlight the point on the map
        const graphic = new Graphic({
            geometry: venue.geometry,
            attributes: {
                id: venue.attributes.id,
            },
            symbol: new PictureMarkerSymbol({
                angle: 0,
                height: 25,
                url: '/images/dooh-inventory-prediction/darkBlueMarker.svg',
                width: 17,
                xoffset: 0,
                yoffset: 0,
            }),
        });
        selectionLayerRef.current.add(graphic);
    };

    const handleImport = async fileLines => {
        const query = featureLayerRef.current.createQuery();
        query.where = `public_id IN (${fileLines.map(id => `'${id}'`).join(',')})`;
        query.returnGeometry = true; // Fetch geometry
        query.outFields = OUT_FIELDS; // Include attributes

        const results = await featureLayerRef.current.queryFeatures(query);

        const newFeatures = results.features.map(feature => ({
            id: feature.attributes.id,
            geometry: feature.geometry,
            attributes: feature.attributes,
        }));

        // Update the selected features in state
        setSelectedFeatures(prevSelected => {
            // Add only unique features (avoid duplicates)
            const updatedFeatures = [...prevSelected];
            newFeatures.forEach(newFeature => {
                if (!updatedFeatures.find(f => f.id === newFeature.id)) {
                    updatedFeatures.push(newFeature);
                }
            });
            return updatedFeatures;
        });
        const { Graphic, PictureMarkerSymbol } = modules;

        const newGraphics = results.features.map(feature => {
            // Use SimpleMarkerSymbol to highlight points
            return new Graphic({
                geometry: feature.geometry,
                attributes: {
                    id: feature.attributes.id,
                },
                // symbol: markerBlue,
                symbol: new PictureMarkerSymbol({
                    angle: 0,
                    height: 25,
                    url: '/images/dooh-inventory-prediction/darkBlueMarker.svg',
                    width: 17,
                    xoffset: 0,
                    yoffset: 0,
                }),
            });
        });

        // Add new graphics to the Selection Layer
        selectionLayerRef.current.addMany(newGraphics);
    };

    const handleFileChange = event => {
        const file = event.target.files[0]; // Get the selected file
        if (file) {
            const reader = new FileReader(); // Create a FileReader instance

            reader.onload = async e => {
                const fileContent = e.target.result; // Read the file content
                const fileLines = fileContent.split(/\r?\n/).map(line => line.trim()); // Split content into lines
                await handleImport(fileLines); // Update state with lines
            };

            // Reset file input after processing
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }

            reader.readAsText(file); // Read the file as text
        }
    };

    const handleImportClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const openImportPopper = Boolean(anchorEl);

    return (
        <StyledGisForm>
            {/* Include the remote CSS file within a style tag */}
            <style>
                @import
                url('https://js.arcgis.com/4.31/@arcgis/core/assets/esri/themes/light/main.css');
            </style>

            {/* GISForm component content */}
            <div className="gis-container">
                <InlineBlockLoadGroup isLoading={mapLoading}>
                    <div ref={mapRef} className="map">
                        <div
                            ref={customPopupRef}
                            style={{
                                backgroundColor: 'white',
                                height: '270px',
                                width: '300px',
                                overflowY: 'auto',
                                position: 'absolute',
                                zIndex: 9999,
                                display: 'none', // Initially hidden
                                // pointerEvents: "none", // Allow clicks to pass through to the map
                                // transform: "translate(-50%, -100%)", // Center above the click point
                            }}
                        >
                            {popupData && (
                                <VenueItems
                                    venues={popupData.venues}
                                    handleAddVenue={handleAddVenue}
                                    handleDelete={handleDelete}
                                    onMap
                                    showSelectButton
                                    selectedIds={selectedFeatures.map(f => f.id)}
                                />
                            )}
                        </div>
                    </div>
                </InlineBlockLoadGroup>
                <div className="selectionBox">
                    <Typography variant="h6">Selected Venues</Typography>
                    <div className="selectionHeader">
                        <Button
                            sx={{ textTransform: 'none' }}
                            variant="outlined"
                            color="primary"
                            onClick={handleImportClick}
                            disabled={!gisDoohMapToken}
                        >
                            Import
                        </Button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }} // Hide the file input element
                            onChange={handleFileChange}
                        />
                        {/* Popper Component */}
                        <Popper
                            open={openImportPopper}
                            anchorEl={anchorEl}
                            placement="bottom-start"
                        >
                            <Paper
                                elevation={3}
                                sx={{ p: 2, width: 250, borderRadius: 1, textAlign: 'center' }}
                            >
                                {/* Button inside the Popper */}
                                <Button
                                    sx={{ textTransform: 'none' }}
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => {
                                        fileInputRef.current.click();
                                        setAnchorEl(null);
                                    }}
                                >
                                    Import Venues
                                </Button>

                                {/* Instructions Text */}
                                <Typography mt={2}>
                                    To import venues, board IDs should be uploaded in a plain text
                                    file (.txt) or CSV (no headers). Each board ID should be on a
                                    separate line without extra characters.
                                </Typography>
                            </Paper>
                        </Popper>
                        <ToggleButtonGroup
                            color="primary"
                            value={includeExcludeSelection}
                            exclusive
                            onChange={handleIncludeExcludeToggleChange}
                            disabled={!gisDoohMapToken}
                        >
                            <ToggleButton sx={{ textTransform: 'none' }} value="include">
                                <CheckCircleOutlineIcon color="primary" />
                                &nbsp;Include
                            </ToggleButton>
                            <ToggleButton sx={{ textTransform: 'none' }} value="exclude">
                                <CancelOutlinedIcon color="secondary" />
                                &nbsp;Exclude
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Button
                            sx={{ textTransform: 'none' }}
                            variant="text"
                            color="primary"
                            onClick={clearSelection}
                            disabled={!gisDoohMapToken}
                        >
                            Clear All
                        </Button>
                    </div>
                    <div className="selectionList">
                        <ul>
                            {selectedFeatures.map(feature => (
                                <VenueItem
                                    key={feature.attributes.id}
                                    venue={feature}
                                    zoomToFeature={zoomToFeature}
                                    handleDelete={handleDelete}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </StyledGisForm>
    );
};

export default connect(
    selector,
    { getGisToken: predictionActions.getGISAuthToken }
)(GISForm);
