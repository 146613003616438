import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import createReactClass from 'create-react-class';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const moment = extendMoment(Moment);
import 'moment-timezone';

import { createHttp } from 'utils/http';
import { formatNumber_currency, formatNumber_percentage } from 'utils/formatting';
import { formatDimensions, formatMetrics } from 'widgets/pivot-table/service';
import PivotTable from 'widgets/pivot-table';
import TimezonelessDateRangePicker from 'widgets-v5/timezoneless-date-range-picker';
import TimezoneSelector from 'widgets-v5/timezone-selector';
import { AccountExportButton } from 'widgets/pivot-table/export-button';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import { InlineTooltip, InlineTooltipContent } from 'widgets-v5/inline-tooltip';
import { SingleSelect } from 'widgets-v6/select';
import { SearchInput } from 'widgets-v6/standard-input';

import {
    filterByDateRange,
    sortColumn,
    expandRow,
    collapseRow,
    addSplit,
    removeSplit,
    updateSplits,
    refreshPivotTable,
    metricSelector_toggleSelection,
    metricSelector_toggleVisiblity,
    onSearchCampaign,
    onSelectClient,
    onFilterByStatus,
    updateTimezone,
    initializeWithGraphqlFetch,
} from 'pages/account/actions';
import select from './selector';

const Account = createReactClass({
    displayName: 'Account',

    statics: {
        refresh(dispatch, nextState) {
            const queryString = _.get(nextState, 'location.query', {});

            const query = {
                ...queryString,
            };

            dispatch(initializeWithGraphqlFetch(query));
        },
    },

    init() {
        const { dispatch, location, params, routes } = this.props;
        const nextState = { location, params, routes };
        Account.refresh(dispatch, nextState, browserHistory.push);
    },

    getInitialState: function() {
        return {
            displayDateError: false,
            campaignSummaryAnchor: null,
        };
    },

    componentDidMount() {
        this.init();

        this.refreshPivotTable = _.debounce(this.refreshPivotTable, 2000);
    },

    componentWillUnmount() {
        // Cancel any debounced calls waitng for their chance to fire
        this.refreshPivotTable.cancel();
    },

    refreshPivotTable() {
        const { dispatch } = this.props;
        dispatch(refreshPivotTable());
    },

    handleSort(column) {
        const { dispatch } = this.props;
        dispatch(sortColumn(column));
    },

    handleExpand(rowId) {
        const { dispatch } = this.props;
        dispatch(expandRow(rowId));
    },

    handleCollapse(rowId) {
        const { dispatch } = this.props;
        dispatch(collapseRow(rowId));
    },

    handleAddSplit(split) {
        const { dispatch, query } = this.props;
        const splits = { ..._.get(query, 'splits', { campaign_id: 'Campaigns' }) };

        const index = _.findIndex(splits, it => {
            return it.name === split.name;
        });

        if (index === -1) {
            splits[split.name] = split.label;
        }

        const qs = {
            ...query,
            splits,
        };

        browserHistory.push('/account', qs);

        dispatch(addSplit(split));

        this.refreshPivotTable();
    },

    handleRemoveSplit(split) {
        const { dispatch, query } = this.props;
        const splits = _.get(query, 'splits', { campaign_id: 'Campaigns' });

        let qs = {
            ...query,
            splits: _.omit(splits, split.name),
        };

        if (split.name === 'campaign_id') {
            qs = _.omit(qs, [
                'campaignSearch',
                'selectedClient',
                'selectedClientsCampaignIds',
                'campaignSearchCampaignIds',
            ]);
        }

        browserHistory.push('/account', qs);

        dispatch(removeSplit(split));

        this.refreshPivotTable();
    },

    handleUpdateSplits(splits) {
        this.props.dispatch(updateSplits(splits));

        this.refreshPivotTable();
    },

    filterByDateRange({ start, end }) {
        const { dispatch, query } = this.props;

        if (moment(start).isBefore('2016-10-01') || moment(end).isBefore('2016-10-01')) {
            this.setState({
                displayDateError: 'Before 2016 Oct 1st is not allowed to select.',
            });
            return false;
        } else {
            if (this.state.displayDateError) {
                this.setState({
                    displayDateError: false,
                });
            }
        }

        const qs = {
            ...query,
            start,
            end,
        };

        browserHistory.push('/account', qs);

        dispatch(filterByDateRange({ start, end }));
    },

    serializePivotTable() {
        return this.refs.pivotTable.serialize();
    },

    metricSelector_toggleVisiblity(metric) {
        const { dispatch, query } = this.props;

        const metrics_on = _.get(query, 'metrics_on', []);
        const metrics_off = _.get(query, 'metrics_off', []);

        if (
            ['selected', 'visible'].indexOf(metric.status) > -1 &&
            metrics_off.indexOf(metric.metricType) === -1
        ) {
            metrics_off.push(metric.metricType);
            const index = metrics_on.indexOf(metric.metricType);
            if (index > -1) {
                metrics_on.splice(index, 1);
            }
        }

        if (metric.status === 'hidden' && metrics_on.indexOf(metric.metricType) === -1) {
            metrics_on.push(metric.metricType);
            const index = metrics_off.indexOf(metric.metricType);
            if (index > -1) {
                metrics_off.splice(index, 1);
            }
        }

        const qs = {
            ...query,
            metrics_on,
            metrics_off,
        };

        browserHistory.push('/account', qs);

        dispatch(metricSelector_toggleVisiblity(metric));
    },

    metricSelector_toggleSelection(metric) {
        const { dispatch } = this.props;
        dispatch(metricSelector_toggleSelection(metric));
    },

    onSearchCampaign(searchString) {
        const { dispatch, query } = this.props;

        const qs = {
            ...query,
            campaignSearch: searchString,
        };
        browserHistory.push('/account', qs);

        dispatch(onSearchCampaign(searchString));
    },

    onSelectClient(value) {
        const { dispatch, query } = this.props;

        const qs = {
            ...query,
            selectedClient: value,
        };

        browserHistory.push('/account', qs);

        dispatch(onSelectClient(value));
    },

    updateTimezone(timezone) {
        this.props.dispatch(updateTimezone(timezone));
    },

    onFilterByStatus(value) {
        const { dispatch, query } = this.props;

        const qs = {
            ...query,
            campaignStatus: value,
        };

        browserHistory.push('/account', qs);

        dispatch(onFilterByStatus(value));
    },

    formatRow(row) {
        const { pivotTableState, dictionary, organizationCustomFields } = this.props;

        const attributes = _.map(organizationCustomFields, customField => customField.key);

        let nextRow = { ...row };
        nextRow = formatDimensions(
            nextRow,
            pivotTableState.splits,
            dictionary,
            // The pivot table service needs a campaign to split by week,
            // but since we don't allow that here we just pass null to hack
            // around it.
            null
        );

        let columnData = { ...nextRow.columnData };

        const campaignsAttribute = _.get(nextRow, 'attributes.campaigns', []);
        let campaignNameAndId = _(campaignsAttribute)
            .filter(campaign => campaign)
            .map(campaign => `#${campaign.campaign_id} ${campaign.name}`)
            .join(', ');

        // fallback in case the campaign name is not available
        if (_.isEmpty(campaignNameAndId)) {
            campaignNameAndId = _.get(nextRow, 'id', 'unknown');
        }

        const adsAttribute = _.get(nextRow, 'attributes.ads', []);
        let adNameAndId = _(adsAttribute)
            .filter(ad => ad)
            .map(ad => `#${ad.ad_id} ${ad.name}`)
            .join(', ');

        // fallback in case the ad name is not available
        if (_.isEmpty(adNameAndId)) {
            adNameAndId = _.get(nextRow, 'id', 'unknown');
        }

        columnData = {
            ...columnData,
            campaign_id: campaignNameAndId,
            ad_id: adNameAndId,
        };

        switch (nextRow.dimension) {
            case 'campaign_id': {
                if (nextRow.attributes) {
                    const campaignsAttribute = _.get(nextRow, 'attributes.campaigns', []);

                    // Add custom field attributes
                    _.each(attributes, attribute => {
                        const attributeValue = _(campaignsAttribute)
                            .map(campaign => campaign[attribute])
                            .filter(a => a)
                            .join(', ');

                        columnData = {
                            ...columnData,
                            [attribute]: attributeValue,
                        };
                    });

                    columnData = {
                        ...columnData,
                        dimension: campaignNameAndId,
                    };
                }

                break;
            }
            case 'ad_id': {
                if (nextRow.attributes) {
                    const adsAttribute = _.get(nextRow, 'attributes.ads', []);

                    // Add custom field attributes
                    _.each(attributes, attribute => {
                        const attributeValue = _(adsAttribute)
                            .map(ad => ad[attribute])
                            .filter(a => a)
                            .join(', ');

                        columnData = {
                            ...columnData,
                            [attribute]: attributeValue,
                        };
                    });

                    columnData = {
                        ...columnData,
                        dimension: adNameAndId,
                    };
                }

                break;
            }
        }

        if (nextRow.columnData.dimension !== 'Total') {
            const adsAttribute = _.get(nextRow, 'attributes.ads', []);
            columnData.billing_rate = _(adsAttribute)
                .sortBy(['billing_enabled', 'billing_term', 'billing_rate'])
                .map(ad => {
                    if (!ad.billing_enabled) {
                        return 'Bonus';
                    }

                    const isCpmCpc = _.includes(['CPM', 'CPC'], ad.billing_term);

                    if (isCpmCpc) {
                        return `$${ad.billing_rate} ${ad.billing_term}`;
                    } else {
                        return `${formatNumber_percentage(ad.billing_rate)}`;
                    }
                })
                .uniq()
                .join(', ');
        }

        return {
            ...nextRow,
            columnData,
        };
    },

    setAnchor({ anchorEl, campaignId }) {
        this.setState({
            campaignSummaryAnchor: anchorEl,
            campaignSummaryId: campaignId,
        });
    },

    render() {
        const {
            organizationName,

            orgCurrency,
            creditLimitForOrganization,
            totalForThisMonth,
            totalInventoryCostForThisMonth,

            isPivotTableLoading,
            isAccountSummaryLoading,
            statsExist,

            pivotTableState,
            dictionary,
            columns,
            metrics,

            earliestStart,
            latestEnd,
            selectedDateRange,

            isCampaignSplitSelected,

            campaignSearch,
            campaignStatus,
            clients,
            selectedClient,

            initKey,
            responseTimeout,
        } = this.props;

        let workspace;

        if (responseTimeout) {
            workspace = <LinearProgress />;
        } else if (!statsExist) {
            workspace = (
                <div className="ef3-account_pivotTable ef3-account_pivotTable_isLoading">
                    No stats found for your selection
                </div>
            );
        } else {
            workspace = (
                <React.Fragment>
                    <Box mb={1}>
                        <Grid container spacing={1} alignItems="center">
                            {isCampaignSplitSelected && (
                                <Grid item xs={4}>
                                    <SearchInput
                                        placeholder="Filter Campaigns"
                                        value={campaignSearch}
                                        onChange={e => this.onSearchCampaign(e.target.value)}
                                    />
                                </Grid>
                            )}
                            {isCampaignSplitSelected && (
                                <Grid item xs={3}>
                                    <SingleSelect
                                        placeholder="Campaign Status"
                                        value={campaignStatus}
                                        onChange={this.onFilterByStatus}
                                        options={[
                                            {
                                                value: 'live',
                                                label: 'Live',
                                            },
                                            {
                                                value: 'paused',
                                                label: 'Paused',
                                            },
                                            {
                                                value: 'pending',
                                                label: 'Pending',
                                            },
                                            {
                                                value: 'ended',
                                                label: 'Ended',
                                            },
                                        ]}
                                    />
                                </Grid>
                            )}
                            {clients.length > 0 && isCampaignSplitSelected && (
                                <Grid item xs={4}>
                                    <SingleSelect
                                        onChange={this.onSelectClient}
                                        value={selectedClient}
                                        placeholder="Partner / Advertiser / Sales Rep"
                                        options={clients}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    <PivotTable
                        ref="pivotTable"
                        config={pivotTableState}
                        dictionary={dictionary}
                        columns={columns}
                        messageClass="ef3-account__info-message"
                        rowFilterer={row => {
                            if (pivotTableState.hideZeros) {
                                return !getIsZeroStat(row.stats, metrics);
                            }

                            return true;
                        }}
                        topbarWorkspace={
                            <AccountExportButton
                                options={['json', 'csv', 'xls']}
                                key="3"
                                meta={{
                                    fileName: moment
                                        .utc(selectedDateRange.start)
                                        .format('MMMM-YYYY'),
                                    organization: organizationName,
                                    date: moment.utc(selectedDateRange.start).format('MMMM-YYYY'),
                                    exportDate: moment.utc().format('MM-DD-YYYY'),
                                }}
                                serializePivotTable={this.serializePivotTable}
                                dictionary={dictionary}
                            />
                        }
                        onSerializeFormatRow={(row, columns) => {
                            return this.formatRow(row, columns);
                        }}
                        onRenderFormatRow={(row, columns) => {
                            let nextRow = { ...row };

                            nextRow = formatMetrics(nextRow, columns);
                            nextRow = this.formatRow(nextRow, columns);

                            let columnData = { ...nextRow.columnData };
                            let associatedCurrency;

                            if (nextRow.id === 'root') {
                                // Org's currency settings
                                associatedCurrency = orgCurrency;
                            } else {
                                switch (nextRow.dimension) {
                                    case 'campaign_id':
                                        associatedCurrency = _(nextRow.attributes.campaigns)
                                            .filter(campaign => campaign)
                                            .map(campaign => campaign.currency)
                                            .join(', ');

                                        break;
                                    case 'ad_id':
                                        associatedCurrency = _(nextRow.attributes.ads)
                                            .filter(ad => ad)
                                            .map(ad => ad.currency)
                                            .join(', ');
                                        break;
                                    default:
                                        associatedCurrency = orgCurrency;
                                        break;
                                }
                            }

                            columnData.billings_local = `${associatedCurrency} ${
                                columnData.billings_local
                            }`;

                            columnData.owner_inventory_cost_local = `${associatedCurrency} ${
                                columnData.owner_inventory_cost_local
                            }`;

                            columnData.spend = `${associatedCurrency} ${columnData.spend}`;

                            columnData.owner_media_cost_2_local = `${associatedCurrency} ${
                                columnData.owner_media_cost_2_local
                            }`;

                            columnData.data_cost = `${associatedCurrency} ${columnData.data_cost}`;
                            columnData.third_party_fees = `${associatedCurrency} ${
                                columnData.third_party_fees
                            }`;
                            columnData.owner_total_media_cost_local = `${associatedCurrency} ${
                                columnData.owner_total_media_cost_local
                            }`;

                            return {
                                ...nextRow,
                                columnData,
                            };
                        }}
                        sortByHook={(sorter, columnName, row, dictionary) => {
                            switch (columnName) {
                                case 'billing_rate': {
                                    return row.columnData.billing_rate;
                                }
                                default:
                                    return sorter(columnName, row, dictionary);
                            }
                        }}
                        renderCell={(content, row, column) => {
                            if (row.dimension !== 'campaign_id' || column.name !== 'dimension') {
                                return content;
                            }

                            const campaignId = row.group;

                            return (
                                <div
                                    onMouseEnter={event => {
                                        this.setAnchor({
                                            anchorEl: event.currentTarget,
                                            campaignId,
                                        });
                                    }}
                                    onMouseLeave={() => {
                                        this.setAnchor({
                                            anchorEl: null,
                                            campaignId: null,
                                        });
                                    }}
                                >
                                    {content}
                                </div>
                            );
                        }}
                        onSort={this.handleSort}
                        onExpand={this.handleExpand}
                        onCollapse={this.handleCollapse}
                        onAddSplit={this.handleAddSplit}
                        onRemoveSplit={this.handleRemoveSplit}
                        onUpdateSplits={this.handleUpdateSplits}
                    />
                    <InlineTooltip anchorEl={this.state.campaignSummaryAnchor}>
                        <InlineTooltipContent className="ef3-account__campaign-info">
                            {this.state.campaignSummaryId && (
                                <CampaignSummary
                                    start={selectedDateRange.start}
                                    end={selectedDateRange.end}
                                    timezone={selectedDateRange.timezone}
                                    campaignId={this.state.campaignSummaryId}
                                />
                            )}
                        </InlineTooltipContent>
                    </InlineTooltip>
                </React.Fragment>
            );
        }

        const dropDownOptions = createDateRangePickerOptions(earliestStart, latestEnd);

        return (
            <AccountLayout
                orgCurrency={orgCurrency}
                creditLimitForOrganization={creditLimitForOrganization}
                earliestStart={earliestStart}
                latestEnd={latestEnd}
                displayDateError={this.state.displayDateError}
                totalInventoryCostForThisMonth={totalInventoryCostForThisMonth}
                totalForThisMonth={totalForThisMonth}
                dropDownOptions={dropDownOptions}
                selectedDateRange={selectedDateRange}
                filterByDateRange={this.filterByDateRange}
                isAccountSummaryLoading={isAccountSummaryLoading}
                workspace={workspace}
                updateTimezone={this.updateTimezone}
                isPivotTableLoading={isPivotTableLoading}
                initKey={initKey}
                responseTimeout={this.props.responseTimeout}
                init={this.init}
            />
        );
    },
});

let CampaignSummary = ({ start, end, timezone, campaignId }) => {
    const [campaign, setCampaign] = useState(null);

    useEffect(() => {
        const http = createHttp();
        const query = `
            query getCampaign($id: Int) {
                campaign(id: $id) {
                    audienceEnabled
                    currency
                    end
                    ftaEnabled
                    start
                    total_billings_local
                    spendCap
                    actualSpend
                }
            }
        `;

        const variables = { id: campaignId };

        http.graphql(query, variables).then(data => {
            setCampaign(data.campaign);
        });
    }, [campaignId]);

    if (!campaign) {
        return <div>loading...</div>;
    }

    const actualFlight = moment.range(
        moment(campaign.start).tz(timezone),
        moment(campaign.end).tz(timezone)
    );
    const filteredFlight = moment.range(moment.tz(start, timezone), moment.tz(end, timezone));
    const flightIntersection = actualFlight.intersect(filteredFlight);

    return (
        <table>
            <tbody>
                <tr>
                    <td>Flight Range</td>
                    <td>
                        {moment(campaign.start).format('LLL')} -{' '}
                        {moment(campaign.end).format('LLL')}
                    </td>
                </tr>
                <tr>
                    <td>Flight Range (Current Month)</td>
                    <td>
                        {flightIntersection ? (
                            `${moment
                                .tz(flightIntersection.start, timezone)
                                .format('LLL')} - ${moment
                                .tz(flightIntersection.end, timezone)
                                .format('LLL')}`
                        ) : (
                            <span className="ef3-account__highlight">N/A</span>
                        )}
                    </td>
                </tr>
                <tr>
                    <td>Foot Traffic Attribution</td>
                    <td>{campaign.ftaEnabled ? 'Enabled' : 'Disabled'}</td>
                </tr>
                <tr>
                    <td>Pelmorex Audience Segments</td>
                    <td>{campaign.audienceEnabled ? 'Enabled' : 'Disabled'}</td>
                </tr>
                <tr>
                    <td>Total Budget</td>
                    <td>
                        {campaign.spendCap === 0
                            ? 'Unlimited'
                            : `${campaign.currency} ${formatNumber_currency(campaign.spendCap)}`}
                    </td>
                </tr>
                <tr>
                    <td>Total Cost</td>
                    <td>
                        <span
                            className={
                                campaign.total_billings_local > 0 &&
                                campaign.actualSpend > campaign.spendCap
                                    ? 'ef3-account__highlight'
                                    : ''
                            }
                        >
                            {campaign.currency} {formatNumber_currency(campaign.actualSpend)}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

CampaignSummary = connect()(CampaignSummary);

class AccountLayout extends React.Component {
    componentDidMount() {
        this.changeSelectedDate = _.debounce(this.changeSelectedDate, 500);
    }

    changeSelectedDate = ({ start, end }) => {
        this.props.filterByDateRange({ start, end });
    };

    render() {
        const {
            dropDownOptions,
            selectedDateRange,
            displayDateError,
            isPivotTableLoading,
            initKey,
            init,
        } = this.props;

        return (
            <div className="ef3-account">
                <Box mb={3}>
                    <Typography variant="h4">Account</Typography>
                </Box>
                <Box mb={1}>
                    {selectedDateRange.start && (
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <TimezoneSelector
                                    label="Timezone"
                                    timezone={selectedDateRange.timezone}
                                    onChange={this.props.updateTimezone}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <TimezonelessDateRangePicker
                                    key={initKey}
                                    campaignFlight={{
                                        start: selectedDateRange.start,
                                        end: selectedDateRange.end,
                                    }}
                                    dropDownOptions={dropDownOptions}
                                    onChange={this.props.filterByDateRange}
                                    displayError={displayDateError}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Box>
                <div className="ef3-account_workspace">
                    {isPivotTableLoading ? (
                        <div
                            className="ef6-alignment__center"
                            style={{ width: '100%', height: '100%' }}
                        >
                            <BlockLoadGroup isLoading={true} />
                        </div>
                    ) : (
                        this.props.workspace
                    )}
                </div>
                {this.props.responseTimeout && <AlertDialog init={init} />}
            </div>
        );
    }
}

function createDateRangePickerOptions(start, end) {
    const startDateAsMoment = moment(start);
    const endDateAsMoment = moment(end);

    const monthsBetween = [];
    const endOfEndingMonth = endDateAsMoment.endOf('month');
    const monthIncrement = endOfEndingMonth;

    let i = 0;
    let reachedOrgCreation = false;

    // Work backwards to build out at most 6 months of options, starting from
    // the current month and going back (at most) to the creation date of the current org.
    //
    // Note: Orgs created earlier than the local currency feature will only go back as far
    // as that feature's implementation date (10-01-2016)
    while (i++ <= 6 && !reachedOrgCreation) {
        monthsBetween.push({
            title: monthIncrement.format('MMMM, YYYY'),
            start: monthIncrement.startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
            end: monthIncrement.endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
        });

        monthIncrement.subtract(1, 'month');

        reachedOrgCreation = startDateAsMoment >= monthIncrement;
    }

    monthsBetween.push({ title: 'Custom' });

    return monthsBetween;
}

const AlertDialog = () => {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">Oops...</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Looks like the server is taking too long to respond, please try to set
                        shorter date range or less dimensions to simplify the settings.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default connect(select)(Account);
