import _ from 'lodash';
import React from 'react';
import { browserHistory } from 'react-router';
import toastr from 'toastr';

import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CodeIcon from '@mui/icons-material/Code';
import ImageIcon from '@mui/icons-material/ImageOutlined';
import UnarchiveIcon from '@mui/icons-material/UnarchiveOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { lighten } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';

import { SearchInput } from 'widgets-v6/standard-input';
import OverviewTabs from 'pages/campaigns/campaigns-overview/overview-tabs';
import {
    platformsMapping,
    isSupportedExporter,
    CONVERSIO_MARKETING_ORG_ID,
} from 'forms/creative-form/constants';
import { can } from 'services/can';
import { submitFromCreativeOverview } from './actions';
import {
    expandCreative,
    collapseCreative,
    expandAllCreatives,
    collapseAllCreatives,
} from 'pages/campaigns/creatives/creatives-overview/actions';
import {
    openCreativeWarningModal,
    closeCreativeWarningModal,
} from './modules/creative-bulk-edit-audit-warning/actions';
import { CreativeBulkEdit } from 'forms/creative-bulk-edit-form/actions';
import { default as creativeBulkUploadActions } from 'forms/creative-bulk-upload-form/actions';
import { canCreateCreative } from 'states/resources/creatives/business-logic';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import VirtualizedCreativesTable from './modules/creative-list-virtualized-table';
import { PermissionsMapping } from 'common/constants/role-intents';

const styles = theme => ({
    paper: {
        margin: '0 20px',
        overflow: 'hidden',
        paddingBottom: '80px',
    },
    image: {
        maxHeight: '70px',
        maxWidth: '250px',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    nameContainer: {
        position: 'relative',
    },
    expandedRow: {
        display: 'table-row',
    },
    detailsCell: {
        padding: 0,
    },
    detailsContent: {
        borderBottom: 'none',
    },
    detailsRow: {
        display: 'flex',
    },
    rowRightContent: {
        flex: 1,
        borderBottom: 'none',
    },
    hiddenRow: {
        display: 'none',
    },
    auditContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    newCreativeMenuTopContainer: {
        padding: '16px',
        textAlign: 'center',
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                  color: theme.palette.primary.main,
                  backgroundColor: lighten(theme.palette.primary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.primary.dark,
              },
    creativeOptions: {
        paddingLeft: '25px',
    },
    tooltip: {
        fontSize: '11px',
    },
});
class CampaignOverviewCreativesLayoutV2 extends React.Component {
    state = {
        expanded: false,
        collapsed: true,
        selectedRows: [],
        bulkUploadFormatAnchor: null,
        creativeUploadFormatAnchor: null,
    };

    goTo = path => {
        browserHistory.push(path);
    };

    UNSAFE_componentWillMount() {
        // NOTE: set some state for the highlighted ad from the hash - Alex
        const { hash } = this.props.location;
        this.setState({ linkedCreative: hash.slice(1) }); // remove the hash symbol
    }

    componentDidUpdate(prevProps) {
        if (this.props.creatives.length > prevProps.creatives.length) {
            const { hash } = this.props.location;
            if (hash) {
                const creativeDomElement = document.querySelector(hash.replace('#', '#creative-'));

                if (creativeDomElement) {
                    creativeDomElement.scrollIntoView();
                }
            }
        }
    }

    setPermalink = (creativeId, textToClipboard) => {
        if (!creativeId) {
            return;
        }
        if (undefined === textToClipboard) {
            navigator.clipboard.writeText(textToClipboard).then(function() {}, function() {});
        }
        toastr.success('Permalink copied to Clipboard.', '');
        this.setState({ linkedCreative: creativeId });
    };

    openCreativeHistory = creativeId => {
        browserHistory.push(
            `/campaigns/${this.props.campaignId}/setup/creatives/${creativeId}/history`
        );
    };

    newCreative = () => {
        const { campaignId } = this.props;

        browserHistory.push(`/campaigns/${campaignId}/setup/creatives/new`);
    };

    handleFilterChange = (filterType, value) => {
        const { campaignId } = this.props;
        this.props.filterCreativeList2({
            campaignId,
            filterType,
            value,
        });
    };

    expandAllCreatives = () => {
        const { campaignId, dispatch, creatives } = this.props;

        this.setState({
            expanded: true,
            collapsed: false,
        });

        dispatch(expandAllCreatives(campaignId, creatives));
    };

    submitCreativeEditFormDraft = creative => {
        const { dispatch, campaignId } = this.props;

        dispatch(submitFromCreativeOverview(campaignId, creative.id));
    };

    editCreative = creativeId => {
        browserHistory.push(
            `/campaigns/${this.props.campaignId}/setup/creatives/${creativeId}/edit`
        );
    };

    expandCreative = creative => {
        const { campaignId, dispatch } = this.props;

        if (creative.isExpanded === true) {
            dispatch(collapseCreative(campaignId, creative.id));
            return;
        }

        dispatch(expandCreative(campaignId, creative.id));
    };

    collapseAllCreatives = () => {
        const { campaignId, dispatch, creatives } = this.props;

        this.setState({
            expanded: false,
            collapsed: true,
        });

        dispatch(collapseAllCreatives(campaignId, creatives));
    };

    handleCheckAll = () => {
        const { creatives } = this.props;

        const selectedRows = this.props.selectedRows;
        if (selectedRows.length === creatives.length) {
            this.props.setSelectedRows([]);
        } else {
            this.props.setSelectedRows(_.map(creatives, creative => creative.id));
        }
    };

    handleCheckRow = id => {
        const selectedRows = this.props.selectedRows;
        if (_.includes(selectedRows, id)) {
            this.props.setSelectedRows(_.filter(selectedRows, row => row !== id));
        } else {
            this.props.setSelectedRows(_.union(selectedRows, [id]));
        }
    };

    checkAuditStatus = () => {
        const { creatives } = this.props;
        const selectedRows = this.props.selectedRows;

        const selectedCreatives = _.filter(creatives, creative =>
            selectedRows.includes(creative.id)
        );
        const approvedCreatives = _.filter(
            selectedCreatives,
            ({ audit_status }) => audit_status === 'pending' || audit_status === 'approved'
        );
        if (approvedCreatives.length > 0) {
            this.openCreativeAuditWarning();
        } else {
            this.openCreativeBulkEditForm();
        }
    };

    openCreativeBulkEditForm = () => {
        this.closeCreativeAuditWarning();
        this.props.dispatch(
            CreativeBulkEdit.openForm(this.props.campaignId, this.props.selectedRows)
        );
    };

    openCreativeAuditWarning = () => {
        this.props.dispatch(openCreativeWarningModal());
    };

    closeCreativeAuditWarning = () => {
        this.props.dispatch(closeCreativeWarningModal());
    };

    openCreativeForm = option => {
        const { campaignId } = this.props;

        this.closeCreativeUploadFormatMenu();

        if (option && isSupportedExporter(option)) {
            browserHistory.push(`/campaigns/${campaignId}/setup/creatives/new?exporter=${option}`);
        } else {
            browserHistory.push(`/campaigns/${campaignId}/setup/creatives/new`);
        }
    };

    openCreativeBulkUploadForm = format => {
        const { campaignId, campaign } = this.props;
        const campaignType = campaign.type;
        this.props.dispatch(
            creativeBulkUploadActions.openForm({ campaignId, format, campaignType })
        );
        this.closeBulkUploadFormatMenu();
        this.closeCreativeUploadFormatMenu();
    };

    openBulkUploadFormatMenu = e => {
        this.setState({ bulkUploadFormatAnchor: e.currentTarget });
    };

    closeBulkUploadFormatMenu = () => {
        this.setState({ bulkUploadFormatAnchor: null });
    };

    openCreativeUploadFormatMenu = event => {
        this.setState({ creativeUploadFormatAnchor: event.currentTarget });
    };

    closeCreativeUploadFormatMenu = () => {
        this.setState({ creativeUploadFormatAnchor: null });
    };

    renderPlatformLabel = platforms => {
        if (platforms.length === 1) {
            return platformsMapping[platforms[0]];
        } else if (platforms.length > 1) {
            let multiPlatformValue = _.join(
                _.map(platforms, platform => platformsMapping[platform]),
                `,\n`
            );
            return multiPlatformValue;
        }
    };

    render() {
        const {
            campaignId,
            creatives,
            campaign,
            workspace,
            searchQuery,
            classes,
            organization,
            shouldShowArchivedCreatives,
            toggleArchivedAdVisibility,
            openBulkArchiveConfirmModal,
            openArchiveConfirmModal,
        } = this.props;
        const selectedRows = this.props.selectedRows;
        const campaignType = campaign.type;
        const canUploadConversioZipFiles = organization === CONVERSIO_MARKETING_ORG_ID;

        return (
            <div>
                {workspace}
                <OverviewTabs tab="creatives" campaignId={campaignId} />
                <div className="ef3-standardToolbar">
                    <div className="ef3-campaignOverview_CreativeList_WorkspaceToolbar_search">
                        <SearchInput
                            className="ef3-standardInput"
                            value={searchQuery}
                            key={campaignId}
                            type="text"
                            placeholder="Search"
                            onChange={e => {
                                this.handleFilterChange('search', e.target.value);
                            }}
                        />
                    </div>
                    {can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT) && (
                        <div className="ef3-campaignOverview_CreativeList_WorkspaceToolbar_primaryBtn">
                            {canCreateCreative(campaign) && (
                                <NewCreativeMenu buttonLabel="New Creative">
                                    <Container className={classes.newCreativeMenuTopContainer}>
                                        <Button
                                            classes={{ root: 'new-creative-modal-button' }}
                                            onClick={() => this.openCreativeForm('standard')}
                                            color="primary"
                                            variant="contained"
                                            fullWidth={true}
                                            startIcon={<AddIcon />}
                                        >
                                            New Creative
                                        </Button>
                                        <Box mt={1}>
                                            <Typography variant="caption" component="p">
                                                Full setup with all formats and options
                                            </Typography>
                                        </Box>
                                    </Container>
                                    <Divider />
                                    {campaignType !== CampaignTypeMapping.DOOH && (
                                        <List dense={true} className={classes.root}>
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    this.openCreativeBulkUploadForm('custom_html')
                                                }
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <CodeIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Upload Rich Media Tag Spreadsheets"
                                                    secondary="Serve JavaScript tags from third-party ad servers"
                                                />
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    this.openCreativeBulkUploadForm('standard')
                                                }
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <ImageIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Bulk Upload Images"
                                                    secondary="Serve static or animated images without tags"
                                                />
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() => this.openCreativeForm('gwd')}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <UnarchiveIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Import from Google Web Designer"
                                                    secondary="Upload .zip files published from GWD"
                                                />
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() => this.openCreativeForm('adobe')}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <UnarchiveIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Import from Adobe Animate"
                                                    secondary="Upload .zip files published from Adobe Animate"
                                                />
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() => this.openCreativeForm('html5')}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <UnarchiveIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Import from Generic HTML5 Creative"
                                                    secondary="Upload .zip files with HTML5 creative"
                                                />
                                            </ListItem>
                                            {canUploadConversioZipFiles && (
                                                <ListItem
                                                    button
                                                    onClick={() =>
                                                        this.openCreativeForm('conversio')
                                                    }
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <UnarchiveIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary="Import from Conversio Marketing"
                                                        secondary="Upload .zip files published by Conversio Marketing"
                                                    />
                                                </ListItem>
                                            )}
                                        </List>
                                    )}
                                </NewCreativeMenu>
                            )}
                        </div>
                    )}
                </div>
                {
                    <VirtualizedCreativesTable
                        dispatch={this.props.dispatch}
                        selectedRows={selectedRows}
                        campaignId={campaignId}
                        creatives={creatives}
                        campaign={campaign}
                        workspace={workspace}
                        classes={classes}
                        organization={organization}
                        handleCheckAll={this.handleCheckAll}
                        handleCheckRow={this.handleCheckRow}
                        editCreative={this.editCreative}
                        openCreativeHistory={this.openCreativeHistory}
                        duplicateCreative={this.props.duplicateCreative}
                        setPermalink={this.setPermalink}
                        renderPlatformLabel={this.renderPlatformLabel}
                        submitCreativeEditFormDraft={this.submitCreativeEditFormDraft}
                        closeCreativeAuditWarning={this.closeCreativeAuditWarning}
                        openCreativeBulkEditForm={this.openCreativeBulkEditForm}
                        openArchiveConfirmModal={openArchiveConfirmModal}
                        openBulkArchiveConfirmModal={openBulkArchiveConfirmModal}
                        shouldShowArchivedCreatives={shouldShowArchivedCreatives}
                        toggleArchivedAdVisibility={toggleArchivedAdVisibility}
                    />
                }
            </div>
        );
    }
}

function NewCreativeMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                startIcon={<AddIcon />}
            >
                {props.buttonLabel}
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {props.children}
            </Popover>
        </React.Fragment>
    );
}

export default withStyles(styles)(CampaignOverviewCreativesLayoutV2);
