import _ from 'lodash';
import c from 'common/constants/flux-events';

import moment from 'moment';
import 'moment-timezone';

import { validateScheduleReport } from './services';
import createPivotTableService from 'widgets/pivot-table/service';
import { getDimensions } from 'common/constants/dimensions';

const pt = createPivotTableService();

export const NAME = 'scheduledReport';

const initialState = {
    isModalOpen: false,
    id: 'new',
    errors: [],
    showErrors: false,
    draft: {
        scheduleInfo: {
            excludeTodaysStat: true,
            repeat: 'none',
            atDate: moment.tz(new Date(), 'UTC').toISOString(),
            atTime: moment(moment().format('HH'), 'HH').toISOString(),
            weekDays: [false, false, false, false, false, false, false],
            timezone: 'UTC',
        },
        organization: null,
        campaignId: null,
        recipients: [],
        splits: [{ label: 'Date', name: 'date' }],
        metrics: {
            enabledMetrics: [],
            disabledMetrics: [],
        },
        hideZeroRows: true,
    },
    columns: [{ label: 'Dimensions', name: 'dimension', defaultValue: '', formatType: '' }],
    pivotTable: {
        splits: [{ label: 'Date', name: 'date' }],
        sort: {
            column: 'dimension',
            order: 'asc',
        },
        dimensions: [
            {
                label: 'Age',
                name: 'age',
                serverName: 'age',
            },
            {
                label: 'Date',
                name: 'date',
                serverName: '',
            },
            {
                label: 'Day Of Week',
                name: 'day_of_week',
                serverName: 'time',
            },
            {
                label: 'Device OS',
                name: 'device_os',
                serverName: 'os',
            },
            {
                label: 'Gender',
                name: 'gender',
                serverName: 'gender',
            },
            {
                label: 'Country',
                name: 'geo_country',
                serverName: 'country',
            },
            {
                label: 'Region',
                name: 'geo_country_region',
                serverName: 'region',
            },
            {
                name: 'markup_id',
                serverName: 'markup_id',
                label: 'Creative',
            },
            {
                name: 'audience_segment',
                serverName: 'audience_segment',
                label: 'Audience',
            },
            {
                label: 'Hour',
                name: 'hour',
                serverName: '',
            },
            {
                label: 'Month',
                name: 'month',
                serverName: '',
            },
            {
                label: 'Week',
                name: 'week',
                serverName: '',
                children: [
                    {
                        label: 'Week (Monday to Sunday)',
                        name: 'week_by_monday_to_sunday',
                        parent: 'Week',
                    },
                    {
                        label: 'Week (every 7 days)',
                        name: 'week_by_campaign_start_day',
                        parent: 'Week',
                    },
                ],
            },
            {
                label: 'Ad',
                name: 'ad_id',
                serverName: 'ad_id',
            },
            {
                label: 'Ad Group',
                name: 'adgroup',
                serverName: '',
                children: [],
            },
            {
                label: 'Platform',
                name: 'platform',
                serverName: 'platform',
            },
        ],
        tree: {},
    },
};

export default function(state = initialState, action) {
    switch (action.type) {
        case c.REPORT_EXPLORE__SCHEDULED_REPORT__OPEN: {
            return {
                ...state,
                isModalOpen: true,
            };
        }

        case c.REPORT_EXPLORE__SCHEDULED_REPORT__CLOSE: {
            return {
                ...state,
                ...initialState,
                isModalOpen: false,
            };
        }

        case c.REPORT_EXPLORE__SCHEDULED_REPORT__INIT: {
            const { organizations, organization, campaign, campaignId, users } = action.payload;

            const sharingSettingsMetrics = _.get(campaign, 'sharing_settings.metrics');

            let isConversionShared = true;
            let isEngagementShared = true;
            _.each(sharingSettingsMetrics, sharedMetrics => {
                if (sharedMetrics.name === 'conversions_sharing_settings_flag') {
                    isConversionShared = sharedMetrics.shared;
                }
                if (sharedMetrics.name === 'engagements_sharing_settings_flag') {
                    isEngagementShared = sharedMetrics.shared;
                }
            });

            const beacons = _.get(campaign, 'beacons');

            // Create/Update metrics
            const metrics = {
                enabledMetrics: [],
                disabledMetrics: [],
            };

            _.forEach(sharingSettingsMetrics, metric => {
                const option = {
                    name: metric.name,
                    checked: false,
                };

                if (metric.shared) {
                    metrics.enabledMetrics.push(option);
                } else {
                    metrics.disabledMetrics.push(option);
                }
            });
            _.forEach(beacons, beacon => {
                const option = {
                    name: beacon.name,
                    isBeacon: true,
                    checked: false,
                };

                if (beacon.hide || !isEngagementShared) {
                    metrics.disabledMetrics.push(option);
                } else {
                    metrics.enabledMetrics.push(option);
                }
            });

            if (isConversionShared) {
                const conversions = _.get(campaign, 'conversions');
                _.forEach(conversions, conversion => {
                    const option = {
                        name: `conv_total_${conversion.event_name}`,
                        checked: false,
                    };

                    metrics.enabledMetrics.push(option);
                });
            }

            // Create/Update recipients
            const recipients = buildRecipientsList(users, organizations, campaign);

            const dimensions = state.pivotTable.dimensions;

            return {
                ...state,
                pivotTable: {
                    ...state.pivotTable,
                    dimensions,
                },
                draft: {
                    ...state.draft,
                    metrics,
                    recipients,
                    campaignId,
                    organization,
                },
            };
        }

        case c.REPORT_EXPLORE__SCHEDULED_REPORT__SHOW_ERRORS: {
            return {
                ...state,
                showErrors: true,
            };
        }

        case c.REPORT_EXPLORE__SCHEDULED_REPORT__UPDATE_DRAFT: {
            const { newDraft } = action.payload;

            const nextDraft = {
                ...state.draft,
                ...newDraft,
            };

            const errors = validateScheduleReport(nextDraft);

            return {
                ...state,
                draft: nextDraft,
                errors,
            };
        }

        case c.REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_DRAFT: {
            const errors = validateScheduleReport(state.draft);
            return {
                ...state,
                errors,
            };
        }

        case c.REPORT_EXPLORE__SCHEDULED_REPORT__FETCH_SUCCESS: {
            const { scheduledReport, campaign, users, organizations } = action.payload;

            const id = _.get(scheduledReport, 'id', 'new');
            const campaignId = _.get(scheduledReport, 'campaignId');
            const organization = _.get(scheduledReport, 'organization');

            const isSharingSettingsChanged =
                _.get(scheduledReport, 'metrics.disabledMetrics', []).length > 0;

            // Update dimensions by sharing_settings if sharing_settings is updated.
            const sharingSettingsDimensions = _.get(
                campaign,
                'sharing_settings.report_tabs.explore.dimensions',
                []
            );

            // map server names to its names
            const mappedDimensions = _.map(_.compact(sharingSettingsDimensions), dimension => {
                const found = _.find(getDimensions(), it => it.serverName === dimension.name);
                return {
                    name: found.name,
                    shared: dimension.shared,
                };
            });

            // filter pivottable dimensions by sharing settings.
            const dimensions = _.filter(state.pivotTable.dimensions, dimension => {
                const ignoreDimension = _.find(
                    mappedDimensions,
                    dim => dim.name === dimension.name && !dim.shared
                );
                return !ignoreDimension;
            });

            const checkedEmails = getCheckedUserEmails(scheduledReport);
            const recipients = buildRecipientsList(users, organizations, campaign, checkedEmails);

            const nextDraft = {
                ...state.draft,
                ..._.pick(scheduledReport, _.keys(initialState.draft)),
                recipients,
            };

            const errors = validateScheduleReport(nextDraft);

            return {
                ...state,
                campaignId,
                organization,
                id,
                errors,
                draft: nextDraft,
                pivotTable: {
                    ...state.pivotTable,
                    splits: scheduledReport.splits,
                    ...(isSharingSettingsChanged && {
                        dimensions,
                    }),
                },
            };
        }

        case c.REPORT_EXPLORE__SCHEDULED_REPORT__FETCH_FAIL: {
            const { error } = action.payload;

            return {
                ...state,
                errors: error,
            };
        }

        case 'REPORT_EXPLORE__SCHEDULED_REPORT__FETCH_USERS_FAIL': {
            const { error } = action.payload;

            return {
                ...state,
                errors: error,
            };
        }

        case c.REPORT_EXPLORE__PIVOT_TABLE__SPLIT_CHANGE: {
            const { splits } = action.payload;

            const nextDraft = {
                ...state.draft,
                splits,
            };

            const errors = validateScheduleReport(nextDraft);

            return {
                ...state,
                draft: nextDraft,
                errors,
                pivotTable: {
                    ...state.pivotTable,
                    splits,
                },
            };
        }

        case c.REPORT_EXPLORE__PIVOT_TABLE__INITIALIZE: {
            const { stats } = action.payload;

            return {
                ...state,
                pivotTable: pt.generatePivotTableState(state.pivotTable, stats),
                isLoading: false,
                isInitializing: false,
                isInitialized: true,
            };
        }

        case c.REPORT_EXPLORE__SCHEDULED_REPORT__TOGGLE_ZERO_ROWS: {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    hideZeroRows: !state.draft.hideZeroRows,
                },
            };
        }

        case 'REPORT_EXPLORE__SCHEDULED_REPORT__AVAILABLE_ADGROUPS__FETCH_SUCCESS': {
            const adgroups = action.payload.response;

            const dimensions = _.map(state.pivotTable.dimensions, option => {
                if (option.label === 'Ad Group') {
                    const children = _(adgroups)
                        // .filter(adgroup => !adgroup.auto_hidden)
                        .filter(adGroup => adGroup.groups.length > 0)
                        .map(adGroup => ({
                            label: adGroup.scope_name,
                            name: adGroup.id,
                        }))
                        .value();

                    return {
                        ...option,
                        children,
                    };
                }

                return option;
            });

            return {
                ...state,
                pivotTable: {
                    ...state.pivotTable,
                    dimensions,
                },
            };
        }

        default:
            return state;
    }
}

function buildRecipientsList(users, organizations, campaign, checkedEmails = {}) {
    // Create/Update recipients
    // add campaign organization as recipient as well.
    const recipients = _.map(
        _.union(campaign.clients, [
            {
                organization: campaign.organization,
            },
        ]),
        client => {
            const clientUsers = [];

            _.each(users, user => {
                if (
                    user.attr.organization === client.organization &&
                    user.attr.global_role !== 'revoked'
                ) {
                    const email = _.get(user, 'attr.email');
                    clientUsers.push({
                        id: _.get(user, 'attr.id'),
                        email,
                        checked: checkedEmails[email] ? true : false,
                    });
                }
            });
            const clientOrganizationObj = _.find(
                organizations,
                org => org.id === client.organization
            );

            const clientName = _.get(clientOrganizationObj, 'name');
            return {
                organization: client.organization,
                clientName,
                expanded: false,
                users: clientUsers,
            };
        }
    );

    return recipients;
}

function getCheckedUserEmails(scheduledReport) {
    const recipients = {};
    _.each(scheduledReport.recipients, organization => {
        _.each(organization.users, user => {
            if (user.checked) {
                recipients[user.email] = true;
            }
        });
    });

    return recipients;
}
