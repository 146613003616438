import floor from 'lodash/floor';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { AUDIENCE_STATUS_MAPPING } from 'pages/presets/poi-editor/services/constants';

export const AUDIENCE_SOURCE_TYPES = {
    adobe: {
        type: 'prebuilt',
        label: 'Adobe',
    },
    pds_age: {
        type: 'prebuilt',
        label: 'PDS Segment - Age',
    },
    pds_custom: {
        type: 'custom_built',
        label: 'PDS Segment - Custom',
    },
    pds_mastercard: {
        type: 'prebuilt',
        label: 'PDS Segment - Mastercard',
    },
    pds_environics: {
        type: 'prebuilt',
        label: 'PDS Segment - Environics',
    },
    pds_standard: {
        type: 'prebuilt',
        label: 'PDS Segment - Standard',
    },
    pds_brand: {
        type: 'prebuilt',
        label: 'PDS Segment - Brand',
    },
    pds_behaviour: {
        type: 'prebuilt',
        label: 'PDS Segment - Behaviour',
    },
    pds_mweb: {
        type: 'custom_built',
        label: 'PDS Segment - All Captured TWN Users',
    },
    pds_manifold: {
        type: 'prebuilt',
        label: 'PDS Segment - CanaCode',
    },
};

// An active segment is one whose source campaign or ad is still running
export function isAudienceActive(audience) {
    return audience.status === 'active';
}

export const RETARGETING_TYPES = {
    Click: 'click',
    Impression: 'impression',
    Conversion: 'conversion',
    Attribution: 'attribution',
    Pixel: 'pixel',
};

export const STANDARD_RETARGETING_TYPES = [
    RETARGETING_TYPES.Click,
    RETARGETING_TYPES.Impression,
    RETARGETING_TYPES.Conversion,
];

// An audience's source represents where the segment's users came from.
// The answer comes from one of 3 places:
// `source_type` - Newer segments contain this field, making it easy to determine
// `retargeting_type` - Segments of mode 'retargeting' have this field, and not the `source_type` field
// `upload_origin` - Segments of mode 'upload' have this field, and not the `source_type` field. It represents where the data in the uploaded file came from.
export function getAudienceSource(audience) {
    if (AUDIENCE_SOURCE_TYPES[audience.source_type]) {
        return AUDIENCE_SOURCE_TYPES[audience.source_type].label;
    }

    switch (audience.retargeting_type) {
        case RETARGETING_TYPES.Click:
            return 'Clicks';
        case RETARGETING_TYPES.Impression:
            return 'Impressions';
        case RETARGETING_TYPES.Conversion:
            return 'Event';
        default:
        // Nothing, it means there was none so we move on.
    }

    switch (audience.upload_origin) {
        case 'client':
            return 'Client';
        case 'environics':
            return 'Environics';
        case 'kochava':
            return 'Kochava';
        case 'PDS_poi':
            return 'PDS Segment - POI';
        case 'PDS_behavioural':
            return 'PDS Segment - Behavioural';
        case 'PDS_pattison_connect':
            return 'PDS Segment - Pattison Connect';
        case 'other':
            return 'Other';
        default:
            return 'NA';
    }
}

export function getAudienceStatusClass(status, classes) {
    switch (status) {
        case AUDIENCE_STATUS_MAPPING.active:
        case AUDIENCE_STATUS_MAPPING.suspending:
            return classes.activeAudience;
        case AUDIENCE_STATUS_MAPPING.suspended:
        case AUDIENCE_STATUS_MAPPING.pending:
            return classes.pendingOrSuspendedAudience;
        default:
            return classes.activeAudience;
    }
}

// Newer, automated segments are easier to figure out. All PDS sourced segments are usable if they've been
// processed at least once, indicated by a value existing for `last_dmp_update`.
// Other segments may either:
// A) Have a match rate (as in the case of a retargeting segment), OR
// B) Lack a match rate, but are marked as processed. I'm not sure why this is the case.
export function isAudienceUsable(audience) {
    if (audience.source_type.match(/pds_/)) {
        return !!audience.last_dmp_update;
    }

    return typeof audience.match_rate === 'number' || audience.status === 'processed';
}

// A segment must contain both of these fields in order for matched users to be calculated
export function hasMatchInformation(audience) {
    return audience.uniques !== null && audience.match_rate !== null;
}

export function computeMatchedUsers(audience) {
    return floor(audience.uniques);
}

export function isDownloadEnabled(audience) {
    if (audience.mode !== 'retargeting') {
        return false;
    }

    if (['active', 'processed', 'processing'].indexOf(audience.status) === -1) {
        return false;
    }

    if (audience.uniques === 0 && audience.match_rate === 0) {
        return false;
    }

    return true;
}

/**
 * Get the label for the status of the audience
 * If it is suspending, we still show as active, but add extra info to the table
 * @param {*} audience
 * @returns string the label of the status for the user
 */
export function getAudienceStatus(audience) {
    if (isEmpty(audience.status)) {
        return 'N/A';
    }

    if (undefined === AUDIENCE_STATUS_MAPPING[audience.status]) {
        return audience.status;
    }
    return _.capitalize(AUDIENCE_STATUS_MAPPING[audience.status]);
}

/**
 * Get number of days the background job is expected to suspend the suspending audience
 * If negative (first day of implementation of this feature), return 0
 * @param {*} audience
 * @returns integer number of days from the current date
 */
export function getAudienceDaysToExpire(audience) {
    if (isEmpty(audience.expire_at)) {
        return 'N/A';
    }

    const expiration = moment(audience.expire_at).endOf('day');
    const today = moment().endOf('day');

    const days = expiration.diff(today, 'days');
    return days > 0 ? days : 0;
}
