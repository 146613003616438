import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import numeral from 'numeral';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';

import Label from '../label';
import FileUpload from 'widgets-v5/file-upload';
import UrlStandardInput from 'widgets-v5/url-standard-input';
import StandardInput from 'widgets-v5/standard-input';
import CreativeAutoComplete from 'widgets-v5/creative-auto-complete';
import InlineTooltip from 'widgets-v5/inline-tooltip';
import { NeutralButton } from 'widgets-v5/buttons';
import { ComposableDataTable, Cell, Row } from 'widgets-v5/data-table';
import { ImagePreview, VideoPreview } from 'widgets-v5/media-preview';
import Spacer from 'widgets-v5/spacer';

import { ADAPTIVE_SIZE } from 'common/constants/creatives';

import { red } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import TextFieldFormatter from 'widgets-v6/text-field-formatter';
import { TextField } from 'widgets-v6/standard-input';
import ImageIcon from '@mui/icons-material/Image';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { ASSET_SELECTION_METHODS, getDOOHSizeVariants } from 'forms/creative-form/constants';
import flags from 'containers/flags/service';
import { getEnvironmentSettings } from 'services/environment';
import { PlatformMapping } from 'states/resources/ads/business-logic';

const ImagePlaceholder = styled(ImageIcon)({
    height: '150px',
    width: '150px',
});

export default class extends React.Component {
    constructor(props, context) {
        super(props, context);
        const selectedAssetAsArray = this.getActiveAssetFromAssets();

        this.state = {
            selectedAsset: selectedAssetAsArray[0],
            selectedTab: '0',
            assetSelectionMethod: 'selectFromAssets',
        };
    }

    findInputByUrl = () => {
        const imageUrlFields = [
            'image_preview_url',
            'image_url',
            'main_image_url',
            'main_image_preview_url',
            'icon_image_preview_url',
            'icon_image_url',
            'companion_url',
            'reference_image_preview_url',
            'companion_preview_url',
            'video_url',
            'video_preview_url',
        ];

        let found = false;

        _.each(imageUrlFields, field => {
            if (this.props.draft[field]) {
                found = true;
            }
        });

        return found;
    };

    UNSAFE_componentWillReceiveProps() {
        const activeAsset = this.getActiveAssetFromAssets()[0];
        const { size } = this.props.draft;

        if (!this.state.selectedAsset) return;

        if (activeAsset) {
            const assetSize = `${activeAsset.attr.width}x${activeAsset.attr.height}`;

            if (size !== assetSize) {
                this.setState({ selectedAsset: undefined });
            }
        }
    }

    getActiveAssetFromAssets = () => {
        const { draft, assets, inputUrlFieldsType } = this.props;
        return _.filter(assets, asset => {
            switch (draft.format) {
                case 'mraid':
                case 'custom_html':
                    return asset.id === draft.reference_image_asset;
                case 'video':
                    if (inputUrlFieldsType === 'videoCompanion') {
                        return asset.id === draft.companion_asset;
                    } else {
                        return asset.id === draft.video_asset;
                    }
                    break;
                case 'native':
                    if (inputUrlFieldsType === 'nativeMainImage') {
                        return asset.id === draft.main_image_asset;
                    } else {
                        return asset.id === draft.icon_image_asset;
                    }
                    break;
                default:
                    return asset.id === draft.image_asset;
            }
        });
    };

    tabToggle = value => {
        this.setState({ selectedTab: value });
    };

    selectAssetAtIndex = index => {
        const { inputUrlFieldsType, assets, draft } = this.props;

        const selectedAssetAsArray = _.filter(assets, asset => {
            return asset.id === index ? asset : undefined;
        });
        const selectedAsset = selectedAssetAsArray[0];

        const formData = this.getCorrectAssetData(draft.format, inputUrlFieldsType, selectedAsset);

        this.props.onMultiFieldChange(formData);

        if (draft.format === 'video' && draft.platforms.includes(PlatformMapping.CTV)) {
            const errors = this.checkVideoForCTV(selectedAsset.attr);
            this.props.onSelectAssetWithWarnings(errors);
        }
    };

    checkVideoForCTV = ({ url, type, duration, width, height, bit_rate_kbps }) => {
        const SUGGESTED_PROPS = {
            aspectRatio: 16 / 9,
            minVideoBitrate: 8,
            maxVideoBitrate: 40,
            minVideoDuration: 15,
            maxVideoDuration: 30,
            validFileType: 'video/mp4',
        };
        const FLOAT_COMPARISON_TOLERANCE = 0.0001;
        const ONE_MEGA = 1024;

        const errors = {};

        const video = document.createElement('video');
        video.src = url;
        const videoCanPlayType = video.canPlayType(SUGGESTED_PROPS.validFileType);
        video.remove();

        if (type !== 'video' || !['maybe', 'probably'].includes(videoCanPlayType)) {
            errors.fileType = `File type must be ${SUGGESTED_PROPS.validFileType}`;
            return;
        }

        if (
            (SUGGESTED_PROPS.minVideoDuration && duration < SUGGESTED_PROPS.minVideoDuration) ||
            (SUGGESTED_PROPS.maxVideoDuration && duration > SUGGESTED_PROPS.maxVideoDuration)
        ) {
            const message =
                SUGGESTED_PROPS.minVideoDuration && SUGGESTED_PROPS.maxVideoDuration
                    ? `between ${SUGGESTED_PROPS.minVideoDuration} and ${
                          SUGGESTED_PROPS.maxVideoDuration
                      }`
                    : SUGGESTED_PROPS.minVideoDuration
                    ? `greater than or equal ${SUGGESTED_PROPS.minVideoDuration}`
                    : `less than or equal ${SUGGESTED_PROPS.maxVideoDuration}`;

            errors.duration = `Duration must be ${message} seconds`;
        }

        const bitrateMbps = bit_rate_kbps / ONE_MEGA;

        if (
            (SUGGESTED_PROPS.minVideoBitrate && bitrateMbps < SUGGESTED_PROPS.minVideoBitrate) ||
            (SUGGESTED_PROPS.maxVideoBitrate && bitrateMbps > SUGGESTED_PROPS.maxVideoBitrate)
        ) {
            const message =
                SUGGESTED_PROPS.minVideoBitrate && SUGGESTED_PROPS.maxVideoBitrate
                    ? `between ${SUGGESTED_PROPS.minVideoBitrate} and ${
                          SUGGESTED_PROPS.maxVideoBitrate
                      }`
                    : SUGGESTED_PROPS.minVideoBitrate
                    ? `greater than or equal ${SUGGESTED_PROPS.minVideoBitrate}`
                    : `less than or equal ${SUGGESTED_PROPS.maxVideoBitrate}`;

            errors.bitrate = `Bitrate must be ${message} Mbps`;
        }

        const aspectRatio = width / height;

        if (Math.abs(aspectRatio - SUGGESTED_PROPS.aspectRatio) > FLOAT_COMPARISON_TOLERANCE) {
            errors.aspectRatio = `Aspect ratio is invalid`;
        }

        return errors;
    };

    getCorrectAssetData = (format, type, rawAsset) => {
        switch (format) {
            case 'standard':
                return this.createSelectedAssetValue(rawAsset, 'image');
            case 'mraid':
            case 'custom_html':
                return this.createSelectedAssetValue(rawAsset, 'reference_image');
            case 'video':
                if (type === 'videoCompanion') {
                    return this.createSelectedAssetValue(rawAsset, 'companion');
                } else {
                    const assetWithoutDuration = this.createSelectedAssetValue(rawAsset, 'video');
                    const duration = rawAsset.attr.duration;
                    const bit_rate_kbps = rawAsset.attr.bit_rate_kbps;

                    return {
                        ...assetWithoutDuration,
                        image_preview_url: rawAsset.attr.thumbnail_url,
                        video_duration: duration,
                        video_bit_rate_kbps: bit_rate_kbps,
                    };
                }
                break;
            case 'native':
                if (type === 'nativeMainImage') {
                    return this.createSelectedAssetValue(rawAsset, 'main_image');
                } else {
                    return this.createSelectedAssetValue(rawAsset, 'icon_image');
                }
                break;
        }
    };

    createSelectedAssetValue = (inputData, type) => {
        return {
            [`${type}_asset`]: inputData.attr.id,
            [`${type}_url`]: inputData.attr.url,
            [`${type}_preview_url`]: inputData.attr.preview_url,
        };
    };

    clearSelectedAssetValue = type => {
        /*
            NOTE:
            As of May 27/2016 - Alex
            The behaviour of clearing or unsetting a value is incorrect. The ideal
            functionality would be setting the following code to null instead of
            empty string.

            In order for the resource to properly merge and omit the cleared values
            they must have some value. Otherwise the previous value will be merged
            into the new value. See the example below for what we DON'T want to happen:

            old = { 1: 'one', 2: 'two', 3: 'three' }
            newWithUnsetTwo = { 1: 'new_one', 3: 'new_three' }

            MERGE:
            { ...old, ...newWithUnsetTwo }

            whenMerged = {
                1: 'new_one',
                2: 'two', <--- we wanted this gone but it's still here :(
                3: 'new_three',
            }

        */
        return {
            [`${type}`]: '',
            [`${type}_url`]: '',
            [`${type}_preview_url`]: '',
        };
    };

    onUrlChange = (value, urlName) => {
        const { draft, inputUrlFieldsType } = this.props;

        let clearedValues = this.clearSelectedAssetValue('image_asset');

        switch (draft.format) {
            case 'mraid':
            case 'custom_html':
                clearedValues = this.clearSelectedAssetValue('reference_image_asset');
                break;
            case 'standard':
                clearedValues = this.clearSelectedAssetValue('image_asset');
                break;
            case 'video':
                if (inputUrlFieldsType === 'videoCompanion') {
                    clearedValues = this.clearSelectedAssetValue('companion_asset');
                } else {
                    this.props.onSelectAssetWithWarnings({});
                    clearedValues = this.clearSelectedAssetValue('video_asset');
                }
                break;
            case 'native':
                if (inputUrlFieldsType === 'nativeMainImage') {
                    clearedValues = this.clearSelectedAssetValue('main_image_asset');
                } else {
                    clearedValues = this.clearSelectedAssetValue('icon_image_asset');
                }
                break;
        }

        const formData_preSanitize = {
            ...clearedValues,
            [urlName]: value,
        };

        // Sanitize formData:
        //     If not selected by asset id then only one field changed,
        //     there is not need to dispatch a batch of fields, so we remove value that is null
        const formData = _.reduce(
            formData_preSanitize,
            (a, v, k) => {
                if (v !== null && v !== undefined) {
                    a[k] = v;
                }
                return a;
            },
            {}
        );

        this.props.onMultiFieldChange(formData);
    };

    handleChangeSelectionMethod = e => {
        this.setState({ assetSelectionMethod: e.target.value });
    };

    render() {
        const { draft, errors, inputUrlFieldsType, onFieldChange } = this.props;

        if (!draft.size && draft.format !== 'native') {
            return <div>...Loading</div>;
        }

        const rightSideProps = {
            draft: draft,
            errors: errors,
            clearSelectedAsset: this.clearSelectedAsset,
            onUrlChange: this.onUrlChange,
            inputUrlFieldsType,
            onFieldChange,
            showErrors: this.props.showErrors,
        };

        let inputByUrlContent;
        switch (inputUrlFieldsType) {
            case 'standard':
                inputByUrlContent = <InputByUrlStandard {...rightSideProps} />;
                break;
            case 'mraid':
                inputByUrlContent = <InputByUrlMraid {...rightSideProps} />;
                break;
            case 'video':
                inputByUrlContent = <InputByUrlVideo {...rightSideProps} />;
                break;
            case 'videoCompanion':
                inputByUrlContent = <InputByUrlVideoCompanion {...rightSideProps} />;
                break;
            case 'nativeIconImage':
                inputByUrlContent = <InputByUrlNativeIcon {...rightSideProps} />;
                break;
            case 'nativeMainImage':
                inputByUrlContent = <InputByUrlNativeMain {...rightSideProps} />;
                break;
            default:
                inputByUrlContent = <div>...Loading</div>;
        }

        const userUploadedAssets = _.filter(
            this.props.assets,
            asset => asset.attr.source !== 'autoGenerated'
        );
        const activeAsset = _.find(
            this.props.assets,
            asset => asset.id === this.props.draft.reference_image_asset
        );

        const isActiveAssetAutoGenerated = activeAsset
            ? activeAsset.attr.source === 'autoGenerated'
            : false;

        const canUseAutoGeneratedReferenceImage = inputUrlFieldsType === 'mraid';

        const assetSelectionMethods = canUseAutoGeneratedReferenceImage
            ? ASSET_SELECTION_METHODS
            : _.filter(
                  ASSET_SELECTION_METHODS,
                  method => method.value !== 'autoGeneratedScreenshot'
              );

        const environmentSettings = getEnvironmentSettings();
        const maxFileUploadSizeMB = environmentSettings.getMaxFileUploadSizeMB();

        return (
            <div>
                <RadioGroup
                    value={this.props.draft.assetSelectionMethod}
                    onChange={this.props.onChangeAssetSelectionMethod}
                >
                    {_.map(assetSelectionMethods, method => (
                        <React.Fragment key={method.value}>
                            <FormControlLabel
                                value={method.value}
                                control={<Radio color="primary" />}
                                label={method.label}
                            />
                            <Box ml="30px">
                                {this.props.draft.assetSelectionMethod ===
                                    'autoGeneratedScreenshot' &&
                                    method.value === 'autoGeneratedScreenshot' && (
                                        <React.Fragment>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                gutterBottom
                                            >
                                                If the screenshot looks broken due to animations in
                                                the creative, you can try regenerating the
                                                screenshot with a different screenshot delay.
                                            </Typography>
                                            <Box mb={2} mt={2}>
                                                <TextFieldFormatter
                                                    initialValue={this.props.renderDelay}
                                                    onChange={this.props.onChangeRenderDelay}
                                                    formatIn={v => {
                                                        return _.isNil(v)
                                                            ? '0'
                                                            : numeral(v).format('0.00');
                                                    }}
                                                    formatOut={v => {
                                                        return v ? numeral(v).value() : 0;
                                                    }}
                                                    shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                                    renderInput={({ value, onChange }) => (
                                                        <TextField
                                                            value={value}
                                                            onChange={onChange}
                                                            disableMargin
                                                            label="Screenshot Delay"
                                                            endAdornment="sec"
                                                        />
                                                    )}
                                                />
                                            </Box>
                                            {this.props.isReferenceImageLoading ? (
                                                <Box
                                                    mb={1}
                                                    height="150px"
                                                    width="150px"
                                                    alignItems="center"
                                                    display="flex"
                                                    justifyContent="center"
                                                >
                                                    <CircularProgress />
                                                </Box>
                                            ) : this.props.draft.reference_image_preview_url &&
                                              isActiveAssetAutoGenerated ? (
                                                <ImagePreview
                                                    height={150}
                                                    type="image"
                                                    src={
                                                        this.props.draft.reference_image_preview_url
                                                    }
                                                />
                                            ) : (
                                                <ImagePlaceholder color="disabled" />
                                            )}
                                            <Box>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={this.props.generateReferenceImage}
                                                    disabled={
                                                        this.props
                                                            .isGenerateReferenceImageDisabled ||
                                                        this.props.isReferenceImageLoading
                                                    }
                                                >
                                                    Regenerate Screenshot
                                                </Button>
                                            </Box>
                                        </React.Fragment>
                                    )}
                                {this.props.draft.assetSelectionMethod === 'selectFromAssets' &&
                                    method.value === 'selectFromAssets' && (
                                        <div
                                            className={classnames(
                                                'ef3-creativeAssetSelector',
                                                this.props.className
                                            )}
                                        >
                                            <div className="ef3-creativeAssetSelector__header">
                                                {!this.props.hideUploadAssetsErrorMessage &&
                                                    !_.isEmpty(
                                                        this.props.uploadAssetsErrorMessage
                                                    ) && (
                                                        <Box
                                                            textAlign="center"
                                                            alignItems="right"
                                                            padding="10px"
                                                            color={red[500]}
                                                        >
                                                            <Typography>
                                                                One or more files failed to upload,{' '}
                                                                {
                                                                    this.props
                                                                        .uploadAssetsErrorMessage
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                <FileUpload
                                                    onChange={e => this.props.uploadFile(e)}
                                                    isLoading={this.props.areAssetsLoading}
                                                    maxSizeInMB={maxFileUploadSizeMB}
                                                />
                                            </div>
                                            <Spacer />
                                            <div
                                                className={classnames(
                                                    'ef6-creative-asset-selector__select-asset-tab',
                                                    {
                                                        'ef6-creative-asset-selector__select-asset-tab--is-active':
                                                            this.state.selectedTab === '0',
                                                    }
                                                )}
                                            >
                                                <ComposableDataTable
                                                    condensed
                                                    className={classnames(
                                                        'ef6-creative-asset-selector__asset-table',
                                                        this.props.className
                                                    )}
                                                >
                                                    <Row key="header" header>
                                                        <Cell>Name</Cell>
                                                        <Cell>Preview</Cell>
                                                        <Cell>Width</Cell>
                                                        <Cell>Height</Cell>
                                                        <Cell>Type</Cell>
                                                        <Cell>Duration</Cell>
                                                    </Row>
                                                    {_.orderBy(
                                                        userUploadedAssets,
                                                        'attr._created',
                                                        'desc'
                                                    ).map(asset => {
                                                        const {
                                                            name,
                                                            width,
                                                            height,
                                                            type,
                                                            duration,
                                                            preview_url,
                                                            url,
                                                            thumbnail_url,
                                                        } = asset.attr;

                                                        const draftSize = this.props.draft.size;
                                                        const assetSizeRatio = (
                                                            width / height
                                                        ).toFixed(3);
                                                        const assetSize = `${width}x${height}`;

                                                        let expectedSize = draftSize;
                                                        let expectedType = 'image';

                                                        let isActiveAsset = false;
                                                        let assetUrlsMatchInputs = false;

                                                        switch (this.props.inputUrlFieldsType) {
                                                            case 'video':
                                                                isActiveAsset =
                                                                    draft.video_asset === asset.id;
                                                                assetUrlsMatchInputs =
                                                                    preview_url ===
                                                                        draft.video_preview_url &&
                                                                    url === draft.video_url;
                                                                expectedType = 'video';
                                                                break;
                                                            case 'videoCompanion':
                                                                isActiveAsset =
                                                                    draft.companion_asset ===
                                                                    asset.id;
                                                                assetUrlsMatchInputs =
                                                                    preview_url ===
                                                                        draft.companion_preview_url &&
                                                                    url === draft.companion_url;
                                                                break;
                                                            case 'mraid':
                                                                isActiveAsset =
                                                                    draft.reference_image_asset ===
                                                                    asset.id;
                                                                assetUrlsMatchInputs =
                                                                    preview_url ===
                                                                    draft.reference_image_preview_url;
                                                                break;
                                                            case 'nativeIconImage':
                                                                isActiveAsset =
                                                                    draft.icon_image_asset ===
                                                                    asset.id;
                                                                assetUrlsMatchInputs =
                                                                    preview_url ===
                                                                        draft.icon_image_preview_url &&
                                                                    url === draft.icon_image_url;
                                                                expectedSize = '80x80';
                                                                break;
                                                            case 'nativeMainImage':
                                                                isActiveAsset =
                                                                    draft.main_image_asset ===
                                                                    asset.id;
                                                                assetUrlsMatchInputs =
                                                                    preview_url ===
                                                                        draft.main_image_preview_url &&
                                                                    url === draft.main_image_url;
                                                                expectedSize = '1200x627';
                                                                break;
                                                            default:
                                                                isActiveAsset =
                                                                    draft.image_asset === asset.id;
                                                                assetUrlsMatchInputs =
                                                                    preview_url ===
                                                                        draft.image_preview_url &&
                                                                    url === draft.image_url;
                                                        }

                                                        const expectedSizeDimensions = expectedSize.split(
                                                            'x'
                                                        );
                                                        const expectedSizeRatio = (
                                                            expectedSizeDimensions[0] /
                                                            expectedSizeDimensions[1]
                                                        ).toFixed(3);

                                                        const isNonSelectable =
                                                            type !== expectedType;

                                                        let isIncorrectSize;
                                                        let aspectRatioForValidationMessage;
                                                        if (this.props.draft.format === 'native') {
                                                            isIncorrectSize =
                                                                assetSize !== expectedSize;
                                                        } else {
                                                            isIncorrectSize =
                                                                assetSizeRatio !==
                                                                expectedSizeRatio;
                                                        }

                                                        if (
                                                            flags.isEnabled(
                                                                'efb_63_dooh_creative_resizing'
                                                            ) &&
                                                            draft.platforms.includes(
                                                                PlatformMapping.DOOH
                                                            )
                                                        ) {
                                                            const {
                                                                size,
                                                                type,
                                                                audio_required,
                                                            } = this.props.draft;

                                                            const sizeVariants = getDOOHSizeVariants(
                                                                type,
                                                                audio_required
                                                            );

                                                            isIncorrectSize = !sizeVariants[
                                                                size
                                                            ]?.supportedSizes?.includes(assetSize);

                                                            aspectRatioForValidationMessage =
                                                                sizeVariants[size]?.aspectRatio;
                                                        }

                                                        let isAdaptiveVideo =
                                                            this.props.draft.size === ADAPTIVE_SIZE;

                                                        return (
                                                            <Row
                                                                key={asset.id}
                                                                onClick={() => {
                                                                    if (isNonSelectable) return;
                                                                    this.selectAssetAtIndex(
                                                                        asset.id
                                                                    );
                                                                }}
                                                                className={classnames(
                                                                    'ef3-creativeAssetSelector_listItem',
                                                                    {
                                                                        'is-nonSelectable': isNonSelectable,
                                                                        'is-incorrectSize': isIncorrectSize,
                                                                    }
                                                                )}
                                                            >
                                                                <Cell className="creatives-overview__stackable-table-first-column">
                                                                    <NeutralButton
                                                                        className={classnames(
                                                                            'ef6-creative-asset-selector__radio-button',
                                                                            'ef5-radio-selector__item-name',
                                                                            `ef5-radio-selector__item-name_align-${
                                                                                this.props.align
                                                                            }`,
                                                                            {
                                                                                'ef5-radio-selector__item-name_is-active':
                                                                                    (isActiveAsset &&
                                                                                        !isNonSelectable) ||
                                                                                    (assetUrlsMatchInputs &&
                                                                                        !isNonSelectable),
                                                                            }
                                                                        )}
                                                                        icon={
                                                                            (isActiveAsset &&
                                                                                !isNonSelectable) ||
                                                                            (assetUrlsMatchInputs &&
                                                                                !isNonSelectable) ? (
                                                                                <i className="fa fa-check-circle fa-lg fa-fw ef5-radio-selector__item-icon_is-active" />
                                                                            ) : (
                                                                                <i className="fa fa-circle-o fa-lg fa-fw ef5-radio-selector__item-icon_is-inactive" />
                                                                            )
                                                                        }
                                                                        text={''}
                                                                        disabled={false}
                                                                        onClick={() =>
                                                                            this.selectAssetAtIndex(
                                                                                asset.id
                                                                            )
                                                                        }
                                                                    />
                                                                    {isIncorrectSize &&
                                                                        !isAdaptiveVideo && (
                                                                            <InlineTooltip
                                                                                position="right"
                                                                                tooltip={
                                                                                    aspectRatioForValidationMessage
                                                                                        ? `Warning: asset does not match expected sizes in aspect ratio group (${aspectRatioForValidationMessage})`
                                                                                        : `Warning: asset does not match expected size (${expectedSize})`
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    <i className="fa fa-exclamation-triangle" />
                                                                                    &nbsp;
                                                                                </span>
                                                                            </InlineTooltip>
                                                                        )}
                                                                    {name}
                                                                </Cell>
                                                                <Cell>
                                                                    {type === 'video' ? (
                                                                        <VideoPreview
                                                                            height={50}
                                                                            type="video"
                                                                            poster={thumbnail_url}
                                                                            src={preview_url}
                                                                        />
                                                                    ) : (
                                                                        <ImagePreview
                                                                            height={50}
                                                                            type="image"
                                                                            src={preview_url}
                                                                        />
                                                                    )}
                                                                </Cell>
                                                                <Cell>{width}px</Cell>
                                                                <Cell>{height}px</Cell>
                                                                <Cell>{type}</Cell>
                                                                <Cell>{duration || ''}</Cell>
                                                            </Row>
                                                        );
                                                    })}
                                                </ComposableDataTable>
                                            </div>
                                        </div>
                                    )}
                                {this.props.draft.assetSelectionMethod === 'inputByUrl' &&
                                    method.value === 'inputByUrl' && (
                                        <React.Fragment>{inputByUrlContent}</React.Fragment>
                                    )}
                            </Box>
                        </React.Fragment>
                    ))}
                </RadioGroup>
                <Box />
            </div>
        );
    }
}

class InputByUrlStandard extends React.Component {
    render() {
        return (
            <div>
                <FormControl
                    fullWidth
                    margin="normal"
                    error={this.props.showErrors && this.props.errors.image_url}
                >
                    <Label>Banner URL</Label>
                    <CreativeAutoComplete
                        placeholder="Enter URL"
                        value={this.props.draft.image_url}
                        onChange={val => {
                            this.props.onUrlChange(val, 'image_url');
                            this.props.onUrlChange(val, 'image_preview_url');
                        }}
                    />
                    <FormHelperText>
                        Banner URL should start with 'http://', 'https://', or 'tel:'
                    </FormHelperText>
                </FormControl>
                <Spacer type="small" />
            </div>
        );
    }
}

class InputByUrlMraid extends React.Component {
    render() {
        return (
            <div>
                <FormControl
                    fullWidth
                    margin="normal"
                    error={this.props.showErrors && this.props.errors.reference_image_preview_url}
                >
                    <Label>Reference Screenshot</Label>
                    <CreativeAutoComplete
                        placeholder="Enter URL"
                        value={this.props.draft.reference_image_preview_url}
                        onChange={val => this.props.onUrlChange(val, 'reference_image_preview_url')}
                    />
                </FormControl>
            </div>
        );
    }
}

class InputByUrlVideo extends React.Component {
    render() {
        return (
            <div>
                <FormControl
                    fullWidth
                    margin="normal"
                    error={this.props.showErrors && this.props.errors.video_url}
                >
                    <Label>Video URL</Label>
                    <CreativeAutoComplete
                        placeholder="Enter URL"
                        value={this.props.draft.video_url}
                        onChange={val => {
                            this.props.onUrlChange(val, 'video_url');
                            this.props.onUrlChange(val, 'video_preview_url');
                        }}
                    />
                    <FormHelperText>
                        Video URL should start with 'http://', 'https://', or 'tel:
                    </FormHelperText>
                </FormControl>
                <FormControl
                    fullWidth
                    margin="normal"
                    error={this.props.showErrors && this.props.errors.image_preview_url}
                >
                    <Label>Reference Screenshot</Label>
                    <CreativeAutoComplete
                        placeholder="Enter URL"
                        value={this.props.draft.image_preview_url}
                        onChange={val => this.props.onUrlChange(val, 'image_preview_url')}
                    />
                    <FormHelperText>
                        Reference Screenshot should start with 'http://', 'https://', or 'tel:
                    </FormHelperText>
                </FormControl>
                <FormControl
                    fullWidth
                    margin="normal"
                    error={this.props.showErrors && this.props.errors.video_duration}
                >
                    <Label>Duration</Label>
                    <StandardInput
                        placeholder="Number of seconds"
                        formatIn={value => {
                            if (!value) {
                                return '';
                            } else {
                                return value.toString();
                            }
                        }}
                        formatOut={string => {
                            if (string === '') {
                                return void 0;
                            } else {
                                return parseInt(string);
                            }
                        }}
                        shouldAllowInput={input => {
                            // Allow empty input
                            if (!input) {
                                return true;
                            }
                            // Check if all digits
                            const isDigits = /^\d+$/.test(input);
                            if (isDigits) return true;
                            return false;
                        }}
                        onChange={this.props.onFieldChange('video_duration')}
                        value={this.props.draft.video_duration}
                    />
                    <FormHelperText>Duration must be between 1 and 30</FormHelperText>
                </FormControl>
                <FormControl
                    fullWidth
                    margin="normal"
                    error={this.props.showErrors && this.props.errors.video_bit_rate_kbps}
                >
                    <Label>Bit Rate (Kbps)</Label>
                    <StandardInput
                        placeholder="Kilobytes per second"
                        formatIn={value => {
                            if (!value) {
                                return '';
                            } else {
                                return value.toString();
                            }
                        }}
                        formatOut={string => {
                            if (string === '') {
                                return void 0;
                            } else {
                                return parseInt(string);
                            }
                        }}
                        shouldAllowInput={input => {
                            // Allow empty input
                            if (!input) {
                                return true;
                            }
                            // Check if all digits
                            const isDigits = /^\d+$/.test(input);
                            if (isDigits) return true;
                            return false;
                        }}
                        onChange={this.props.onFieldChange('video_bit_rate_kbps')}
                        value={this.props.draft.video_bit_rate_kbps}
                    />
                    <FormHelperText>Bit Rate must be a number</FormHelperText>
                </FormControl>
            </div>
        );
    }
}

class InputByUrlVideoCompanion extends React.Component {
    render() {
        return (
            <div>
                <FormControl
                    fullWidth
                    margin="normal"
                    error={this.props.showErrors && this.props.errors.companion_url}
                >
                    <Label>Companion Banner URL</Label>
                    <UrlStandardInput
                        value={this.props.draft.companion_url}
                        onChange={val => {
                            this.props.onUrlChange(val, 'companion_url');
                            this.props.onUrlChange(val, 'companion_preview_url');
                        }}
                    />
                    <FormHelperText>
                        Companion Banner URL should start with 'http://', 'https://', or 'tel:
                    </FormHelperText>
                </FormControl>
            </div>
        );
    }
}

class InputByUrlNativeIcon extends React.Component {
    render() {
        return (
            <div>
                <FormControl
                    fullWidth
                    margin="normal"
                    error={this.props.showErrors && this.props.errors.icon_image_url}
                >
                    <Label>Icon Image URL</Label>
                    <CreativeAutoComplete
                        placeholder="Enter URL"
                        value={this.props.draft.icon_image_url}
                        onChange={val => {
                            this.props.onUrlChange(val, 'icon_image_url');
                            this.props.onUrlChange(val, 'icon_image_preview_url');
                        }}
                    />
                </FormControl>
            </div>
        );
    }
}

class InputByUrlNativeMain extends React.Component {
    render() {
        return (
            <div>
                <FormControl
                    fullWidth
                    margin="normal"
                    error={this.props.showErrors && this.props.errors.main_image_url}
                >
                    <Label>Main Image URL</Label>
                    <CreativeAutoComplete
                        placeholder="Enter URL"
                        value={this.props.draft.main_image_url}
                        onChange={val => {
                            this.props.onUrlChange(val, 'main_image_url');
                            this.props.onUrlChange(val, 'main_image_preview_url');
                        }}
                    />
                </FormControl>
            </div>
        );
    }
}
