import _ from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';

import { VariableSizeGrid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { connect } from 'react-redux';

import { formatNumber_currency, formatNumber_whole } from 'utils/formatting';
import AudienceSegmentSummary from '../audience-segment-summary-v2';
import {
    calculateAudienceFee,
    calculateTacTicsThirdPartyFees,
    PlatformMapping,
} from 'states/resources/ads/business-logic';
import { BlockLoadGroup } from 'widgets-v5/load-group';

import actions from './actions';
import selector from './selector';
import { SearchInput } from 'widgets-v6/standard-input';

import LinearProgress from '@mui/material/LinearProgress';

import {
    AUDIENCE_CATEGORIES,
    SOURCES,
    SUPPORTED_AUDIENCES_PLATFORMS_PERCENTAGES,
} from './constants';
import { AUDIENCE_STATUS_MAPPING } from 'pages/presets/poi-editor/services/constants';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import {
    getAudienceWarning,
    getSuspendedSegmentsWarning,
} from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';

import PLATFORM_OPTIONS from 'common/constants/platforms';
const PLATFORMS = {};
_.each(PLATFORM_OPTIONS, option => (PLATFORMS[option.value] = option));

const getTierIcon = tier => {
    let tierIcon;

    if (tier === 'Top Tier Spender' || tier === 'Top Spending Geography') {
        tierIcon = 'fa fa-trophy';
    } else if (tier === 'Frequent Transactor' || tier === 'Frequently Transacting Geography') {
        tierIcon = 'fa fa-credit-card';
    } else if (tier === 'In Market' || tier === 'In Market Geography') {
        tierIcon = 'fa fa-shopping-cart';
    } else if (tier === 'Big Ticket Shopper') {
        tierIcon = 'fa fa-ticket';
    }

    return tierIcon;
};

export const categoryMap = {
    pds_brand: 'Precise Geolocation',
    pds_standard: 'Precise Geolocation',
    pds_environics: 'Lifestyle',
    pds_mastercard: 'Lifestyle',
    pds_behaviour: 'Lifestyle',
    pds_age: 'Demographics',
    pds_intent: 'Online Behavioural',
    pds_custom: 'Custom',
    ad: 'Custom',
    campaign: 'Custom',
    pixel: 'Custom',
    upload: 'Custom',
    adobe: 'DMP Integration',
    permutive: 'DMP Integration',
    flex_standard_brand: 'Flex Geolocation',
    flex_location_category: 'Flex Geolocation',
    flex_custom_brand: 'Custom Audiences',
    pds_manifold: 'Lifestyle',
};

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 80,
    },
    textColorPrimary: {
        color: 'black',
    },
    inputContainer: {
        margin: `${theme.spacing(2)} 0`,
        paddingRight: theme.spacing(2.5),
    },
    mainContent: {
        paddingRight: theme.spacing(2.5),
        height: '70%',
    },
    contentGridItem: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    summaryContainer: {
        padding: theme.spacing(2),
        height: '100%',
    },
    select: {
        height: '42px',
    },
    selectRoot: {
        padding: '12.5px 14px',
    },
}));

const PickerV2 = ({
    audienceSegmentOptions,
    toggleInclude,
    toggleExclude,
    includedItems,
    excludedItems,
    optionMapping,
    removeIncludedItem,
    removeExcludedItem,
    restrictedSegments,
    geo_targeting_settings,
    audienceWarning,
    orgId,
    isCTVCampaign,
    isDOOHCampaign,
    ...props
}) => {
    const [activeTabAndOptions, setTabAndOptions] = useState({
        tab: 'all',
        options: [],
        currentSourceType: 'all',
        third_party_fees: 0,
    });

    let availableCategories = _.cloneDeep(AUDIENCE_CATEGORIES);

    const typeOptions = _(availableCategories)
        .map(category => {
            if (category.subCategories) {
                return category.subCategories.map(subcategory => subcategory);
            }
        })
        .filter(option => option)
        .flatten()
        .value();

    const availableOptions = _.filter(
        typeOptions,
        option =>
            !option.shouldHide({
                restrictedSegments,
                shouldHideFlexSegments: props.shouldHideFlexSegments,
                platforms: props.platforms,
                platform: props.platform,
                isCrossPlatformCampaign: props.isCrossPlatformCampaign,
                orgId,
            })
    );

    const availableSegmentOptions = audienceSegmentOptions;

    useEffect(() => {
        props.dispatch(actions.init());
        setTabAndOptions({ ...activeTabAndOptions, options: availableOptions });
    }, []);

    const classes = useStyles();
    const audienceRatesMap = {};
    _.forEach(props.audienceRates, rate => {
        audienceRatesMap[rate.name] = rate.fee;
    });

    const onChangeTextFilterDebounced = useMemo(
        () => _.debounce(value => props.dispatch(actions.filterByText(value)), 200),
        []
    );

    const onChangeTextFilter = e => {
        const { value } = e.target;
        onChangeTextFilterDebounced(value);
    };

    const categoriesWithOptions = _.map(availableOptions, option => option.category);
    availableCategories = _.filter(
        availableCategories,
        category => category.value === 'all' || _.includes(categoriesWithOptions, category.value)
    );

    const onChangeSourceTypeFilter = e => {
        const { value } = e.target;
        setTabAndOptions({ ...activeTabAndOptions, currentSourceType: value });
        props.dispatch(actions.changeSourceTypeFilter(value));
    };
    const onTabChange = (event, value) => {
        const options =
            value === 'all'
                ? availableOptions
                : _(availableOptions)
                      .filter(option => option.category === value)
                      .flatten()
                      .value();
        setTabAndOptions({ tab: value, options, currentSourceType: 'all' });
        props.dispatch(actions.changeTab(value));
    };

    const categoryLayers = _.get(geo_targeting_settings, 'category_layers');
    const geoCategories = _.get(geo_targeting_settings, 'categories');
    const customLayers = _.get(geo_targeting_settings, 'custom_layers');

    const mappedGeoTargetingSettingsItems = _(categoryLayers)
        .concat(geoCategories, customLayers)
        .map(item => props.flexSegmentMapping[item])
        .filter(value => value)
        .value();
    const mappedAudienceItems = _.map(includedItems, value => optionMapping[value]).filter(audience => audience);
    const mergedIncludedItems = _.concat(
        includedItems,
        categoryLayers,
        geoCategories,
        customLayers
    ).filter(item => item);

    const allMappedSegments = _.concat(mappedAudienceItems, mappedGeoTargetingSettingsItems);
    let warningData = audienceWarning;

    let suspendedSegmentWarning = getSuspendedSegmentsWarning(allMappedSegments);

    if (props.isCrossPlatformCampaign) {
        warningData = getAudienceWarning({
            adPlatforms: props.platforms,
            allMappedSegments,
            isCTVCampaign,
            isDOOHCampaign,
            orgId,
        });
    }

    return (
        <BlockLoadGroup isLoading={props.isLoading}>
            <Grid container style={{ height: '100%' }}>
                <Grid item xs={8} className={classes.contentGridItem}>
                    <div>
                        <Tabs
                            onChange={onTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            value={activeTabAndOptions.tab}
                        >
                            {availableCategories.map(category => (
                                <Tab
                                    classes={{
                                        textColorPrimary: classes.textColorPrimary,
                                        root: classes.root,
                                    }}
                                    key={category.value}
                                    value={category.value}
                                    label={category.label}
                                />
                            ))}
                        </Tabs>
                    </div>
                    <div className={classes.inputContainer}>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    value={activeTabAndOptions.currentSourceType}
                                    className={classes.select}
                                    onChange={onChangeSourceTypeFilter}
                                    classes={{ root: classes.selectRoot }}
                                >
                                    <MenuItem value="all"> All Types </MenuItem>
                                    {activeTabAndOptions.options.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {' '}
                                            {option.label}{' '}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={9}>
                                <SearchInput
                                    placeholder="Filter Segments"
                                    onChange={onChangeTextFilter}
                                    key={`${activeTabAndOptions.currentSourceType}-${
                                        activeTabAndOptions.tab
                                    }`}
                                    defaultValue=""
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.mainContent}>
                        {props.isTableLoading && <LinearProgress />}
                        <AudiencePickerTable
                            options={availableSegmentOptions}
                            audienceRatesMap={audienceRatesMap}
                            includedItems={mergedIncludedItems}
                            excludedItems={excludedItems}
                            toggleInclude={toggleInclude}
                            toggleExclude={toggleExclude}
                            toggleIncludeFlexSegment={props.toggleIncludeFlexSegment}
                            activeFiltersAndIsLoading={`${activeTabAndOptions.tab}-${
                                activeTabAndOptions.currentSourceType
                            }-${props.isTableLoading}`}
                            platforms={props.platforms}
                            isCrossPlatformCampaign={props.isCrossPlatformCampaign}
                            isCTVCampaign={isCTVCampaign}
                            isDOOHCampaign={isDOOHCampaign}
                        />
                    </div>
                </Grid>

                <Grid item xs={4} className={classes.summaryContainer}>
                    <AudienceSegmentSummary
                        className="audience-segment-picker__summary"
                        includedItems={
                            !props.shouldHideFlexSegments
                                ? _.concat(mappedAudienceItems, mappedGeoTargetingSettingsItems)
                                : mappedAudienceItems
                        }
                        excludedItems={_.map(excludedItems, value => optionMapping[value])}
                        removeIncludedItem={removeIncludedItem}
                        removeExcludedItem={removeExcludedItem}
                        audienceRatesMap={audienceRatesMap}
                        footerLabel={({ includedItems, excludedItems }) => {
                            const audienceFee = calculateAudienceFee({
                                includedAudiences: includedItems,
                                excludedAudiences: excludedItems,
                                audienceRates: props.audienceRates,
                            });

                            return `Data Cost: ${formatNumber_currency(audienceFee)} CPM`;
                        }}
                        thirdPartyFees={({ includedItems, excludedItems }) => {
                            const thirdPartyFees = calculateTacTicsThirdPartyFees({
                                includedAudiences: includedItems,
                                excludedAudiences: excludedItems,
                            });
                            return `Third Party Fees: ${formatNumber_currency(thirdPartyFees)} CPM`;
                        }}
                        removeAllIncluded={props.removeAllIncluded}
                        removeAllExcluded={props.removeAllExcluded}
                        restrictedSegments={restrictedSegments}
                        removeFlexSegment={props.removeFlexSegment}
                        geo_targeting_settings={geo_targeting_settings}
                        changeLookbackWindow={props.changeLookbackWindow}
                        shouldHideFlexSegments={props.shouldHideFlexSegments}
                        handleChangeTargetingSetting={props.handleChangeTargetingSetting}
                    />
                    {props.isCrossPlatformCampaign && warningData.hasWarning && (
                        <React.Fragment>
                            <br />
                            <WarningText
                                title={warningData.title}
                                message={warningData.message}
                                severity={warningData.severity}
                            />
                        </React.Fragment>
                    )}
                    {suspendedSegmentWarning.hasWarning && (
                        <React.Fragment>
                            <br />
                            <WarningText
                                title={suspendedSegmentWarning.title}
                                message={suspendedSegmentWarning.message}
                                severity={suspendedSegmentWarning.severity}
                            />
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        </BlockLoadGroup>
    );
};
const nameColumnStyles = makeStyles(theme => ({
    chip: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(0.5),
    },
    tooltip: {
        fontSize: '11px',
    },
    icon: {
        marginLeft: theme.spacing(0.75),
    },
}));
const columns = [
    {
        key: 'name',
        label: 'Name',
        width: 415,
        widthMultiplier: 0.49,
        align: 'left',
        getData: ({ option, style }) => {
            const classes = nameColumnStyles();

            return (
                <TableCell style={style} component="div">
                    <Grid container spacing={1} wrap="nowrap">
                        <Grid item xs={option.source_type === 'pds_mastercard' ? 11 : 12}>
                            <Typography variant="body1" component="span">
                                {option.name + ' '}
                                {option.status === AUDIENCE_STATUS_MAPPING.suspended && (
                                    <Chip label={_.startCase(option.status)} size="small" />
                                )}
                            </Typography>
                        </Grid>
                        {option.source_type === 'pds_mastercard' &&
                            option.description &&
                            option.description.length > 0 && (
                                <Grid item xs={1}>
                                    <Tooltip
                                        classes={{ tooltip: classes.tooltip }}
                                        title={option.description}
                                    >
                                        <InfoOutlinedIcon
                                            classes={{ root: classes.icon }}
                                            fontSize="small"
                                        />
                                    </Tooltip>
                                </Grid>
                            )}
                    </Grid>
                    <Typography color="textSecondary">{option.source_type_category}</Typography>
                    {option.source_type !== 'pds_mastercard' && (
                        <Typography color="textSecondary" variant="body2" gutterBottom>
                            {option.description}
                        </Typography>
                    )}
                    {option.source_type === 'pds_mastercard' &&
                        option.tier &&
                        option.tier.length > 0 && (
                            <Chip
                                label={option.tier}
                                size="small"
                                className={classes.chip}
                                icon={
                                    <Icon fontSize="small" className={getTierIcon(option.tier)} />
                                }
                                variant="outlined"
                            />
                        )}
                </TableCell>
            );
        },
    },
    {
        key: 'audience_size',
        label: ({ isCTVCampaign, isDOOHCampaign }) => {
            if (isCTVCampaign) {
                return 'Uniques (Connected TV)';
            } else if (isDOOHCampaign) {
                return 'Unique Boards';
            }

            return 'Uniques';
        },
        width: 215,
        widthMultiplier: 0.17,
        align: 'right',
        getData: ({ option, style, isCTVCampaign, isDOOHCampaign }) => {
            let uniques = null;
            if (isCTVCampaign) {
                if (option.supports.includes(PlatformMapping.CTV)) {
                    uniques = option.uniqueCTVs;
                }
            } else if (!isDOOHCampaign) {
                uniques = option.uniques;
            } else {
                uniques = option.uniqueDOOHs;
            }

            return (
                <TableCell style={style} align="right" component="div">
                    {uniques ? formatNumber_whole(uniques) : 'N/A'}
                </TableCell>
            );
        },
    },
    {
        key: 'price',
        label: 'Price',
        width: 142,
        widthMultiplier: 0.16,
        align: 'right',
        getData: ({ option, style, audienceRatesMap }) => {
            return (
                <TableCell style={style} align="right" component="div">
                    {formatNumber_currency(audienceRatesMap[option.source_type])}
                </TableCell>
            );
        },
    },
    {
        key: 'actions',
        width: 175,
        widthMultiplier: 0.17,
        getData: ({
            option,
            style,
            includedItems,
            excludedItems,
            toggleInclude,
            toggleExclude,
            toggleIncludeFlexSegment,
            platforms,
            isCrossPlatformCampaign,
            isCTVCampaign,
            isDOOHCampaign,
        }) => {
            const classes = nameColumnStyles();

            const segmentPlatforms = option.supports;

            const unsupportedPlatforms = _.filter(
                platforms,
                platform => !_.includes(segmentPlatforms, platform)
            );

            let dominantSource;

            if (isCTVCampaign) {
                dominantSource = 'uniqueCTVs';
            } else if (isDOOHCampaign) {
                dominantSource = 'uniqueDOOHs';
            } else {
                dominantSource = SUPPORTED_AUDIENCES_PLATFORMS_PERCENTAGES[
                    option.source_type
                ].getDominantSource({ segment: option });
            }

            let showWarning = false;

            if (
                (_.includes(platforms, 'mweb') || _.includes(platforms, 'desktop')) &&
                dominantSource === SOURCES.devices
            ) {
                showWarning = true;
            }
            if (_.includes(platforms, 'inapp') && dominantSource === SOURCES.cookies) {
                showWarning = true;
            }
            const actionStyles = { ...style, paddingTop: 0 };
            const flexSegmentTypes = [
                'flex_standard_brand',
                'flex_custom_brand',
                'flex_location_category',
            ];
            const tooltipMessage = `This segment is best used in ${
                dominantSource === SOURCES.devices ? 'an app-based' : 'a web-based'
            } environment`;
            if (flexSegmentTypes.includes(option.source_type)) {
                return (
                    <TableCell style={actionStyles} align="right" component="div">
                        {(showWarning || unsupportedPlatforms.length > 0) &&
                        isCrossPlatformCampaign ? (
                            <Tooltip title={tooltipMessage} classes={{ tooltip: classes.tooltip }}>
                                <IconButton
                                    onClick={() =>
                                        toggleIncludeFlexSegment({
                                            id: option.id,
                                            source_type: option.source_type,
                                        })
                                    }
                                    size="large"
                                >
                                    <CheckIcon
                                        color={
                                            includedItems.includes(option.value)
                                                ? 'primary'
                                                : 'inherit'
                                        }
                                    />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <IconButton
                                onClick={() =>
                                    toggleIncludeFlexSegment({
                                        id: option.id,
                                        source_type: option.source_type,
                                    })
                                }
                                size="large"
                            >
                                <CheckIcon
                                    color={
                                        includedItems.includes(option.value) ? 'primary' : 'inherit'
                                    }
                                />
                            </IconButton>
                        )}
                    </TableCell>
                );
            }

            const audienceIsSuspended = option.status === AUDIENCE_STATUS_MAPPING.suspended;

            const shouldContactCES = audienceIsSuspended && option.contact_ces;

            let actionSection = null;
            if (!shouldContactCES) {
                actionSection = (
                    <React.Fragment>
                        <IconButton onClick={() => toggleExclude(option)} size="large">
                            <BlockIcon
                                color={
                                    excludedItems && excludedItems.includes(option.value)
                                        ? 'secondary'
                                        : 'inherit'
                                }
                            />
                        </IconButton>
                        {(showWarning || unsupportedPlatforms.length > 0) &&
                        isCrossPlatformCampaign ? (
                            <Tooltip title={tooltipMessage} classes={{ tooltip: classes.tooltip }}>
                                <IconButton onClick={() => toggleInclude(option)} size="large">
                                    <CheckIcon
                                        color={
                                            includedItems.includes(option.value)
                                                ? 'primary'
                                                : 'inherit'
                                        }
                                    />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <IconButton onClick={() => toggleInclude(option)} size="large">
                                <CheckIcon
                                    color={
                                        includedItems.includes(option.value) ? 'primary' : 'inherit'
                                    }
                                />
                            </IconButton>
                        )}
                    </React.Fragment>
                );
            } else {
                actionSection = (
                    <Box display="flex" alignItems="center" height="100%">
                        Please, contact your CES.
                    </Box>
                );
            }

            return (
                <TableCell style={actionStyles} align="right" component="div">
                    {actionSection}
                </TableCell>
            );
        },
    },
];

const getHeight = ({ option }) => {
    let height = 90;
    if (!_.isNil(option.tier) && option.tier.length > 0) {
        height += 30;
    }
    if (
        option.description &&
        option.description.length > 0 &&
        option.source_type !== 'pds_mastercard'
    ) {
        height += 15;
    }
    return height;
};

const AudiencePickerTable = ({
    options,
    audienceRatesMap,
    includedItems,
    excludedItems,
    toggleInclude,
    toggleExclude,
    activeFiltersAndIsLoading,
    toggleIncludeFlexSegment,
    platforms,
    isCrossPlatformCampaign,
    isCTVCampaign,
    isDOOHCampaign,
}) => {
    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        {_.map(columns, column => {
                            const label = _.get(column, 'label', '');
                            return (
                                <TableCell
                                    key={column.key}
                                    align={_.get(column, 'align')}
                                    style={{ width: column.width }}
                                >
                                    {_.isFunction(label)
                                        ? label({ isCTVCampaign, isDOOHCampaign })
                                        : label}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
            </Table>
            <AutoSizer>
                {({ width }) => {
                    return (
                        <VirtualizedTable
                            width={width}
                            options={options}
                            audienceRatesMap={audienceRatesMap}
                            includedItems={includedItems}
                            excludedItems={excludedItems}
                            toggleInclude={toggleInclude}
                            toggleExclude={toggleExclude}
                            activeFiltersAndIsLoading={activeFiltersAndIsLoading}
                            toggleIncludeFlexSegment={toggleIncludeFlexSegment}
                            platforms={platforms}
                            isCrossPlatformCampaign={isCrossPlatformCampaign}
                            isCTVCampaign={isCTVCampaign}
                            isDOOHCampaign={isDOOHCampaign}
                        />
                    );
                }}
            </AutoSizer>
        </div>
    );
};

const VirtualizedTable = ({
    width,
    options,
    audienceRatesMap,
    includedItems,
    excludedItems,
    toggleInclude,
    toggleExclude,
    activeFiltersAndIsLoading,
    toggleIncludeFlexSegment,
    platforms,
    isCrossPlatformCampaign,
    isCTVCampaign,
    isDOOHCampaign,
}) => {
    return (
        <VariableSizeGrid
            key={`${activeFiltersAndIsLoading}-${width}`}
            columnCount={columns.length}
            columnWidth={index => {
                return columns[index].widthMultiplier * width;
            }}
            height={430}
            rowCount={options.length}
            rowHeight={index => {
                return getHeight({ option: options[index] });
            }}
            width={width}
        >
            {props => {
                const row = options[props.rowIndex];
                const column = columns[props.columnIndex];
                return column.getData({
                    option: row,
                    style: props.style,
                    audienceRatesMap,
                    includedItems,
                    excludedItems,
                    toggleInclude,
                    toggleExclude,
                    toggleIncludeFlexSegment,
                    platforms,
                    isCrossPlatformCampaign,
                    isCTVCampaign,
                    isDOOHCampaign,
                });
            }}
        </VariableSizeGrid>
    );
};

export default connect(selector)(PickerV2);
