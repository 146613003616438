import _ from 'lodash';
import numeral from 'numeral';
import React, { useEffect, useReducer } from 'react';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import 'moment-timezone';
import { connect } from 'react-redux';
import { getEnvironmentSettings } from 'services/environment';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import selector from './selector';
import { labelExchange, formatDate_formSelect } from 'utils/formatting';
import { IAS_GROUPM_VIEWABILITY_MAPPING } from 'common/constants/ias-groupm-viewability';
import { IAS_CONTEXTUAL_TARGETING_MAPPING } from 'common/constants/ias-contextual-targeting';
import { IAS_PUBLICIS_VIEWABILITY_MAPPING } from 'common/constants/ias-publicis-viewability';
import {
    IAS_BRAND_SAFETY_CATEGORIES,
    IAS_BRAND_SAFETY_OPTIONS,
} from 'common/constants/ias-brand-safety';
import { IAS_FRAUD_PREVENTION_RISK_OPTIONS } from 'common/constants/ias-fraud-prevention';
import { IAS_VIEWABILITY_OPTIONS } from 'common/constants/ias-viewability';
import PLATFORMS from 'common/constants/platforms';
import LANGUAGE_CODES from 'common/constants/language-codes';
import APP_STORE_CATEGORIES from 'common/constants/app-store-categories';
import APPCATEGORY_TARGETING from 'common/constants/app-category-targeting';
import IABCATEGORY_TARGETING from 'common/constants/iab-categories';
import CARRIER_TARGETING from 'common/constants/carrier-targeting';
import {
    hasWeatherConditions,
    getConditionLabel,
    getQualifierLabel,
    inputAdornmentMap,
} from 'common/constants/weather-condition-targeting';
import {
    getTargetCarriersInclude,
    getTargetCarriersExclude,
} from 'common/constants/carrier-targeting-v2';
import Profile from 'states/profile';
import {
    getBillableVolume,
    getMaxCpmRate,
    calculateFirstPartyDataFee,
    calculateMediaCost,
    calculatePlatformTechFee,
    calculateBidPrice,
    isTacticsEnabled,
    CreativeRotationMethodMapping,
    BiddingStrategyMode,
    DeviceOSMapping,
} from 'states/resources/ads/business-logic';
import { getTechFee } from 'states/resources/organizations/business-logic';
import { calculateThirdPartyFees as calculateAdThirdPartyFees } from 'states/resources/ads/business-logic';
import {
    formatNumber_currency,
    formatNumber_percentage,
    formatNumber_whole,
    formatNumber_wholeFixed,
} from 'utils/formatting';
import { can } from 'services/can';
import { getPOI } from './get-poi';
import { createHttp } from 'utils/http';
import toastr from 'toastr';

import { SummaryLayout, SummaryGroup } from 'widgets/summary-view/summary-layout';
import SummaryTypeText from 'widgets/summary-view/summary-type-text';
import SummaryTypeComponent from 'widgets/summary-view/summary-type-component';
import SummaryTypeTextCollection from 'widgets/summary-view/summary-type-text-collection';
import SummaryTypeCreative from 'widgets/summary-view/summary-type-creative';
import SummaryTypeGeoTargeting from 'widgets/summary-view/summary-type-geo-targeting';
import { IncludeAndExcludeSummary } from 'widgets-v5/include-and-exclude';
import AudienceOptions from 'containers/audience-options';
import Separator from 'widgets-v5/separator';
import LogicalDivider from 'widgets-v6/logical-divider';
import Spacer from 'widgets-v5/spacer';

import { ApplistSummary } from 'forms/ad-form/modules/target-applists';
import { doohVenueTranslate } from 'common/constants/dooh-venue-types';
import { DoohVenueTypeSummary } from 'forms/ad-form/modules/target-dooh-venues';
import { useAdSummary } from './v2';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import { ALL_DEVICE_OPTIONS } from 'forms/ad-form/services/default-values';
import { PermissionsMapping } from 'common/constants/role-intents';

import flags from 'containers/flags/service';

const http = createHttp();

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const PLATFORM_MAPPING = {};
_.each(PLATFORMS, platform => (PLATFORM_MAPPING[platform.value] = platform));

const IAS_FRAUD_PREVENTION_RISK_OPTIONS_MAPPING = {};
_.each(IAS_FRAUD_PREVENTION_RISK_OPTIONS, option => {
    IAS_FRAUD_PREVENTION_RISK_OPTIONS_MAPPING[option.value] = option;
});

const IAS_VIEWABILITY_OPTIONS_MAPPING = {};
_.each(IAS_VIEWABILITY_OPTIONS, option => {
    IAS_VIEWABILITY_OPTIONS_MAPPING[option.value] = option;
});

function getIasBrandSafetyRiskLabels(formData) {
    const labels = _.map(IAS_BRAND_SAFETY_CATEGORIES, category => {
        const value = _.get(formData, `ias.brandSafety.${category.value}.value`);
        const option = _.find(IAS_BRAND_SAFETY_OPTIONS, { value });

        let selectionLabel;
        if (option) {
            selectionLabel = option.label;
        }

        return `${category.label}: ${selectionLabel}`;
    }).concat(
        formData.ias.brandSafety.unrateable.value === 1
            ? 'Exclude unrated content: On'
            : 'Exclude unrated content: Off'
    );

    return labels;
}
const TitleContainer = withStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1),
    },
}))(Grid);

const InvetoryIconContainer = withStyles(() => ({
    root: {
        paddingRight: '9px',
        flexBasis: '4.33%',
    },
}))(Grid);

const getIcon = (iconType, color) => {
    return iconType === 'check' ? (
        <CheckIcon fontSize="small" color={color} />
    ) : (
        <BlockIcon fontSize="small" color={color} />
    );
};

export const labelWeatherConditionQualifier = qualifier => {
    if (qualifier === '') {
        return '';
    }
    return getQualifierLabel(qualifier);
};

export const labelWeatherConditionValue = (value, type) => {
    if (value === -1) {
        return '';
    }
    return `${value} ${inputAdornmentMap[type]}`;
};

export const labelWeatherConditionTimeframe = value => {
    switch (value) {
        case 'next_14d':
            return 'Next 14 days';
        case 'next_36h':
            return 'Next 36 hours';
        case 'now':
            return 'Now';
        case 'next_6d':
            return 'Next 6 Days';
        case 'next_3d':
            return 'Next 3 Days';
        default:
            return '';
    }
};

const MultiSelectSummary = ({ color, title, items, iconType }) => {
    return (
        <React.Fragment>
            <Grid item>
                <TitleContainer container alignItems="center">
                    <InvetoryIconContainer item>{getIcon(iconType, color)}</InvetoryIconContainer>
                    <Grid item>{title}</Grid>
                </TitleContainer>
                <Grid container alignItems="center" wrap="nowrap">
                    <InvetoryIconContainer item />
                    <Grid item>{items}</Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export const getAgeGroups = values => {
    // Reuse code from v2
    function _formatAgeGroup(val) {
        if (!val) return '';

        let out;
        if (val.length === 0 || val.length === 7) {
            out = ['Everyone'];
        } else {
            var displayTextArray = [];
            _.forEach(val, function(item) {
                switch (item) {
                    case 2:
                        displayTextArray.push('19-24');
                        break;
                    case 3:
                        displayTextArray.push('25-34');
                        break;
                    case 4:
                        displayTextArray.push('35+');
                        break;
                    case 5:
                        displayTextArray.push('35-44');
                        break;
                    case 6:
                        displayTextArray.push('45-54');
                        break;
                    case 7:
                        displayTextArray.push('55-64');
                        break;
                    case 8:
                        displayTextArray.push('65+');
                        break;
                    case -1:
                        displayTextArray.push('Unknown');
                        break;
                    default:
                        throw new Error(
                            'Age group array is not empthy and contains unregonize value'
                        );
                }
                out = displayTextArray;
            });
        } // End else
        return out;
    }

    return _formatAgeGroup(values);
};

export const getGenders = values => {
    // Reuse code from v2
    function _formatTargetGenders(val) {
        if (_.isArray(val)) {
            if (val.length === 0 || val.length === 3) {
                return 'Everyone';
            } else {
                var displayTextArray = [];
                _.forEach(val, function(item) {
                    if (item === 'M') {
                        displayTextArray.push('Male');
                    } else if (item === 'F') {
                        displayTextArray.push('Female');
                    } else if (item === 'D') {
                        displayTextArray.push('Unknown');
                    } else {
                        throw new Error('Gender array is not empthy and contains unregonize value');
                    }
                });
                return displayTextArray.join(', ');
            } // End else
        }
    }

    return _formatTargetGenders(values);
};
export const getExchangesBlocked = values => {
    if (!values || values.length === 0) {
        return 'None';
    }

    return values
        .map(value => labelExchange(value))
        .sort()
        .join(', ');
};

export const getDeviceOs = values => {
    function _formatDeviceOs(val) {
        var displayTextArray = [];
        if (_.isArray(val)) {
            if (val.length === 0) {
                displayTextArray = [
                    DeviceOSMapping.IOS,
                    DeviceOSMapping.Android,
                    DeviceOSMapping.BlackBerry,
                ];
                return displayTextArray.join(', ');
            } else {
                return val
                    .map(current => {
                        const item = ALL_DEVICE_OPTIONS.find(option => current === option.value);
                        // Get the label or keep the original value if the label is not defined
                        return item?.label ?? current;
                    })
                    .join(', ');
            }
        }
    }
    return _formatDeviceOs(values);
};

export const getPattisonBillboards = values => {
    return values.join(', ');
};

export const getDayPart = (dayparts, timezone, adStart) => {
    if (dayparts.value.length < 1) return ['Full Day'];

    const formattedTimezone = moment(adStart)
        .tz(timezone)
        .format('z');
    const dateAsYearMonthDay = adStart.slice(0, 10);

    const createOutputString = (start, end) =>
        `${start.hour}:${start.minute} ~ ${end.hour}:${end.minute} ${
            dayparts.field === 'dayparts' ? formattedTimezone : 'Local Time'
        }`;

    function createMergedTimeranges(timeranges) {
        function getSelectedTimeBlocks(_dayparts, _timezone) {
            const selectedTimeblocks = [];
            selectedTimeblocks.length = 96;
            _.fill(selectedTimeblocks, false);

            _dayparts.forEach(daypart => {
                const startMoment = moment
                    .utc(`${dateAsYearMonthDay}T${daypart.start}Z`)
                    .tz(_timezone);
                const endMoment = moment.utc(`${dateAsYearMonthDay}T${daypart.end}Z`).tz(_timezone);

                const startIndex =
                    startMoment.hours() * 4 +
                    (startMoment.minutes() >= 15 ? 1 : 0) +
                    (startMoment.minutes() >= 30 ? 1 : 0) +
                    (startMoment.minutes() >= 45 ? 1 : 0);

                const endIndex =
                    endMoment.hours() * 4 +
                    (endMoment.minutes() >= 15 ? 1 : 0) +
                    (endMoment.minutes() >= 30 ? 1 : 0) +
                    (endMoment.minutes() >= 45 ? 1 : 0);

                _.fill(selectedTimeblocks, true, startIndex, endIndex + 1);
            });
            return selectedTimeblocks;
        }

        function getContinuousTrueValues(array) {
            const startEndPairs = [];
            let trueStartIndex = null;
            let inTrueRange = false;
            array.forEach((value, index) => {
                if (value && !inTrueRange) {
                    trueStartIndex = index;
                    inTrueRange = true;
                }
                if (!value && inTrueRange) {
                    startEndPairs.push({
                        start: trueStartIndex,
                        end: index - 1,
                    });
                    trueStartIndex = null;
                    inTrueRange = false;
                }
                // If last item
                if (index === array.length - 1 && inTrueRange) {
                    startEndPairs.push({
                        start: trueStartIndex,
                        end: index,
                    });
                }
            });
            return startEndPairs;
        }

        function createTimeBlocks() {
            const timeblocks = [];
            for (let i = 0; i < 24; i++) {
                const blocks = [
                    {
                        label: `${i}`,
                        start: { hour: i, minute: '00', second: '00' },
                        end: { hour: i, minute: '14', second: '59' },
                    },
                    {
                        label: '',
                        start: { hour: i, minute: '15', second: '00' },
                        end: { hour: i, minute: '29', second: '59' },
                    },
                    {
                        label: '',
                        start: { hour: i, minute: '30', second: '00' },
                        end: { hour: i, minute: '44', second: '59' },
                    },
                    {
                        label: '',
                        start: { hour: i, minute: '45', second: '00' },
                        end: { hour: i, minute: '59', second: '59' },
                    },
                ];
                timeblocks.push(...blocks);
            }
            return timeblocks;
        }

        function make2Digits(n) {
            n.hour = n.hour > 9 ? '' + n.hour : '0' + n.hour;
            return n;
        }

        const selectedTimeblocks = getSelectedTimeBlocks(timeranges, timezone);
        const startEndIndexes = getContinuousTrueValues(selectedTimeblocks);
        const timeBlocksWithTimes = createTimeBlocks();

        const startEndBlocks = startEndIndexes.map(({ start: startIndex, end: endIndex }) => ({
            start: make2Digits(timeBlocksWithTimes[startIndex].start),
            end: make2Digits(timeBlocksWithTimes[endIndex].end),
        }));

        return startEndBlocks;
    }

    const mergedTimeranges = createMergedTimeranges(dayparts.value);
    const formattedRanges = _.map(mergedTimeranges, item =>
        createOutputString(item.start, item.end)
    );
    return formattedRanges;
};

export const getWeekparting = weekparts => {
    function formatWeekparts(obj) {
        var names = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        return _.compact(
            _.map(obj, function(bool, idx) {
                return bool ? names[idx] : '';
            })
        ).join(', ');
    }

    return [
        weekparts.field === 'weekparts' ? '(UTC)' : '(Local Time)',
        formatWeekparts(weekparts.value),
    ];
};

const joinLanguages = (obj, includeUnknown) => {
    const newDictLang = includeUnknown
        ? {
              NA: 'Unknown',
              ...LANGUAGE_CODES,
          }
        : LANGUAGE_CODES;

    return _.map(obj, item => newDictLang[item]).join(', ');
};

export const getDeviceLanguage = (obj, includeUnknown = false) => {
    if (_.isArray(obj) && obj.length === 0) {
        return 'All';
    } else if (_.isArray(obj)) {
        return joinLanguages(obj, includeUnknown);
    } else {
        return '';
    }
};

export const getDeviceLanguageExclude = (obj, includeUnknown = false) => {
    return _.isArray(obj) ? joinLanguages(obj, includeUnknown) : '';
};

export const getAppCat = values => {
    return _.isArray(values)
        ? _.map(values, function(i) {
              return APPCATEGORY_TARGETING[i];
          }).join(', ')
        : '';
};

export const getApplists = (listIds, applists) => {
    const out = [];
    if (_.isArray(listIds)) {
        _.each(listIds, listId => {
            const applist = _.find(applists, { id: listId });
            if (applist) {
                out.push(applist.name);
            }
        });
    }
    return out;
};

export const getIABCat = values => {
    return _.isArray(values)
        ? _.map(values, function(i) {
              return IABCATEGORY_TARGETING[i];
          }).join(', ')
        : '';
};

export const getGeofencing = obj => {
    if (!obj) return [];

    return obj.map(function(box) {
        var shorter = {};
        _.each(box, function(val, key) {
            if (key !== 'type') {
                shorter[key] = val.toFixed(2);
            }
        });
        return `E: ${shorter.E}, W: ${shorter.W}, S: ${shorter.S}, N: ${shorter.N}`;
    });
};

export const getTargetCarriers = obj => {
    if (_.isArray(obj) && obj.length === 0) {
        return 'All';
    } else if (_.isArray(obj)) {
        return _.map(obj, i => CARRIER_TARGETING[i]).join(', ');
    } else {
        return '';
    }
};

const getConversionMapping = conversions => {
    const mapping = {};
    _.each(conversions, ({ event_name, reporting_name }) => (mapping[event_name] = reporting_name));
    return mapping;
};

export const getConversionsForCreativeOptimization = (obj, conversions) => {
    if (_.isArray(obj) && obj.length === 0) {
        return 'All events';
    } else if (_.isArray(obj)) {
        const mapping = getConversionMapping(conversions);
        return _.map(obj, i => mapping[i]).join(', ');
    } else {
        return '';
    }
};

export function getMaxUserFrequency(value) {
    if (value === 0) {
        return 'Unlimited';
    }

    return value;
}

export function getFlexibleFrequency(config) {
    if (config.imp === null) {
        return 'Unlimited';
    }

    if (config.interval_unit === 'ad') {
        return `${config.imp} Impressions Over the Lifetime of the Ad`;
    }

    const intervalUnitsForDisplay = {
        days: 'Day',
        hours: 'Hour',
        weeks: 'Week',
    };

    return `${config.imp} Impressions Per ${config.interval_count} ${
        intervalUnitsForDisplay[config.interval_unit]
    }(s)`;
}

const adSummaryContainerReducer = (state, action) => {
    switch (action.type) {
        case 'AD_SUMMARY__ORGANIZATION__FETCH__SUCCESS':
            const { organization, deals, ftaLocationLists, applists } = action.payload;

            const locationListMapping = {};
            _.each(ftaLocationLists, locationList => {
                locationListMapping[locationList.id] = locationList.name;
            });

            return {
                ...state,
                organization,
                deals,
                locationListMapping,
                isLoading: false,
                applists,
            };

        case 'AD_SUMMARY__ORGANIZATION__FETCH__FAIL':
            return {
                ...state,
                organization: null,
                deals: [],
                locationListMapping: {},
                loading: false,
            };
    }
};

const AdSummaryContainer = props => {
    const { adId } = props;
    const { ad, init } = useAdSummary();

    const [state, dispatch] = useReducer(adSummaryContainerReducer, {
        organization: null,
        deals: [],
        isLoading: true,
        locationListMapping: {},
        applists: [],
    });

    const fetchData = async () => {
        const { organizationId } = props;
        const query = `
            query getOrganization ($organizationId: String, $adIds: [Int]) {
                organization(id: $organizationId) {
                    tech_fee
                    fta_partner_id
                    isIasPostBidVerificationEnabled
                }
                deals(filter: { adIds: $adIds }) {
                    id
                    app_name
                }
                ftaLocationLists {
                    id
                    name
                }
                applists(filters: {}) {
                    id
                    name
                }
            }
        `;

        const variables = {
            organizationId,
            adIds: props.adId ? [Number(props.adId)] : [],
        };

        try {
            const data = await http.graphql(query, variables);

            dispatch({
                type: 'AD_SUMMARY__ORGANIZATION__FETCH__SUCCESS',
                payload: {
                    ...data,
                },
            });
        } catch (error) {
            dispatch({ type: 'AD_SUMMARY__ORGANIZATION__FETCH__FAIL' });

            toastr.warning(
                '',
                '<p>Something went wrong, please try again later. The EngageFront team has been notified</p>',
                TOASTR_OPTIONS
            );

            if (window.bugsnagClient) {
                window.bugsnagClient.notify(`Failed to fetch organization data in ad summary`, {
                    metaData: {
                        id: `${organizationId}`,
                        adId: `${props.adId}`,
                        campaignId: `${props.campaign.id}`,
                    },
                });
            }
        }
    };

    useEffect(() => {
        init(adId);
        fetchData();
    }, []);

    if (!ad || state.isLoading) {
        return <div>loading...</div>;
    }

    return <AdSummary {...props} {...state} />;
};

class AdSummary extends React.Component {
    componentDidMount() {
        if (this.props.onDoneLoading) {
            this.props.onDoneLoading();
        }
    }

    getAudienceSegments = audiences => {
        const { audienceResources } = this.props;

        const out = _(audiences)
            .map(audienceId => audienceResources[audienceId])
            .filter(audience => !!audience)
            .map(audience => audience.attr.name)
            .join(', ');

        return out;
    };

    getGoalKey = () => {
        const { formData } = this.props;

        const mapping = {
            CPM: 'Total Impressions',
            CPC: 'Total Clicks',
            billable_media_cost_markup: 'Total Cost',
        };

        return mapping[formData.ef_billing_terms];
    };

    getGoalType = () => {
        const { formData } = this.props;

        const mapping = {
            CPM: 'Impressions',
            CPC: 'Clicks',
            billable_media_cost_markup: 'Total Cost',
        };

        return mapping[formData.ef_billing_terms];
    };

    getGoalValue = () => {
        const { formData } = this.props;
        const nonBillableVolume = Math.max(0, formData.non_billable_volume);

        switch (formData.ef_billing_terms) {
            case 'CPM':
                return formatNumber_whole(nonBillableVolume);
            case 'CPC':
                return formatNumber_whole(nonBillableVolume);
            case 'billable_media_cost_markup':
                return formatNumber_currency(nonBillableVolume);
        }
    };

    renderTargetCarrierSection = () => {
        const { formData, campaign } = this.props;

        if (campaign.carriersISPsVersion === 1) {
            return (
                <SummaryTypeText
                    keyName="Target Carrier"
                    value={getTargetCarriers(formData.target_carriers)}
                />
            );
        } else {
            const carrierISPIncludeValue = getTargetCarriersInclude(formData.target_carriers);
            const carrierISPExcludeValue = getTargetCarriersExclude(formData.carriers_exclude);
            return (
                <SummaryTypeComponent
                    keyName="Carrier / ISP"
                    value={
                        <Grid container spacing={2} direction="column">
                            <MultiSelectSummary
                                color="primary"
                                title="Include"
                                items={carrierISPIncludeValue}
                                iconType="check"
                            />
                            <MultiSelectSummary
                                color="secondary"
                                title="Exclude"
                                items={carrierISPExcludeValue}
                                iconType="block"
                            />
                        </Grid>
                    }
                />
            );
        }
    };

    getPacingValue = term => {
        const { formData } = this.props;

        const termMapping = {
            CPM: 'max_daily_impressions',
            CPC: 'max_daily_clicks',
            billable_media_cost_markup: 'max_daily_spend_local',
            billable_media_cost_margin: 'max_daily_billings',
        };

        if (!formData[termMapping[term]]) {
            return 'Auto';
        }
        switch (term) {
            case 'CPM':
                return formatNumber_whole(formData.max_daily_impressions);
            case 'CPC':
                return formatNumber_whole(formData.max_daily_clicks);
            case 'billable_media_cost_markup':
                return formatNumber_currency(formData.max_daily_spend_local);
            case 'billable_media_cost_margin':
                return formatNumber_currency(formData.max_daily_billings);
        }
    };

    getPacingStrategyValue = () => {
        const { formData } = this.props;
        const { use_front_load_pacing } = formData;

        const mapping = {
            CPM: 'Impressions',
            CPC: 'Clicks',
            billable_media_cost_markup: 'Total Cost',
        };
        const goalType = mapping[formData.ef_billing_terms];
        return use_front_load_pacing
            ? 'Front-loaded Pacing'
            : `${_.isNil(goalType) ? `Even Pacing` : `Even Pacing: ${goalType}`}`;
    };

    render() {
        const {
            creativesByCampaign,
            formData,
            campaignBillingEnabled,
            campaign,
            poiCategories,
            poiSegments,
            geoTargetingSubCategories,
            geoLayers,
            organization,
            locationListMapping,
            applists,
            actions,
            organizationId,
        } = this.props;

        const environmentSettings = getEnvironmentSettings();

        let currencyLabel = '';

        if (environmentSettings.showCurrencyLabel()) {
            currencyLabel = campaign.currency || 'USD';
        }

        const getViewabilityValues = formData => {
            const options = environmentSettings.getViewabilityOptions();
            const valuesMapping = {
                viewability: `Viewability: ${
                    IAS_VIEWABILITY_OPTIONS_MAPPING[formData.ias.viewability.value].label
                }`,
                groupM: `GroupM Performance Matching: ${
                    IAS_GROUPM_VIEWABILITY_MAPPING[formData.ias.groupmViewability.value]
                }`,
                publicis: `Publicis Performance Matching: ${
                    IAS_PUBLICIS_VIEWABILITY_MAPPING[formData.ias.publicisViewability.value]
                }`,
            };
            return options.map(option => valuesMapping[option]);
        };

        const isProspectMedia = organizationId === '61ba37fb834aef8e532e0687';

        const creativeId = _.map(formData.creative, creative => creative);

        const weekpartValue = {
            field: _.isArray(formData.weekparts) ? 'weekparts' : 'weekparts_local',
            value: _.isArray(formData.weekparts) ? formData.weekparts : formData.weekparts_local,
        };

        const daypartValue = {
            field: _.isArray(formData.dayparts) ? 'dayparts' : 'dayparts_local',
            value: _.isArray(formData.dayparts) ? formData.dayparts : formData.dayparts_local,
        };

        const billingEnabled = formData.billing_enabled && campaignBillingEnabled;
        const billableVolume = Math.max(0, formData.billable_volume);

        let bidPrice,
            platformTechFee,
            mediaCost,
            firstPartyFees,
            thirdPartyFees,
            maxCpmRate,
            techFee;
        techFee = getTechFee(organization);

        firstPartyFees = calculateFirstPartyDataFee(formData);

        thirdPartyFees = calculateAdThirdPartyFees(formData);

        maxCpmRate = getMaxCpmRate(formData);

        mediaCost = calculateMediaCost({
            maxCpmRate,
            thirdPartyFees,
            firstPartyFees,
        });

        platformTechFee = calculatePlatformTechFee({
            mediaCost,
            techFee,
        });

        bidPrice = calculateBidPrice({
            mediaCost,
            platformTechFee,
        });

        const showPlatformRestrictedFields = campaign.isCrossPlatformCampaign
            ? true
            : formData.platform === 'inapp';

        const isCTVCampaign = campaign.type === CampaignTypeMapping.CTV;
        const isDOOHCampaign = campaign.type === CampaignTypeMapping.DOOH;

        const showCarrierSection =
            campaign.carriersISPsVersion === 2 ||
            (campaign.carriersISPsVersion === 1 && showPlatformRestrictedFields);

        const showConversions =
            formData.rotation_rules.mode === CreativeRotationMethodMapping.OptimizedForConversions;
        const conversionsInCampaign = _.concat(
            campaign.conversions,
            campaign.advertiserConversions
        );

        return (
            <SummaryLayout title="Summary" actions={actions}>
                <SummaryGroup groupName="Platform">
                    <SummaryTypeText
                        keyName={campaign.isCrossPlatformCampaign ? 'Platforms' : 'Platform'}
                        value={
                            campaign.isCrossPlatformCampaign
                                ? _.map(
                                      formData.platforms,
                                      platform => PLATFORM_MAPPING[platform].label
                                  ).join(', ')
                                : PLATFORM_MAPPING[formData.platform].label
                        }
                    />
                </SummaryGroup>
                <SummaryGroup groupName="Basic">
                    <SummaryTypeText keyName="Name" value={formData.name} />
                    {_.map(formData.custom_fields, customField => (
                        <SummaryTypeText
                            key={customField.name}
                            keyName={customField.name}
                            value={customField.value}
                        />
                    ))}
                    <SummaryTypeText keyName="Notes" value={formData.notes} />
                </SummaryGroup>
                <SummaryGroup groupName="Schedule">
                    <SummaryTypeText keyName="Timezone" value={formData.timezone} />
                    <SummaryTypeText
                        keyName="Start"
                        value={formatDate_formSelect({
                            date: formData.start,
                            timezone: formData.timezone,
                        })}
                    />
                    <SummaryTypeText
                        keyName="End"
                        value={formatDate_formSelect({
                            date: formData.end,
                            timezone: formData.timezone,
                        })}
                    />
                    <SummaryTypeTextCollection
                        keyName="Dayparts"
                        values={getDayPart(
                            daypartValue,
                            _.isArray(formData.dayparts_local) ? 'UTC' : formData.timezone,
                            formData.start
                        )}
                    />
                    <SummaryTypeTextCollection
                        keyName="Weekparts"
                        values={getWeekparting(weekpartValue)}
                    />
                </SummaryGroup>
                <React.Fragment>
                    {formData.ef_billing_terms === 'CPM' && billingEnabled && (
                        <SummaryGroup groupName="Revenue & Budget">
                            <SummaryTypeText keyName="Revenue Model" value="CPM" />
                            <SummaryTypeText
                                keyName="Billing Rate"
                                value={`${formatNumber_currency(formData.billing_rate)} CPM`}
                            />
                            <SummaryTypeText
                                keyName="Billable Impressions"
                                value={formatNumber_whole(billableVolume)}
                            />
                            <SummaryTypeText
                                keyName="Non-billable Impressions"
                                value={formatNumber_whole(formData.non_billable_volume)}
                            />
                            <SummaryTypeText
                                keyName="Total Impressions"
                                value={formatNumber_whole(
                                    billableVolume + formData.non_billable_volume
                                )}
                            />
                            <SummaryTypeText
                                keyName="Total Revenue"
                                value={formatNumber_currency(formData.max_total_billings)}
                            />
                            <SummaryTypeText
                                keyName="Third Party Fees"
                                value={`${environmentSettings.getCurrencySign()} ${formatNumber_wholeFixed(
                                    thirdPartyFees
                                )} CPM`}
                            />
                        </SummaryGroup>
                    )}
                    {formData.ef_billing_terms === 'CPC' && billingEnabled && (
                        <SummaryGroup groupName="Revenue & Budget">
                            <SummaryTypeText keyName="Revenue Model" value="CPC" />
                            <SummaryTypeText
                                keyName="Billing Rate"
                                value={`${formatNumber_currency(formData.billing_rate)} CPC`}
                            />
                            <SummaryTypeText
                                keyName="Billable Clicks"
                                value={formatNumber_whole(getBillableVolume(formData))}
                            />
                            <SummaryTypeText
                                keyName="Non-billable Clicks"
                                value={formatNumber_whole(formData.non_billable_volume)}
                            />
                            <SummaryTypeText
                                keyName="Total Clicks"
                                value={formatNumber_whole(
                                    billableVolume + formData.non_billable_volume
                                )}
                            />
                            <SummaryTypeText
                                keyName="Total Revenue"
                                value={formatNumber_currency(formData.max_total_billings)}
                            />
                            <SummaryTypeText
                                keyName="Third Party Fees"
                                value={`${environmentSettings.getCurrencySign()} ${formatNumber_wholeFixed(
                                    thirdPartyFees
                                )} CPM`}
                            />
                        </SummaryGroup>
                    )}
                    {formData.ef_billing_terms === 'billable_media_cost_markup' && billingEnabled && (
                        <SummaryGroup groupName="Revenue & Budget">
                            <SummaryTypeText keyName="Revenue Model" value="Total Cost Markup" />
                            <SummaryTypeText
                                keyName="Markup Rate"
                                value={`${formatNumber_percentage(formData.billing_rate)}`}
                            />
                            <SummaryTypeText
                                keyName="Total Cost Budget"
                                value={formatNumber_currency(getBillableVolume(formData))}
                            />
                            <SummaryTypeText
                                keyName="Revenue Budget"
                                value={formatNumber_currency(formData.max_total_billings)}
                            />
                            <SummaryTypeText
                                keyName="Third Party Fees"
                                value={`${environmentSettings.getCurrencySign()} ${formatNumber_wholeFixed(
                                    thirdPartyFees
                                )} CPM`}
                            />
                        </SummaryGroup>
                    )}
                    {formData.ef_billing_terms === 'billable_media_cost_margin' && billingEnabled && (
                        <SummaryGroup groupName="Revenue & Budget">
                            {campaign.budget_allocation_method === 'manual' ? (
                                <React.Fragment>
                                    <SummaryTypeText
                                        keyName="Revenue Model"
                                        value="Agency Margin"
                                    />
                                    <SummaryTypeText
                                        keyName="Agency Margin"
                                        value={`${formatNumber_percentage(formData.billing_rate)}`}
                                    />
                                    <SummaryTypeText
                                        keyName="Revenue Budget"
                                        value={formatNumber_currency(formData.billable_volume)}
                                    />
                                    <SummaryTypeText
                                        keyName="Total Cost Budget"
                                        value={formatNumber_currency(
                                            formData.max_total_spend_local
                                        )}
                                    />
                                    <SummaryTypeText
                                        keyName="Third Party Fees"
                                        value={`${environmentSettings.getCurrencySign()} ${formatNumber_wholeFixed(
                                            thirdPartyFees
                                        )} CPM`}
                                    />
                                </React.Fragment>
                            ) : (
                                <LockedMessage
                                    autoBudgetAllocationMethod={
                                        campaign.automaticBudgetAllocationOptimizationStrategy
                                    }
                                />
                            )}
                        </SummaryGroup>
                    )}
                    {!billingEnabled && (
                        <SummaryGroup groupName="Budget">
                            {campaign.budget_allocation_method === 'auto' ? (
                                <LockedMessage
                                    autoBudgetAllocationMethod={
                                        campaign.automaticBudgetAllocationOptimizationStrategy
                                    }
                                />
                            ) : (
                                <React.Fragment>
                                    <SummaryTypeText
                                        keyName="Goal Type"
                                        value={this.getGoalType()}
                                    />
                                    <SummaryTypeText
                                        keyName={this.getGoalKey()}
                                        value={this.getGoalValue()}
                                    />
                                    <SummaryTypeText
                                        keyName="Third Party Fees"
                                        value={`${environmentSettings.getCurrencySign()} ${formatNumber_wholeFixed(
                                            thirdPartyFees
                                        )} CPM`}
                                    />
                                </React.Fragment>
                            )}
                        </SummaryGroup>
                    )}
                </React.Fragment>
                <SummaryGroup groupName="Pacing">
                    {campaign.budget_allocation_method === 'manual' ? (
                        <React.Fragment>
                            <SummaryTypeText
                                keyName="Pacing Strategy"
                                value={this.getPacingStrategyValue()}
                            />
                            {!formData.use_front_load_pacing && (
                                <React.Fragment>
                                    <SummaryTypeText
                                        keyName="Impressions"
                                        value={this.getPacingValue('CPM')}
                                    />
                                    {!isCTVCampaign && !isDOOHCampaign && (
                                        <SummaryTypeText
                                            keyName="Clicks"
                                            value={this.getPacingValue('CPC')}
                                        />
                                    )}
                                    {formData.ef_billing_terms === 'billable_media_cost_margin' && (
                                        <SummaryTypeText
                                            keyName={'Revenue'}
                                            value={this.getPacingValue(
                                                'billable_media_cost_margin'
                                            )}
                                        />
                                    )}
                                    <SummaryTypeText
                                        keyName="Total Cost"
                                        value={this.getPacingValue('billable_media_cost_markup')}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (
                        <LockedMessage
                            pacing
                            autoBudgetAllocationMethod={
                                campaign.automaticBudgetAllocationOptimizationStrategy
                            }
                        />
                    )}
                </SummaryGroup>

                {campaign.fta_version === 2 &&
                    organization.fta_partner_id &&
                    environmentSettings.canUseFta() && (
                        <SummaryGroup groupName="Foot Traffic Attribution">
                            <SummaryTypeText
                                keyName="Enabled"
                                value={formData.fta.enabled ? 'Yes' : 'No'}
                            />
                            {formData.fta.enabled && (
                                <React.Fragment>
                                    <SummaryTypeText
                                        keyName="Line ID"
                                        uncapitalize={true}
                                        value={formData.fta.line_id ? formData.fta.line_id : ''}
                                    />
                                    <SummaryTypeText
                                        keyName="Partner ID"
                                        value={
                                            campaign.fta_management_level === 'campaign'
                                                ? campaign.fta_partner_id
                                                : formData.fta.partner_id || ''
                                        }
                                    />
                                    <SummaryTypeText
                                        keyName="Location List"
                                        uncapitalize={true}
                                        value={locationListMapping[formData.fta.location_list]}
                                    />
                                </React.Fragment>
                            )}
                        </SummaryGroup>
                    )}

                {campaign.fta_version === 1 && environmentSettings.canUseFta() && (
                    <Profile>
                        {({ ownOrganization }) => {
                            if (!ownOrganization.fta_partner_id) {
                                return null;
                            }

                            return (
                                <SummaryGroup groupName="Foot Traffic Attribution">
                                    <SummaryTypeText
                                        keyName="Enabled"
                                        value={formData.fta.enabled ? 'Yes' : 'No'}
                                    />
                                    {formData.fta.enabled && (
                                        <React.Fragment>
                                            <SummaryTypeText
                                                keyName="Line ID"
                                                uncapitalize={true}
                                                value={
                                                    formData.fta.line_id ? formData.fta.line_id : ''
                                                }
                                            />
                                            <SummaryTypeText
                                                keyName="Partner ID"
                                                value={
                                                    formData.fta.partner_id
                                                        ? formData.fta.partner_id
                                                        : ''
                                                }
                                            />
                                        </React.Fragment>
                                    )}
                                </SummaryGroup>
                            );
                        }}
                    </Profile>
                )}
                <SummaryGroup groupName="Creative">
                    <SummaryTypeCreative
                        creativeLink={`/campaigns/${campaign.id}/setup/creatives#${creativeId}`}
                        keyName="Creative"
                        value={{
                            creativeField: formData.creative,
                            rotationRules: formData.rotation_rules,
                            timezone: formData.timezone,
                            creativesByCampaign,
                        }}
                        onLabelWeatherConditionQualifier={labelWeatherConditionQualifier}
                        onLabelWeatherConditionValue={labelWeatherConditionValue}
                        onLabelWeatherTimeFrame={labelWeatherConditionTimeframe}
                    />
                </SummaryGroup>
                {showConversions && (
                    <SummaryGroup groupName="Conversion Optimization">
                        <SummaryTypeText
                            keyName="Optimized Conversion Events"
                            value={getConversionsForCreativeOptimization(
                                formData.conversionsForCreativeOptimization,
                                conversionsInCampaign
                            )}
                        />
                    </SummaryGroup>
                )}
                {environmentSettings.canUseIas() && !isCTVCampaign && !isDOOHCampaign && (
                    <SummaryGroup groupName="Third Party Verification">
                        {!isProspectMedia && (
                            <SummaryTypeText
                                keyName="Fraud Prevention"
                                value={
                                    IAS_FRAUD_PREVENTION_RISK_OPTIONS_MAPPING[
                                        formData.ias.fraudPrevention.risk.value
                                    ].label
                                }
                            />
                        )}
                        <SummaryTypeTextCollection
                            keyName="Viewability"
                            values={getViewabilityValues(formData)}
                        />
                        {!isProspectMedia && (
                            <SummaryTypeTextCollection
                                keyName="Brand Safety"
                                values={getIasBrandSafetyRiskLabels(formData)}
                            />
                        )}
                        {organization.isIasPostBidVerificationEnabled && (
                            <SummaryTypeText
                                keyName="Post-bid Verification"
                                value={formData.isIasPostBidVerificationEnabled ? 'On' : 'Off'}
                            />
                        )}
                    </SummaryGroup>
                )}
                <SummaryGroup groupName="Targeting">
                    <SummaryTypeGeoTargeting keyName="Geotargeting" value={formData.geotargets} />
                    {showPlatformRestrictedFields && (
                        <React.Fragment>
                            {environmentSettings.canUseGeoFencing() && !isCTVCampaign && (
                                <SummaryTypeTextCollection
                                    keyName="Geofencing"
                                    values={getGeofencing(formData.geoboxes)}
                                />
                            )}
                            {!isTacticsEnabled(formData.tactics_enabled, campaign.id) && (
                                <SummaryTypeTextCollection
                                    keyName="POI Segments"
                                    values={getPOI(
                                        formData.geo_targeting_settings,
                                        poiCategories,
                                        poiSegments,
                                        can(PermissionsMapping.CAMPAIGN__VIEW)
                                    )}
                                />
                            )}
                        </React.Fragment>
                    )}
                    {!isDOOHCampaign && (
                        <SummaryTypeText
                            keyName="Device OS"
                            value={getDeviceOs(formData.target_device_os)}
                        />
                    )}
                    {showCarrierSection &&
                        environmentSettings.canUseCarrierIsp() &&
                        !isDOOHCampaign &&
                        this.renderTargetCarrierSection()}
                    {!isCTVCampaign && !isDOOHCampaign && (
                        <>
                            <SummaryTypeText
                                keyName="User Language"
                                value={getDeviceLanguage(formData.target_device_language)}
                            />
                            <SummaryTypeText
                                keyName="User Language (Exclude)"
                                value={getDeviceLanguageExclude(
                                    formData.target_device_language_exclude
                                )}
                            />
                        </>
                    )}
                    {showPlatformRestrictedFields && (
                        <React.Fragment>
                            {campaign.isAppStoreCatEnabled === false && (
                                <SummaryTypeText
                                    keyName="IAB App Categories"
                                    value={getIABCat(formData.target_iab_categories)}
                                />
                            )}
                            {campaign.isAppStoreCatEnabled === false && (
                                <SummaryTypeText
                                    keyName="IAB App Categories (Exclude)"
                                    value={getIABCat(formData.target_iab_categories_exclude)}
                                />
                            )}
                            {!isTacticsEnabled(formData.tactics_enabled, campaign.id) &&
                                campaign.isAppStoreCatEnabled === true && (
                                    <SummaryTypeText
                                        keyName="App Categories"
                                        value={formData.target_app_store_cat.join(', ')}
                                    />
                                )}
                            {!isTacticsEnabled(formData.tactics_enabled, campaign.id) &&
                                campaign.isAppStoreCatEnabled === true && (
                                    <SummaryTypeText
                                        keyName="App Categories (Exclude)"
                                        value={formData.target_app_store_cat_exclude.join(', ')}
                                    />
                                )}
                        </React.Fragment>
                    )}
                    {!isCTVCampaign &&
                        !isDOOHCampaign &&
                        environmentSettings.canUseGenderTargeting() && (
                            <SummaryTypeText
                                keyName="Gender"
                                value={getGenders(formData.target_genders)}
                            />
                        )}
                    {!isCTVCampaign &&
                        !isDOOHCampaign &&
                        environmentSettings.canUseAgeTargeting() && (
                            <SummaryTypeTextCollection
                                keyName="Age"
                                values={getAgeGroups(formData.target_age_groups)}
                            />
                        )}
                    {isTacticsEnabled(formData.tactics_enabled, campaign.id) &&
                        environmentSettings.canUseTactics() && (
                            <SummaryTypeComponent
                                keyName="Tactics"
                                value={_.map(formData.tactics_generators, tactic => (
                                    <TacticSummary
                                        key={tactic.id}
                                        tactic={tactic}
                                        geoTargetingSubCategories={geoTargetingSubCategories}
                                        geoLayers={geoLayers}
                                        shouldHideFlexSegments={campaign.shouldHideFlexSegments}
                                    />
                                ))}
                            />
                        )}
                    {campaign.isWeatherConditionEnabled &&
                        environmentSettings.canUseWeatherConditions() && (
                            <SummaryTypeComponent
                                keyName="Weather Condition"
                                value={
                                    hasWeatherConditions(formData.weatherConditions)
                                        ? _.map(
                                              formData.weatherConditions.conditions,
                                              (condition, index) => (
                                                  <WeatherConditionSummary
                                                      key={condition.id}
                                                      title={`Condition ${index + 1}`}
                                                      condition={condition}
                                                      direction="column"
                                                      alignItems="flex-start"
                                                      spacing={1}
                                                      logicRelationship={
                                                          formData.weatherConditions
                                                              .logicRelationship
                                                      }
                                                      showDivider={
                                                          index !==
                                                          formData.weatherConditions.conditions
                                                              .length -
                                                              1
                                                      }
                                                  />
                                              )
                                          )
                                        : 'None'
                                }
                            />
                        )}
                </SummaryGroup>

                {!isTacticsEnabled(formData.tactics_enabled, campaign.id) && (
                    <SummaryGroup groupName="Audience Segments">
                        <SummaryTypeComponent
                            keyName="Audience Segments"
                            value={
                                <AudienceOptions
                                    shouldHideFlexSegments={campaign.shouldHideFlexSegments}
                                >
                                    {({ mapping }) => (
                                        <IncludeAndExcludeSummary
                                            includedItems={_.map(
                                                formData.audiences,
                                                audienceId => ({
                                                    value: mapping[audienceId]?.value || null,
                                                    label: mapping[audienceId]?.label || '',
                                                })
                                            )}
                                            excludedItems={_.map(
                                                formData.audience_exclude,
                                                audienceId => ({
                                                    value: mapping[audienceId]?.value || null,
                                                    label: mapping[audienceId]?.label || '',
                                                })
                                            )}
                                        />
                                    )}
                                </AudienceOptions>
                            }
                        />
                    </SummaryGroup>
                )}

                <SummaryGroup groupName="Inventory">
                    <SummaryTypeText
                        keyName="Public Inventory"
                        value={
                            formData.dealApplicationType === 'deal_augmented'
                                ? 'Bid on open auctions'
                                : 'Do not bid on open auctions'
                        }
                    />
                    <SummaryTypeTextCollection
                        keyName={
                            flags.isEnabled('efb_35_curated_deals') ? 'Deals' : 'Private Deals'
                        }
                        values={_.map(this.props.deals, deal => deal.app_name)}
                    />
                    {showPlatformRestrictedFields &&
                        campaign.isExchangeTargetingEnabled &&
                        environmentSettings.canUseExchanges() && (
                            <SummaryTypeTextCollection
                                keyName="Exchanges"
                                values={[
                                    `Included: All compatible and new exchanges`,
                                    `Excluded: ${getExchangesBlocked(
                                        formData.exchanges_blocked_ui
                                    )}`,
                                ]}
                            />
                        )}
                    <SummaryTypeComponent
                        keyName={environmentSettings.getAppsAndSitesLabel()}
                        value={
                            <div>
                                <ApplistSummary
                                    mode="whitelist"
                                    allowedList={getApplists(
                                        formData.whitelistedAppsAndSites,
                                        applists
                                    )}
                                    blockedList={getApplists(
                                        formData.blacklistedAppsAndSites,
                                        applists
                                    )}
                                />
                            </div>
                        }
                    />
                    {isDOOHCampaign && flags.isEnabled('efb_17_dooh_target_venue') && (
                        <SummaryTypeComponent
                            keyName="Venue Type"
                            value={
                                <DoohVenueTypeSummary
                                    doohVenuesIncludeValue={doohVenueTranslate(
                                        formData.target_dooh_venues
                                    )}
                                    doohVenuesExcludeValue={doohVenueTranslate(
                                        formData.target_dooh_venues_exclude
                                    )}
                                />
                            }
                        />
                    )}
                </SummaryGroup>

                <SummaryGroup groupName="Bid Optimization">
                    {formData.bid_strategy_mode === 'automatic_bid_price' && (
                        <React.Fragment>
                            {formData.automatic_bid_price.apply_max_ecpm && (
                                <SummaryTypeText
                                    keyName="Automatic Bid Price"
                                    value={`Do not exceed ${currencyLabel} ${environmentSettings.getCurrencySign()} ${numeral(
                                        formData.automatic_bid_price.max_ecpm_local
                                    ).format('0,0.00')} eCPM`}
                                />
                            )}
                            {formData.automatic_bid_price.optimizing_strategy.mode ===
                                BiddingStrategyMode.SpendFullBudget && (
                                <SummaryTypeText
                                    keyName="Optimization"
                                    value={'Optimize for spending full budget'}
                                />
                            )}
                            {formData.automatic_bid_price.optimizing_strategy.mode ===
                                BiddingStrategyMode.Performance &&
                                !isCTVCampaign &&
                                !isDOOHCampaign && (
                                    <SummaryTypeText
                                        keyName="Optimization"
                                        value={`Optimize for performance: ${currencyLabel} ${environmentSettings.getCurrencySign()} ${numeral(
                                            formData.automatic_bid_price.optimizing_strategy
                                                .max_ecpc_local
                                        ).format('0,0.00')} eCPC or less`}
                                    />
                                )}
                            {formData.automatic_bid_price.optimizing_strategy.mode ===
                                BiddingStrategyMode.Performance &&
                                (isCTVCampaign || isDOOHCampaign) && (
                                    <SummaryTypeText
                                        keyName="Optimization"
                                        value={`Optimize for performance: ${currencyLabel} ${environmentSettings.getCurrencySign()} ${numeral(
                                            formData.automatic_bid_price.optimizing_strategy
                                                .max_ecpcv_local
                                        ).format('0,0.00')} eCPCV or less`}
                                    />
                                )}
                        </React.Fragment>
                    )}
                    {formData.bid_strategy_mode === 'fixed_bid_price' && (
                        <React.Fragment>
                            <SummaryTypeText
                                keyName="Total Bid Price"
                                value={`${currencyLabel} ${environmentSettings.getCurrencySign()} ${formatNumber_wholeFixed(
                                    maxCpmRate
                                )} CPM`}
                            />
                            <SummaryTypeText
                                keyName="Net Bid Price"
                                value={`${currencyLabel} ${environmentSettings.getCurrencySign()} ${formatNumber_wholeFixed(
                                    bidPrice
                                )} CPM`}
                            />
                            <SummaryTypeText
                                uncapitalize={true}
                                keyName="Frequency Cap"
                                value={getFlexibleFrequency(formData.fcaps)}
                            />
                        </React.Fragment>
                    )}
                </SummaryGroup>
            </SummaryLayout>
        );
    }
}

export const WeatherConditionSummary = ({
    condition,
    title,
    direction,
    spacing,
    showDivider,
    logicRelationship,
}) => {
    return (
        <React.Fragment>
            {title && (
                <Separator
                    label={
                        <Typography variant="subtitle1" gutterBottom>
                            {title}
                        </Typography>
                    }
                />
            )}
            <Grid
                container
                spacing={spacing ? spacing : 3}
                direction={direction ? direction : 'row'}
            >
                <Grid item>
                    <Typography variant="subtitle2">Condition</Typography>
                    <Typography>{getConditionLabel(condition.type) || ''}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">Qualifier</Typography>
                    <Typography>{labelWeatherConditionQualifier(condition.qualifier)}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">Value</Typography>
                    <Typography>
                        {labelWeatherConditionValue(condition.amount, condition.type)}
                    </Typography>
                </Grid>
                {
                    <Grid item>
                        <Typography variant="subtitle2">Timeframe</Typography>
                        <Typography>
                            {labelWeatherConditionTimeframe(condition.timeframe)}
                        </Typography>
                    </Grid>
                }
            </Grid>
            <React.Fragment>
                {showDivider && (
                    <LogicalDivider label={logicRelationship === 'and' ? 'AND' : 'OR'} />
                )}
                {showDivider && <Spacer />}
            </React.Fragment>
        </React.Fragment>
    );
};
export function TacticSummary({
    tactic,
    geoTargetingSubCategories,
    geoLayers,
    shouldHideFlexSegments,
}) {
    return (
        <React.Fragment>
            <Separator
                label={
                    <Typography variant="subtitle1" gutterBottom>
                        {tactic.title}
                    </Typography>
                }
            />
            <Spacer type="small" />
            {_.map(tactic.targeting, (target, targetIndex) => {
                switch (target.module) {
                    case 'app_categories':
                        const categoriesIncluded = _.get(target, 'draft.target_app_store_cat', []);
                        const categoriesExcluded = _.get(
                            target,
                            'draft.target_app_store_cat_exclude',
                            []
                        );

                        return (
                            <React.Fragment key={target.id}>
                                <Typography variant="subtitle2" gutterBottom>
                                    App Categories
                                </Typography>
                                {categoriesIncluded.length > 0 && (
                                    <React.Fragment>
                                        <Grid container alignItems="center">
                                            <CheckIcon color="primary" />
                                            Included
                                        </Grid>
                                        <div>{categoriesIncluded.join(', ')}</div>
                                    </React.Fragment>
                                )}
                                {categoriesExcluded.length > 0 && (
                                    <React.Fragment>
                                        <Grid container alignItems="center">
                                            <NotInterestedIcon color="error" />
                                            Excluded
                                        </Grid>
                                        <div>{categoriesExcluded.join(', ')}</div>
                                    </React.Fragment>
                                )}
                                {targetIndex !== tactic.targeting.length - 1 && (
                                    <LogicalDivider label="AND" />
                                )}
                                <Spacer />
                            </React.Fragment>
                        );

                    case 'audience_segments':
                        // TODO: flag for new campaigns and check for flag EN-1202
                        const categoryLayers = _.get(
                            target.draft,
                            'geo_targeting_settings.category_layers'
                        );
                        const categories = _.get(target.draft, 'geo_targeting_settings.categories');
                        const customLayers = _.get(
                            target.draft,
                            'geo_targeting_settings.custom_layers'
                        );
                        const includedTargetingSettings = _.concat(
                            categoryLayers,
                            categories,
                            customLayers
                        ).filter(item => item);

                        return (
                            <React.Fragment key={target.id}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Audiences
                                </Typography>
                                <AudienceOptions shouldHideFlexSegments={shouldHideFlexSegments}>
                                    {({ mapping, flexMapping }) => {
                                        const mappedAudiences = _.map(
                                            _.get(target, 'draft.audiences', []),
                                            audienceId => ({
                                                value: mapping[audienceId]?.value || null,
                                                label: mapping[audienceId]?.label || '',
                                            })
                                        );

                                        const mappedGeoSettings = _.map(
                                            includedTargetingSettings,
                                            audienceId => {
                                                let value = null;
                                                let label = '';
                                                if (flexMapping[audienceId]) {
                                                    value = flexMapping[audienceId].value;
                                                    label = flexMapping[audienceId].label;
                                                }
                                                return { value, label };
                                            }
                                        );
                                        return (
                                            <IncludeAndExcludeSummary
                                                includedItems={
                                                    !shouldHideFlexSegments
                                                        ? _.concat(
                                                              mappedAudiences,
                                                              mappedGeoSettings
                                                          )
                                                        : mappedAudiences
                                                }
                                                excludedItems={_.map(
                                                    _.get(target, 'draft.audience_exclude', []),
                                                    audienceId => {
                                                        const { value, label } = mapping[
                                                            audienceId
                                                        ];
                                                        return {
                                                            value,
                                                            label,
                                                        };
                                                    }
                                                )}
                                            />
                                        );
                                    }}
                                </AudienceOptions>
                                {targetIndex !== tactic.targeting.length - 1 && (
                                    <LogicalDivider label="AND" />
                                )}
                                <Spacer />
                            </React.Fragment>
                        );
                    case 'pois':
                        const { geo_targeting_settings } = target.draft;
                        let poiCategoriesFromDraft;
                        let poiSegmentsFromDraft;
                        if (geo_targeting_settings) {
                            poiCategoriesFromDraft = _.filter(
                                geoTargetingSubCategories,
                                subCategory =>
                                    geo_targeting_settings.categories.indexOf(subCategory.id) !== -1
                            );
                            const categoryLayers = _.filter(
                                geoLayers,
                                geoLayer =>
                                    geo_targeting_settings.category_layers.indexOf(geoLayer.id) !==
                                    -1
                            );
                            const customLayers = _.filter(
                                geoLayers,
                                geoLayer =>
                                    geo_targeting_settings.custom_layers.indexOf(geoLayer.id) !== -1
                            );
                            poiSegmentsFromDraft = _.concat(categoryLayers, customLayers);
                        }

                        return (
                            <React.Fragment key={target.id}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Flex Geolocation Audiences
                                </Typography>
                                <div>
                                    {getPOI(
                                        geo_targeting_settings,
                                        poiCategoriesFromDraft,
                                        poiSegmentsFromDraft,
                                        can(PermissionsMapping.CAMPAIGN__VIEW)
                                    )}
                                </div>
                                {targetIndex !== tactic.targeting.length - 1 && (
                                    <LogicalDivider label="AND" />
                                )}
                                <Spacer />
                            </React.Fragment>
                        );
                    case 'ias_contextual_targeting': {
                        const values = _.get(target, 'draft.ias.contextualTargeting.value', []);

                        const labels = _.map(
                            values,
                            value => IAS_CONTEXTUAL_TARGETING_MAPPING[value]
                        );

                        return (
                            <React.Fragment key={target.id}>
                                <div>Contextual Targeting</div>
                                <div>{labels}</div>
                                {targetIndex !== tactic.targeting.length - 1 && (
                                    <LogicalDivider label="AND" />
                                )}
                                <Spacer />
                            </React.Fragment>
                        );
                    }
                    case 'categories': {
                        const categoriesIncluded =
                            _.get(target, 'draft.target_app_store_cat') || [];
                        const categoriesIncludedWithLabels =
                            _.map(categoriesIncluded, category => {
                                const categoryFromMapping = _.find(
                                    APP_STORE_CATEGORIES,
                                    categoryMapping => {
                                        if (categoryMapping.value === category) {
                                            return categoryMapping.label;
                                        }
                                    }
                                );
                                return categoryFromMapping.label || category;
                            }) || [];
                        const categoriesExcluded =
                            _.get(target, 'draft.target_app_store_cat_exclude') || [];
                        const iasCategoriesExcluded =
                            _.get(target, 'draft.ias.contextualTargeting.exclude') || false;
                        const iasValues =
                            _.get(target, 'draft.ias.contextualTargeting.value') || [];

                        const iasLabels = _.map(
                            iasValues,
                            value => IAS_CONTEXTUAL_TARGETING_MAPPING[value]
                        ).join(', ');

                        return (
                            <React.Fragment key={target.id}>
                                <Typography variant="subtitle2" gutterBottom>
                                    App Categories
                                </Typography>
                                {categoriesIncludedWithLabels.length > 0 && (
                                    <React.Fragment>
                                        <Grid container alignItems="center">
                                            <CheckIcon color="primary" />
                                            Included
                                        </Grid>
                                        <div>{categoriesIncludedWithLabels.join(', ')}</div>
                                    </React.Fragment>
                                )}
                                {categoriesExcluded.length > 0 && (
                                    <React.Fragment>
                                        <Grid container alignItems="center">
                                            <NotInterestedIcon color="error" />
                                            Excluded
                                        </Grid>
                                        <div>{categoriesExcluded.join(', ')}</div>
                                    </React.Fragment>
                                )}
                                {targetIndex !== tactic.targeting.length - 1 && (
                                    <LogicalDivider label="AND" />
                                )}
                                <Spacer />
                                <Typography variant="subtitle2" gutterBottom>
                                    Contextual Targeting
                                </Typography>
                                {!iasCategoriesExcluded && (
                                    <React.Fragment>
                                        <Grid container alignItems="center">
                                            <CheckIcon color="primary" />
                                            Included
                                        </Grid>
                                    </React.Fragment>
                                )}
                                {iasCategoriesExcluded && (
                                    <React.Fragment>
                                        <Grid container alignItems="center">
                                            <NotInterestedIcon color="error" />
                                            Excluded
                                        </Grid>
                                    </React.Fragment>
                                )}
                                <div>{iasLabels}</div>
                                {targetIndex !== tactic.targeting.length - 1 && (
                                    <LogicalDivider label="AND" />
                                )}
                                <Spacer />
                            </React.Fragment>
                        );
                    }
                    default:
                        return null;
                }
            })}
        </React.Fragment>
    );
}

const autoBudgetAllocationMethodMapping = {
    cpc: 'eCPC',
    cpa: 'eCPA',
    cpcv: 'eCPCV',
};

const LockedMessage = ({ pacing, autoBudgetAllocationMethod }) => {
    return (
        <Grid container direction="column">
            <Grid item xs={11}>
                <Typography variant="subtitle1"> Auto-budget enabled at campaign level </Typography>
            </Grid>
            <Grid item xs={11}>
                <Typography variant="body2">
                    {' '}
                    {`${pacing ? 'Pacing' : 'Budget'} will be automatically adjusted based on ${
                        autoBudgetAllocationMethodMapping[autoBudgetAllocationMethod]
                    }`}{' '}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default connect(selector)(AdSummaryContainer);
