import _ from 'lodash';
import VError from 'verror';

import AudienceActions from 'states/resources/audiences/actions';
import notify from 'utils/notify';
import { createHttp } from 'utils/http';
import draftToPayload from './services/draft-to-payload';

import flags from 'containers/flags/service';

const actions = {
    initialize(audienceId, target) {
        return async (dispatch, getState) => {
            dispatch({
                type: 'RETARGETING_EDITOR__INIT_START',
                payload: {
                    audienceId,
                    target,
                },
            });

            const orgId = _.get(getState(), 'profile.organizationId');

            let data = await fetchAudienceEditorData(orgId);

            if (!audienceId) {
                dispatch({
                    type: 'RETARGETING_EDITOR__INIT_END',
                    payload: {
                        audienceId,
                        audience: null,
                        data,
                    },
                });
                return;
            }

            // fetch existing audience and load into draft
            dispatch(AudienceActions.getOne(audienceId)).then(audience => {
                dispatch({
                    type: 'RETARGETING_EDITOR__INIT_END',
                    payload: {
                        audienceId,
                        audience,
                        data,
                    },
                });
            });
        };
    },
    save(audienceId) {
        return async (dispatch, getState) => {
            dispatch({
                type: 'RETARGETING_EDITOR__SAVE_START',
                payload: {},
            });

            const errors = _.get(getState(), 'retargetingModal.errors', {});

            if (_.keys(errors).length > 0) {
                dispatch({
                    type: 'RETARGETING_EDITOR__SAVE_CLIENT_ERROR',
                    payload: {},
                    error: errors,
                });
                return Promise.reject(errors);
            }

            const draft = _.get(getState(), 'retargetingModal.draft');
            const isEditing = _.get(getState(), 'retargetingModal.isEditing');
            const payload = draftToPayload(draft, isEditing);

            try {
                let audience;
                if (audienceId) {
                    audience = await dispatch(
                        AudienceActions.update(
                            audienceId,
                            _.pick(payload, ['name', 'description', 'supports', 'third_party_fees'])
                        )
                    );
                } else if (payload.mode === 'upload') {
                    audience = await dispatch(AudienceActions.createAndUpload(payload));
                } else {
                    // check if any campaign or ad is DOOH
                    // we have to filter out DOOH campaigns and ads
                    const source_type = draft.source_type;

                    let checkForDooh = false;
                    const doohSourceIds = [];
                    const nonDoohSourceIds = [];

                    if (source_type === 'ad' || source_type === 'campaign') {
                        checkForDooh = true;
                    }

                    //check if the selected campaigns or ads are from the same campaign_type
                    // if one of them is dooh, then prompt the user
                    // that two audiences will be create, one for DOOH and one for the rest
                    if (checkForDooh && flags.isEnabled('efb_195_warn_dooh_retargeting')) {
                        const campaignAdOptions = _.get(
                            getState(),
                            'retargetingModal.campaigns',
                            []
                        );
                        _.forEach(draft.source_id, source_id => {
                            let lookUpId = source_id;
                            if (source_type === 'ad') {
                                //remove two last chars from the source_id to get the campaign_id
                                lookUpId = source_id.toString().slice(0, -2) * 1;
                            }
                            const foundOption = _.find(
                                campaignAdOptions,
                                option => option.id === lookUpId
                            );
                            if (foundOption.campaign_type === 'dooh') {
                                doohSourceIds.push(source_id);
                            } else {
                                nonDoohSourceIds.push(source_id);
                            }
                        });
                    }

                    //create the DOOH audience
                    //its a copy of the current audience with the source_id
                    // being the doohSourceIds and the name being the current name + ' DOOH'
                    if (doohSourceIds.length > 0) {
                        const doohPayload = _.cloneDeep(payload);
                        doohPayload.source_id = doohSourceIds;
                        doohPayload.name = `${payload.name} (DOOH)`;
                        audience = await dispatch(AudienceActions.create(doohPayload));

                        //remove the doohSourceIds from the source_id
                        payload.source_id = nonDoohSourceIds;
                        //rename the audience
                        payload.name = `${payload.name} (Other Platforms)`;
                    }

                    audience = await dispatch(AudienceActions.create(payload));
                }
                return dispatch({
                    type: 'RETARGETING_EDITOR__SAVE_END',
                    payload: { audience },
                });
            } catch (error) {
                if (error.body && error.body[0] === 'SyntaxError') {
                    return dispatch({
                        type: 'RETARGETING_EDITOR__SAVE_SERVER_ERROR',
                        payload: {
                            error: error.body[1],
                            invalidLines: error.body.slice(2),
                        },
                    });
                } else if (error.code && error.code === 413) {
                    return dispatch({
                        type: 'RETARGETING_EDITOR__SAVE_SERVER_ERROR',
                        payload: {
                            error: 'Uploaded file must be 100mb or less',
                            invalidLines: error.body.slice(2),
                        },
                    });
                } else {
                    return dispatch({
                        type: 'RETARGETING_EDITOR__SAVE_SERVER_ERROR',
                        payload: {
                            error: 'Unknown error',
                        },
                    });
                }
            }
        };
    },
    updateDraft(changes) {
        return {
            type: 'RETARGETING_EDITOR__UPDATE_DRAFT',
            payload: {
                changes,
            },
        };
    },
    updateField(fieldName, changes) {
        return {
            type: 'RETARGETING_EDITOR__UPDATE_FIELD',
            payload: {
                [fieldName]: changes,
            },
        };
    },
    updateMode(mode) {
        return {
            type: 'RETARGETING_EDITOR__UPDATE_MODE',
            payload: {
                mode,
            },
        };
    },
    updateRetargetingType(type) {
        return {
            type: 'RETARGETING_EDITOR__UPDATE_RETARGETING_TYPE',
            payload: {
                type,
            },
        };
    },
    openModal(audienceId) {
        return dispatch => {
            dispatch(actions.initialize(audienceId));
        };
    },
    closeModal() {
        return {
            type: 'RETARGETING_EDITOR__CLOSE_EDITOR_MODAL',
        };
    },
};

async function fetchAudienceEditorData(orgId) {
    try {
        const http = createHttp();
        const query = `
            query getAudienceEditorData ($relationship: String, $clientType: [String])  {
                campaigns {
                    id
                    name
                    start
                    end
                    iab_categories
                    campaign_type
                    ads {
                        id
                        name
                        start
                        end
                        platforms
                    }
                    beacons {
                        name
                        label
                    }
                    conversions {
                        reporting_name
                        event_name
                        use_click_through
                        use_view_through
                    }
                }
                organizations(filters: { relationship: $relationship, client_type: $clientType }) {
                    id
                    name
                    client_type
                }
                ownOrganization {
                    conversions {
                        id
                        event_name
                        reporting_name
                        use_click_through
                        click_through_attribution_window
                        use_view_through
                        view_through_attribution_window
                        use_dynamic_data_subevent
                        use_dynamic_data_value
                        dynamic_data_subevent_name
                        dynamic_data_value_name
                        should_optimize_for_budget_allocation
                        advertiser {
                            id
                            name
                        }
                    }
                }
            }
        `;

        const variables = {
            orgId,
            relationship: 'child',
            clientType: ['advertiser'],
        };

        const data = await http.graphql(query, variables);
        const advertisers = _.filter(data.organizations, org => org.client_type === 'advertiser');

        return {
            ...data,
            organizations: advertisers,
        };
    } catch (err) {
        notify({
            error: new VError(err, 'failed to fetch audience editor data'),
            metaData: {
                orgId,
            },
        });

        return {};
    }
}

export default actions;
