import _ from 'lodash';

import c from 'common/constants/flux-events';
import { defaultSetting } from './services';
import VError from 'verror';

const actions = {
    open(getInitialDraft, campaignId, creativeId, location, campaignType) {
        return dispatch => {
            dispatch({
                type: c.CREATIVE_FORM__INIT_STATE,
                payload: { campaignId, creativeId },
            });
            dispatch({
                type: c.CREATIVE_FORM__OPEN,
                payload: { campaignId, creativeId },
            });

            return getInitialDraft().then(draft => {
                let advertiser;
                if (draft.advertisers) {
                    advertiser = draft.advertisers[0].name;
                }
                dispatch({
                    type: c.CREATIVE_FORM__INIT,
                    payload: {
                        campaignId,
                        creativeId,
                        creative: draft,
                        location: location,
                        advertiser,
                        campaignType,
                    },
                });
            });
        };
    },

    back(campaignId, creativeId, campaignType) {
        return dispatch => {
            dispatch({
                type: 'CREATIVE_FORM__BACK',
                payload: { campaignId, creativeId, campaignType },
            });
        };
    },

    updateDraft(formData, campaignId, creativeId) {
        return {
            type: c.CREATIVE_FORM__UPDATE,
            payload: {
                campaignId,
                creativeId,
                formData,
            },
        };
    },

    validateDraft({ campaignId, creativeId, onValidDraft, isSaveAudit = false }) {
        return (dispatch, getState) => {
            // Get creative's draft by (creativeId, campaignId ) from session
            const store_creativeForm = _.get(getState(), `creativeForm`);
            if (!store_creativeForm) {
                throw new Error(
                    'Store error: creativeForm does not exist for campaignId, creativeId : ',
                    campaignId,
                    creativeId
                );
            }

            // Get Errors
            const errors = _.get(getState(), `creativeForm.errors`, []);
            if (errors.length !== 0) {
                // There are errors don't do ajax
                dispatch({
                    type: 'CREATIVE_FORM__SHOW_ERRORS',
                    payload: { campaignId, creativeId, errors },
                });
                return;
            }

            // No errors, we can show pop up for confirmation, then essemble payload and serialize

            // Get creative attributes by creativeId from resources
            const audit_status = _.get(
                getState(),
                `resources.creatives.${creativeId}.attr.audit_status`
            );

            // Only ask for confirmation if previous audit_status is 'pending' or 'approved'
            if (audit_status === 'pending' || audit_status === 'approved') {
                const store_creativeByIdInResources = _.get(
                    getState(),
                    `resources.creatives.${creativeId}`,
                    void 0
                );
                if (!store_creativeByIdInResources) {
                    throw new VError(
                        'Store error: creative does not exist in resources, creativeId: ',
                        creativeId
                    );
                }
                // Get ads collection in resources
                const adMapping = _.get(getState(), `resources.ads`, void 0);
                if (!adMapping) {
                    throw new VError('Store error: Ads collection does not exist in resources:');
                }
                const ads = _.map(Object.keys(adMapping), adId => adMapping[adId]);

                const connectedAds = _.filter(ads, ad => _.includes(ad.attr.creative, creativeId));
                const confirmMessage = createConfirmationMessage(
                    audit_status,
                    connectedAds,
                    isSaveAudit
                );

                return dispatch(actions.openEditConfirmationModal({ confirmMessage, isSaveAudit }));
            } else {
                return onValidDraft();
            }
        };
    },

    submitDraft(campaignId, creativeId, isSaveAudit = false) {
        if (!campaignId) {
            throw new Error('Edit Creative campaignId not found');
        }

        return (dispatch, getState) => {
            dispatch({
                type: c.CREATIVE_FORM__SUBMIT,
                payload: { campaignId, creativeId },
            });

            // Deciding the value of audit_status
            let _audit_status = defaultSetting.meta.audit_status;
            if (!isSaveAudit) {
                _audit_status = 'no_audit';
            } else {
                _audit_status = 'pending';
            }

            const draft = _.get(getState(), `creativeForm.draft`, {});

            // delete vendorOptions from draft as we do not store them in backend
            const draftWithoutOptions = _.omit(draft, [
                'creativeVendorsOptions',
                'trackingVendorsOptions',
            ]);

            draftWithoutOptions.third_party_vendors = [
                ...draftWithoutOptions.creative_vendors,
                ...draftWithoutOptions.tracking_vendors,
            ];

            const payload_beforeSerialized = {
                ...draftWithoutOptions,
                audit_status: _audit_status,
            };
            return Promise.resolve(payload_beforeSerialized);
        };
    },

    submitDraftAndAudit(campaignId, creativeId) {
        return dispatch => {
            const isSaveAudit = true;
            return dispatch(actions.submitDraft(campaignId, creativeId, isSaveAudit));
        };
    },

    openEditConfirmationModal({ confirmMessage, isSaveAudit }) {
        return {
            type: 'CREATIVE_FORM__OPEN_CREATIVE_CONFIRMATION_MODAL',
            payload: { confirmMessage, isSaveAudit },
        };
    },

    closeEditConfirmationModal() {
        return {
            type: 'CREATIVE_FORM__CLOSE_CREATIVE_CONFIRMATION_MODAL',
            payload: {},
        };
    },

    close(campaignId, creativeId) {
        return {
            type: c.CREATIVE_FORM__CLOSE,
            payload: { campaignId, creativeId },
        };
    },

    showServerErrors(error) {
        return {
            type: c.CREATIVE_FORM__SUBMIT_FAIL,
            error,
            payload: {},
        };
    },

    showErrors() {
        return {
            type: 'CREATIVE_FORM__SHOW_ERRORS',
            payload: {},
        };
    },

    nextStep({ creativePlatformRestrictions = [] } = {}) {
        return {
            type: 'CREATIVE_FORM__NEXT_STEP',
            payload: { creativePlatformRestrictions },
        };
    },

    togglePlatform(platform) {
        return {
            type: 'CREATIVE_FORM__TOGGLE_PLATFORMS',
            payload: {
                platform,
            },
        };
    },

    changeName(name) {
        return {
            type: 'CREATIVE_FORM__CHANGE_NAME',
            payload: {
                name,
            },
        };
    },

    changeType(type) {
        return {
            type: 'CREATIVE_FORM__CHANGE_TYPE',
            payload: {
                type,
            },
        };
    },

    togglePlacement(placement) {
        return {
            type: 'CREATIVE_FORM__TOGGLE_PLACEMENTS',
            payload: {
                placement,
            },
        };
    },

    togglePosition(position) {
        return {
            type: 'CREATIVE_FORM__TOGGLE_POSITIONS',
            payload: {
                position,
            },
        };
    },

    changeSize(size) {
        return {
            type: 'CREATIVE_FORM__CHANGE_SIZE',
            payload: {
                size,
            },
        };
    },

    changeAspectRatio(aspect_ratio) {
        return {
            type: 'CREATIVE_FORM__CHANGE_ASPECT_RATIO',
            payload: {
                aspect_ratio,
            },
        };
    },

    changeAudio(audio_required) {
        return {
            type: 'CREATIVE_FORM__CHANGE_AUDIO',
            payload: {
                audio_required,
            },
        };
    },

    changeFormat(format) {
        return {
            type: 'CREATIVE_FORM__CHANGE_FORMAT',
            payload: {
                format,
            },
        };
    },

    changeAssetSelectionMethod(method) {
        return {
            type: 'CREATIVE_FORM__CHANGE_ASSET_SELECTION_METHOD',
            payload: {
                method,
            },
        };
    },

    toggleClicktracker(clicktracker) {
        return {
            type: 'CREATIVE_FORM__TOGGLE_CLICKTRACKER',
            payload: {
                clicktracker,
            },
        };
    },
};

// ----------------------------------
// Helper function for Audit Messages
// ----------------------------------
function createConfirmationMessage(audit_status, connectedAds, isSaveAudit) {
    const onSaveMessages = [];
    onSaveMessages[0] = '';
    onSaveMessages[1] =
        "You're about to modify a locked creative. Doing so will cancel its existing audit status.";
    onSaveMessages[2] = 'The creative must be submitted for approval again.';
    onSaveMessages[3] = 'The creative will be submitted for approval again.';
    onSaveMessages[4] =
        'The following ads associated with this creative will be temporarily paused until this creative has been reapproved:';
    onSaveMessages[5] = 'Proceed?';

    const connectedAdsAsMessage = _.reduce(
        connectedAds,
        (result, ad) => {
            return result + `#${ad.id} - ${ad.attr.name}\n`;
        },
        ''
    );

    let message = '';

    if (audit_status === 'pending') {
        message =
            `${onSaveMessages[1]}\n\n` +
            `${onSaveMessages[isSaveAudit ? 3 : 2]}\n\n` +
            `${onSaveMessages[5]}`;
    }

    if (audit_status === 'approved') {
        message =
            `${onSaveMessages[1]}\n\n` +
            `${onSaveMessages[isSaveAudit ? 3 : 2]}\n\n` +
            `${onSaveMessages[5]}`;
        if (connectedAds.length) {
            message =
                `${onSaveMessages[1]}\n\n` +
                `${onSaveMessages[isSaveAudit ? 3 : 2]}\n\n` +
                `${onSaveMessages[4]}\n\n` +
                `${connectedAdsAsMessage}\n\n` +
                `${onSaveMessages[5]}`;
        }
    }

    return message;
}

export default actions;
