import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MuiToolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import {
    TacticSummary,
    WeatherConditionSummary,
    getAgeGroups,
    getConversionsForCreativeOptimization,
    getDayPart,
    getDeviceLanguage,
    getDeviceLanguageExclude,
    getDeviceOs,
    getFlexibleFrequency,
    getGenders,
    getGeofencing,
    getIABCat,
    getTargetCarriers,
    getWeekparting,
    labelWeatherConditionQualifier,
    labelWeatherConditionTimeframe,
    labelWeatherConditionValue,
} from 'containers/ad-summary';
import { hasAnyAudiences, hasAnyPois } from 'states/resources/ads/business-logic';
import notify from 'utils/notify';

import {
    getTargetCarriersExclude,
    getTargetCarriersInclude,
} from 'common/constants/carrier-targeting-v2';
import { hasWeatherConditions } from 'common/constants/weather-condition-targeting';

import actions from './actions';
import selector from './selector';

import {
    calculateThirdPartyFees as calculateAdThirdPartyFees,
    calculateFrequencyCapMode,
    getDaysRunning,
    isTacticsEnabled,
    updateFrequencyCap,
    updateFrequencyCapOverall,
} from 'states/resources/ads/business-logic';
import {
    formatDate_formSelect,
    formatNumber_currency,
    formatNumber_whole,
    formatNumber_wholeFixed,
} from 'utils/formatting';

import { FEATURE_NOTIFICATIONS } from 'common/constants/feature-notifications';
import c from 'common/constants/flux-events';
import { LEVEL_BY_VALUE } from 'common/constants/forensiq-risk-levels';

import { getEnvironmentSettings } from 'services/environment';
import formatGeoCountry from 'services/gis/geo-country-format';
import Profile from 'states/profile';
import { isInternalUser } from 'states/profile/business-rules';

import AdPresetModal from './modules/ad-preset-modal';
import Audience from './modules/audience';
import { Currency, Name, NetsuiteID, Notes } from './modules/basic';
import BidStrategyWrapper from './modules/bid-strategy';
import BrandSafety from './modules/brand-safety';
import { Revenue, RevenueBudget, ThirdPartyFeesBudget } from './modules/budget';
import { TargetAgeGroups_EF_1418, TargetGenders } from './modules/checkbox-groups';
import {
    ConversionsForCreativeOptimization,
    ConversionsForCreativeOptimizationSummary,
} from './modules/conversions-for-creative-optimization';
import Creative from './modules/creative';
import FootTrafficAttribution from './modules/foot-traffic-attribution';
import LocationList from './modules/foot-traffic-attribution/location-list';
import FootTrafficAttributionV2 from './modules/foot-traffic-attribution/v2';
import Geoboxes from './modules/geo-boxes';
import Geotargets from './modules/geotargets';
import IASBrandSafety from './modules/ias-brand-safety';
import IASFraudPrevention from './modules/ias-fraud-prevention';
import IASGroupmViewability from './modules/ias-groupm-viewability';
import IASPublicisViewability from './modules/ias-publicis-viewability';
import IASViewability from './modules/ias-viewability';
import AdFormInventory from './modules/inventory/adFormInventory';
import { FrequencyCap } from './modules/optimization';
import {
    ClicksPacing,
    ImpressionsPacing,
    RevenuePacing,
    TotalMediaSpendPacing,
} from './modules/pacing';
import Platform from './modules/platform';
import PointsOfInterest from './modules/points-of-interest';
import { ScheduleDayparts, ScheduleWeekparts, StartEnd, Timezone } from './modules/schedule';
import Tactics from './modules/tactics';
import TargetCarrieries from './modules/target-carrieries';
import { TargetCarriersIsps, TargetCarriersIspsSummary } from './modules/target-carriers-isps';
import { TargetDeviceLanguage } from './modules/target-device-language';
import { TargetDeviceLanguageExclude } from './modules/target-device-language-exclude';
import TargetIABCategories from './modules/target-iab-categories';
import { AppCategories, DeviceOS } from './modules/targeting';
import { WeatherConditions } from './modules/weather-conditions';

import { IAS_FRAUD_PREVENTION_RISK_MAPPING } from 'common/constants/ias-fraud-prevention';
import { IAS_GROUPM_VIEWABILITY_MAPPING } from 'common/constants/ias-groupm-viewability';
import { IAS_PUBLICIS_VIEWABILITY_MAPPING } from 'common/constants/ias-publicis-viewability';
import { IAS_VIEWABILITY_MAPPING } from 'common/constants/ias-viewability';
import { PLATFORM_MAPPING } from 'common/constants/platforms';
import { IAS_BRAND_SAFETY_MAPPING } from 'common/constants/ias-brand-safety';

import CenterLayout from 'widgets-v5/center-layout';
import AccordionItem from 'widgets-v5/expansion-panel-item';
import { InventoryIndicatorWithDailyPrediction } from 'widgets-v5/inventory-indicator';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import Spacer from 'widgets-v5/spacer';
import { BodyText, Caption, Label } from 'widgets-v5/typography';
import { IncludeExcludeLayout } from 'widgets-v6/layout';
import { MuiFormLabel } from 'widgets-v6/standard-input';
import { DottedStepper } from 'widgets-v6/stepper';
import { Creatives } from 'widgets/summary-view/summary-type-creative';

import { getCarrierIspOptions } from 'common/constants/carrier-targeting-v2';
import {
    SetupWarningConfirmation,
    WarningText,
} from 'forms/ad-form/modules/cross-platform-setup-warning';
import LockedMessage from './modules/locked-message';

import { useAdForm } from './v2';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

const styles = () => ({
    testing: {
        color: 'inherit',
    },
    bidStrategyTextField: {
        whiteSpace: 'nowrap',
    },
    bidStrategyDescription: {
        marginLeft: 30,
    },
    bidStrategyBody: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 25,
    },
    formContent: {
        marginBottom: 70,
    },
    appListError: {
        marginTop: 10,
        marginLeft: 30,
        color: 'red',
    },
});

const OptionCaption = withStyles(() => ({
    root: {
        marginLeft: '30px',
        width: '60%',
    },
}))(Typography);

class AdForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedPanel: '',
        };
    }

    componentDidMount() {
        const { dispatch, campaignId, adId, getInitialDraft, useTactics, profileRole } = this.props;

        this.updatePrediction = _.debounce(() => {
            if (profileRole !== 'analyst') {
                dispatch(actions.updatePrediction());
            }
        }, 1000);

        dispatch(actions.open(getInitialDraft, campaignId, adId, useTactics));
    }

    updateDraft = changes => {
        const {
            campaignId,
            adId,
            campaignBillingEnabled,
            campaignBudgetEnabled,
            campaignBillingRate,
            campaignBillingTerm,
        } = this.props;

        this.props.dispatch(
            actions.updateDraft(changes, campaignId, adId, {
                campaignBillingEnabled,
                campaignBudgetEnabled,
                campaignBillingRate,
                campaignBillingTerm,
            })
        );
    };

    getCarrierIspOptions = platform => {
        if (platform === 'desktop') {
            return CANADIAN_ISPs;
        } else {
            return CANADIAN_US_CARRIERS_ISPs;
        }
    };

    handleChangeLineId = lineId => {
        this.props.dispatch(actions.updateFtaLineId(lineId));
    };

    handleChangePartnerId = partnerId => {
        this.props.dispatch(actions.updateFtaPartnerId(partnerId));
    };

    tacticsEnabled = () => {
        const { formData, campaignId } = this.props;

        if (formData && campaignId) {
            return isTacticsEnabled(formData.tactics_enabled, campaignId);
        }

        return false;
    };
    onFieldChange = fieldName => {
        return fieldValue => {
            const newFormData = {
                [fieldName]: fieldValue,
            };
            this.updateDraft(newFormData);

            this.updatePrediction();
        };
    };

    updateFrequencyCap = fieldName => {
        const { props } = this;

        return newValue => {
            const { formData: draft, shouldShowFrequencyCapWarningOnChange } = props;

            this.onFieldChange('fcaps')(updateFrequencyCap(draft, fieldName, newValue));

            if (shouldShowFrequencyCapWarningOnChange) {
                props.dispatch(actions.updateFrequencyCapWarning());
            }
        };
    };

    toggleFrequencyCapMode = () => {
        const { formData: draft } = this.props;

        if (draft.fcaps.imp === null) {
            this.updateDraft({
                fcaps: updateFrequencyCapOverall(draft, {
                    imp: 4,
                    interval_count: 1,
                    interval_unit: 'days',
                }),
            });
        } else {
            this.updateDraft({
                fcaps: updateFrequencyCapOverall(draft, {
                    imp: null,
                    interval_count: null,
                    interval_unit: null,
                }),
            });
        }

        this.updatePrediction();
    };

    showGeoEntityInMap = (entityType, id) => {
        const { campaignId, adId } = this.props;
        this.props.dispatch(actions.showGeoEntityInMap(campaignId, adId, entityType, id));
    };

    handlePoiSearchStringChanged = searchString => {
        const { campaignId, adId } = this.props;
        this.props.dispatch(actions.poiSearchStringChanged(campaignId, adId, searchString));
    };

    handleGeoLayerFinder_folderStateChanged = (nodeId, folderState) => {
        const { campaignId, adId } = this.props;
        this.props.dispatch(
            actions.geoLayerFinder_folderStateChanged(campaignId, adId, nodeId, folderState)
        );
    };

    startAsSoonAsPossible = () => {
        // emit action which will set `startNow` property in session to true or false
        // That will cause the date time widget to emit the current date
        const { campaignId, adId } = this.props;
        this.props.dispatch(actions.setStartToASAP(campaignId, adId));

        this.updatePrediction();
    };

    handleButtonClick_submit = () => {
        const { dispatch, campaignId, isSubmitting } = this.props;

        if (isSubmitting) {
            return;
        }

        dispatch(actions.submitDraft(campaignId)).then(
            draft => {
                return this.props.submit(draft).then(null, error => {
                    if (error[0] === 'Unknown Error') {
                        notify({ error });
                    } else {
                        dispatch(actions.showServerErrors(error));
                    }
                });
            },
            () => {
                dispatch(actions.showErrors());
            }
        );
    };

    validateAdDraft = () => {
        const { dispatch, isSubmitting, campaignId } = this.props;
        const { overrideDealPriceWarning, hasCrossPlatformCampaignWarning } = this.props.adForm2;
        if (isSubmitting) {
            return;
        }
        dispatch(
            actions.validateDraft({
                onValidForm: this.handleButtonClick_submit,
                campaignId,
                overrideDealPriceWarning,
                hasCrossPlatformCampaignWarning,
            })
        );
    };

    handleCancelSave = () => {
        const { dispatch } = this.props;
        dispatch(actions.cancelSave());
    };

    handleButtonClick_cancel = () => {
        const { adId, campaignId } = this.props;

        this.props.dispatch({
            type: c.AD_FORM__CLOSE,
            payload: { adId, campaignId },
        });

        this.props.cancel();
    };

    openAdPresetModal = () => {
        const { dispatch, isAdPresetModalOpen, campaignId, adId } = this.props;
        dispatch(actions.openAdPresetModal(isAdPresetModalOpen, campaignId, adId));
    };

    closeAdPresetModal = () => {
        const { dispatch, isAdPresetModalOpen, campaignId, adId } = this.props;
        dispatch(actions.closeAdPresetModal(isAdPresetModalOpen, campaignId, adId));
    };

    setAdPreset = adpreset => {
        const { dispatch, campaignId, adId, campaignById } = this.props;
        const campaign = campaignById.attr;

        const adpresetWithoutCarriers = _.omit(adpreset, ['target_carriers']);
        if (campaign.carriersISPsVersion === 2) {
            dispatch(actions.setAdPreset(adpresetWithoutCarriers, campaignId, adId));
        } else {
            dispatch(actions.setAdPreset(adpreset, campaignId, adId));
        }

        dispatch(actions.updatePrediction());
    };

    switchCreativeMode = (newMode, selectedCreatives) => {
        const { dispatch } = this.props;
        dispatch(actions.switchCreativeMode(newMode, selectedCreatives));

        this.updatePrediction();
    };

    changeCreativeRotationWeight = (creativeId, newWeightAsWholeNumber) => {
        const { dispatch } = this.props;
        dispatch(actions.changeCreativeRotationWeight(creativeId, newWeightAsWholeNumber));
    };

    selectSingleCreative = creativeId => {
        const { dispatch } = this.props;
        dispatch(actions.selectSingleCreative(creativeId));

        this.updatePrediction();
    };

    selectEvenCreative = creativeIds => {
        const { dispatch } = this.props;
        dispatch(actions.selectEvenCreative(creativeIds));
    };

    selectWeightedCreative = creativeIds => {
        const { dispatch } = this.props;
        dispatch(actions.selectWeightedCreative(creativeIds));
    };

    selectMultiCreative = creativeIds => {
        const { dispatch } = this.props;
        dispatch(actions.selectMultiCreative(creativeIds));
    };

    clearCreatives = () => {
        const { dispatch } = this.props;
        dispatch(actions.clearCreatives());

        this.updatePrediction();
    };

    updateCreativeScheduleRow = (rowId, data) => {
        const { dispatch } = this.props;
        dispatch(actions.updateCreativeScheduleRow(rowId, data));
    };

    addCreativeScheduleRow = () => {
        const { dispatch } = this.props;
        dispatch(actions.addCreativeScheduleRow());
    };

    handleCreativeRotationByWeatherChange = ({ draft, errors }) => {
        this.props.dispatch(actions.updateSubmoduleDraft({ errors, draft }));
        this.props.dispatch(actions.refreshAdFormWarnings());
    }

    removeCreativeScheduleRow = rowId => {
        const { dispatch } = this.props;
        dispatch(actions.removeCreativeScheduleRow(rowId));
    };

    handleChangeFtaEnabled = ({ partnerId, ftaFee }) => checked => {
        this.updateDraft({
            fta_fee: checked ? ftaFee : 0,
            fta: {
                ...this.props.formData.fta,
                enabled: checked,
                partner_id: checked ? partnerId : null,
                line_id: null,
            },
        });
    };

    handleChangeSegments = ({ includedItems, excludedItems, geo_targeting_settings }) => {
        this.props.dispatch(
            actions.updateAudiences({
                includedItems,
                excludedItems,
                geo_targeting_settings,
            })
        );

        this.updatePrediction();
    };

    handleUpdateModuleDraft = ({ draft, errorKey, errors }) => {
        this.props.dispatch(
            actions.updateModuleDraft({
                errorKey,
                draft,
                errors,
            })
        );

        this.updatePrediction();
    };

    getPacingValue = term => {
        const { formData } = this.props;

        const termMapping = {
            CPM: 'max_daily_impressions',
            CPC: 'max_daily_clicks',
            billable_media_cost_markup: 'max_daily_spend_local',
            billable_media_cost_margin: 'max_daily_billings',
        };

        if (!formData[termMapping[term]]) {
            return 'Auto';
        }

        switch (term) {
            case 'CPM':
                return formatNumber_whole(formData.max_daily_impressions);
            case 'CPC':
                return formatNumber_whole(formData.max_daily_clicks);
            case 'billable_media_cost_markup':
                return formatNumber_currency(formData.max_daily_spend_local);
            case 'billable_media_cost_margin':
                return formatNumber_currency(formData.max_daily_billings);
        }
    };

    addPanel = key => {
        this.props.dispatch(actions.addPanel(key));
    };

    expandPanel = panel => {
        return () => {
            this.state.expandedPanel === panel
                ? this.setState({
                      expandedPanel: '',
                  })
                : this.setState({
                      expandedPanel: panel,
                  });
        };
    };

    handlePlatformChange = () => {
        const { isCrossPlatformCampaign } = this.props;
        if (!isCrossPlatformCampaign) {
            this.onFieldChange('exchanges_blocked_ui')([]);
            this.onFieldChange('exchanges_blocked')([]);
            this.onFieldChange('target_carriers')([]);
            this.onFieldChange('carriers_exclude')([]);
            this.updatePrediction();
        }
    };

    getBillingsListDataCostLabel = formData => {
        const ftaFee = formData.fta_fee;

        if (formData.tactics_generators.length === 0) {
            return formatNumber_wholeFixed(ftaFee);
        }

        const tacticCosts = [];
        _.each(formData.tactics_generators, tactic => {
            const targetingSegmentCosts = [0];
            _.each(tactic.targeting, target => {
                if (target.module === 'audience_segments' && target.draft) {
                    targetingSegmentCosts.push(target.draft.audience_fee + ftaFee);
                } else {
                    targetingSegmentCosts.push(0 + ftaFee);
                }
            });

            tacticCosts.push(Math.max(...targetingSegmentCosts));
        });

        const dataCosts = [...tacticCosts];

        const minCost = _.min(dataCosts);
        const maxCost = _.max(dataCosts);

        if (minCost === maxCost) {
            return formatNumber_wholeFixed(maxCost);
        }

        return `${formatNumber_wholeFixed(minCost)}-${formatNumber_wholeFixed(maxCost)}`;
    };

    addDateRange = () => {
        this.props.dispatch(actions.addScheduledWeightedCreativeDateRange());
    };

    removeMultiCreatives = ({ dateRangeIndex }) => {
        this.props.dispatch(actions.removeMultiCreatives({ dateRangeIndex }));
    };

    removeScheduledWeightedCreative = ({ dateRangeIndex, creativeIndex }) => {
        this.props.dispatch(
            actions.removeScheduledWeightedCreative({ dateRangeIndex, creativeIndex })
        );
    };

    removeWarningMessage = () => {};

    removeDateRange = dateRangeIndex => {
        this.props.dispatch(actions.removeDateRange(dateRangeIndex));
    };

    changeScheduledWeightedCreativeEndDate = ({ dateRangeIndex, end }) => {
        this.props.dispatch(
            actions.changeScheduledWeightedCreativeEndDate({ dateRangeIndex, end })
        );
    };

    selectScheduledWeightedCreative = ({ dateRangeIndex, creativeIndex, creativeId }) => {
        this.props.dispatch(
            actions.selectScheduledWeightedCreative({
                dateRangeIndex,
                creativeIndex,
                creativeId,
            })
        );
    };

    changeScheduledWeightedCreativeWeight = ({ dateRangeIndex, creativeIndex, weight }) => {
        this.props.dispatch(
            actions.changeScheduledWeightedCreativeWeight({
                dateRangeIndex,
                creativeIndex,
                weight,
            })
        );
    };

    changeScheduledEvenWeighted = ({ dateRangeIndex }) => {
        this.props.dispatch(
            actions.changeScheduledEvenWeighted({
                dateRangeIndex,
            })
        );
    };

    addScheduledWeightedCreative = dateRangeIndex => {
        this.props.dispatch(actions.addScheduledWeightedCreative(dateRangeIndex));
    };

    getPacingStrategyValue = () => {
        const { formData } = this.props;
        const { use_front_load_pacing } = formData;
        const goalType = this.getGoalType();
        return use_front_load_pacing
            ? `${_.isNil(goalType) ? `Front-loaded Pacing` : `Front-loaded Pacing: ${goalType}`}`
            : `${_.isNil(goalType) ? `Even Pacing` : `Even Pacing: ${goalType}`}`;
    };

    setPacingStrategyValue = e => {
        const isUsingFrontLoadPacing = e.target.value === 'true';
        this.onFieldChange('use_front_load_pacing')(isUsingFrontLoadPacing);
    };

    updateWeatherConditions = ({ errors, draft }) =>
        this.props.dispatch(actions.updateSubmoduleDraft({ errors, draft }));

    getGoalType = () => {
        const { formData } = this.props;
        const mapping = {
            CPM: 'Impressions',
            CPC: 'Clicks',
            billable_media_cost_markup: 'Total Cost',
        };

        return mapping[formData.ef_billing_terms];
    };

    render() {
        const {
            formData,
            errors,
            errorsByField,
            showErrors,
            campaignId,
            creativesByCampaign,
            adId,
            campaignBillingEnabled,
            profileToken,
            profileRole,
            startNow,
            dispatch,
            history,
            location,
            isIasEnabled,
            creativesForCreativeSelector,
            scheduledRotationWarningEnabled,
            rotationMode,
            assignedPacing,
            pacingColumnEnabled,
            totalRotationWeight,
            geoCategories,
            geoLayerSearchString,
            currency,
            geofencingEntityInView,
            poiViewer_eTag,
            inventoryPredictionLoading,
            inventoryPredictionError,
            adForm,
            mode,
            showFrequencyCapWarning,
            visiblePanel,
            applists,
            dealsByOrganization,
            classes,
            ftaLocationListsMapping,
            ownOrganization,
            shouldUseIasPostBidVerification,
            isCrossPlatformCampaign,
            adFormSetupWarnings,
            advertiser,
            adForm2,
            orgId,
        } = this.props;

        // log errors for production debugging
        if (window.DEBUG) {
            console.log('errors', errors);
        }
        if ((formData && Object.keys(formData).length === 0) || !formData) {
            return (
                <CenterLayout>
                    <BlockLoadGroup isLoading={true} />
                </CenterLayout>
            );
        }
        const { adPresets } = this.props;
        const audienceRates = _.get(this.props, 'campaignById.attr.audience_rates', []);
        const shouldHideFlexSegments = _.get(
            this.props,
            'campaignById.attr.shouldHideFlexSegments'
        );
        const { geo_targeting_settings } = formData;
        const categories = _.get(geo_targeting_settings, 'categories');
        const categoryLayers = _.get(geo_targeting_settings, 'category_layers');
        const customLayers = _.get(geo_targeting_settings, 'custom_layers');
        const audiences = !shouldHideFlexSegments
            ? _.concat(formData.audiences, categories, categoryLayers, customLayers)
            : formData.audiences;

        const adPresetArray = _.map(_.keys(adPresets), adPresetId => {
            return adPresets[adPresetId].attr;
        });

        const showPlatformRestrictedFields = isCrossPlatformCampaign
            ? true
            : formData.platform === 'inapp';

        const campaign = this.props.campaignById.attr;
        const isCTVCampaign = campaign.type === CampaignTypeMapping.CTV;
        const isDOOHCampaign = campaign.type === CampaignTypeMapping.DOOH;
        const canShowGeoFencing =
            (isCrossPlatformCampaign || formData.platform === 'inapp') && !isCTVCampaign;

        if (this.props.isLoading) {
            return (
                <CenterLayout>
                    <BlockLoadGroup isLoading={true} />
                </CenterLayout>
            );
        }

        const tacticsEnabled = this.tacticsEnabled();

        const daypartValue = {
            field: _.isArray(formData.dayparts) ? 'dayparts' : 'dayparts_local',
            value: _.isArray(formData.dayparts) ? formData.dayparts : formData.dayparts_local,
        };
        const weekpartValue = {
            field: _.isArray(formData.weekparts) ? 'weekparts' : 'weekparts_local',
            value: _.isArray(formData.weekparts) ? formData.weekparts : formData.weekparts_local,
        };

        const billableVolume = Math.max(0, formData.billable_volume);
        const billingEnabled = formData.billing_enabled && campaignBillingEnabled;

        const thirdPartyFees = calculateAdThirdPartyFees(formData);

        const canRoleUseInventoryForecast = _.includes(
            ['admin', 'campaign_manager', 'media_planner'],
            profileRole
        );

        const showCarrierSection =
            this.props.campaignCarriersISPsVersion === 2 ||
            (this.props.campaignCarriersISPsVersion === 1 && showPlatformRestrictedFields);

        const gridLayout = isCrossPlatformCampaign ? false : canRoleUseInventoryForecast;

        const {
            fraudPrevention,
            brandSafety,
            viewability,
            groupmViewability,
            publicisViewability,
        } = formData.ias;

        const hasRotationErrors = errors => {
            return !!_.find(_.keys(errors), field => field.indexOf('rotation_rules') === 0);
        };

        const hasWeatherConditionErrors = errors => {
            return !!_.find(_.keys(errors), key => _.includes(key, 'weatherConditions'));
        };

        const ftaEnabledAtOrgLevel = !!ownOrganization.ftaEnabled;

        const conversionsOptions = _.map(this.props.conversions, conversion => ({
            value: conversion.event_name,
            label: conversion.reporting_name,
        }));

        const advertiserConversions = _.map(campaign.advertiserConversions, conversion => ({
            value: conversion.event_name,
            label: conversion.reporting_name,
        }));

        conversionsOptions.push(...advertiserConversions);

        const conversionsInCampaign = _.concat(
            this.props.conversions,
            campaign.advertiserConversions
        );

        const creativeWarning = _.get(adFormSetupWarnings, 'creative', {});

        const environmentSettings = getEnvironmentSettings();

        let currencyLabel = '';
        if (environmentSettings.showCurrencyLabel()) {
            currencyLabel = campaign.currency || 'USD';
        }

        let isCreativeFieldEmpty;

        if (formData.rotation_rules.mode === CreativeRotationMethodMapping.Scheduled) {
            isCreativeFieldEmpty =
                _.find(_.keys(errors), key => key === 'rotation_rules') ||
                _.find(_.keys(errors), key => _.includes(key, 'rotation_rules.scheduled')) ||
                _(formData.rotation_rules.scheduled)
                    .map(rotation => rotation.weighted)
                    .flatten()
                    .map(weighted => weighted.markup_id)
                    .filter(markup => markup)
                    .isEmpty();
        } else if (
            formData.rotation_rules.mode === CreativeRotationMethodMapping.OptimizedForClicks
        ) {
            isCreativeFieldEmpty = _(formData.rotation_rules.weighted)
                .map(weighted => weighted.markup_id)
                .filter(markup => markup)
                .isEmpty();
        } else if (formData.rotation_rules.mode === CreativeRotationMethodMapping.Weather) {
            isCreativeFieldEmpty =
                _.find(_.keys(errors), key => _.includes(key, 'rotation_rules.weather')) ||
                _(formData.rotation_rules.weather)
                    .map(weather => weather.markupId)
                    .filter(markup => markup)
                    .isEmpty();
        }

        const isProspectMedia = orgId === '61ba37fb834aef8e532e0687';

        return (
            <div>
                <div className={classes.formContent}>
                    <Grid container spacing={1}>
                        <Grid item xs={gridLayout ? 9 : 12}>
                            <Container maxWidth="lg">
                                <div>
                                    <Toolbar>
                                        <Typography variant="h6">
                                            #{adForm.campaign.id} {adForm.campaign.name}
                                        </Typography>
                                        {showPlatformRestrictedFields &&
                                            environmentSettings.canUseAdPresets() && (
                                                <AdPresetModal
                                                    campaign={adForm.campaign}
                                                    adPresets={adPresetArray}
                                                    isModalOpen={this.props.isAdPresetModalOpen}
                                                    openModal={this.openAdPresetModal}
                                                    dispatch={this.props.dispatch}
                                                    closeModal={this.closeAdPresetModal}
                                                    setAdPreset={this.setAdPreset}
                                                    adPlatforms={this.props.formData.platforms}
                                                />
                                            )}
                                    </Toolbar>
                                    <Typography>Basic</Typography>
                                    <Spacer />
                                    <AccordionItem
                                        onClick={this.expandPanel('platform')}
                                        expanded={this.state.expandedPanel === 'platform'}
                                        title={
                                            isCrossPlatformCampaign ? (
                                                <React.Fragment>
                                                    <span>Platforms</span>
                                                </React.Fragment>
                                            ) : (
                                                'Platform'
                                            )
                                        }
                                        details={
                                            <Platform
                                                onChange={this.handlePlatformChange}
                                                isCrossPlatformCampaign={isCrossPlatformCampaign}
                                            />
                                        }
                                        summary={
                                            <PlatformSummary
                                                formData={formData}
                                                isCrossPlatformCampaign={isCrossPlatformCampaign}
                                            />
                                        }
                                    />
                                    <AccordionItem
                                        onClick={this.expandPanel('name')}
                                        expanded={this.state.expandedPanel === 'name'}
                                        title="Name"
                                        required
                                        incomplete={!showErrors && !formData.name}
                                        error={showErrors && errors.name}
                                        details={
                                            <Name
                                                value={adForm2.draft.name}
                                                onFieldChange={name => adForm2.changeName(name)}
                                                errors={adForm2.errors.name}
                                                showErrors={adForm2.showErrors}
                                            />
                                        }
                                        summary={
                                            formData.name ? (
                                                <Label>{formData.name}</Label>
                                            ) : (
                                                <Label placeholder>Add a name</Label>
                                            )
                                        }
                                        helper="This information will be used to identify your ad."
                                    />
                                    <AccordionItem
                                        onClick={this.expandPanel('currency')}
                                        expanded={this.state.expandedPanel === 'currency'}
                                        title="Currency"
                                        readOnly
                                        details={<Currency currency={currency} />}
                                        summary={<Label>{campaign.currency}</Label>}
                                    />
                                    {_.map(formData.custom_fields, (customField, i) => (
                                        <AccordionItem
                                            key={i}
                                            onClick={this.expandPanel(customField.name)}
                                            expanded={this.state.expandedPanel === customField.name}
                                            title={customField.name}
                                            incomplete={
                                                !showErrors &&
                                                customField.required &&
                                                !customField.value
                                            }
                                            error={showErrors && errors[`custom_fields.${i}.value`]}
                                            required={customField.required}
                                            details={
                                                <NetsuiteID
                                                    formData={formData}
                                                    onFieldChange={this.onFieldChange}
                                                    errors={errors[`custom_fields.${i}.value`]}
                                                    showErrors={showErrors}
                                                    customField={customField}
                                                />
                                            }
                                            summary={
                                                customField.value ? (
                                                    <Label>{customField.value}</Label>
                                                ) : (
                                                    <Label placeholder>
                                                        Add {customField.name}
                                                    </Label>
                                                )
                                            }
                                        />
                                    ))}
                                    <AccordionItem
                                        onClick={this.expandPanel('notes')}
                                        expanded={this.state.expandedPanel === 'notes'}
                                        title="Notes"
                                        error={showErrors && errors.notes}
                                        details={
                                            <Notes
                                                formData={formData.notes}
                                                onFieldChange={this.onFieldChange('notes')}
                                                errors={errors.notes}
                                                showErrors={showErrors}
                                            />
                                        }
                                        summary={
                                            formData.notes ? (
                                                <Label>{formData.notes}</Label>
                                            ) : (
                                                <Label placeholder>Add notes</Label>
                                            )
                                        }
                                    />
                                    <Spacer />
                                    <Typography>Schedule</Typography>
                                    <Spacer />
                                    <AccordionItem
                                        onClick={this.expandPanel('timezone')}
                                        expanded={this.state.expandedPanel === 'timezone'}
                                        title="Timezone"
                                        error={showErrors && errors.timezone}
                                        details={
                                            <Timezone
                                                formData={formData.timezone}
                                                startNow={startNow}
                                                onFieldChange={this.onFieldChange}
                                                errors={errors.timezone}
                                                showErrors={showErrors}
                                            />
                                        }
                                        summary={<Label>{formData.timezone}</Label>}
                                    />
                                    <AccordionItem
                                        onClick={this.expandPanel('schedule')}
                                        disabled={campaign.flightPacingStrategy === 'campaign'}
                                        expanded={this.state.expandedPanel === 'schedule'}
                                        title="Start / End"
                                        required
                                        incomplete={
                                            !showErrors && (!formData.start || !formData.end)
                                        }
                                        error={showErrors && (errors.start || errors.end)}
                                        details={
                                            <Grid xl={6}>
                                                <StartEnd
                                                    startAsSoonAsPossible={
                                                        this.startAsSoonAsPossible
                                                    }
                                                    formData={formData}
                                                    startNow={startNow}
                                                    adId={adId}
                                                    onFieldChange={this.onFieldChange}
                                                    errors={errors}
                                                    showErrors={showErrors}
                                                    isDelivering={this.props.isDelivering}
                                                    isStale={this.props.isStale}
                                                    shouldShowStartASAP={
                                                        this.props.shouldShowStartASAP
                                                    }
                                                />
                                            </Grid>
                                        }
                                        summary={
                                            campaign.flightPacingStrategy === 'campaign' ? (
                                                <LockedMessage
                                                    title={
                                                        <Typography variant="h6">
                                                            Flights enabled at the campaign level
                                                        </Typography>
                                                    }
                                                    body={
                                                        <React.Fragment>
                                                            <Typography variant="h7">
                                                                Ad will use the following flight
                                                                dates:
                                                                <Spacer />
                                                                Start:{' '}
                                                                {formatDate_formSelect({
                                                                    date: formData.start,
                                                                    timezone: formData.timezone,
                                                                })}
                                                                <Spacer />
                                                                End:{' '}
                                                                {formatDate_formSelect({
                                                                    date: formData.end,
                                                                    timezone: formData.timezone,
                                                                })}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                            ) : formData.start === '' ? (
                                                <Label placeholder>
                                                    Select start and end dates
                                                </Label>
                                            ) : (
                                                <React.Fragment>
                                                    <Typography color="textSecondary">
                                                        Start
                                                    </Typography>
                                                    <Label>
                                                        {formatDate_formSelect({
                                                            date: formData.start,
                                                            timezone: formData.timezone,
                                                        })}
                                                    </Label>
                                                    <Typography color="textSecondary">
                                                        End
                                                    </Typography>
                                                    <Label>
                                                        {formatDate_formSelect({
                                                            date: formData.end,
                                                            timezone: formData.timezone,
                                                        })}
                                                    </Label>
                                                </React.Fragment>
                                            )
                                        }
                                    />
                                    <AccordionItem
                                        onClick={this.expandPanel('dayparts')}
                                        expanded={this.state.expandedPanel === 'dayparts'}
                                        title="Dayparts"
                                        error={showErrors && errors.dayparts}
                                        details={
                                            <ScheduleDayparts
                                                startAsSoonAsPossible={this.startAsSoonAsPossible}
                                                formData={formData}
                                                startNow={startNow}
                                                adId={adId}
                                                onFieldChange={this.onFieldChange}
                                                errors={errors}
                                                showErrors={showErrors}
                                                isDelivering={this.props.isDelivering}
                                                isStale={this.props.isStale}
                                            />
                                        }
                                        summary={
                                            <Label>
                                                {getDayPart(
                                                    daypartValue,
                                                    _.isArray(formData.dayparts_local)
                                                        ? 'UTC'
                                                        : formData.timezone,
                                                    formData.start
                                                )}
                                            </Label>
                                        }
                                    />
                                    <AccordionItem
                                        onClick={this.expandPanel('weekparts')}
                                        expanded={this.state.expandedPanel === 'weekparts'}
                                        title={
                                            weekpartValue.field === 'weekparts'
                                                ? 'Weekparts (UTC)'
                                                : 'Weekparts (Local Time)'
                                        }
                                        error={
                                            showErrors && _.isArray(formData.weekparts)
                                                ? errors.weekparts
                                                : errors.weekparts_local
                                        }
                                        details={
                                            <ScheduleWeekparts
                                                startAsSoonAsPossible={this.startAsSoonAsPossible}
                                                formData={formData}
                                                startNow={startNow}
                                                adId={adId}
                                                onFieldChange={this.onFieldChange}
                                                errors={errors}
                                                showErrors={showErrors}
                                                isDelivering={this.props.isDelivering}
                                                isStale={this.props.isStale}
                                            />
                                        }
                                        summary={<Label>{getWeekparting(weekpartValue)}</Label>}
                                        helper="Weekparts can only be set in the UTC timezone."
                                    />
                                    {campaign.revenueModel !== 'disabled' && (
                                        <React.Fragment>
                                            <Spacer />
                                            <Typography>Revenue</Typography>
                                            <Spacer type="small" />
                                            <Caption type="info">
                                                Set how revenue is calculated in reporting and
                                                specify the delivery goal for this ad.
                                            </Caption>
                                            <Spacer />
                                        </React.Fragment>
                                    )}
                                    <Revenue
                                        expandPanel={this.expandPanel}
                                        expanded={this.state.expandedPanel}
                                        formData={formData}
                                        campaign={campaign}
                                        currency={currency}
                                        onFieldChange={this.onFieldChange}
                                        errors={errors}
                                        errorsByField={errorsByField}
                                        showErrors={showErrors}
                                        billingEnabled={billingEnabled}
                                        billableVolume={billableVolume}
                                    />
                                    <Spacer />
                                    <Typography>Budget</Typography>
                                    <Spacer type="small" />
                                    <Caption type="info">
                                        Specify the delivery goal for this ad.
                                    </Caption>
                                    <Spacer />
                                    <RevenueBudget
                                        expandPanel={this.expandPanel}
                                        expanded={this.state.expandedPanel}
                                        formData={formData}
                                        campaign={campaign}
                                        billableVolume={billableVolume}
                                        currency={currency}
                                        onFieldChange={this.onFieldChange}
                                        showErrors={showErrors}
                                        errors={errors}
                                        adOverBudgetWarning={adForm2.adOverBudgetWarning}
                                    />
                                    <AccordionItem
                                        onClick={this.expandPanel('thirdPartyFees')}
                                        expanded={this.state.expandedPanel === 'thirdPartyFees'}
                                        title="Third Party Fees"
                                        error={showErrors && errorsByField && thirdPartyFees > 0}
                                        details={
                                            <ThirdPartyFeesBudget
                                                formData={formData}
                                                campaign={campaign}
                                                currency={currency}
                                                onFieldChange={this.onFieldChange}
                                                errors={errors}
                                                errorsByField={errorsByField}
                                                showErrors={showErrors}
                                            />
                                        }
                                        summary={
                                            formData.thirdPartyFees.length &&
                                            _.find(
                                                formData.thirdPartyFees,
                                                fee => !fee.description || !fee.fee
                                            ) ? (
                                                <Typography color="error">
                                                    Incomplete fields
                                                </Typography>
                                            ) : (
                                                <Label placeholder={thirdPartyFees <= 0}>
                                                    {`${currencyLabel} ${environmentSettings.getCurrencySign()} ${formatNumber_wholeFixed(
                                                        thirdPartyFees
                                                    )} CPM`}
                                                </Label>
                                            )
                                        }
                                        helper="Plan your campaign budget holistically by entering any non-media CPM costs, such as third-party data fees or vendor fees. Third-party fees are automatically deducted from your total cost budget to make sure you don't go over-budget. Changes to this field are not applied retroactively and only take effect for new impressions moving forward."
                                    />
                                    <Spacer />
                                    <Typography>Pacing</Typography>
                                    <Spacer type="small" />
                                    <Caption type="info">
                                        To override the default behaviour, enter a daily goal. If
                                        multiple goals are entered, the ad will stop serving when
                                        any of the goals have been met.
                                    </Caption>
                                    <Spacer />
                                    <AccordionItem
                                        onClick={this.expandPanel('use_front_load_pacing')}
                                        expanded={
                                            this.state.expandedPanel === 'use_front_load_pacing'
                                        }
                                        title="Pacing Strategy"
                                        disabled={campaign.budget_allocation_method === 'auto'}
                                        details={
                                            <RadioGroup
                                                aria-label="use_front_load_pacing"
                                                name="use_front_load_pacing"
                                                value={formData.use_front_load_pacing}
                                                onChange={this.setPacingStrategyValue}
                                            >
                                                <FormControlLabel
                                                    value={false}
                                                    control={<Radio color="primary" />}
                                                    label={`${
                                                        _.isNil(this.getGoalType())
                                                            ? `Even Pacing`
                                                            : `Even Pacing: ${this.getGoalType()}`
                                                    }`}
                                                />
                                                <OptionCaption
                                                    variant="body2"
                                                    paragraph
                                                    color="textSecondary"
                                                >
                                                    System will evenly pace the ad line to deliver
                                                    the same daily goal - either spend, impressions
                                                    or clicks - throughout the duration of the ad
                                                    line flight.
                                                </OptionCaption>
                                                <FormControlLabel
                                                    value={true}
                                                    control={<Radio color="primary" />}
                                                    label={`${
                                                        _.isNil(this.getGoalType())
                                                            ? `Front-loaded Pacing`
                                                            : `Front-loaded Pacing: ${this.getGoalType()}`
                                                    }`}
                                                />
                                                <OptionCaption
                                                    variant="body2"
                                                    paragraph
                                                    color="textSecondary"
                                                >
                                                    System will frontload the ad line to deliver 50%
                                                    of the total goal - either spend, impression or
                                                    clicks, within the first third of the ad line
                                                    flight. Use this method to mitigate under
                                                    delivery risk.
                                                </OptionCaption>
                                            </RadioGroup>
                                        }
                                        summary={
                                            campaign.budget_allocation_method === 'auto' ? (
                                                <LockedMessage
                                                    pacing
                                                    autoBudgetAllocationMethod={
                                                        campaign.automaticBudgetAllocationOptimizationStrategy
                                                    }
                                                />
                                            ) : (
                                                <Label>{this.getPacingStrategyValue()}</Label>
                                            )
                                        }
                                    />
                                    {!formData.use_front_load_pacing &&
                                        campaign.budget_allocation_method !== 'auto' && (
                                            <React.Fragment>
                                                <AccordionItem
                                                    onClick={this.expandPanel(
                                                        'dailyPacingOverride'
                                                    )}
                                                    expanded={
                                                        this.state.expandedPanel ===
                                                        'dailyPacingOverride'
                                                    }
                                                    title="Daily Pacing"
                                                    details={
                                                        <React.Fragment>
                                                            <FormControl
                                                                error={showErrors && errors}
                                                                fullWidth
                                                            >
                                                                <MuiFormLabel>
                                                                    Impressions
                                                                </MuiFormLabel>
                                                                <ImpressionsPacing
                                                                    formData={formData}
                                                                    adId={adId}
                                                                    currency={currency}
                                                                    campaignBillingEnabled={
                                                                        campaignBillingEnabled
                                                                    }
                                                                    onFieldChange={
                                                                        this.onFieldChange
                                                                    }
                                                                    errors={errors}
                                                                    showErrors={showErrors}
                                                                    updateDraft={this.updateDraft}
                                                                />
                                                            </FormControl>
                                                            {!isCTVCampaign && !isDOOHCampaign && (
                                                                <FormControl
                                                                    margin="normal"
                                                                    error={showErrors && errors}
                                                                    fullWidth
                                                                >
                                                                    <MuiFormLabel>
                                                                        Clicks
                                                                    </MuiFormLabel>
                                                                    <ClicksPacing
                                                                        formData={formData}
                                                                        adId={adId}
                                                                        currency={currency}
                                                                        campaignBillingEnabled={
                                                                            campaignBillingEnabled
                                                                        }
                                                                        onFieldChange={
                                                                            this.onFieldChange
                                                                        }
                                                                        errors={errors}
                                                                        showErrors={showErrors}
                                                                        updateDraft={
                                                                            this.updateDraft
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                            {formData.ef_billing_terms ===
                                                                'billable_media_cost_margin' && (
                                                                <FormControl
                                                                    margin="normal"
                                                                    error={showErrors && errors}
                                                                    fullWidth
                                                                >
                                                                    <MuiFormLabel>
                                                                        Revenue
                                                                    </MuiFormLabel>
                                                                    <RevenuePacing
                                                                        formData={formData}
                                                                        adId={adId}
                                                                        currency={currency}
                                                                        campaignBillingEnabled={
                                                                            campaignBillingEnabled
                                                                        }
                                                                        onFieldChange={
                                                                            this.onFieldChange
                                                                        }
                                                                        errors={errors}
                                                                        showErrors={showErrors}
                                                                        updateDraft={
                                                                            this.updateDraft
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                            {formData.ef_billing_terms !==
                                                                'billable_media_cost_margin' && (
                                                                <FormControl
                                                                    margin="normal"
                                                                    error={showErrors && errors}
                                                                    fullWidth
                                                                >
                                                                    <MuiFormLabel>
                                                                        Total Cost
                                                                    </MuiFormLabel>
                                                                    <TotalMediaSpendPacing
                                                                        formData={formData}
                                                                        adId={adId}
                                                                        currency={currency}
                                                                        campaignBillingEnabled={
                                                                            campaignBillingEnabled
                                                                        }
                                                                        onFieldChange={
                                                                            this.onFieldChange
                                                                        }
                                                                        errors={errors}
                                                                        showErrors={showErrors}
                                                                        updateDraft={
                                                                            this.updateDraft
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        </React.Fragment>
                                                    }
                                                    summary={
                                                        <React.Fragment>
                                                            {this.getPacingValue('CPM') ===
                                                                'Auto' &&
                                                            this.getPacingValue('CPC') === 'Auto' &&
                                                            this.getPacingValue(
                                                                'billable_media_cost_margin'
                                                            ) === 'Auto' &&
                                                            this.getPacingValue(
                                                                'billable_media_cost_markup'
                                                            ) === 'Auto' ? (
                                                                <Label>Auto</Label>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {this.getPacingValue('CPM') !==
                                                                        'Auto' && (
                                                                        <React.Fragment>
                                                                            <Typography color="textSecondary">
                                                                                Impressions
                                                                            </Typography>
                                                                            <Label>
                                                                                {`${this.getPacingValue(
                                                                                    'CPM'
                                                                                )} per day`}
                                                                            </Label>
                                                                        </React.Fragment>
                                                                    )}
                                                                    {this.getPacingValue('CPC') !==
                                                                        'Auto' && (
                                                                        <React.Fragment>
                                                                            <Typography color="textSecondary">
                                                                                Clicks
                                                                            </Typography>
                                                                            <Label>
                                                                                {`${this.getPacingValue(
                                                                                    'CPC'
                                                                                )} per day`}
                                                                            </Label>
                                                                        </React.Fragment>
                                                                    )}
                                                                    {formData.ef_billing_terms ===
                                                                        'billable_media_cost_margin' &&
                                                                        this.getPacingValue(
                                                                            'billable_media_cost_margin'
                                                                        ) !== 'Auto' && (
                                                                            <React.Fragment>
                                                                                <Typography color="textSecondary">
                                                                                    Revenue
                                                                                </Typography>
                                                                                <Label>
                                                                                    {`${this.getPacingValue(
                                                                                        'billable_media_cost_margin'
                                                                                    )} per day`}
                                                                                </Label>
                                                                            </React.Fragment>
                                                                        )}
                                                                    {this.getPacingValue(
                                                                        'billable_media_cost_markup'
                                                                    ) !== 'Auto' && (
                                                                        <React.Fragment>
                                                                            <Typography color="textSecondary">
                                                                                Total Cost
                                                                            </Typography>
                                                                            <Label>
                                                                                {`${this.getPacingValue(
                                                                                    'billable_media_cost_markup'
                                                                                )} per day`}
                                                                            </Label>
                                                                        </React.Fragment>
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    }
                                                />
                                            </React.Fragment>
                                        )}
                                    <Spacer />
                                    {campaign.fta_version === 2 &&
                                        ftaEnabledAtOrgLevel &&
                                        environmentSettings.canUseFta() && (
                                            <React.Fragment>
                                                <Typography>
                                                    Foot Traffic Attribution (FTA)
                                                </Typography>
                                                <Spacer type="small" />
                                                <Spacer />
                                                <AccordionItem
                                                    readOnly={
                                                        campaign.fta_management_level === 'campaign'
                                                    }
                                                    onClick={this.expandPanel('fta')}
                                                    expanded={this.state.expandedPanel === 'fta'}
                                                    title="Foot Traffic Attribution"
                                                    incomplete={false}
                                                    required={false}
                                                    details={
                                                        <FootTrafficAttributionV2
                                                            fta={formData.fta}
                                                            onChange={this.updateDraft}
                                                        />
                                                    }
                                                    summary={
                                                        <React.Fragment>
                                                            {campaign.fta_management_level ===
                                                            'campaign' ? (
                                                                <Label>
                                                                    FTA Enabled - Managed by
                                                                    Campaign
                                                                </Label>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {formData.fta.enabled ? (
                                                                        <Label>Enabled</Label>
                                                                    ) : (
                                                                        <Label>Disabled</Label>
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    }
                                                />
                                                {formData.fta.enabled && (
                                                    <AccordionItem
                                                        readOnly={
                                                            campaign.fta_management_level ===
                                                            'campaign'
                                                        }
                                                        onClick={this.expandPanel(
                                                            'fta_location_list'
                                                        )}
                                                        expanded={
                                                            this.state.expandedPanel ===
                                                            'fta_location_list'
                                                        }
                                                        title="Location List"
                                                        incomplete={
                                                            !showErrors &&
                                                            !formData.fta.location_list
                                                        }
                                                        required={true}
                                                        error={showErrors && errors.fta}
                                                        details={
                                                            <LocationList
                                                                fta={formData.fta}
                                                                onChange={this.updateDraft}
                                                            />
                                                        }
                                                        summary={
                                                            <React.Fragment>
                                                                <React.Fragment>
                                                                    {formData.fta.location_list ? (
                                                                        <Label>
                                                                            {formData.fta
                                                                                .location_list
                                                                                ? _.get(
                                                                                      ftaLocationListsMapping,
                                                                                      `${
                                                                                          formData
                                                                                              .fta
                                                                                              .location_list
                                                                                      }.name`,
                                                                                      ''
                                                                                  )
                                                                                : ''}
                                                                        </Label>
                                                                    ) : (
                                                                        <Label placeholder>
                                                                            Select a value
                                                                        </Label>
                                                                    )}
                                                                </React.Fragment>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                )}
                                            </React.Fragment>
                                        )}

                                    {campaign.fta_version === 1 && environmentSettings.canUseFta() && (
                                        <Profile>
                                            {({ ownOrganization }) => {
                                                if (!ownOrganization.fta_partner_id) {
                                                    return null;
                                                }

                                                return (
                                                    <React.Fragment>
                                                        <Typography>
                                                            Foot Traffic Attribution
                                                        </Typography>
                                                        <Spacer type="small" />
                                                        <Caption type="info">
                                                            Foot Traffic Attribution (FTA).
                                                        </Caption>
                                                        <Spacer />
                                                        <AccordionItem
                                                            onClick={this.expandPanel('fta')}
                                                            expanded={
                                                                this.state.expandedPanel === 'fta'
                                                            }
                                                            title="FTA Pixel"
                                                            incomplete={
                                                                !showErrors &&
                                                                !(
                                                                    formData.fta &&
                                                                    formData.fta.enabled !== null
                                                                )
                                                            }
                                                            required
                                                            error={showErrors && errors.fta}
                                                            details={
                                                                <FootTrafficAttribution
                                                                    ftaEnabled={
                                                                        formData.fta.enabled
                                                                    }
                                                                    errors={errors.fta}
                                                                    showErrors={showErrors}
                                                                    isInternalUser={isInternalUser()}
                                                                    onChangeEnabled={this.handleChangeFtaEnabled(
                                                                        {
                                                                            partnerId:
                                                                                ownOrganization.fta_partner_id,
                                                                            ftaFee:
                                                                                campaign.fta_fee_standard,
                                                                        }
                                                                    )}
                                                                    lineId={
                                                                        formData.fta.line_id || ''
                                                                    }
                                                                    onChangeLineId={
                                                                        this.handleChangeLineId
                                                                    }
                                                                    partnerId={
                                                                        formData.fta.partner_id ||
                                                                        ''
                                                                    }
                                                                    onChangePartnerId={
                                                                        this.handleChangePartnerId
                                                                    }
                                                                />
                                                            }
                                                            summary={
                                                                <React.Fragment>
                                                                    {formData.fta.enabled ===
                                                                    null ? (
                                                                        <Label placeholder>
                                                                            Select a value
                                                                        </Label>
                                                                    ) : formData.fta.enabled ? (
                                                                        <Label>Enabled</Label>
                                                                    ) : (
                                                                        <Label>Disabled</Label>
                                                                    )}
                                                                    {formData.fta.line_id && (
                                                                        <Label>
                                                                            Line ID{' '}
                                                                            {formData.fta.line_id
                                                                                ? formData.fta
                                                                                      .line_id
                                                                                : ''}
                                                                        </Label>
                                                                    )}
                                                                    {formData.fta.partner_id && (
                                                                        <Label>
                                                                            Partner ID
                                                                            {formData.fta.partner_id
                                                                                ? formData.fta
                                                                                      .partner_id
                                                                                : ''}
                                                                        </Label>
                                                                    )}
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </React.Fragment>
                                                );
                                            }}
                                        </Profile>
                                    )}
                                    <Spacer />
                                    <Typography>Creative</Typography>
                                    <Spacer />
                                    <AccordionItem
                                        onClick={this.expandPanel('creative')}
                                        expanded={this.state.expandedPanel === 'creative'}
                                        title="Creative"
                                        required
                                        incomplete={!showErrors && isCreativeFieldEmpty}
                                        error={showErrors && hasRotationErrors(errors)}
                                        details={
                                            <React.Fragment>
                                                <Creative
                                                    adId={adId}
                                                    hasConversions={conversionsOptions.length > 0}
                                                    formData={formData}
                                                    onFieldChange={this.onFieldChange}
                                                    onCreativeChange={this.onFieldChange(
                                                        'creative'
                                                    )}
                                                    onRotationChange={this.onFieldChange(
                                                        'rotation_rules'
                                                    )}
                                                    showWeightColumn={
                                                        !_.includes(
                                                            [
                                                                CreativeRotationMethodMapping.OptimizedForClicks,
                                                                CreativeRotationMethodMapping.OptimizedForConversions,
                                                                CreativeRotationMethodMapping.OptimizedForVideoCompletion,
                                                                CreativeRotationMethodMapping.Weather,
                                                            ],
                                                            rotationMode
                                                        )
                                                    }
                                                    switchMode={this.switchCreativeMode}
                                                    changeWeight={this.changeCreativeRotationWeight}
                                                    selectSingleCreative={this.selectSingleCreative}
                                                    selectEvenCreative={this.selectEvenCreative}
                                                    selectWeightedCreative={
                                                        this.selectWeightedCreative
                                                    }
                                                    clearCreatives={this.clearCreatives}
                                                    updateScheduleRow={
                                                        this.updateCreativeScheduleRow
                                                    }
                                                    addScheduleRow={this.addCreativeScheduleRow}
                                                    onCreativeRotationByWeatherChange={
                                                        this.handleCreativeRotationByWeatherChange
                                                    }
                                                    removeScheduleRow={
                                                        this.removeCreativeScheduleRow
                                                    }
                                                    errors={errors}
                                                    showErrors={showErrors}
                                                    creatives={creativesForCreativeSelector}
                                                    rotationMode={rotationMode}
                                                    scheduledRotationWarningEnabled={
                                                        scheduledRotationWarningEnabled
                                                    }
                                                    assignedPacing={assignedPacing}
                                                    pacingColumnEnabled={pacingColumnEnabled}
                                                    totalWeight={totalRotationWeight}
                                                    campaignId={campaignId}
                                                    dispatch={dispatch}
                                                    history={history}
                                                    location={location}
                                                    campaign={this.props.campaignById}
                                                    mode={mode}
                                                    addDateRange={this.addDateRange}
                                                    removeMultiCreatives={this.removeMultiCreatives}
                                                    removeScheduledWeightedCreative={
                                                        this.removeScheduledWeightedCreative
                                                    }
                                                    removeWarningMessage={this.removeWarningMessage}
                                                    removeDateRange={this.removeDateRange}
                                                    changeScheduledWeightedCreativeEndDate={
                                                        this.changeScheduledWeightedCreativeEndDate
                                                    }
                                                    selectScheduledWeightedCreative={
                                                        this.selectScheduledWeightedCreative
                                                    }
                                                    changeScheduledWeightedCreativeWeight={
                                                        this.changeScheduledWeightedCreativeWeight
                                                    }
                                                    changeScheduledEvenWeighted={
                                                        this.changeScheduledEvenWeighted
                                                    }
                                                    addScheduledWeightedCreative={
                                                        this.addScheduledWeightedCreative
                                                    }
                                                    isCrossPlatformCampaign={
                                                        isCrossPlatformCampaign
                                                    }
                                                    creativeWarning={creativeWarning}
                                                    isCTVCampaign={isCTVCampaign}
                                                />
                                                {creativeWarning.hasWarning &&
                                                    isCrossPlatformCampaign && (
                                                        <WarningText
                                                            message={creativeWarning.message}
                                                            severity={creativeWarning.severity}
                                                            title={creativeWarning.title}
                                                        />
                                                    )}
                                            </React.Fragment>
                                        }
                                        summary={
                                            <Creatives
                                                keyName="Creative"
                                                value={{
                                                    creativeField: formData.creative,
                                                    rotationRules: formData.rotation_rules,
                                                    timezone: formData.timezone,
                                                    creativesByCampaign,
                                                }}
                                                onLabelWeatherConditionQualifier={
                                                    labelWeatherConditionQualifier
                                                }
                                                onLabelWeatherConditionValue={
                                                    labelWeatherConditionValue
                                                }
                                                onLabelWeatherTimeFrame={
                                                    labelWeatherConditionTimeframe
                                                }
                                            />
                                        }
                                        helper={
                                            <React.Fragment>
                                                {mode === 'edit' &&
                                                    campaign.isUsingConsolidateCreativeOptions && (
                                                        <div>
                                                            <BodyText>Single</BodyText>
                                                            <Spacer type="small" />
                                                            <Caption type="info">
                                                                Choose a single creative to be
                                                                delivered for the entire ad flight.
                                                            </Caption>
                                                            <Spacer />
                                                            <BodyText>Even Rotation</BodyText>
                                                            <Spacer type="small" />
                                                            <Caption type="info">
                                                                Choose multiple creatives to be
                                                                delivered evenly with the same
                                                                ratio.
                                                            </Caption>
                                                            <Spacer />
                                                            <BodyText>Weighted Rotation</BodyText>
                                                            <Spacer type="small" />
                                                            <Caption type="info">
                                                                Choose multiple creatives to be
                                                                delivered and customize the ratio at
                                                                which they appear.
                                                            </Caption>
                                                            <Spacer />
                                                        </div>
                                                    )}
                                                <BodyText>{'Custom Rotation'}</BodyText>
                                                <Spacer type="small" />
                                                <Caption type="info">
                                                    Assign one or more creatives to be delivered at
                                                    a selected weighting and schedule.
                                                </Caption>
                                                {environmentSettings.canUseCreativeOptimization() && (
                                                    <React.Fragment>
                                                        {!isCTVCampaign && !isDOOHCampaign && (
                                                            <React.Fragment>
                                                                <Spacer />
                                                                <BodyText>
                                                                    Optimize for Clicks
                                                                </BodyText>
                                                                <Spacer type="small" />
                                                                <Caption type="info">
                                                                    Prioritize serving creatives
                                                                    generating the best click
                                                                    performance.
                                                                </Caption>
                                                                <React.Fragment>
                                                                    <Spacer />
                                                                    <BodyText>
                                                                        Optimize for Conversions
                                                                    </BodyText>
                                                                    <Spacer type="small" />
                                                                    <Caption type="info">
                                                                        At least one conversion is
                                                                        required to set up in this
                                                                        campaign.
                                                                    </Caption>
                                                                    <Spacer type="small" />
                                                                    <Caption type="info">
                                                                        Prioritize serving creatives
                                                                        generating the best eCPA
                                                                        performance. To enable,
                                                                        create a Campaign Pixel or
                                                                        Advertiser Pixel for this
                                                                        campaign.
                                                                    </Caption>
                                                                </React.Fragment>
                                                            </React.Fragment>
                                                        )}
                                                        {isCTVCampaign && (
                                                            <React.Fragment>
                                                                <Spacer />
                                                                <BodyText>
                                                                    Optimize for Video Completion
                                                                </BodyText>
                                                                <Spacer type="small" />
                                                                <Caption type="info">
                                                                    Prioritize serving creatives
                                                                    generating the best video
                                                                    completion performance.
                                                                </Caption>
                                                            </React.Fragment>
                                                        )}
                                                        <React.Fragment>
                                                            <Spacer />
                                                            <BodyText>Weather Condition</BodyText>
                                                            <Spacer type="small" />
                                                            <Caption type="info">
                                                                Display different creatives based on
                                                                the current weather condition of
                                                                targeted cities.
                                                            </Caption>
                                                        </React.Fragment>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        }
                                        mdHidden={
                                            this.state.expandedPanel === 'creative' ? true : false
                                        }
                                    />
                                    <Spacer />
                                    {rotationMode ===
                                        CreativeRotationMethodMapping.OptimizedForConversions && (
                                        <React.Fragment>
                                            <Typography>Conversion Optimization</Typography>
                                            <Spacer />
                                            <AccordionItem
                                                onClick={this.expandPanel(
                                                    'conversionsForCreativeOptimization'
                                                )}
                                                expanded={
                                                    this.state.expandedPanel ===
                                                    'conversionsForCreativeOptimization'
                                                }
                                                title="Optimized Conversion Events"
                                                error={
                                                    showErrors &&
                                                    errors.conversionsForCreativeOptimization
                                                }
                                                details={
                                                    <ConversionsForCreativeOptimization
                                                        onChange={this.onFieldChange(
                                                            'conversionsForCreativeOptimization'
                                                        )}
                                                        value={
                                                            formData.conversionsForCreativeOptimization
                                                        }
                                                        options={conversionsOptions}
                                                    />
                                                }
                                                summary={
                                                    <ConversionsForCreativeOptimizationSummary
                                                        value={getConversionsForCreativeOptimization(
                                                            formData.conversionsForCreativeOptimization,
                                                            conversionsInCampaign
                                                        )}
                                                    />
                                                }
                                                helper={
                                                    <React.Fragment>
                                                        <Spacer type="small" />
                                                        <Caption type="info">
                                                            Select the conversion events from
                                                            advertiser pixels or campaign pixels to
                                                            optimize for. If no specific events are
                                                            selected, all associated events will be
                                                            optimized for.
                                                        </Caption>
                                                        <Spacer type="small" />
                                                        <Caption type="info">
                                                            Note: conversion events from associated
                                                            advertiser pixels and campaigns pixels
                                                            are always tracked and reported on, even
                                                            if not selected for optimization.
                                                        </Caption>
                                                    </React.Fragment>
                                                }
                                            />
                                            <Spacer />
                                        </React.Fragment>
                                    )}
                                    {isIasEnabled &&
                                        environmentSettings.canUseIas() &&
                                        !isCTVCampaign &&
                                        !isDOOHCampaign && (
                                            <React.Fragment>
                                                <Typography>Third Party Verification</Typography>
                                                <Spacer type="small" />
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Caption type="info">
                                                        Real-time ad verification is powered by{' '}
                                                        <a
                                                            href="https://integralads.com/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Integral Ad Science (IAS)
                                                        </a>
                                                        .{' '}
                                                        {!isProspectMedia
                                                            ? `Filter out high-risk inventory, set
                                                    viewability thresholds and brand safety measures
                                                    at no additional cost.`
                                                            : `Set viewability thresholds at no additional cost.`}
                                                        <br />
                                                        EngageFront is{' '}
                                                        <a
                                                            href="https://www.tagtoday.net/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            TAG Certified Against Fraud
                                                        </a>
                                                        .
                                                    </Caption>
                                                    <Box display="flex" alignItems="center">
                                                        <LogoImage
                                                            src="/images/IAS-logo.png"
                                                            height={20}
                                                        />
                                                        <LogoImage
                                                            src="/images/tag-logo.png"
                                                            height={50}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Spacer />
                                                {!isProspectMedia && (
                                                    <AccordionItem
                                                        title="Fraud Prevention"
                                                        onClick={this.expandPanel(
                                                            'iasFraudPrevention'
                                                        )}
                                                        expanded={
                                                            this.state.expandedPanel ===
                                                            'iasFraudPrevention'
                                                        }
                                                        details={<IASFraudPrevention />}
                                                        summary={
                                                            <React.Fragment>
                                                                <Label>
                                                                    <span>Content-based: </span>
                                                                    {
                                                                        IAS_FRAUD_PREVENTION_RISK_MAPPING[
                                                                            fraudPrevention.risk
                                                                                .value
                                                                        ]
                                                                    }
                                                                </Label>
                                                                <Label>
                                                                    <span>
                                                                        Exclude unrated content:{' '}
                                                                    </span>
                                                                    {fraudPrevention.unrateable
                                                                        .value === 1
                                                                        ? 'On'
                                                                        : 'Off'}
                                                                </Label>
                                                                <Label>
                                                                    <span>
                                                                        Exclude fraudulent IP
                                                                        Addresses:{' '}
                                                                    </span>
                                                                    {fraudPrevention.ip.value === 1
                                                                        ? 'On'
                                                                        : 'Off'}
                                                                </Label>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                )}
                                                {visiblePanel['viewability'] && (
                                                    <AccordionItem
                                                        title="Viewability"
                                                        onClick={this.expandPanel('iasViewability')}
                                                        expanded={
                                                            this.state.expandedPanel ===
                                                            'iasViewability'
                                                        }
                                                        details={
                                                            <React.Fragment>
                                                                <IASViewability />
                                                                {_.includes(
                                                                    environmentSettings.getViewabilityOptions(),
                                                                    'groupM'
                                                                ) && (
                                                                    <IASGroupmViewability
                                                                        isCrossPlatformCampaign={
                                                                            isCrossPlatformCampaign
                                                                        }
                                                                    />
                                                                )}
                                                                {_.includes(
                                                                    environmentSettings.getViewabilityOptions(),
                                                                    'publicis'
                                                                ) && (
                                                                    <IASPublicisViewability
                                                                        isCrossPlatformCampaign={
                                                                            isCrossPlatformCampaign
                                                                        }
                                                                    />
                                                                )}
                                                            </React.Fragment>
                                                        }
                                                        summary={
                                                            <React.Fragment>
                                                                <Label>
                                                                    Viewability:{' '}
                                                                    {
                                                                        IAS_VIEWABILITY_MAPPING[
                                                                            viewability.value
                                                                        ]
                                                                    }
                                                                </Label>
                                                                {_.includes(
                                                                    environmentSettings.getViewabilityOptions(),
                                                                    'groupM'
                                                                ) && (
                                                                    <Label>
                                                                        <span>
                                                                            GroupM Performance
                                                                            Matching:{' '}
                                                                        </span>
                                                                        {
                                                                            IAS_GROUPM_VIEWABILITY_MAPPING[
                                                                                groupmViewability
                                                                                    .value
                                                                            ]
                                                                        }
                                                                    </Label>
                                                                )}
                                                                {_.includes(
                                                                    environmentSettings.getViewabilityOptions(),
                                                                    'publicis'
                                                                ) && (
                                                                    <Label>
                                                                        <span>
                                                                            Publicis Performance
                                                                            Matching:{' '}
                                                                        </span>
                                                                        {
                                                                            IAS_PUBLICIS_VIEWABILITY_MAPPING[
                                                                                publicisViewability
                                                                                    .value
                                                                            ]
                                                                        }
                                                                    </Label>
                                                                )}
                                                            </React.Fragment>
                                                        }
                                                    />
                                                )}
                                                {visiblePanel['brandSafety'] && !isProspectMedia && (
                                                    <AccordionItem
                                                        title="Brand Safety"
                                                        onClick={this.expandPanel('iasBrandSafety')}
                                                        expanded={
                                                            this.state.expandedPanel ===
                                                            'iasBrandSafety'
                                                        }
                                                        details={<IASBrandSafety />}
                                                        summary={
                                                            <React.Fragment>
                                                                <Label>
                                                                    Adult:{' '}
                                                                    {
                                                                        IAS_BRAND_SAFETY_MAPPING[
                                                                            brandSafety.adult.value
                                                                        ]
                                                                    }
                                                                </Label>
                                                                <Label>
                                                                    Alcohol:{' '}
                                                                    {
                                                                        IAS_BRAND_SAFETY_MAPPING[
                                                                            brandSafety.alcohol
                                                                                .value
                                                                        ]
                                                                    }
                                                                </Label>
                                                                <Label>
                                                                    Gambling:{' '}
                                                                    {
                                                                        IAS_BRAND_SAFETY_MAPPING[
                                                                            brandSafety.gambling
                                                                                .value
                                                                        ]
                                                                    }
                                                                </Label>
                                                                <Label>
                                                                    Hate Speech:{' '}
                                                                    {
                                                                        IAS_BRAND_SAFETY_MAPPING[
                                                                            brandSafety.hateSpeech
                                                                                .value
                                                                        ]
                                                                    }
                                                                </Label>
                                                                <Label>
                                                                    Illegal Downloads:{' '}
                                                                    {
                                                                        IAS_BRAND_SAFETY_MAPPING[
                                                                            brandSafety
                                                                                .illegalDownloads
                                                                                .value
                                                                        ]
                                                                    }
                                                                </Label>
                                                                <Label>
                                                                    Illegal Drugs:{' '}
                                                                    {
                                                                        IAS_BRAND_SAFETY_MAPPING[
                                                                            brandSafety.illegalDrugs
                                                                                .value
                                                                        ]
                                                                    }
                                                                </Label>
                                                                <Label>
                                                                    Offensive Language:{' '}
                                                                    {
                                                                        IAS_BRAND_SAFETY_MAPPING[
                                                                            brandSafety
                                                                                .offensiveLanguage
                                                                                .value
                                                                        ]
                                                                    }
                                                                </Label>
                                                                <Label>
                                                                    Violence:{' '}
                                                                    {
                                                                        IAS_BRAND_SAFETY_MAPPING[
                                                                            brandSafety.violence
                                                                                .value
                                                                        ]
                                                                    }
                                                                </Label>
                                                                <Label>
                                                                    <span>
                                                                        Exclude unrated content:{' '}
                                                                    </span>
                                                                    {brandSafety.unrateable
                                                                        .value === 1
                                                                        ? 'On'
                                                                        : 'Off'}
                                                                </Label>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                )}
                                                {visiblePanel['postBidVerification'] && (
                                                    <AccordionItem
                                                        title="Post-bid Verification"
                                                        onClick={this.expandPanel(
                                                            'postBidVerification'
                                                        )}
                                                        expanded={
                                                            this.state.expandedPanel ===
                                                            'postBidVerification'
                                                        }
                                                        details={
                                                            <React.Fragment>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            disabled={
                                                                                !shouldUseIasPostBidVerification
                                                                            }
                                                                            checked={
                                                                                formData.isIasPostBidVerificationEnabled
                                                                            }
                                                                            onChange={e => {
                                                                                this.onFieldChange(
                                                                                    'isIasPostBidVerificationEnabled'
                                                                                )(e.target.checked);
                                                                            }}
                                                                        />
                                                                    }
                                                                    label="Enable IAS Post-bid Reporting pixel for display creatives"
                                                                />
                                                                <Typography color="textSecondary">
                                                                    Other creative types are not
                                                                    currently supported
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                        summary={`${
                                                            formData.isIasPostBidVerificationEnabled
                                                                ? 'On'
                                                                : 'Off'
                                                        }`}
                                                    />
                                                )}
                                            </React.Fragment>
                                        )}
                                    {isIasEnabled &&
                                        environmentSettings.canUseIas() &&
                                        !isCTVCampaign &&
                                        !isDOOHCampaign && (
                                            <React.Fragment>
                                                <Spacer />
                                                <IASOptions
                                                    addPanel={this.addPanel}
                                                    formData={formData}
                                                    isIasPostBidVerificationEnabled={
                                                        ownOrganization.isIasPostBidVerificationEnabled
                                                    }
                                                    isProspectMedia={isProspectMedia}
                                                />
                                                <Spacer />
                                            </React.Fragment>
                                        )}
                                    {!isIasEnabled &&
                                        showPlatformRestrictedFields &&
                                        !isCTVCampaign &&
                                        !isDOOHCampaign && (
                                            <React.Fragment>
                                                <Typography>Third Party Verification</Typography>
                                                <Spacer />
                                                <AccordionItem
                                                    onClick={this.expandPanel(
                                                        'viewabilityAndFraud'
                                                    )}
                                                    expanded={
                                                        this.state.expandedPanel ===
                                                        'viewabilityAndFraud'
                                                    }
                                                    title="Viewability and Fraud"
                                                    details={
                                                        <React.Fragment>
                                                            <BrandSafety
                                                                formData={formData}
                                                                dispatch={dispatch}
                                                                onFieldChange={this.onFieldChange}
                                                                errors={errors}
                                                                showErrors={showErrors}
                                                            />
                                                        </React.Fragment>
                                                    }
                                                    summary={
                                                        <Label>
                                                            {_.get(
                                                                LEVEL_BY_VALUE,
                                                                `${
                                                                    formData.forensiq_risk_cap
                                                                }.label`
                                                            )}
                                                        </Label>
                                                    }
                                                    helper="Real-time blacklisting of high-risk and suspect inventory is enabled on all ads by default, at no added cost, powered by Forensiq."
                                                />
                                            </React.Fragment>
                                        )}

                                    <Spacer />
                                    <Typography>Targeting</Typography>
                                    <Spacer />
                                    <AccordionItem
                                        onClick={this.expandPanel('geotargeting')}
                                        expanded={this.state.expandedPanel === 'geotargeting'}
                                        title="Geotargeting"
                                        required
                                        incomplete={!showErrors && !formData.geotargets.length}
                                        error={showErrors && errors.geotargets}
                                        details={
                                            <Geotargets
                                                value={formData.geotargets}
                                                errors={errors.geotargets}
                                                showErrors={showErrors}
                                                profileToken={profileToken}
                                                onChange={this.onFieldChange('geotargets')}
                                                campaignType={campaign.type}
                                            />
                                        }
                                        summary={
                                            formData.geotargets.length <= 0 ? (
                                                <Label placeholder>Select a country</Label>
                                            ) : (
                                                <div>
                                                    {formData.geotargets.map(formatGeoCountry)}
                                                </div>
                                            )
                                        }
                                    />
                                    {adForm &&
                                        adForm.campaign &&
                                        adForm.campaign.isWeatherConditionEnabled &&
                                        !(
                                            adForm.draft.geotargets &&
                                            adForm.draft.geotargets.some(
                                                ({ country }) => country === 'GB'
                                            )
                                        ) &&
                                        environmentSettings.canUseWeatherConditions() && (
                                            <AccordionItem
                                                onClick={this.expandPanel('weatherConditions')}
                                                expanded={
                                                    this.state.expandedPanel === 'weatherConditions'
                                                }
                                                title="Weather Condition"
                                                details={
                                                    <WeatherConditions
                                                        weatherConditions={
                                                            formData.weatherConditions
                                                        }
                                                        onChange={this.updateWeatherConditions}
                                                        showErrors={showErrors}
                                                        errors={errors}
                                                    />
                                                }
                                                error={
                                                    showErrors && hasWeatherConditionErrors(errors)
                                                }
                                                summary={
                                                    hasWeatherConditions(
                                                        formData.weatherConditions
                                                    ) ? (
                                                        <Label>
                                                            {_.map(
                                                                formData.weatherConditions
                                                                    .conditions,
                                                                (condition, index) => (
                                                                    <WeatherConditionSummary
                                                                        key={condition.id}
                                                                        title={`Condition ${index +
                                                                            1}`}
                                                                        condition={condition}
                                                                        showDivider={
                                                                            index !==
                                                                            formData
                                                                                .weatherConditions
                                                                                .conditions.length -
                                                                                1
                                                                        }
                                                                        logicRelationship={
                                                                            formData
                                                                                .weatherConditions
                                                                                .logicRelationship
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </Label>
                                                    ) : (
                                                        <Label placeholder>
                                                            Select a weather condition
                                                        </Label>
                                                    )
                                                }
                                            />
                                        )}
                                    {!isDOOHCampaign && (
                                        <AccordionItem
                                            onClick={this.expandPanel('device')}
                                            expanded={this.state.expandedPanel === 'device'}
                                            title="Device OS"
                                            error={showErrors && errors.target_device_os}
                                            details={
                                                <DeviceOS
                                                    formData={formData}
                                                    errors={errors.target_device_os}
                                                    showErrors={showErrors}
                                                    onFieldChange={this.onFieldChange(
                                                        'target_device_os'
                                                    )}
                                                    isCrossPlatformCampaign={
                                                        isCrossPlatformCampaign
                                                    }
                                                    isCTVCampaign={isCTVCampaign}
                                                    deviceOsWarnings={_.get(
                                                        adFormSetupWarnings,
                                                        'device_os',
                                                        {}
                                                    )}
                                                />
                                            }
                                            summary={
                                                <Label>
                                                    {getDeviceOs(formData.target_device_os)}
                                                </Label>
                                            }
                                        />
                                    )}
                                    {showCarrierSection &&
                                        environmentSettings.canUseCarrierIsp() &&
                                        !isDOOHCampaign && (
                                            <AccordionItem
                                                onClick={this.expandPanel('carrier')}
                                                expanded={this.state.expandedPanel === 'carrier'}
                                                title={
                                                    this.props.campaignCarriersISPsVersion === 2
                                                        ? 'Carrier / ISP'
                                                        : 'Target Carrier'
                                                }
                                                error={
                                                    this.props.campaignCarriersISPsVersion === 1
                                                        ? showErrors && errors.target_carriers
                                                        : showErrors &&
                                                          (errors.target_carriers ||
                                                              errors.carriers_exclude)
                                                }
                                                details={
                                                    this.props.campaignCarriersISPsVersion === 1 ? (
                                                        <TargetCarrieries
                                                            value={formData.target_carriers}
                                                            errors={errors.target_carriers}
                                                            showErrors={showErrors}
                                                            onChange={this.onFieldChange(
                                                                'target_carriers'
                                                            )}
                                                        />
                                                    ) : (
                                                        <TargetCarriersIsps
                                                            onFieldChange={this.onFieldChange}
                                                            options={getCarrierIspOptions({
                                                                platform: formData.platform,
                                                                platforms: formData.platforms,
                                                                isCrossPlatformCampaign,
                                                            })}
                                                            carrierISPIncludeValue={
                                                                formData.target_carriers
                                                            }
                                                            carrierISPExcludeValue={
                                                                formData.carriers_exclude
                                                            }
                                                            platforms={formData.platforms}
                                                            carrierIspWarning={_.get(
                                                                adFormSetupWarnings,
                                                                'carrier_isp',
                                                                {}
                                                            )}
                                                            isCrossPlatformCampaign={
                                                                isCrossPlatformCampaign
                                                            }
                                                        />
                                                    )
                                                }
                                                summary={
                                                    this.props.campaignCarriersISPsVersion === 1 ? (
                                                        <Label>
                                                            {getTargetCarriers(
                                                                formData.target_carriers
                                                            )}
                                                        </Label>
                                                    ) : (
                                                        <TargetCarriersIspsSummary
                                                            carrierISPIncludeValue={getTargetCarriersInclude(
                                                                formData.target_carriers
                                                            )}
                                                            carrierISPExcludeValue={getTargetCarriersExclude(
                                                                formData.carriers_exclude
                                                            )}
                                                        />
                                                    )
                                                }
                                            />
                                        )}
                                    {!isDOOHCampaign && !isCTVCampaign && (
                                        <AccordionItem
                                            onClick={this.expandPanel('language')}
                                            expanded={this.state.expandedPanel === 'language'}
                                            title="User Language"
                                            error={showErrors && errors.target_device_language}
                                            details={
                                                <React.Fragment>
                                                    <IncludeExcludeLayout
                                                        type="Include"
                                                        marginBottom
                                                    >
                                                        <TargetDeviceLanguage
                                                            value={formData.target_device_language}
                                                            errors={errors.target_device_language}
                                                            showErrors={showErrors}
                                                            onChange={this.onFieldChange(
                                                                'target_device_language'
                                                            )}
                                                        />
                                                    </IncludeExcludeLayout>
                                                    <IncludeExcludeLayout type="Exclude">
                                                        <TargetDeviceLanguageExclude
                                                            value={
                                                                formData.target_device_language_exclude
                                                            }
                                                            errors={
                                                                errors.target_device_language_exclude
                                                            }
                                                            showErrors={showErrors}
                                                            onChange={this.onFieldChange(
                                                                'target_device_language_exclude'
                                                            )}
                                                        />
                                                    </IncludeExcludeLayout>
                                                </React.Fragment>
                                            }
                                            summary={
                                                <React.Fragment>
                                                    {formData.target_device_language_exclude
                                                        .length > 0 ? (
                                                        <IncludeExcludeLayout
                                                            type="Include"
                                                            marginBottom
                                                        >
                                                            <Label>
                                                                {getDeviceLanguage(
                                                                    formData.target_device_language
                                                                )}
                                                            </Label>
                                                        </IncludeExcludeLayout>
                                                    ) : (
                                                        getDeviceLanguage(
                                                            formData.target_device_language
                                                        )
                                                    )}
                                                    {formData.target_device_language_exclude
                                                        .length > 0 && (
                                                        <IncludeExcludeLayout type="Exclude">
                                                            <Label>
                                                                {getDeviceLanguageExclude(
                                                                    formData.target_device_language_exclude
                                                                )}
                                                            </Label>
                                                        </IncludeExcludeLayout>
                                                    )}
                                                </React.Fragment>
                                            }
                                        />
                                    )}
                                    {showPlatformRestrictedFields &&
                                        campaign.isAppStoreCatEnabled === false && (
                                            <AccordionItem
                                                onClick={this.expandPanel('iabCat')}
                                                expanded={this.state.expandedPanel === 'iabCat'}
                                                title="IAB App Categories"
                                                error={showErrors && errors.target_iab_categories}
                                                details={
                                                    <React.Fragment>
                                                        <TargetIABCategories
                                                            label="IAB App Categories (Include)"
                                                            errors={errors.target_iab_categories}
                                                            showErrors={showErrors}
                                                            checkedCategories={
                                                                formData.target_iab_categories
                                                            }
                                                            onChange={this.onFieldChange(
                                                                'target_iab_categories'
                                                            )}
                                                        />
                                                        <TargetIABCategories
                                                            label="IAB App Categories (Exclude)"
                                                            errors={
                                                                errors.target_iab_categories_exclude
                                                            }
                                                            showErrors={showErrors}
                                                            checkedCategories={
                                                                formData.target_iab_categories_exclude
                                                            }
                                                            onChange={this.onFieldChange(
                                                                'target_iab_categories_exclude'
                                                            )}
                                                        />
                                                    </React.Fragment>
                                                }
                                                summary={
                                                    formData.target_device_language_exclude
                                                        .length <= 0 ||
                                                    formData.target_iab_categories.length <= 0 ? (
                                                        <Label placeholder>
                                                            Select IAB App category
                                                        </Label>
                                                    ) : (
                                                        <React.Fragment>
                                                            <IncludeExcludeLayout type="Include">
                                                                {getIABCat(
                                                                    formData.target_iab_categories
                                                                )}
                                                            </IncludeExcludeLayout>
                                                            <IncludeExcludeLayout type="Exclude">
                                                                {getDeviceLanguageExclude(
                                                                    formData.target_device_language_exclude
                                                                )}
                                                            </IncludeExcludeLayout>
                                                        </React.Fragment>
                                                    )
                                                }
                                            />
                                        )}
                                    {visiblePanel['appCategories'] &&
                                        showPlatformRestrictedFields &&
                                        !tacticsEnabled &&
                                        adForm.campaign.isAppStoreCatEnabled === true && (
                                            <AccordionItem
                                                onClick={this.expandPanel('appCategories')}
                                                expanded={
                                                    this.state.expandedPanel === 'appCategories'
                                                }
                                                title="App Categories"
                                                details={
                                                    <React.Fragment>
                                                        <AppCategories
                                                            adId={adId}
                                                            campaignId={campaignId}
                                                            geoCategories={geoCategories}
                                                            geoLayerSearchString={
                                                                geoLayerSearchString
                                                            }
                                                            showGeoEntityInMap={
                                                                this.showGeoEntityInMap
                                                            }
                                                            geofencingEntityInView={
                                                                geofencingEntityInView
                                                            }
                                                            poiViewer_eTag={poiViewer_eTag}
                                                            onPoiSearchStringChanged={
                                                                this.handlePoiSearchStringChanged
                                                            }
                                                            onGeoLayerFinder_folderStateChanged={
                                                                this
                                                                    .handleGeoLayerFinder_folderStateChanged
                                                            }
                                                            formData={formData}
                                                            dispatch={dispatch}
                                                            onFieldChange={this.onFieldChange}
                                                            errors={errors}
                                                            showErrors={showErrors}
                                                            profileToken={profileToken}
                                                            adForm={adForm}
                                                            tacticsEnabled={tacticsEnabled}
                                                            isPoiRecategorized={
                                                                adForm.isPoiRecategorized
                                                            }
                                                        />
                                                    </React.Fragment>
                                                }
                                                summary={
                                                    formData.target_app_store_cat.length <= 0 &&
                                                    formData.target_app_store_cat_exclude.length <=
                                                        0 ? (
                                                        <Label placeholder>
                                                            Include or exclude app category
                                                        </Label>
                                                    ) : (
                                                        <React.Fragment>
                                                            <IncludeExcludeLayout
                                                                type="Include"
                                                                marginBottom
                                                            >
                                                                {formData.target_app_store_cat.join(
                                                                    ', '
                                                                )}
                                                            </IncludeExcludeLayout>
                                                            <IncludeExcludeLayout type="Exclude">
                                                                {formData.target_app_store_cat_exclude.join(
                                                                    ', '
                                                                )}
                                                            </IncludeExcludeLayout>
                                                        </React.Fragment>
                                                    )
                                                }
                                            />
                                        )}
                                    {/* CTV/DOOH Campaigns can't target Gender and Age due to the shared nature of the device */}
                                    {environmentSettings.canUseGenderTargeting() &&
                                        !isCTVCampaign &&
                                        !isDOOHCampaign && (
                                            <AccordionItem
                                                onClick={this.expandPanel('gender')}
                                                expanded={this.state.expandedPanel === 'gender'}
                                                title="Gender"
                                                error={showErrors && errors.target_genders}
                                                details={
                                                    <TargetGenders
                                                        value={formData.target_genders}
                                                        errors={errors.target_genders}
                                                        showErrors={showErrors}
                                                        onChange={this.onFieldChange(
                                                            'target_genders'
                                                        )}
                                                        marginBottom={25}
                                                    />
                                                }
                                                summary={
                                                    <Label>
                                                        {getGenders(formData.target_genders)}
                                                    </Label>
                                                }
                                            />
                                        )}
                                    {environmentSettings.canUseAgeTargeting() &&
                                        !isCTVCampaign &&
                                        !isDOOHCampaign && (
                                            <AccordionItem
                                                onClick={this.expandPanel('age')}
                                                expanded={this.state.expandedPanel === 'age'}
                                                title="Age"
                                                errors={showErrors && errors.target_age_groups}
                                                details={
                                                    <TargetAgeGroups_EF_1418
                                                        value={formData.target_age_groups}
                                                        errors={errors.targetAgeGroup}
                                                        showErrors={showErrors}
                                                        onChange={this.onFieldChange(
                                                            'target_age_groups'
                                                        )}
                                                        marginBottom={25}
                                                    />
                                                }
                                                summary={
                                                    <Label>
                                                        {getAgeGroups(
                                                            formData.target_age_groups
                                                        ).join(', ')}
                                                    </Label>
                                                }
                                            />
                                        )}
                                    {canShowGeoFencing && formData.geoboxes && visiblePanel['geo'] && (
                                        <AccordionItem
                                            onClick={this.expandPanel('geofencing')}
                                            expanded={this.state.expandedPanel === 'geofencing'}
                                            title="Geofencing"
                                            error={showErrors && errors.geoboxes}
                                            details={
                                                <React.Fragment>
                                                    <Geoboxes
                                                        ref="geoboxes"
                                                        value={formData.geoboxes}
                                                        errors={errors.geoboxes}
                                                        showErrors={showErrors}
                                                        onChange={this.onFieldChange('geoboxes')}
                                                        platforms={formData.platforms}
                                                        isCrossPlatformCampaign={
                                                            isCrossPlatformCampaign
                                                        }
                                                    />
                                                </React.Fragment>
                                            }
                                            summary={
                                                formData.geoboxes.length <= 0 ? (
                                                    <Label placeholder>Input a value</Label>
                                                ) : (
                                                    _.map(
                                                        getGeofencing(formData.geoboxes),
                                                        (geobox, index) => (
                                                            <Label key={index}>{geobox}</Label>
                                                        )
                                                    )
                                                )
                                            }
                                        />
                                    )}
                                    {visiblePanel['poi'] && shouldHideFlexSegments && (
                                        <AccordionItem
                                            onClick={this.expandPanel('poi')}
                                            expanded={this.state.expandedPanel === 'poi'}
                                            title="POI Segments"
                                            error={showErrors && errors.geo_targeting_settings}
                                            disabled={hasAnyAudiences(formData)}
                                            details={
                                                <PointsOfInterest
                                                    adId={adId}
                                                    campaignId={campaignId}
                                                    geoTargetingSettings={
                                                        formData.geo_targeting_settings
                                                    }
                                                    dispatch={dispatch}
                                                    geoCategories={geoCategories}
                                                    geoLayerSearchString={this.geoLayerSearchString}
                                                    showGeoEntityInMap={this.showGeoEntityInMap}
                                                    geofencingEntityInView={geofencingEntityInView}
                                                    poiViewer_eTag={poiViewer_eTag}
                                                    onPoiSearchStringChanged={
                                                        this.handlePoiSearchStringChanged
                                                    }
                                                    onGeoLayerFinder_folderStateChanged={
                                                        this.handleGeoLayerFinder_folderStateChanged
                                                    }
                                                    onChange={this.onFieldChange(
                                                        'geo_targeting_settings'
                                                    )}
                                                    isPoiRecategorized={adForm.isPoiRecategorized}
                                                />
                                            }
                                            summary={
                                                !isTacticsEnabled(
                                                    formData.tactics_enabled,
                                                    campaign.id
                                                ) && (
                                                    <React.Fragment>
                                                        {geoCategories.selectedCustomLayers.length >
                                                            0 ||
                                                        geoCategories.selectedCategories.length >
                                                            0 ||
                                                        geoCategories.selectedCategoryLayers
                                                            .length > 0 ? (
                                                            <React.Fragment>
                                                                {geoCategories.selectedCustomLayers
                                                                    .length > 0 && (
                                                                    <Typography color="textSecondary">
                                                                        Custom Segments
                                                                    </Typography>
                                                                )}
                                                                {_.map(
                                                                    geoCategories.selectedCustomLayers,
                                                                    (customLayer, index) => (
                                                                        <Label key={index}>
                                                                            - {customLayer.name}
                                                                        </Label>
                                                                    )
                                                                )}
                                                                {(geoCategories.selectedCategories
                                                                    .length > 0 ||
                                                                    geoCategories
                                                                        .selectedCategoryLayers
                                                                        .length > 0) && (
                                                                    <Typography color="textSecondary">
                                                                        Standard Segments
                                                                    </Typography>
                                                                )}
                                                                {_.map(
                                                                    geoCategories.selectedCategories,
                                                                    (category, index) => (
                                                                        <Label key={index}>
                                                                            - {category.name}
                                                                        </Label>
                                                                    )
                                                                )}
                                                                {_.map(
                                                                    geoCategories.selectedCategoryLayers,
                                                                    (categoryLayer, index) => (
                                                                        <Label key={index}>
                                                                            - {categoryLayer.name}
                                                                        </Label>
                                                                    )
                                                                )}
                                                            </React.Fragment>
                                                        ) : (
                                                            <Label placeholder>
                                                                Select POI segments
                                                            </Label>
                                                        )}
                                                    </React.Fragment>
                                                )
                                            }
                                        />
                                    )}
                                    {visiblePanel['audienceSegments'] && (
                                        <AccordionItem
                                            onClick={this.expandPanel('audienceSegments')}
                                            expanded={
                                                this.state.expandedPanel === 'audienceSegments'
                                            }
                                            title="Audiences"
                                            disabled={
                                                hasAnyPois(formData) && shouldHideFlexSegments
                                            }
                                            details={
                                                <Audience
                                                    adPresetKeyCounter={adForm.adPresetKeyCounter}
                                                    formData={formData}
                                                    dispatch={dispatch}
                                                    onFieldChange={this.onFieldChange}
                                                    onChangeSegments={this.handleChangeSegments}
                                                    errors={errors}
                                                    showErrors={showErrors}
                                                    profileToken={profileToken}
                                                    tacticsEnabled={tacticsEnabled}
                                                    audienceRates={audienceRates}
                                                    campaignId={campaignId}
                                                    shouldHideFlexSegments={shouldHideFlexSegments}
                                                />
                                            }
                                            summary={
                                                audiences.length <= 0 ? (
                                                    <Label placeholder>Select audiences</Label>
                                                ) : (
                                                    <Audience
                                                        adPresetKeyCounter={
                                                            adForm.adPresetKeyCounter
                                                        }
                                                        formData={formData}
                                                        dispatch={dispatch}
                                                        onFieldChange={this.onFieldChange}
                                                        onChangeSegments={this.handleChangeSegments}
                                                        errors={errors}
                                                        showErrors={showErrors}
                                                        profileToken={profileToken}
                                                        tacticsEnabled={tacticsEnabled}
                                                        audienceRates={audienceRates}
                                                        campaignId={campaignId}
                                                        shouldHideFlexSegments={
                                                            shouldHideFlexSegments
                                                        }
                                                    />
                                                )
                                            }
                                        />
                                    )}
                                    {tacticsEnabled && environmentSettings.canUseTactics() && (
                                        <AccordionItem
                                            onClick={this.expandPanel('tactics')}
                                            expanded={this.state.expandedPanel === 'tactics'}
                                            title={
                                                <div>
                                                    <Grid container alignItems="center">
                                                        Tactics
                                                    </Grid>
                                                    <TutorialDialog id={3} />
                                                </div>
                                            }
                                            details={
                                                <Tactics
                                                    platform={formData.platform}
                                                    platforms={formData.platforms}
                                                    showErrors={showErrors}
                                                    updateModuleDraft={this.handleUpdateModuleDraft}
                                                    tactics_generators={formData.tactics_generators}
                                                    adId={adId}
                                                    campaignId={campaignId}
                                                    isCTVCampaign={isCTVCampaign}
                                                    isDOOHCampaign={isDOOHCampaign}
                                                    isAppStoreCatEnabled={
                                                        adForm.campaign.isAppStoreCatEnabled
                                                    }
                                                    audienceRates={audienceRates}
                                                    isCrossPlatformCampaign={
                                                        isCrossPlatformCampaign
                                                    }
                                                    tacticWarnings={_.get(
                                                        adFormSetupWarnings,
                                                        'tactics',
                                                        {}
                                                    )}
                                                />
                                            }
                                            summary={
                                                <React.Fragment>
                                                    {isTacticsEnabled(
                                                        formData.tactics_enabled,
                                                        campaign.id
                                                    ) && (
                                                        <React.Fragment>
                                                            {formData.tactics_generators.length >
                                                            0 ? (
                                                                _.map(
                                                                    formData.tactics_generators,
                                                                    tactic => (
                                                                        <TacticSummary
                                                                            key={tactic.id}
                                                                            tactic={tactic}
                                                                        />
                                                                    )
                                                                )
                                                            ) : (
                                                                <Label placeholder>
                                                                    Select tactics
                                                                </Label>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            }
                                        />
                                    )}
                                    <Spacer />
                                    <TargetingOptions
                                        addPanel={this.addPanel}
                                        tacticsEnabled={tacticsEnabled}
                                        formData={formData}
                                        isAppStoreCatEnabled={adForm.campaign.isAppStoreCatEnabled}
                                        shouldHideFlexSegments={shouldHideFlexSegments}
                                        showPlatformRestrictedFields={showPlatformRestrictedFields}
                                        canUseGeoFencing={
                                            environmentSettings.canUseGeoFencing() && !isCTVCampaign
                                        }
                                    />
                                    <Spacer />
                                    <AdFormInventory
                                        expandPanel={this.expandPanel}
                                        expanded={this.state.expandedPanel}
                                        formData={formData}
                                        campaign={campaign}
                                        currency={currency}
                                        onFieldChange={this.onFieldChange}
                                        errors={errors}
                                        showErrors={showErrors}
                                        dealsByOrganization={dealsByOrganization}
                                        adForm={adForm}
                                        environmentSettings={environmentSettings}
                                        isCrossPlatformCampaign={isCrossPlatformCampaign}
                                        showPlatformRestrictedFields={showPlatformRestrictedFields}
                                        applists={applists}
                                        classes={classes}
                                        organizationName={ownOrganization.name}
                                        advertiser={advertiser}
                                        exchangeWarning={_.get(adFormSetupWarnings, 'exchange', {})}
                                        isDOOHCampaign={isDOOHCampaign}
                                        doohVenuesWarning={_.get(
                                            adFormSetupWarnings,
                                            'target_dooh_venues',
                                            {}
                                        )}
                                    />
                                    <Spacer />
                                    <Typography>Bid Optimization</Typography>
                                    <Spacer />

                                    <BidStrategyWrapper
                                        expandPanel={this.expandPanel}
                                        expandedPanel={this.state.expandedPanel}
                                        classes={classes}
                                        formData={formData}
                                        showErrors={showErrors}
                                        errors={errors}
                                        currency={currency}
                                        onFieldChange={this.onFieldChange}
                                        techFee={this.props.techFee}
                                        campaignId={campaign.id}
                                        campaignRevenueModel={campaign.revenueModel}
                                        totalFirstPartyDataFee={this.getBillingsListDataCostLabel(
                                            formData
                                        )}
                                        organizationName={ownOrganization.name}
                                        isCTVCampaign={isCTVCampaign}
                                        isDOOHCampaign={isDOOHCampaign}
                                    />

                                    <AccordionItem
                                        onClick={this.expandPanel('frequencyCap')}
                                        expanded={this.state.expandedPanel === 'frequencyCap'}
                                        title="Frequency Cap"
                                        details={
                                            <FrequencyCap
                                                formData={formData}
                                                updateFrequencyCap={this.updateFrequencyCap}
                                                toggleFrequencyCapMode={this.toggleFrequencyCapMode}
                                                frequencyCapMode={calculateFrequencyCapMode(
                                                    formData
                                                )}
                                                showFrequencyCapWarning={showFrequencyCapWarning}
                                                errors={errors}
                                                showErrors={showErrors}
                                                isDOOHCampaign={isDOOHCampaign}
                                            />
                                        }
                                        summary={
                                            <Label>{getFlexibleFrequency(formData.fcaps)}</Label>
                                        }
                                        helper={
                                            isDOOHCampaign &&
                                            'DOOH ads only support unlimited frequency cap.'
                                        }
                                        error={showErrors && errors.fcaps}
                                    />
                                </div>
                            </Container>
                        </Grid>
                        {!isCrossPlatformCampaign && (
                            <Grid item xs={3}>
                                <FixedLayout>
                                    <Profile>
                                        {({ profileState }) => {
                                            const canRoleUseInventoryForecast = _.includes(
                                                ['admin', 'campaign_manager', 'media_planner'],
                                                profileState.globalRole
                                            );

                                            if (canRoleUseInventoryForecast) {
                                                return (
                                                    <Paper>
                                                        <InventoryIndicatorWithDailyPrediction
                                                            isOpenByDefault={true}
                                                            isLoading={inventoryPredictionLoading}
                                                            error={inventoryPredictionError}
                                                            impressionGoal={
                                                                formData.max_total_impressions
                                                            }
                                                            predictedImpressions={
                                                                formData.predicted_daily_impressions
                                                            }
                                                            uniqueUsers={
                                                                formData.predicted_daily_uniques
                                                            }
                                                            daysRunning={getDaysRunning(formData)}
                                                            predictedUniqueUsersMin={
                                                                formData.predicted_total_uniques_min
                                                            }
                                                            predictedUniqueUsersMax={
                                                                formData.predicted_total_uniques_max
                                                            }
                                                            predictedImpressionsMin={
                                                                formData.predicted_total_impressions_min
                                                            }
                                                            predictedImpressionsMax={
                                                                formData.predicted_total_impressions_max
                                                            }
                                                            goal={
                                                                formData.ef_billing_terms === 'CPM'
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </Paper>
                                                );
                                            }
                                            return null;
                                        }}
                                    </Profile>
                                </FixedLayout>
                            </Grid>
                        )}
                    </Grid>
                </div>
                <AppBar position="fixed">
                    <BottomToolbar>
                        <Button
                            disabled={this.props.isSubmitting}
                            onClick={this.validateAdDraft}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        <Button
                            disabled={this.props.isSubmitting}
                            onClick={this.handleButtonClick_cancel}
                            color="primary"
                        >
                            Cancel
                        </Button>
                    </BottomToolbar>
                </AppBar>
                {(isCrossPlatformCampaign || adForm2.overrideDealPriceWarning.hasWarning) && (
                    <SetupWarningConfirmation
                        onCancel={this.handleCancelSave}
                        onConfirm={this.handleButtonClick_submit}
                        isSubmitting={this.props.isSubmitting}
                    />
                )}
            </div>
        );
    }
}

function IASOptions(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <div>
            <Button
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                More Verification Options
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        props.addPanel('viewability');
                        handleClose();
                    }}
                >
                    Viewability
                </MenuItem>
                {!props.isProspectMedia && (
                    <MenuItem
                        onClick={() => {
                            props.addPanel('brandSafety');
                            handleClose();
                        }}
                    >
                        Brand Safety
                    </MenuItem>
                )}
                {props.isIasPostBidVerificationEnabled && (
                    <MenuItem
                        onClick={() => {
                            props.addPanel('postBidVerification');
                            handleClose();
                        }}
                    >
                        Post-bid Verification
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}

const targetingItems = [
    {
        key: 'geo',
        shouldShow: props => props.canUseGeoFencing,
        component: ({ props, handleClose }) => (
            <MenuItem
                key="geo"
                onClick={() => {
                    props.addPanel('geo');
                    handleClose();
                }}
            >
                Geofencing
            </MenuItem>
        ),
    },
    {
        key: 'poi',
        shouldShow: props => !props.tacticsEnabled && props.shouldHideFlexSegments,
        component: ({ props, handleClose }) => (
            <MenuItem
                key="poi"
                onClick={() => {
                    props.addPanel('poi');
                    handleClose();
                }}
                disabled={hasAnyAudiences(props.formData) || props.tacticsEnabled}
            >
                POI Segments
            </MenuItem>
        ),
    },
    {
        key: 'audiences',
        shouldShow: props => !props.tacticsEnabled,
        component: ({ props, handleClose }) => (
            <MenuItem
                key="audiences"
                onClick={() => {
                    props.addPanel('audienceSegments');
                    handleClose();
                }}
                disabled={
                    (hasAnyPois(props.formData) && props.shouldHideFlexSegments) ||
                    props.tacticsEnabled
                }
            >
                Audiences
            </MenuItem>
        ),
    },
    {
        key: 'appCategories',
        shouldShow: props => !props.tacticsEnabled && props.showPlatformRestrictedFields,
        component: ({ props, handleClose }) => (
            <MenuItem
                key="appCategories"
                onClick={() => {
                    props.addPanel('appCategories');
                    handleClose();
                }}
                disabled={!props.isAppStoreCatEnabled || props.tacticsEnabled}
            >
                App Categories
            </MenuItem>
        ),
    },
];

function TargetingOptions(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const shownTargetingItems = _.filter(targetingItems, item => item.shouldShow(props));

    if (shownTargetingItems.length === 0) {
        return null;
    }
    return (
        <div>
            <Button
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                More Targeting Options
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {_.map(shownTargetingItems, item => item.component({ props, handleClose }))}
            </Menu>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    fixedLayout: {
        position: 'fixed',
        paddingTop: 20,
        paddingRight: 20,
        display: 'flex',
    },
    logo: ({ height }) => ({
        margin: '0px 5px',
        height: height,
    }),
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    mainContent: {
        display: 'flex',
    },
    widthNotificationDialog: {
        maxWidth: 700,
    },
    link: {
        color: theme.palette.primary.main,
        padding: '10px 0px',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
}));

const AppBar = withStyles(theme => ({
    root: {
        bottom: 0,
        top: 'auto',
        backgroundColor: theme.palette.grey[50],
        boxShadow: 'none',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
}))(MuiAppBar);

const Toolbar = withStyles({
    root: {
        justifyContent: 'space-between',
        padding: 0,
    },
})(MuiToolbar);

const BottomToolbar = withStyles({
    root: {
        justifyContent: 'flex-end',
    },
})(MuiToolbar);

function FixedLayout(props) {
    const classes = useStyles();

    return <div className={classes.fixedLayout}>{props.children}</div>;
}

function PlatformSummary(props) {
    const { formData, isCrossPlatformCampaign } = props;
    return (
        <>
            {!isCrossPlatformCampaign ? (
                <Label> {PLATFORM_MAPPING[formData.platform].label} </Label>
            ) : (
                <Typography>
                    {_.map(formData.platforms, platform => PLATFORM_MAPPING[platform].label).join(
                        ', '
                    )}{' '}
                </Typography>
            )}
        </>
    );
}

function LogoImage(props) {
    const classes = useStyles({ height: props.height });

    return <img className={classes.logo} src={props.src} />;
}

const TutorialDialog = ({ id }) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div>
            <div className={classes.link} onClick={handleOpenDialog}>
                Learn more
            </div>
            <Dialog
                onClose={handleCloseDialog}
                aria-labelledby="simple-dialog-title"
                open={openDialog}
                fullWidth
                classes={{ paperWidthSm: classes.widthNotificationDialog }}
            >
                <DialogActions>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseDialog}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
                <DottedStepper data={FEATURE_NOTIFICATIONS} id={id} onClose={handleCloseDialog} />
            </Dialog>
        </div>
    );
};

function AdFormWrapper(props) {
    const adForm2 = useAdForm();

    return <AdForm {...props} adForm2={adForm2} />;
}

export default withStyles(styles)(connect(selector)(AdFormWrapper));
