import _ from 'lodash';

import { getEnv } from 'services/environment';
import c from 'common/constants/flux-events';
import createPivotTableService from 'widgets/pivot-table/service';
import { getDimensions } from 'common/constants/dimensions';
import findCacheState from 'utils/find-cache-state';

// index by client ot server
function getDimensionsClientToServerName() {
    const DIMENSIONS_CLIENT_TO_SERVER_NAME = {};
    _.each(getDimensions(), dimension => {
        DIMENSIONS_CLIENT_TO_SERVER_NAME[dimension.name] = dimension.serverName;
    });

    return DIMENSIONS_CLIENT_TO_SERVER_NAME;
}

const pt = createPivotTableService();
const columnsWithSpendBreakdown = [
    { label: 'Dimensions', name: 'dimension', defaultValue: '', formatType: '', required: true },

    { name: 'impressions' },
    { name: 'clicks' },
    { name: 'ctr' },
    { name: 'unique_users' },
    { name: 'daily_uniq' },
    { name: 'average_freq' },
    { name: 'owner_total_media_cost_local', disabled: false },
    { name: 'owner_media_cost_2_local' },
    { name: 'data_cost' },
    { name: 'third_party_fees' },
    { name: 'owner_total_media_cost_local_ecpm', disabled: false },
    { name: 'owner_total_media_cost_local_ecpc', disabled: false },
    { name: 'win_rate' },

    {
        label: 'Billing Rate',
        name: 'billing_rate',
        defaultValue: '',
        formatType: '',
        required: true,
    },
    { name: 'revenue' },
    { name: 'erpm' },
    { name: 'erpc' },
];

const initialState = {
    cache: {},
    campaignId: null,
    isInitializing: false,
    isLoading: true,
    hideZeros: true,
    dictionary: {},

    columns: []
        .concat([
            {
                label: 'Dimensions',
                name: 'dimension',
                defaultValue: '',
                formatType: '',
                required: true,
            },

            { name: 'impressions' },
            { name: 'clicks' },
            { name: 'ctr' },
            { name: 'unique_users' },
            { name: 'daily_uniq' },
            { name: 'average_freq' },
            { name: 'win_rate' },
            { name: 'spend' },
            { name: 'ecpm' },
            { name: 'ecpc' },
            { name: 'owner_media_cost_2_local' },
            {
                label: 'Billing Rate',
                name: 'billing_rate',
                defaultValue: '',
                formatType: '',
                required: true,
            },

            { name: 'revenue' },
            { name: 'erpm' },
            { name: 'erpc' },
            { name: 'owner_total_media_cost_local', disabled: true },
        ])
        .filter(x => x),
    // Pivot Table fields are always
    pivotTable: {
        splits: [{ label: 'Date', name: 'date' }],
        dimensions: [
            {
                label: 'Age',
                name: 'age',
                serverName: 'age',
            },
            {
                label: 'Date',
                name: 'date',
                serverName: '',
            },
            {
                label: 'Day Of Week',
                name: 'day_of_week',
                serverName: 'time',
            },
            {
                label: 'Device OS',
                name: 'device_os',
                serverName: 'os',
            },
            {
                label: 'Gender',
                name: 'gender',
                serverName: 'gender',
            },
            {
                label: 'Country',
                name: 'geo_country',
                serverName: 'country',
            },
            {
                label: 'Region',
                name: 'geo_country_region',
                serverName: 'region',
            },
            {
                label: 'Creative',
                name: 'markup_id',
                serverName: 'markup_id',
            },
            {
                label: 'Audience Segment',
                name: 'audience_segment',
                serverName: 'audience_segment',
            },
            {
                name: 'audience_id',
                serverName: 'audience_id',
                label: 'Audience ID',
            },
            {
                label: 'Hour',
                name: 'hour',
                serverName: '',
            },
            {
                label: 'Month',
                name: 'month',
                serverName: '',
            },
            {
                label: 'Week',
                name: 'week',
                serverName: '',
                children: [
                    {
                        label: 'Week (Monday to Sunday)',
                        name: 'week_by_monday_to_sunday',
                        parent: 'Week',
                    },
                    {
                        label: 'Week (every 7 days)',
                        name: 'week_by_campaign_start_day',
                        parent: 'Week',
                    },
                ],
            },
            {
                label: 'Ad',
                name: 'ad_id',
                serverName: 'ad_id',
            },
            {
                label: 'Custom Dimensions',
                name: 'adgroup',
                serverName: '',
                children: [],
            },
            {
                label: 'Tactic',
                name: 'tactic_id',
                serverName: 'tactic_id',
            },
            {
                label: 'Platform',
                name: 'platform',
                serverName: '',
            },
        ],
        sort: {
            column: 'dimension',
            order: 'asc',
        },
        tree: {},
    },
};

export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            let _initialState = {
                ...initialState,
                columns: columnsWithSpendBreakdown,
            };

            if (getEnv() === 'production') {
                _initialState = {
                    ..._initialState,
                    columns: _.filter(_initialState.columns, c => c.name !== 'billing_rate'),
                };
            }

            // Reset columns because they will be updated with beacons on INITIALIZE_START
            const _state = {
                ...state,
                columns: _initialState.columns,
            };

            return findCacheState(campaignId, _state, _initialState);
        }

        case c.CAMPAIGN_REPORT__PIVOT_TABLE__INITIALIZE_START: {
            const { campaignId, campaign, isClient, isClientReportPage } = action.payload;

            let pivotTableState = { ...initialState.pivotTable };

            if (!campaign.hasAudiences) {
                pivotTableState = removeAudienceDimension(pivotTableState);
            }

            if (isClient || isClientReportPage) {
                pivotTableState = {
                    ...pivotTableState,
                    dimensions: _.filter(state.pivotTable.dimensions, dimension => {
                        if (dimension.name === 'adgroup') {
                            return true;
                        }

                        return _.find(campaign.sharing_settings.report_tabs.explore.dimensions, {
                            name: getDimensionsClientToServerName()[dimension.name],
                            shared: true,
                        });
                    }),
                };
            }

            const beaconColumns = [
                { name: 'overall_engagements', disabled: false },
                ..._(campaign.beacons)
                    .filter(b => !b.hide)
                    .map(b => ({ name: `event_${b.name}` }))
                    .value(),
            ];

            // Toggle column options
            let columns = _(state.columns)
                .filter(shouldShowMediaCost(campaign.isMediaCostMetricEnabled))
                .filter(column => {
                    if (column.name === 'unique_users') {
                        const isUniqueUsersEnabled = campaign.isUniqueUsersDisabled !== true;

                        return isUniqueUsersEnabled;
                    }

                    return true;
                })
                // Remove billing fields when Campaign billing is off
                .filter(column => {
                    if (campaign.billing_enabled) {
                        return true;
                    }

                    if (_.includes(['erpm', 'erpc', 'revenue'], column.name)) {
                        return false;
                    } else {
                        return true;
                    }
                })
                // remove existing beacons because they will be appended at the bottom
                .filter(column => !_.startsWith(column.name, 'event_'))
                .filter(column => {
                    // only apply sharing to clients
                    if (!(isClient || isClientReportPage)) {
                        return true;
                    }

                    if (column.required) {
                        return true;
                    }

                    const metricConfig = _.find(campaign.sharing_settings.metrics, metric => {
                        if (metric.name === column.name) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    if (!metricConfig) {
                        return false;
                    }

                    return metricConfig.shared;
                })
                .map(column => {
                    switch (column.name) {
                        case 'owner_total_media_cost_local': {
                            return column;
                        }
                        case 'overall_engagements': {
                            return {
                                ...column,
                                disabled: beaconColumns.length < 2,
                            };
                        }
                        default: {
                            return column;
                        }
                    }
                })
                .concat(beaconColumns)
                .value();

            let conversionColumns = [];

            if (campaign.conversions.length > 0 || campaign.advertiserConversions.length > 0) {
                conversionColumns.push({ name: 'conv_overall' });
                conversionColumns.push({ name: 'conv_overall_click' });
                conversionColumns.push({ name: 'conv_overall_imp' });
                conversionColumns.push({ name: 'conv_overall_total_cost_ecpa' });
                conversionColumns.push({ name: 'conv_overall_revenue_ecpa' });

                let advertiserConversionEvents = [];
                _(campaign.advertiserConversions)
                    .map(conversion => {
                        advertiserConversionEvents = advertiserConversionEvents.concat([
                            { name: `conv_total_adv_${conversion.event_name}` },
                            { name: `conv_total_imp_adv_${conversion.event_name}` },
                            { name: `conv_total_click_adv_${conversion.event_name}` },
                            { name: `conv_total_cost_ecpa_adv_${conversion.event_name}` },
                            { name: `conv_revenue_ecpa_adv_${conversion.event_name}` },
                        ]);
                        if (conversion.dynamic_data.length > 0) {
                            advertiserConversionEvents = advertiserConversionEvents.concat(
                                _(conversion.dynamic_data)
                                    .map(dynamicData => [{ name: `${dynamicData}` }])
                                    .flatten()
                                    .value()
                            );
                        }
                    })
                    .value();

                let conversionEvents = [];
                _(campaign.conversions)
                    .map(conversion => {
                        conversionEvents = conversionEvents.concat([
                            { name: `conv_total_${conversion.event_name}` },
                            { name: `conv_total_imp_${conversion.event_name}` },
                            { name: `conv_total_click_${conversion.event_name}` },
                            { name: `conv_total_cost_ecpa_${conversion.event_name}` },
                            { name: `conv_revenue_ecpa_${conversion.event_name}` },
                        ]);
                        if (conversion.dynamic_data.length > 0) {
                            conversionEvents = conversionEvents.concat(
                                _(conversion.dynamic_data)
                                    .map(dynamicData => [{ name: `${dynamicData}` }])
                                    .flatten()
                                    .value()
                            );
                        }
                    })
                    .value();

                conversionColumns = _.flatten(
                    _.concat(conversionColumns, advertiserConversionEvents, conversionEvents)
                );
            }

            columns = _.concat(columns, conversionColumns);

            return {
                ...state,
                columns,
                isLoading: true,
                isInitializing: true,
                campaignId,
                pivotTable: pivotTableState,
            };
        }
        case c.CAMPAIGN_REPORT__PIVOT_TABLE__INITIALIZE: {
            const { stats } = action.payload;

            return {
                ...state,
                pivotTable: pt.generatePivotTableState(state.pivotTable, stats),
                isLoading: false,
                isInitializing: false,
            };
        }
        case c.CAMPAIGN_REPORT__PIVOT_TABLE__DATA_REFRESH: {
            return {
                ...state,
                pivotTable: pt.generatePivotTableState(state.pivotTable, action.payload.stats),
            };
        }
        case c.CAMPAIGN_REPORT__PIVOT_TABLE__EXPAND_ROW: {
            return {
                ...state,
                pivotTable: pt.expandRow(state.pivotTable, action.payload.rowId),
            };
        }
        case c.CAMPAIGN_REPORT__PIVOT_TABLE__COLLAPSE_ROW: {
            return {
                ...state,
                pivotTable: pt.collapseRow(state.pivotTable, action.payload.rowId),
            };
        }
        case c.CAMPAIGN_REPORT__PIVOT_TABLE__ADD_SPLIT: {
            return {
                ...state,
                pivotTable: pt.addSplit(state.pivotTable, action.payload.dimension),
            };
        }
        case c.CAMPAIGN_REPORT__PIVOT_TABLE__REMOVE_SPLIT: {
            return {
                ...state,
                pivotTable: pt.removeSplit(state.pivotTable, action.payload.dimension),
            };
        }
        case c.CAMPAIGN_REPORT__PIVOT_TABLE__UPDATE_SPLITS: {
            return {
                ...state,
                pivotTable: pt.updateSplits(state.pivotTable, action.payload.splits),
            };
        }
        case c.CAMPAIGN_REPORT__PIVOT_TABLE__SORT: {
            return {
                ...state,
                pivotTable: pt.sortColumn(state.pivotTable, action.payload.column),
            };
        }
        case c.CAMPAIGN_REPORT__PIVOT_TABLE__HIDE_ZEROS_TOGGLE: {
            return {
                ...state,
                hideZeros: !state.hideZeros,
            };
        }
        case c.CAMPAIGN_REPORT__AVAILABLE_ADGROUPS__FETCH_SUCCESS: {
            const { campaign } = action.payload;

            const customDimensions = campaign.customDimensions;

            const dimensions = _.map(state.pivotTable.dimensions, option => {
                if (option.label === 'Custom Dimensions') {
                    const children = _(customDimensions)
                        .filter(dimension => dimension.groups.length > 0)
                        .map(dimension => ({
                            label: dimension.name,
                            name: dimension.id,
                        }))
                        .value();

                    return {
                        ...option,
                        children,
                    };
                }

                return option;
            });

            return {
                ...state,
                pivotTable: {
                    ...state.pivotTable,
                    dimensions,
                },
            };
        }
        default:
            return state;
    }
}

function removeAudienceDimension(pivotTableState) {
    return {
        ...pivotTableState,
        dimensions: _.filter(
            pivotTableState.dimensions,
            dimension => dimension.name !== 'audience_segment' && dimension.name !== 'audience_id'
        ),
    };
}

function shouldShowMediaCost(isMediaCostMetricEnabled) {
    return column => {
        if (column.name !== 'owner_media_cost_2_local') {
            return true;
        }

        if (isMediaCostMetricEnabled) {
            return true;
        } else {
            return false;
        }
    };
}
