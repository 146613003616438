import get from 'lodash/get';
import difference from 'lodash/difference';
import union from 'lodash/union';

import { actions } from './reducer';
import { graphqlRequest } from 'utils/http/redux';
import { getStartAndEndDate } from '../selector';

const fetchCampaignsAndUpdate = async (
    dispatch,
    getState,
    { init = false, search = false },
    fetchCampaignsV3
) => {
    const campaignScroll = init ? 0 : get(getState(), 'businessReportEditor.campaignScroll');
    const campaigns = init ? [] : get(getState(), 'businessReportEditor.campaigns', []);
    const organizationFilter = get(getState(), 'businessReportEditor.draft.organizationFilter');
    const ownOrgId = get(getState(), 'businessReportEditor.ownOrg.id');
    const searchFilter = get(getState(), 'businessReportEditor.searchFilter');
    const campaignIds = get(getState(), 'businessReportEditor.draft.campaigns.ids');

    const { adStart, adEnd, adEndStart, adEndEnd } = getDates(getState);

    const fetchedCampaigns = await fetchCampaignsV3({
        dispatch,
        organizationFilter,
        ownOrgId,
        adStart,
        adEnd,
        adEndStart,
        adEndEnd,
        searchFilter,
        campaignIds: campaignIds.map(id => parseInt(id)),
        scroll: search && searchFilter ? null : campaignScroll,
        limit: search && searchFilter ? null : 20,
    });

    dispatch(
        actions.initSuccess({
            campaigns: union(campaigns, fetchedCampaigns),
            campaignScroll,
            hasMore: fetchedCampaigns.length >= 20,
        })
    );
};

const initMw = ({ fetchCampaignsV3, fetchCampaignsV3Summary }) => ({
    dispatch,
    getState,
}) => next => async action => {
    next(action);

    if (action.type === actions.init.type) {
        await fetchCampaignsAndUpdate(dispatch, getState, { init: true }, fetchCampaignsV3);

        dispatch(actions.fetchCampaignsSummary());
    }

    if (
        action.type === actions.fetchCampaignsSummary.type ||
        action.type === actions.handleItemToggle.type
    ) {
        const fetchedCampaigns = await fetchCampaignsV3Summary({
            dispatch,
            getState,
        });

        dispatch(
            actions.fetchCampaignsSummarySuccess({
                campaigns: fetchedCampaigns,
            })
        );
    }
};

const fetchMoreCampaignsMw = ({ fetchCampaignsV3 }) => ({
    dispatch,
    getState,
}) => next => async action => {
    next(action);
    if (action.type === actions.fetchMoreCampaigns.type) {
        fetchCampaignsAndUpdate(dispatch, getState, { init: false }, fetchCampaignsV3);
    }
};

const searchMw = ({ fetchCampaignsV3 }) => ({ dispatch, getState }) => next => async action => {
    next(action);

    if (action.type === actions.setSearchFilter.type) {
        fetchCampaignsAndUpdate(dispatch, getState, { init: true, search: true }, fetchCampaignsV3);
    }
};

const changeFiltersMw = ({ fetchCampaignsV3 }) => ({
    dispatch,
    getState,
}) => next => async action => {
    next(action);
    if (
        // When the selected Organizations change
        action.type === actions.selectOrganization.type ||
        // When Include or Exclude change
        action.type === actions.changeFilterMode.type ||
        // When any filter is enabled or disabled
        action.type === actions.toggleFilter.type ||
        // all the dateRange or adEndDate filters related changes
        action.type === actions.updatePresetModeDateRange.type ||
        action.type === actions.updateFilterDateRangeMode.type ||
        action.type === actions.updateFilterLast.type ||
        action.type === actions.updateLastFrame.type ||
        action.type === actions.updateFilterFrom.type
    ) {
        fetchCampaignsAndUpdate(dispatch, getState, { init: true }, fetchCampaignsV3);
    }

    if (action.type === actions.toggleSubevent.type) {
        dispatch(actions.toggleMetric(action.payload.changedValue));
    }
};

async function fetchCampaigns({ dispatch, organizationFilter, ownOrgId }) {
    let organizationIds = [ownOrgId];
    let exclude = false;

    if (organizationFilter.isEnabled) {
        organizationIds = organizationFilter.ids;
        exclude = organizationFilter.mode === 'exclude';
    }

    const res = await dispatch(
        graphqlRequest({
            query: `
            query fetchCampaignsForBusinessReports ($filters: CampaignsForBusinessReportsFilters) {
                campaignsForBusinessReports(filters: $filters) {
                    id
                    name
                    mayContainBadAudienceReportData
                    default_timezone
                    iab_categories
                    organization
                    revenueModel
                    clients {
                        type
                        organization
                        shared
                        name
                    }
                    beacons {
                        name
                        label
                    }
                    conversions {
                        reporting_name
                        event_name
                        use_view_through
                        use_click_through
                        dynamic_data
                    }
                    advertiserConversions {
                        reporting_name
                        event_name
                        use_view_through
                        use_click_through
                        dynamic_data
                    }
                    ads {
                        id
                        name
                        start
                        end
                        custom_fields {
                            key
                            name
                            value
                            required
                        }
                        _deleted
                    }
                }
            }
        `,
            variables: {
                filters: {
                    organizationIds,
                    exclude,
                },
            },
        })
    );

    return res.data.campaignsForBusinessReports;
}

async function fetchCampaignsV3({
    dispatch,
    organizationFilter,
    ownOrgId,
    adStart,
    adEnd,
    adEndStart,
    adEndEnd,
    searchFilter,
    campaignIds,
    scroll,
    limit,
}) {
    let organizationIds = [ownOrgId];
    let exclude = false;

    if (organizationFilter.isEnabled) {
        organizationIds = organizationFilter.ids;
        exclude = organizationFilter.mode === 'exclude';
    }

    const res = await dispatch(
        graphqlRequest({
            query: `
            query fetchCampaignsForBusinessReports ($filters: CampaignsForBusinessReportsFilters) {
                campaignsForBusinessReports(filters: $filters) {
                    id
                    name
                    mayContainBadAudienceReportData
                    default_timezone
                    iab_categories
                    organization
                    revenueModel
                    clients {
                        type
                        organization
                        shared
                        name
                    }
                    beacons {
                        name
                        label
                    }
                    conversions {
                        reporting_name
                        event_name
                        use_view_through
                        use_click_through
                        dynamic_data
                    }
                    advertiserConversions {
                        reporting_name
                        event_name
                        use_view_through
                        use_click_through
                        dynamic_data
                    }
                    ads {
                        id
                        name
                        start
                        end
                        custom_fields {
                            key
                            name
                            value
                            required
                        }
                        _deleted
                    }
                }
            }
        `,
            variables: {
                filters: {
                    organizationIds,
                    exclude,
                    adStart,
                    adEnd,
                    adEndStart,
                    adEndEnd,
                    offset: scroll,
                    limit,
                    keywords: searchFilter,
                    campaignIds,
                },
            },
        })
    );

    return res.data.campaignsForBusinessReports;
}

async function fetchCampaignsV3Summary({ dispatch, getState }) {
    const campaignIds = get(getState(), ['businessReportEditor', 'draft', 'campaigns', 'ids'], []);
    const currentCampaignSummary = get(
        getState(),
        ['businessReportEditor', 'campaignsSummary'],
        []
    );
    const campaignIdsToSearch = difference(campaignIds, currentCampaignSummary.map(({ id }) => id));

    let res = {
        data: {
            campaigns: [],
        },
    };

    if (campaignIdsToSearch.length) {
        res = await dispatch(
            graphqlRequest({
                query: `
                query fetchCampaignsForBusinessReports ($ids: [Int]) {
                    campaigns (ids: $ids) {
                        id
                        name
                    }
                }
            `,
                variables: {
                    ids: campaignIdsToSearch.map(id => parseInt(id)),
                },
            })
        );
    }

    return res.data.campaigns
        .map(campaign => ({ name: campaign.name, id: String(campaign.id) }))
        .concat(currentCampaignSummary);
}

function getDates(getState) {
    let adStart;
    let adEnd;
    let adEndStart;
    let adEndEnd;
    if (get(getState(), 'businessReportEditor.draft.dateRange.isEnabled')) {
        const dateRange = get(getState(), 'businessReportEditor.draft.dateRange');
        const { start, end } = getStartAndEndDate(dateRange);
        adStart = start;
        adEnd = end;
    }
    if (get(getState(), 'businessReportEditor.draft.adEndDate.isEnabled')) {
        const adEndDate = get(getState(), 'businessReportEditor.draft.adEndDate');
        const { start, end } = getStartAndEndDate(adEndDate);
        adEndStart = start;
        adEndEnd = end;
    }

    return { adStart, adEnd, adEndStart, adEndEnd };
}

export const makeMiddlewares = deps => {
    return [initMw(deps), fetchMoreCampaignsMw(deps), searchMw(deps), changeFiltersMw(deps)];
};

export const middlewares = makeMiddlewares({
    fetchCampaigns,
    fetchCampaignsV3,
    fetchCampaignsV3Summary,
});
