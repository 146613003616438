import { connect } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
/** import third-party libraries in the section above, keep the ascending order */

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
/** import mui components in the section above, keep the ascending order */

import Spacer from 'widgets-v5/spacer';
/** import widgets components in the section above, keep the ascending order */

import predictionActions from 'states/resources/inventory-predictions/actions';
/** import classes and others in the section above, keep the ascending order */

// This dynamic import is to comply with mocha commonjs standard
async function loadArcGISModules() {
    const [
        { default: Map },
        { default: MapView },
        { default: FeatureLayer },
        { default: Search },
        { default: SimpleRenderer },
        { default: IdentityManager },
        geometryEngine,
        { default: PictureMarkerSymbol },
    ] = await Promise.all([
        import('@arcgis/core/Map'),
        import('@arcgis/core/views/MapView'),
        import('@arcgis/core/layers/FeatureLayer'),
        import('@arcgis/core/widgets/Search'),
        import('@arcgis/core/renderers/SimpleRenderer'),
        import('@arcgis/core/identity/IdentityManager'),
        import('@arcgis/core/geometry/geometryEngine'),
        import('@arcgis/core/symbols/PictureMarkerSymbol.js'),
    ]);

    // Return all the modules
    return {
        Map,
        MapView,
        FeatureLayer,
        Search,
        SimpleRenderer,
        IdentityManager,
        geometryEngine,
        PictureMarkerSymbol,
    };
}

export const OUT_FIELDS = [
    'id',
    'public_id',
    'publisher_name',
    'venue_name',
    'parent',
    'child',
    'height',
    'width',
    'supports_banner',
    'supports_video',
    'supports_audio',
    'impressions_per_spot',
    'avg_daily_impressions',
    'latitude',
    'longitude',
    'streetnumber',
    'streetname_streetsuffix',
    'city',
    'regioncode',
];

const MapIndicator = ({ boards = [], gisDoohMapToken, getGisToken, cacheStrategy }) => {
    const mapRef = useRef(null);
    const viewRef = useRef(null);
    const featureLayerRef = useRef(null);
    const customPopupRef = useRef(null);
    const [popupData, setPopupData] = useState(null);
    const [modules, setModules] = useState(null);

    useEffect(() => {
        // Fetch the GIS token
        if (!gisDoohMapToken) {
            getGisToken(cacheStrategy);
        }
        const loadModules = async () => {
            const imports = await loadArcGISModules();
            setModules(imports);
        };
        loadModules();
    }, []);

    useEffect(() => {
        if (!mapRef.current || !gisDoohMapToken || !modules) return;
        const {
            Map,
            MapView,
            FeatureLayer,
            Search,
            SimpleRenderer,
            IdentityManager,
            geometryEngine,
            PictureMarkerSymbol,
        } = modules;

        const map = new Map({
            basemap: 'streets-vector',
        });

        IdentityManager.registerToken({
            server: 'https://gis.pelmorex.com/portal/sharing/rest',
            ...gisDoohMapToken,
        });

        const pictureMarkerSymbol = new PictureMarkerSymbol({
            angle: 0,
            height: 25,
            url: '/images/dooh-inventory-prediction/blueMarker.svg',
            width: 17,
            xoffset: 0,
            yoffset: 0,
        });

        const renderer = new SimpleRenderer({
            symbol: pictureMarkerSymbol,
        });

        const queryFeatureLayer = new FeatureLayer({
            url:
                'https://gis.pelmorex.com/server/rest/services/PDS/Vistarmedia_DOOH/FeatureServer/4',
            outFields: OUT_FIELDS,
            visible: false,
        });

        const featureLayer = new FeatureLayer({
            url:
                'https://gis.pelmorex.com/server/rest/services/PDS/Vistarmedia_DOOH/FeatureServer/4',
            outFields: OUT_FIELDS,
            featureReduction: {
                type: 'cluster',
                clusterRadius: '50px',
                maxSingleFlareCount: 1,
                labelingInfo: [
                    {
                        symbol: {
                            type: 'text', // Label as text
                            color: 'white',
                            font: {
                                size: 12,
                                weight: 'bold',
                            },
                        },
                        labelPlacement: 'center-center', // Place label in the center
                        labelExpressionInfo: {
                            expression: '$feature.cluster_count', // Display the cluster count
                        },
                    },
                ],
                clusterMinSize: 20, // Minimum size of cluster symbols
                clusterMaxSize: 50, // Maximum size of cluster symbols
                returnExtent: true,
                symbol: {
                    type: 'simple-marker',
                    style: 'circle',
                    color: '#03A9F4',
                    outline: {
                        color: '#03A9F4',
                        width: 5,
                    },
                },
            },
            renderer: renderer,
        });

        featureLayerRef.current = featureLayer;

        map.addMany([featureLayer]);

        const view = new MapView({
            container: mapRef.current,
            map: map,
            center: [-98, 50],
            zoom: 2,
        });

        viewRef.current = view; // Store reference to the map view

        view.whenLayerView(featureLayer).then(layerView => {
            layerView.filter = {
                where: `public_id IN (${boards.map(id => `'${id}'`).join(',')})`,
            };
        });

        // Disable the default popup
        view.popup.autoOpenEnabled = false;

        view.on('pointer-down', () => {
            setPopupData(null);
            // Hide the popup if no feature was clicked
            customPopupRef.current.style.display = 'none';
        });

        // Handle hover effect to change cursor when over a feature
        view.on('pointer-move', async event => {
            const response = await view.hitTest(event);
            const results = response.results.filter(
                result => result.graphic?.layer === featureLayer
            );

            if (results.length > 0) {
                view.container.style.cursor = 'pointer'; // Change cursor to pointer
            } else {
                view.container.style.cursor = 'default'; // Reset cursor
            }
        });

        // Create a listener for clicks on the layer
        view.on('click', async event => {
            const response = await view.hitTest(event);
            // Check if a feature was clicked
            const results = response.results.filter(
                result => result.graphic?.layer === featureLayer
            );
            if (results.length > 0) {
                const graphic = results[0].graphic;
                const attributes = graphic.attributes;
                if (attributes.cluster_count > 1) {
                    const bufferDistance = 10 * view.resolution;
                    const bufferGeometry = geometryEngine.buffer(graphic.geometry, bufferDistance);

                    const query = queryFeatureLayer.createQuery();
                    query.geometry = bufferGeometry;
                    query.spatialRelationship = 'intersects';
                    query.returnGeometry = true;
                    query.outFields = OUT_FIELDS;

                    const { features } = await queryFeatureLayer.queryFeatures(query);

                    // Store all venues in state
                    setPopupData({
                        venues: features.map(feature => ({
                            attributes: feature.attributes,
                            id: feature.attributes.id,
                            geometry: feature.geometry,
                        })),
                        screenPoint: event.screenPoint,
                    });
                }

                if (attributes.cluster_count === 1) {
                    // Set data for the React popup
                    setPopupData({
                        venues: [
                            {
                                attributes,
                                id: attributes.id,
                                geometry: graphic.geometry,
                            },
                        ],
                        screenPoint: event.screenPoint,
                    });
                }
                // Get the screen point for positioning
                const screenPoint = event.screenPoint;
                // Populate and show the custom popup
                const popup = customPopupRef.current;
                popup.style.left = `${screenPoint.x + 25}px`;
                popup.style.top = `${screenPoint.y + 150}px`;
                popup.style.display = 'block';
            } else {
                setPopupData(null);
                // Hide the popup if no feature was clicked
                customPopupRef.current.style.display = 'none';
            }
        });

        // Add the Search widget
        const searchWidget = new Search({
            view: view,
            popupEnabled: false,
            goToOverride: function(view, goToParams) {
                goToParams.options = {
                    duration: '2000',
                };
                return view.goTo(goToParams.target, goToParams.options);
            },
        });
        view.ui.add(searchWidget, 'top-right');

        // Move the zoom control to the bottom left
        view.ui.move('zoom', 'bottom-left');

        return () => {
            if (view) {
                view.container = null;
            }
        };
    }, [gisDoohMapToken, modules]);

    return (
        <div className="gis-container">
            <div ref={mapRef} className="map">
                <div
                    ref={customPopupRef}
                    style={{
                        backgroundColor: 'white',
                        height: '270px',
                        width: '300px',
                        overflowY: 'auto',
                        position: 'absolute',
                        zIndex: 9999,
                        display: 'none', // Initially hidden
                        // pointerEvents: "none", // Allow clicks to pass through to the map
                        // transform: "translate(-50%, -100%)", // Center above the click point
                    }}
                >
                    {popupData && <VenueItems venues={popupData.venues} onMap />}
                </div>
            </div>
        </div>
    );
};

export const VenueItems = ({
    venues,
    handleDelete,
    handleAddVenue,
    onMap,
    showSelectButton,
    selectedIds,
}) => {
    return venues.map((venue, index) => {
        return (
            <div key={venue.id}>
                <VenueItem
                    venue={venue}
                    handleAddVenue={handleAddVenue}
                    handleDelete={handleDelete}
                    onMap={onMap}
                    showSelectButton={showSelectButton}
                    selected={selectedIds?.find(id => id === venue.id)}
                />
                {index < venues.length - 1 && <hr />}
            </div>
        );
    });
};

export const VenueItem = ({
    venue,
    zoomToFeature,
    handleDelete,
    handleAddVenue,
    onMap = false,
    selected,
    showSelectButton,
}) => {
    const [toggleExpand, setToggleExpand] = useState(false);
    const {
        id,
        public_id,
        publisher_name,
        venue_name,
        impressions_per_spot,
        avg_daily_impressions,
        latitude,
        longitude,
        streetnumber,
        streetname_streetsuffix,
        city,
        regioncode,
        parent,
        child,
        height,
        width,
        supports_banner,
        supports_video,
        supports_audio,
    } = venue?.attributes || {};
    return (
        <li className="venue-item">
            <div className="venue-item-left">
                {onMap &&
                    showSelectButton &&
                    (selected ? (
                        <Button
                            className={'popUpButton'}
                            sx={{ textTransform: 'none' }}
                            variant="outlined"
                            color="primary"
                            onClick={() => handleDelete(id)}
                        >
                            {' '}
                            <CheckIcon color="primary" />
                            &nbsp; Added to Selected Venues
                        </Button>
                    ) : (
                        <Button
                            className={'popUpButton'}
                            sx={{ textTransform: 'none' }}
                            variant="outlined"
                            color="primary"
                            onClick={() => handleAddVenue(venue)}
                        >
                            <AddIcon color="primary" />
                            &nbsp; Add to Selected Venues
                        </Button>
                    ))}
                <Typography className="fieldValue" variant="body1">
                    {publisher_name} - {venue_name}
                </Typography>
                <Stack className="chips" direction="row" spacing={1}>
                    <Chip label={`${parent} > ${child}`} />
                    <Chip label={`${width} x ${height}`} />
                    {supports_banner && <Chip label="Display" />}
                    {supports_video && <Chip label="Video" />}
                    {supports_audio && <Chip label="Audio" />}
                </Stack>
                <Spacer type="small" />
                {!onMap && (
                    <div className="venue-item-controls">
                        <ZoomInMapIcon className="clickable" onClick={() => zoomToFeature(venue)} />
                        <OpenInNewIcon
                            className="clickable"
                            onClick={() => {
                                window.open(
                                    `https://maps.google.com/?q=${latitude},${longitude}`,
                                    '_blank'
                                );
                            }}
                        />
                        <Button
                            sx={{ textTransform: 'none' }}
                            variant="text"
                            onClick={() => setToggleExpand(!toggleExpand)}
                        >
                            {toggleExpand ? 'View Less' : 'View More'}
                        </Button>
                    </div>
                )}
                <div className={toggleExpand || onMap ? 'venue-details' : 'hideDetails'}>
                    <div>
                        <Typography className="fieldTitle" variant="subtitle2">
                            Board ID
                        </Typography>
                        <Typography className="fieldValue" variant="body2">
                            {public_id}
                        </Typography>
                    </div>
                    <br />
                    <div>
                        <Typography className="fieldTitle" variant="subtitle2">
                            Address
                        </Typography>
                        <Typography
                            className="fieldValue"
                            variant="body2"
                        >{`${streetnumber} ${streetname_streetsuffix}, ${city
                            ?.split(' ')
                            .map(
                                token => token.charAt(0) + token.slice(1).toLowerCase() + ' '
                            )} ${regioncode}`}</Typography>
                    </div>
                    <br />
                    <div>
                        <Typography className="fieldTitle" variant="subtitle2">
                            Impression per spot
                        </Typography>
                        <Typography className="fieldValue" variant="body2">
                            {impressions_per_spot}
                        </Typography>
                    </div>
                    <br />
                    <div>
                        <Typography className="fieldTitle" variant="subtitle2">
                            Avg. Daily Impressions
                        </Typography>
                        <Typography className="fieldValue" variant="body2">
                            {avg_daily_impressions}
                        </Typography>
                    </div>
                    <br />
                </div>
            </div>
            {!onMap && (
                <div className="venue-item-right">
                    <DeleteIcon onClick={() => handleDelete(id)} />
                </div>
            )}
        </li>
    );
};

const selector = storeState => {
    const inventoryPredictions = _.get(storeState, `resources.inventoryPredictions`, {});
    const { gisDoohMapToken } = inventoryPredictions;
    return {
        gisDoohMapToken,
    };
};
export default connect(
    selector,
    { getGisToken: predictionActions.getGISAuthToken }
)(MapIndicator);
