import _ from 'lodash';
import map from 'lodash/map';
import React from 'react';
import flags from 'containers/flags/service';

import {
    SOURCES,
    SUPPORTED_AUDIENCES_PLATFORMS_PERCENTAGES,
} from 'containers/audience-segment-picker/modules/picker-v2/constants';
import {
    DESKTOP_DEVICE_OS_OPTIONS,
    INAPP_DEVICES_OS_OPTIONS,
} from 'forms/ad-form/services/default-values';
import { formatNumber_currency } from 'utils/formatting';
import PLATFORM_OPTIONS from 'common/constants/platforms';
import { IAS_BRAND_SAFETY_GAMBLING_VALUES } from 'common/constants/ias-brand-safety';
import { IAS_IP_FRAUD_VALUES } from 'common/constants/ias-fraud-prevention';
import { IAS_GROUPM_VIEWABILITY_VALUES } from 'common/constants/ias-groupm-viewability';
import { IAS_PUBLICIS_VIEWABILITY_VALUES } from 'common/constants/ias-publicis-viewability';
import { EXCHANGES_BY_CAMPAIGN_TYPE } from 'forms/ad-form/constants';
import { IAS_VIEWABILITY_MAPPING_VALUES } from 'common/constants/ias-viewability';
import { AUDIENCE_STATUS_MAPPING } from 'pages/presets/poi-editor/services/constants';
import { PlatformMapping } from 'states/resources/ads/business-logic';
import {
    STANDARD_RETARGETING_TYPES,
    RETARGETING_TYPES,
} from 'states/resources/audiences/business-logic';
import {
    getMobileCarrierOptionValues,
    getDesktopIspOptionValues,
} from 'common/constants/carrier-targeting-v2';
import { CTV_DEVICE_OS_OPTIONS } from 'forms/ad-form/services/default-values';

const PLATFORMS = {};
_.each(PLATFORM_OPTIONS, option => (PLATFORMS[option.value] = option));

export const getAudienceWarning = ({
    adPlatforms,
    allMappedSegments,
    isCTVCampaign,
    isDOOHCampaign,
}) => {
    let isExpandedToIncludeCTV = false;
    let isExpandedToIncludeMobileAndDesktop = false;
    let isExpandedToIncludeAllCTVDevices = false;

    const pickedSegmentsData = _(allMappedSegments)
        .filter(segment => !!segment)
        .map(segment => {
            const isRetargetingAudience = segment.mode === 'retargeting';
            const isFromStandardTypes = STANDARD_RETARGETING_TYPES.includes(
                segment.retargeting_type
            );

            const isCTVImpressionsRetargetingType =
                _.includes(segment.supports, PlatformMapping.CTV) &&
                segment.retargeting_type === RETARGETING_TYPES.Impression;
            const isCTVAttributionRetargetingType =
                _.includes(segment.supports, PlatformMapping.CTV) &&
                segment.retargeting_type === RETARGETING_TYPES.Attribution;
            const isFromPixelRetargetingType = segment.retargeting_type === RETARGETING_TYPES.Pixel;

            const segmentPlatforms = segment.supports ? segment.supports.slice(0) : [];

            if (
                isCTVCampaign &&
                isRetargetingAudience &&
                (isFromStandardTypes || isFromPixelRetargetingType)
            ) {
                // For CTV campaigns we should always expand retargeting audiences to include CTV platform.
                // As a result we need to inform the user this was the case.
                if (!_.includes(segmentPlatforms, PlatformMapping.CTV)) {
                    isExpandedToIncludeCTV = true;
                    segmentPlatforms.push(PlatformMapping.CTV);
                }
            }

            if (
                isCTVCampaign &&
                isRetargetingAudience &&
                (isCTVImpressionsRetargetingType || isFromPixelRetargetingType)
            ) {
                // For CTV campaigns we should expand retargeting audiences to include all CTV devices in the household.
                // As a result we need to inform the user this was the case.
                if (_.includes(segmentPlatforms, PlatformMapping.CTV)) {
                    isExpandedToIncludeAllCTVDevices = true;
                }
            }

            if (
                !isCTVCampaign &&
                !isDOOHCampaign &&
                isRetargetingAudience &&
                (isCTVImpressionsRetargetingType || isCTVAttributionRetargetingType)
            ) {
                // For Standard campaigns we should always expand retargeting audiences to include INAPP and MWEB platforms.
                // As a result we need to inform the user this was the case.
                if (_.includes(segmentPlatforms, PlatformMapping.CTV)) {
                    isExpandedToIncludeMobileAndDesktop = true;
                    segmentPlatforms.push([PlatformMapping.MWEB, PlatformMapping.INAPP]);
                }
            }

            let dominantSource;
            if (isCTVCampaign) {
                dominantSource = 'uniqueCTVs';
            } else if (isDOOHCampaign) {
                dominantSource = 'uniqueDOOHs';
            } else {
                dominantSource = SUPPORTED_AUDIENCES_PLATFORMS_PERCENTAGES[
                    segment.source_type
                ].getDominantSource({
                    segment,
                });
            }

            const incompatiblePlatforms = _.filter(adPlatforms, platform => {
                if (platform === 'desktop') {
                    // Audience segments don't have a 'desktop' platform type
                    // since 'mweb' already supports mobile web and desktop. So if
                    // 'mweb' is part of the segment platforms then we can consider
                    // 'desktop' to be compatible in the ad.
                    return !_.includes(segmentPlatforms, 'mweb');
                }

                return !_.includes(segmentPlatforms, platform);
            });
            return {
                ...segment,
                incompatiblePlatforms,
                dominantSource,
                segmentPlatforms,
            };
        })
        .value();

    const dominantSources = _(pickedSegmentsData)
        .map(segment => segment.dominantSource)
        .uniq()
        .value();
    const incompatiblePlatforms = _(pickedSegmentsData)
        .map(segment => segment.incompatiblePlatforms)
        .flatten()
        .uniq()
        .value();
    const incompatiblePlatformsInAd = _.intersection(adPlatforms, incompatiblePlatforms);

    if (pickedSegmentsData.length > 0) {
        const segmentPlatforms = _(pickedSegmentsData)
            .map(segment => segment.segmentPlatforms)
            .flatten()
            .uniq()
            .value();
        const willDeliverNoImpressions =
            incompatiblePlatformsInAd.length > 0 &&
            _.intersection(segmentPlatforms, adPlatforms).length !== adPlatforms.length;

        if (isExpandedToIncludeMobileAndDesktop) {
            return {
                hasWarning: true,
                severity: 'soft',
                message:
                    'The selected retargeting audience is based on CTV devices. For this standard campaign, it will be expanded to include all mobile/desktop devices in the household.',
            };
        }

        if (willDeliverNoImpressions) {
            const lowReachSegmentNames = _(pickedSegmentsData)
                .filter(
                    segment => _.intersection(segment.incompatiblePlatforms, adPlatforms).length > 0
                )
                .map(segment => segment.name)
                .value();
            const lowReachingPlatform = _(incompatiblePlatforms)
                .map(platform => PLATFORMS[platform].label)
                .value()
                .join(', ');
            return {
                hasWarning: true,
                lowReachingPlatform: _(incompatiblePlatforms)
                    .map(platform => PLATFORMS[platform].label)
                    .value()
                    .join(', '),
                lowReachSegmentNames,
                preferredPlatform: 'Mobile In-App',
                severity: 'hard',
                message: `No impressions will be delivered to ${lowReachingPlatform}. Select at least 1 compatible audience`,
            };
        }

        if (!isCTVCampaign) {
            if (
                _.includes(adPlatforms, 'inapp') &&
                !_.includes(dominantSources, SOURCES.devices) &&
                !isCTVCampaign
            ) {
                const lowReachingPlatform = 'Mobile In-App';
                _(pickedSegmentsData)
                    .filter(segment => segment.dominantSource === SOURCES.cookies)
                    .map(segment => segment.name)
                    .value();
                const lowReachSegmentNames = _(pickedSegmentsData)
                    .filter(segment => segment.dominantSource === SOURCES.cookies)
                    .map(segment => segment.name)
                    .value();

                const preferredPlatform = _(PLATFORM_OPTIONS)
                    .filter(platform => platform.value !== 'inapp')
                    .map(platform => PLATFORMS[platform.value].label)
                    .value()
                    .join(', ');

                return {
                    hasWarning: true,
                    lowReachingPlatform,
                    lowReachSegmentNames,
                    preferredPlatform,
                    severity: 'soft',
                    message: `${lowReachSegmentNames.join(', ')} -- ${
                        lowReachSegmentNames.length > 1 ? 'these audiences are' : 'this audience is'
                    } best used in a ${preferredPlatform} environment`,
                    title: `Low Reach on ${lowReachingPlatform}`,
                };
            }

            if (
                (_.includes(adPlatforms, 'mweb') || _.includes(adPlatforms, 'desktop')) &&
                !_.includes(dominantSources, SOURCES.cookies)
            ) {
                const lowReachingPlatform = _(adPlatforms)
                    .filter(platform => platform !== 'inapp')
                    .map(platform => PLATFORMS[platform].label)
                    .value()
                    .join(', ');
                const lowReachSegmentNames = _(pickedSegmentsData)
                    .filter(segment => segment.dominantSource === SOURCES.devices)
                    .map(segment => segment.name)
                    .value();
                const preferredPlatform = 'Mobile In-App';

                return {
                    hasWarning: true,
                    lowReachingPlatform,
                    lowReachSegmentNames,
                    preferredPlatform,
                    severity: 'soft',
                    message: `${lowReachSegmentNames.join(', ')} -- ${
                        lowReachSegmentNames.length > 1 ? 'these audiences are' : 'this audience is'
                    } best used in a ${preferredPlatform} environment`,
                    title: `Low Reach on ${lowReachingPlatform}`,
                };
            }
        }

        if (isExpandedToIncludeCTV) {
            return {
                hasWarning: true,
                severity: 'soft',
                message:
                    'The selected retargeting audience is based on mobile/desktop devices. For this CTV campaign, it will be expanded to include all CTV devices in the household.',
            };
        }
        if (isExpandedToIncludeAllCTVDevices) {
            return {
                hasWarning: true,
                severity: 'soft',
                message:
                    'The selected retargeting audience will be expanded to include all CTV devices in the household.',
            };
        }
    }

    return {
        hasWarning: false,
        lowReachingPlatform: '',
        lowReachSegmentNames: [],
        preferredPlatform: '',
        severity: 'soft',
        title: '',
    };
};

export const getExchangeTargetingWarning = ({
    platforms,
    exchangesBlockedUI,
    restrictedCategory,
    campaignType,
}) => {
    const VALID_EXCHANGES_VALUES = EXCHANGES_BY_CAMPAIGN_TYPE[campaignType];
    const isTargetingMweb = _.includes(platforms, 'mweb');
    const isTargetingDesktop = _.includes(platforms, 'desktop');
    const isExcludingRubicon = _.includes(exchangesBlockedUI, 'rubicon');
    const isExcludingAdx = _.includes(exchangesBlockedUI, 'adx');
    const isExcludingSmaato = _.includes(exchangesBlockedUI, 'smaato');
    const isExcludingIndex = _.includes(exchangesBlockedUI, 'index');
    const isExcludingSharethrough = _.includes(exchangesBlockedUI, 'sharethrough');

    // If this campaign has cannabis as its restricted category then we should enforce
    // that the user can only use the index or sharethrough exchanges.
    if (restrictedCategory === 'cannabis') {
        const isNotExcludingAllButIndexAndSharethrough = VALID_EXCHANGES_VALUES.some(
            exchangeName => {
                return (
                    exchangeName !== 'index' &&
                    exchangeName !== 'sharethrough' &&
                    !exchangesBlockedUI.includes(exchangeName)
                );
            }
        );

        if (
            (isExcludingIndex && isExcludingSharethrough) ||
            isNotExcludingAllButIndexAndSharethrough
        ) {
            return {
                hasWarning: true,
                severity: 'hard',
                message:
                    'Cannabis campaigns are only permitted on Index Exchange and Sharethrough. Please exclude all other exchanges.',
            };
        }
    }

    if (
        (isTargetingMweb || isTargetingDesktop) &&
        (isExcludingRubicon &&
            isExcludingAdx &&
            isExcludingIndex &&
            isExcludingSharethrough &&
            isExcludingSmaato)
    ) {
        return {
            hasWarning: true,
            message:
                'No impressions will be delivered to Mobile Web or Desktop. Include at least 1 compatible exchange: Google Ad Manager or Rubicon Project',
            severity: 'hard',
        };
    }

    return {
        hasWarning: false,
        message: '',
        severity: 'soft',
    };
};

export const getGeoFencingWarning = ({ platforms, geofence }) => {
    if (
        (_.includes(platforms, 'desktop') || _.includes(platforms, 'mweb')) &&
        geofence.length > 0
    ) {
        const ignoredPlatforms = _(platforms)
            .filter(platform => platform !== 'inapp')
            .map(platform => PLATFORMS[platform].label)
            .value()
            .join(', ');
        return {
            hasWarning: true,
            message: 'Geofencing is best used in a Mobile In-App environment',
            title: `Limited Reach on ${ignoredPlatforms}`,
        };
    }
    return {
        hasWarning: false,
        message: '',
        title: '',
        severity: 'soft',
    };
};

export const getIASGamblingWarning = ({ platforms, gamblingValue }) => {
    if (
        (_.includes(platforms, 'mweb') || _.includes(platforms, 'desktop')) &&
        gamblingValue !== IAS_BRAND_SAFETY_GAMBLING_VALUES.OFF
    ) {
        const gamblingIgnoredPlatforms = _(platforms)
            .filter(platform => platform !== 'inapp')
            .map(platform => PLATFORMS[platform].label)
            .value()
            .join(', ');
        return {
            hasWarning: true,
            message: `Gambling value will be ignored for ${gamblingIgnoredPlatforms} impressions`,
        };
    }
    return {
        hasWarning: false,
        message: '',
        severity: 'soft',
    };
};

export const getIASFraudIpWarning = ({ platforms, ipValue }) => {
    if (ipValue === IAS_IP_FRAUD_VALUES.ON && _.includes(platforms, 'inapp')) {
        return {
            hasWarning: true,
            message:
                'Exclude fraudulent IP addresses option will be ignored for Mobile In-App impressions',
        };
    }
    return {
        hasWarning: false,
        message: '',
        severity: 'soft',
    };
};

export const getIASPublicisGroupMWarning = ({
    platforms,
    viewabilityValue,
    field,
    creativeFormats,
}) => {
    if (field === 'groupm') {
        if (
            viewabilityValue !== IAS_GROUPM_VIEWABILITY_VALUES.OFF &&
            _.includes(platforms, 'inapp')
        ) {
            return {
                hasWarning: true,
                message: 'GroupM value will be ignored for Mobile In-App impressions',
                severity: 'soft',
            };
        }
    }

    if (field === 'publicis') {
        let publicisWarnings = [];
        if (
            viewabilityValue !== IAS_PUBLICIS_VIEWABILITY_VALUES.OFF &&
            _.includes(platforms, 'inapp')
        ) {
            publicisWarnings.push({
                hasWarning: true,
                message: 'Publicis value will be ignored for Mobile In-App impressions',
                severity: 'soft',
            });
        }
        if (
            viewabilityValue !== IAS_PUBLICIS_VIEWABILITY_VALUES.OFF &&
            !_.includes(creativeFormats, 'video')
        ) {
            publicisWarnings.push({
                hasWarning: true,
                message: 'Publicis value will be ignored for non-video creatives',
                severity: 'soft',
            });
        }
        return publicisWarnings;
    }

    if (field === 'viewability') {
        if (
            viewabilityValue !== IAS_VIEWABILITY_MAPPING_VALUES.OFF &&
            _.includes(platforms, 'inapp') &&
            _.includes(creativeFormats, 'video')
        ) {
            return {
                hasWarning: true,
                message: 'Viewability value will be ignored for Mobile In-App Video creatives',
                severity: 'soft',
            };
        }
    }
    return {
        hasWarning: false,
        message: '',
        severity: 'soft',
    };
};

export const getDeviceOsWarning = ({ selectedDevices, platforms }) => {
    const shouldHaveCTVTarget = _.includes(platforms, 'ctv');
    const shouldHaveDesktopTarget = _.includes(platforms, 'desktop');
    const shouldHaveInappMobileTarget =
        _.includes(platforms, 'inapp') || _.includes(platforms, 'mweb');

    const desktopTargets = DESKTOP_DEVICE_OS_OPTIONS.map(option => option.value);
    const selectedDesktopTargets = _.intersection(selectedDevices, desktopTargets);

    const inappMwebTargets = INAPP_DEVICES_OS_OPTIONS.map(option => option.value);
    const selectedMwebTargets = _.intersection(selectedDevices, inappMwebTargets);

    const ctvTargets = CTV_DEVICE_OS_OPTIONS.map(option => option.value);
    const selectedCTVTargets = _.intersection(selectedDevices, ctvTargets);

    if (shouldHaveDesktopTarget && selectedDesktopTargets.length === 0) {
        return {
            hasWarning: true,
            severity: 'hard',
            message:
                'No Impressions will be delivered to Desktop. Select at least 1 compatible Device OS',
        };
    }

    if (shouldHaveInappMobileTarget && selectedMwebTargets.length === 0) {
        return {
            hasWarning: true,
            severity: 'hard',
            message: `No Impressions will be delivered to ${_(platforms)
                .filter(platform => platform !== 'desktop')
                .map(platform => PLATFORMS[platform].label)
                .value()
                .join(', ')}. Select at least 1 compatible Device OS`,
        };
    }

    if (shouldHaveCTVTarget && selectedCTVTargets.length === 0) {
        return {
            hasWarning: true,
            severity: 'hard',
            message:
                'No Impressions will be delivered to Connected TV. Select at least 1 compatible Device OS',
        };
    }

    return {
        hasWarning: false,
        severity: '',
        message: '',
    };
};

export const getCarrierISPWarning = ({ platforms, includedCarriers }) => {
    const mobileCarrierOptionValues = getMobileCarrierOptionValues();
    const desktopIspOptionValues = getDesktopIspOptionValues();

    const shouldHaveDesktopCarriers = _.includes(platforms, 'desktop');
    const shouldHaveMobileCarriers =
        _.includes(platforms, 'mweb') || _.includes(platforms, 'inapp');

    if (includedCarriers.length > 0) {
        const hasIncludedMobileCarriers =
            _.intersection(includedCarriers, mobileCarrierOptionValues).length > 0;
        const hasIncludedDesktopCarriers =
            _.intersection(includedCarriers, desktopIspOptionValues).length > 0;

        if (shouldHaveDesktopCarriers && !hasIncludedDesktopCarriers) {
            return {
                hasWarning: true,
                severity: 'hard',
                message:
                    'No impressions will be delivered to Desktop. Include at least 1 compatible Carrier/ISP',
            };
        }

        if (shouldHaveMobileCarriers && !hasIncludedMobileCarriers) {
            const noImpressionPlatforms = _(platforms)
                .filter(platform => platform !== 'desktop')
                .map(platform => PLATFORMS[platform].label)
                .value()
                .join(', ');
            return {
                hasWarning: true,
                severity: 'hard',
                message: `No impressions will be delivered to ${noImpressionPlatforms}. Include at least 1 compatible Carrier/ISP`,
            };
        }
    }

    return {
        hasWarning: false,
        severity: '',
        message: '',
    };
};

export const getSingleRotationCreativeWarning = ({ adPlatforms, creative }) => {
    const isEveryAdPlatformTargeted =
        _.intersection(adPlatforms, creative.platforms).length === adPlatforms.length;
    if (!isEveryAdPlatformTargeted) {
        const nonTargetedPlatforms = _.filter(
            adPlatforms,
            platform => !_.includes(creative.platforms, platform)
        );
        const nonTargetedPlatformNames = _.map(
            nonTargetedPlatforms,
            platform => PLATFORMS[platform].label
        ).join(', ');
        return {
            hasWarning: true,
            severity: 'hard',
            message: `No impressions will be delivered to ${nonTargetedPlatformNames}. Select at least 1 compatible creative`,
        };
    }
    return {
        hasWarning: false,
        severity: '',
        message: '',
    };
};

export const getEvenRotationCreativeWarning = ({ adPlatforms, creatives }) => {
    const creativePlatforms = _(creatives)
        .map(creative => creative.platforms)
        .flatten()
        .uniq()
        .value();
    const targetedAdPlatformsByCreatives = _.intersection(adPlatforms, creativePlatforms);
    const areAllAdPlatformsTargeted = targetedAdPlatformsByCreatives.length === adPlatforms.length;
    const isCreativeTargetingNonAdPlatform =
        _.difference(creativePlatforms, adPlatforms).length > 0;

    if (!areAllAdPlatformsTargeted || isCreativeTargetingNonAdPlatform) {
        let message = '';
        let severity = 'hard';
        if (!areAllAdPlatformsTargeted) {
            const nonTargetedPlatforms = _.filter(
                adPlatforms,
                platform => !_.includes(targetedAdPlatformsByCreatives, platform)
            );
            const nonTargetedPlatformNames = _.map(
                nonTargetedPlatforms,
                platform => PLATFORMS[platform].label
            ).join(', ');
            message = `No impressions will be delivered to ${nonTargetedPlatformNames}. Select at least 1 compatible creative`;
        } else if (isCreativeTargetingNonAdPlatform) {
            const nonTargetedPlatforms = _.filter(
                creativePlatforms,
                platform => !_.includes(targetedAdPlatformsByCreatives, platform)
            );
            const nonTargetedPlatformNames = _.map(
                nonTargetedPlatforms,
                platform => PLATFORMS[platform].label
            ).join(', ');
            severity = 'soft';
            message = `${nonTargetedPlatformNames} will not be targeted due to this ad’s platform targeting settings.`;
        }

        return {
            hasWarning: true,
            severity,
            message,
        };
    }

    return {
        hasWarning: false,
        severity: '',
        message: '',
    };
};

export const getWeightedRotationCreativeWarning = ({ adPlatforms, creatives }) => {
    const evenRotationResult = getEvenRotationCreativeWarning({ adPlatforms, creatives });

    if (evenRotationResult.hasWarning) {
        return evenRotationResult;
    }

    const adPlatformWeights = _.map(adPlatforms, adPlatform => {
        const creativesTargetingAdPlatform = _.filter(creatives, creative =>
            _.includes(creative.platforms, adPlatform)
        );
        const totalWeightForAdPlatform = _.sumBy(creativesTargetingAdPlatform, 'weight');
        return { weight: totalWeightForAdPlatform, name: adPlatform };
    });

    const zeroWeightedPlatforms = _.filter(adPlatformWeights, adWeight => adWeight.weight === 0);

    if (zeroWeightedPlatforms.length > 0) {
        const zeroWeightedPlatformNames = _.map(
            zeroWeightedPlatforms,
            platform => PLATFORMS[platform.name].label
        );
        const message = `No impressions will be delivered to ${zeroWeightedPlatformNames}. Please assign a weight`;
        return {
            hasWarning: true,
            severity: 'hard',
            message,
        };
    }

    return {
        hasWarning: false,
        severity: '',
        message: '',
    };
};

const generateScheduledRotationWarningMessaging = warnings => {
    const title = `Creative Targeting Errors on ${warnings.length > 1 ? 'Ranges' : 'Range'} ${_.map(
        warnings,
        warning => warning.row
    ).join(', ')}`;

    const formattedWarningMessages = map(
        warnings,
        warning => `${warnings.length > 1 ? `Row ${warning.row}: ` : ''}${warning.message}`
    ).join('\n');
    const warningRows = map(warnings, ({ row, message }) => ({ row, message }));

    return { title, message: formattedWarningMessages, rows: warningRows };
};

export const getScheduledRotationWarning = ({
    adPlatforms,
    scheduledCreatives,
    creativeMapping,
}) => {
    const isEmptyScheduleRow =
        _.size(scheduledCreatives) === 1 && _.size(scheduledCreatives[0].weighted) === 0;
    if (isEmptyScheduleRow) {
        return {
            hasWarning: false,
            severity: '',
            message: '',
            title: '',
            rows: [],
        };
    }
    const warnings = _(scheduledCreatives)
        .map((scheduledCreativeRow, index) => {
            const creativesInRow = _(scheduledCreativeRow.weighted)
                .filter(creative => creative.markup_id)
                .map(creative => ({
                    ...creativeMapping[creative.markup_id],
                    weight: creative.weight,
                }))
                .value();
            const warning = getWeightedRotationCreativeWarning({
                adPlatforms,
                creatives: creativesInRow,
            });
            const row = index + 1;

            return { ...warning, row };
        })
        .filter(warning => warning.hasWarning)
        .value();

    const hasWarning = warnings.length > 0;
    const severity = hasWarning ? warnings[0].severity : '';
    const { message, title, rows } = hasWarning
        ? generateScheduledRotationWarningMessaging(warnings)
        : { message: '', title: '' };

    return {
        hasWarning,
        severity,
        message,
        title,
        rows,
    };
};

export const getAppCategoryWarnings = ({
    includedAppCategories,
    excludedAppCategories,
    platforms,
}) => {
    if (_.includes(platforms, 'mweb') || _.includes(platforms, 'desktop')) {
        if (
            (includedAppCategories && includedAppCategories.length > 0) ||
            (excludedAppCategories && excludedAppCategories.length > 0)
        ) {
            const ignoredPlatforms = _(platforms)
                .filter(platform => platform !== 'inapp')
                .map(platform => PLATFORMS[platform].label)
                .value()
                .join(', ');
            return {
                hasWarning: true,
                severity: 'soft',
                message: `App Categories value will be ignored for ${ignoredPlatforms} impressions`,
            };
        }
    }
    return {
        hasWarning: false,
        severity: 'soft',
        message: '',
    };
};

export const getContextualTargetingWarnings = ({ contextualTargetingValue, platforms }) => {
    if (_.includes(platforms, 'inapp')) {
        if (contextualTargetingValue && contextualTargetingValue.length > 0) {
            return {
                hasWarning: true,
                severity: 'soft',
                message: 'Contextual Targeting value will be ignored for Mobile In-App impressions',
            };
        }
    }
    return {
        hasWarning: false,
        severity: 'soft',
        message: '',
    };
};

export const getTotalBidPriceWarning = bidStrategy => {
    if (bidStrategy === 'fixed_bid_price') {
        return {
            hasWarning: true,
            severity: 'soft',
            message: flags.isEnabled('efb_35_curated_deals')
                ? `Total Bid Price will only be used if bidding on open auctions. The average eCPM may exceed the Total Bid Price if a targeted deal’s floor price is higher.`
                : `Total Bid Price will only be used to purchase public inventory. For Private Deals, the individual Deal's specified price will be used. The overall eCPM may exceed the Total Bid Price set above.`,
        };
    } else if (bidStrategy === 'automatic_bid_price') {
        return {
            hasWarning: true,
            severity: 'soft',
            message: `EngageFront will attempt to honor the Total Bid Price Limit over the ad's entire lifetime, but may exceed this limit at isolated points during its flight in order to purchase private inventory.`,
        };
    }
    return {
        hasWarning: false,
        severity: 'soft',
        message: '',
    };
};

export const getSuspendedSegmentsWarning = allMappedSegments => {
    const hasSuspendedSegments = _.some(
        allMappedSegments,
        segment => segment.status === AUDIENCE_STATUS_MAPPING.suspended
    );

    if (hasSuspendedSegments) {
        return {
            hasWarning: true,
            severity: 'soft',
            message: (
                <>
                    Suspended audiences will be automatically reactivated, but you may not see
                    maximum reach immediately. For best results, please reactivate the audience 2-3
                    weeks ahead of the campaign start date.{' '}
                    <a href="https://engagefront.notion.site/Flex-Geolocation-Audiences-65186564df84408db4278943e03a004e#84055f3d079748ef8c0a5dab9d3c47a8">
                        Details
                    </a>
                </>
            ),
            title: '',
        };
    }
    return {
        hasWarning: false,
        severity: 'soft',
        title: '',
    };
};

export const getDealPriceOverrideWarning = shouldWarning => {
    if (shouldWarning) {
        return {
            hasWarning: true,
            severity: 'soft',
            message: flags.isEnabled('efb_35_curated_deals')
                ? 'The Net Bid Price is set lower than the floor price for one or more targeted deals. Increase your Total Bid Price to avoid underdelivery.'
                : 'Net Bid Price is lower than the floor price for some private deals. Increase your Total Bid Price to avoid underdelivery for these deals.',
        };
    }
    return {
        hasWarning: false,
        severity: 'soft',
        message: '',
    };
};

export const getAdOverBudgetWarning = cost => {
    return {
        severity: 'soft',
        message: `The sum of ad budgets exceed the campaign budget. The campaign\'s Total Cost will be capped at ${formatNumber_currency(
            cost
        )}`,
    };
};
