import _ from 'lodash';

export default function selector(storeState) {
    const { isInventoryDealsModalOpen, ...modalState } = _.get(storeState, 'dealsInventoryModal');
    const { timezone } = _.get(storeState, 'dealsTable');

    return {
        open: isInventoryDealsModalOpen,
        ...modalState,
        timezone,
    };
}
