import _ from 'lodash';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import Link from '@mui/material/Link';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Devices from '@mui/icons-material/Devices';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Home from '@mui/icons-material/Home';
import HolidayVillage from '@mui/icons-material/HolidayVillage';
import Assessment from '@mui/icons-material/Assessment';
import TrendingUp from '@mui/icons-material/TrendingUp';
import CreditCard from '@mui/icons-material/CreditCard';
import SupervisorAccount from '@mui/icons-material/SupervisorAccount';
import TrackChanges from '@mui/icons-material/TrackChanges';
import DirectionsWalk from '@mui/icons-material/DirectionsWalk';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import Person from '@mui/icons-material/Person';
import Flag from '@mui/icons-material/Flag';
import Help from '@mui/icons-material/Help';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import IconButton from '@mui/material/IconButton';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import DvrIcon from '@mui/icons-material/Dvr';
import Alert from '@mui/material/Alert';
import ListItemButton from '@mui/material/ListItemButton';

import classnames from 'classnames';
import { connect } from 'react-redux';
import Impersonate from 'containers/impersonate';
import { browserHistory } from 'react-router';
import selector from './selector';
import { getEnvironmentSettings } from 'services/environment';
import { isInternalUser } from 'states/profile/business-rules';
import { withFlags } from 'containers/flags';
import flags from 'containers/flags/service';
import Notifications from './modules/notifications';
import JobsProgressWindow from 'containers/report-jobs/progress-window';
import { PermissionsMapping } from 'common/constants/role-intents';

import { can } from 'services/can';

import config from '../../../../config';

import {
    removeCampaignTabFromSidebar,
    removeAllCampaignsTabFromSidebar,
    pinCampaignToggled,
    logout,
    updateNotificationsLastSeen,
} from 'containers/sidebar/actions';

import { Link as LinkRouter } from 'react-router';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        justifyContent: 'space-between',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + ${1}px)`,
        justifyContent: 'space-between',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    collapseIcon: {
        transform: 'rotate(180deg)',
    },
    drawerBlock: {
        width: '100%',
    },
    toggleButtonWrapper: {
        position: 'absolute',
        right: '-10',
        top: 46,
        zIndex: 10000,
    },
    toggleButton: {
        backgroundColor: 'white',
        boxShadow: theme.shadows[1],

        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: 'white',
        },
    },
    environmentHeaderDevelopment: {
        backgroundColor: '#0288D11F',
    },
    environmentHeaderStaging: {
        backgroundColor: '#ED6C021F',
    },
}));

const environmentSettings = getEnvironmentSettings();

export const options = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        icon: () => <Home />,
        link: () => '/home',
        auth: () => {
            return can(PermissionsMapping.OVERVIEW__VIEW);
        },
    },
    {
        key: 'self-serve-dashboard',
        label: 'Self Serve Dashboard',
        icon: () => <HolidayVillage />,
        link: () => '/home/self-serve',
        auth: () => {
            return isInternalUser();
        },
    },
    {
        key: 'campaigns',
        label: 'Campaigns',
        icon: () => <DvrIcon />,
        link: props => props.dashboardUrl,
        auth: () => {
            return can(PermissionsMapping.DASHBOARD__VIEW);
        },
    },
    {
        key: 'inventory-forecast',
        label: 'Inventory Forecast',
        icon: () => <TrendingUp />,
        link: () => '/inventory',
        auth: () => {
            const environmentSettings = getEnvironmentSettings();
            if (!environmentSettings.canUseInventoryForecast()) {
                return false;
            }
            return can(PermissionsMapping.CAMPAIGN__VIEW);
        },
    },
    {
        key: 'reports',
        label: 'Reports',
        icon: () => <Assessment />,
        link: () => '/reports',
        auth: () => {
            return can(PermissionsMapping.CAMPAIGN__VIEW);
        },
    },
    {
        key: 'account',
        label: 'Account',
        icon: () => <CreditCard />,
        link: () => '/account',
        auth: () => {
            return can(PermissionsMapping.ACCOUNT__VIEW);
        },
    },
    {
        key: 'admin',
        label: 'Admin',
        icon: () => <SupervisorAccount />,
        link: () => {
            return '/admin';
        },
        auth: ({ profileState }) => {
            if (
                _.includes(
                    [
                        'admin',
                        'campaign_manager',
                        'media_planner',
                        'user_admin',
                        'account_admin',
                        'read_only_super_admin',
                    ],
                    profileState.globalRole
                ) &&
                can(PermissionsMapping.ADMIN__VIEW)
            ) {
                return true;
            }
        },
    },
    {
        key: 'audience-segments',
        label: 'Audiences',
        icon: () => <TrackChanges />,
        link: () => '/audiences/all',
        auth: () => {
            const environmentSettings = getEnvironmentSettings();
            if (!environmentSettings.canUseAudiences()) {
                return false;
            }
            return (
                isInternalUser() || can(PermissionsMapping.AUDIENCE__VIEW)
            );
        },
    },
    {
        key: 'fta-location-lists',
        label: 'Foot Traffic Attribution',
        icon: () => <DirectionsWalk />,
        link: () => '/fta-location-lists',
        auth: () => {
            const environmentSettings = getEnvironmentSettings();
            if (!environmentSettings.canUseFta()) {
                return false;
            }
            return can(PermissionsMapping.FTA__VIEW);
        },
    },
    {
        key: 'apps-sites',
        label: environmentSettings.getAppsAndSitesLabel(),
        icon: () => <Devices />,
        link: () => '/apps-and-sites',
        auth: () => {
            return can(PermissionsMapping.CAMPAIGN__VIEW);
        },
    },
    {
        key: 'advertiser_pixels',
        label: 'Advertiser Pixels',
        icon: () => <ShoppingCart />,
        link: () => '/pixels',
        auth: () => {
            return can(PermissionsMapping.PIXEL__VIEW);
        },
    },
    {
        key: 'ad-presests',
        label: 'Ad Presets',
        icon: () => <SettingsInputComponentIcon />,
        link: () => '/ad-preset',
        auth: () => {
            const environmentSettings = getEnvironmentSettings();
            if (!environmentSettings.canUseAdPresets()) {
                return false;
            }
            return can(PermissionsMapping.ADPRESET__VIEW);
        },
    },
    {
        key: 'profile',
        label: 'Profile',
        icon: () => <Person />,
        link: () => '/profile',
        auth: () => true,
    },
    {
        key: 'flags',
        label: 'Flags',
        icon: () => <Flag />,
        link: () => '/flags',
        auth: () => flags.canManageFlags(),
    },
];

function SidebarContainer(props) {
    return <Sidebar {...props} />;
}

function Sidebar(props) {
    const {
        location,
        dashboardUrl,
        profileState,
        params,
        selectedCampaigns,
        ownOrgType,
        notificationsSeen,
    } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [allCampaignsExpanded, setallCampaignsExpanded] = React.useState(true);
    const impersonate = props.canImpersonate ? <Impersonate /> : null;
    const sidebarOptions = options;

    function handleDrawerOpen() {
        setOpen(true);
    }

    function handleDrawerClose() {
        setOpen(false);
    }

    function handleAllCampaignArrow() {
        setallCampaignsExpanded(!allCampaignsExpanded);
    }

    function handleCloseButtonClicked(campaignId) {
        props.dispatch(removeCampaignTabFromSidebar(campaignId));

        if (props.params.campaignId === campaignId) {
            browserHistory.push('/dashboard');
        }
    }

    function handleCloseAllButtonClicked() {
        props.dispatch(removeAllCampaignsTabFromSidebar());
    }

    function handleTogglePinCampaign(campaignId) {
        props.dispatch(pinCampaignToggled(campaignId));
    }

    function handleLogout(e) {
        if (e) {
            e.preventDefault();
        }

        props.dispatch(logout());
    }

    function isLinkSelected(link) {
        if (
            _.startsWith(link(props, flags), '/audiences') &&
            _.startsWith(location.pathname + location.search, '/audiences')
        ) {
            return true;
        }
        if (
            _.startsWith(link(props, flags), '/admin') &&
            _.startsWith(location.pathname + location.search, '/admin')
        ) {
            return true;
        }

        // For the /home route we have two possible routes. Normal dashboard and
        // self-serve dashboard. We can't just check the initial /home because it would
        // highlight both options in the sidebar which is why we need to be specific
        // to decide what to highlight in this case.
        if (_.startsWith(location.pathname + location.search, '/home')) {
            return _.isEqual(location.pathname + location.search, link(props, flags));
        }

        return (
            location.pathname &&
            _.startsWith(location.pathname + location.search, link(props, flags))
        );
    }

    function handleUpdateNotificationsLastSeen() {
        props.dispatch(updateNotificationsLastSeen());
    }

    function showEnvironmentMessage() {
        if (!['development', 'cloud-dev'].includes(config.ENV_NAME)) {
            return;
        }
        let severity = 'info';
        let message = 'Engagefront Development';

        if (config.ENV_NAME === 'cloud-dev') {
            severity = 'warning';
            message = 'Engagefront Staging';
        }

        return (
            <Alert icon={false} severity={severity}>
                {message}
            </Alert>
        );
    }

    function getHref() {
        return can(PermissionsMapping.OVERVIEW__VIEW) ? '/home' : dashboardUrl;
    }

    return (
        <React.Fragment>
            <div className={classes.toggleButtonWrapper}>
                <IconButton
                    className={classes.toggleButton}
                    classes={{ root: classes.toggleButton }}
                    onClick={open ? handleDrawerClose : handleDrawerOpen}
                    size="small"
                >
                    <ChevronRight fontSize="small" className={open && classes.collapseIcon} />
                </IconButton>
            </div>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <Grid item className={classes.drawerBlock}>
                    {showEnvironmentMessage()}
                    <div className={classes.drawerHeader}>
                        <figure className="ef6-sidebar__header">
                            <div>
                                <Link
                                    className="ef6-sidebar__logo-wrapper"
                                    href={getHref()}
                                    color="inherit"
                                >
                                    <img
                                        src="/images/engagefront_colour.png"
                                        className="ef6-sidebar__logo-icon"
                                    />
                                    <div>
                                        <img
                                            src="/images/EngageFront-logo.png"
                                            className="ef6-sidebar__logo"
                                        />
                                        <figcaption className="ef6-sidebar__logo-tagline">
                                            <span className="ef6-sidebar__tagline">
                                                by Pelmorex
                                            </span>
                                        </figcaption>
                                    </div>
                                </Link>
                            </div>
                        </figure>
                    </div>
                    <Divider />
                    <List>
                        {_(sidebarOptions)
                            .filter(({ auth }) =>
                                auth({ profileState, props, orgType: ownOrgType })
                            )
                            .map(({ label, icon, link }) => (
                                <ListItemButton
                                    key={label}
                                    selected={isLinkSelected(link)}
                                    classes={{
                                        root: classes.menuItem,
                                        selected: classes.selectedMenuItem,
                                    }}
                                    component={props => <LinkRouter {...props} />}
                                    to={link(props, flags)}
                                >
                                    <ListItemIcon classes={{ root: classes.menuIcon }}>
                                        {open ? (
                                            icon()
                                        ) : (
                                            <Tooltip title={label} placement="right">
                                                {icon()}
                                            </Tooltip>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText primary={label} />
                                </ListItemButton>
                            ))
                            .value()}
                        {can(PermissionsMapping.CAMPAIGN__VIEW) && (
                            <ListItemButton
                                classes={{
                                    root: classes.menuItem,
                                    selected: classes.selectedMenuItem,
                                }}
                                component="a"
                                href="https://engagefront.notion.site/EngageFront-Help-d42770ef94114becb999c1042df082d1"
                                target="_blank"
                                color="inherit"
                                underline="none"
                            >
                                <ListItemIcon classes={{ root: classes.menuIcon }}>
                                    {open ? (
                                        <Help />
                                    ) : (
                                        <Tooltip title="Help" placement="right">
                                            <Help />
                                        </Tooltip>
                                    )}
                                </ListItemIcon>
                                <ListItemText primary="Help" />
                            </ListItemButton>
                        )}
                        {can(PermissionsMapping.FEATURE_NOTIFICATION) && (
                            <Notifications
                                updateNotificationsLastSeen={handleUpdateNotificationsLastSeen}
                                notificationsSeen={notificationsSeen}
                                sideBarOpen={open}
                            />
                        )}
                        <ListItemButton onClick={handleLogout}>
                            <ListItemIcon classes={{ root: classes.menuIcon }}>
                                {open ? (
                                    <PowerSettingsNew />
                                ) : (
                                    <Tooltip title="Log out" placement="right">
                                        <PowerSettingsNew />
                                    </Tooltip>
                                )}
                            </ListItemIcon>
                            <ListItemText primary="Log out" />
                        </ListItemButton>
                    </List>
                    {open && (
                        <List>
                            {props.selectedCampaigns.length > 0 && (
                                <SidebarCampaignTabs
                                    allCampaignsExpanded={allCampaignsExpanded}
                                    handleAllCampaignArrow={handleAllCampaignArrow}
                                    location={location}
                                    dashboardUrl={dashboardUrl}
                                    currentCampaign={params.campaignId}
                                    removeCampaign={handleCloseButtonClicked}
                                    togglePinCampaign={handleTogglePinCampaign}
                                    selectedCampaigns={selectedCampaigns}
                                    closeAllTabs={handleCloseAllButtonClicked}
                                />
                            )}
                        </List>
                    )}
                </Grid>
                <JobsProgressWindow />
                <Grid item className={classes.drawerBlock}>
                    {open && <List>{impersonate}</List>}
                </Grid>
            </Drawer>
        </React.Fragment>
    );
}

function SidebarTab(props) {
    return (
        <div
            className={classnames(props.className, 'ef6-sidebar__tab', {
                'ef6-sidebar__tab--is-active': props.isActive,
            })}
        >
            {props.canPin && (
                <i
                    className={classnames('fa fa-thumb-tack', 'ef6-sidebar__pin-tab', {
                        'ef6-sidebar__pin-tab--is-pinned': props.isPinned,
                    })}
                    onClick={props.onPin}
                />
            )}
            <LinkRouter
                component={Link}
                to={props.link}
                className={classnames('ef6-sidebar__tab-name', {
                    'ef6-sidebar__tab-name--is-active': props.isActive,
                })}
            >
                {props.text}
            </LinkRouter>
            <i
                className={classnames('fa fa-times', 'ef6-sidebar__close-tab', {
                    'ef6-sidebar__close-tab--is-pinned': props.isPinned,
                })}
                onClick={props.onClose}
            />
        </div>
    );
}

function SidebarCampaignTabs(props) {
    return (
        <div className="ef6-sidebar__tabs">
            <div
                className={classnames('ef6-sidebar__tab-wrapper', 'ef6-sidebar__tab')}
                onClick={props.handleAllCampaignArrow}
            >
                <div
                    onClick={props.toggleSlidingMobile}
                    className={classnames(
                        'ef6-sidebar__tab-title',
                        'ef6-sidebar__tab-name',
                        'ef6-sidebar__dashboard-tab'
                    )}
                >
                    Recently Selected Campaigns
                </div>
                <i
                    className={classnames(
                        'fa ef6-sidebar__tab-icon',
                        { 'fa-chevron-down': !props.allCampaignsExpanded },
                        { 'fa-chevron-up': props.allCampaignsExpanded }
                    )}
                />
            </div>
            {props.allCampaignsExpanded && (
                <div
                    onClick={props.toggleSlidingMobile}
                    className="ef6-sidebar__nav-campaign-wrapper"
                >
                    <div className="ef6-sidebar__nav-campaign">
                        {props.selectedCampaigns.map(campaign => (
                            <SidebarTab
                                onClick={props.toggleSlidingMobile}
                                key={campaign.id}
                                isActive={props.currentCampaign === campaign.id}
                                canPin={true}
                                isPinned={campaign.isPinned}
                                onPin={() => props.togglePinCampaign(campaign.id)}
                                link={campaign.path}
                                text={`#${campaign.id} ${campaign.name}`}
                                onClose={() => props.removeCampaign(campaign.id)}
                            />
                        ))}
                    </div>
                    <div
                        className={classnames('ef6-sidebar__close-all-tabs-button', {
                            'ef6-sidebar__tab--is-hidden': props.selectedCampaigns.length < 2,
                        })}
                        onClick={props.closeAllTabs}
                    >
                        Close Unpinned Tabs
                    </div>
                </div>
            )}
        </div>
    );
}

export default connect(selector)(withFlags(SidebarContainer));
