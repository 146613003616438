import _ from 'lodash';

import c from 'common/constants/flux-events';
import { combineReducers } from 'redux';
import createPivotTableService from 'widgets/pivot-table/service';
import moment from 'moment';
import metricSelector from './reducer-metric-selector';
import { isInternalUser } from 'states/profile/business-rules';

let initKey = 0;
const pt = createPivotTableService();

const columnsWithSpendBreakdown = [
    { label: 'Dimensions', name: 'dimension', defaultValue: '', formatType: '' },
    { name: 'impressions' },
    { name: 'clicks' },
    { name: 'owner_inventory_cost_local' },
    { name: 'owner_media_cost_2_local' },
    { name: 'data_cost' },
    { name: 'spend' },
    { name: 'third_party_fees' },
    { name: 'owner_total_media_cost_local' },
    { label: 'Billing Rate', name: 'billing_rate', defaultValue: '', formatType: '' },
    { name: 'billings_local' },
];

const initialState = {
    responseTimeout: false,
    isInitializing: true,
    isPivotTableLoading: true,
    isAccountSummaryLoading: true,

    pivotTableStats: {},
    campaignSearch: '',
    campaignStatus: null,
    selectedClient: null,

    selectedRangeCosts: {
        ownerCostLocal: 0,
        inventoryCost: 0,
    },

    earliestStart: moment
        .utc()
        .startOf('month')
        .format('YYYY-MM-DDTHH:mm:ss'),
    latestEnd: moment
        .utc()
        .endOf('month')
        .format('YYYY-MM-DDTHH:mm:ss'),

    cache: {},
    dictionary: {
        total: {
            total: {
                value: 'Total',
            },
        },
        scope: {
            ad: {
                value: 'Ads',
            },
        },
    },
    dateRange: {
        start: moment()
            .startOf('month')
            .format('YYYY-MM-DDTHH:mm:ss'),
        end: moment()
            .endOf('month')
            .format('YYYY-MM-DDTHH:mm:ss'),
        timezone: 'UTC',
    },
    columns: [].concat([
        { label: 'Dimensions', name: 'dimension', defaultValue: '', formatType: '' },
        { name: 'impressions' },
        { name: 'clicks' },
        { name: 'owner_inventory_cost_local' },
        { name: 'owner_media_cost_2_local' },
        { name: 'spend' },
        { label: 'Billing Rate', name: 'billing_rate', defaultValue: '', formatType: '' },
        { name: 'billings_local' },
    ]),
    pivotTable: {
        splits: [{ label: 'Campaigns', name: 'campaign_id' }],
        dimensions: [
            {
                label: 'Campaigns',
                name: 'campaign_id',
                serverName: '',
            },
            {
                label: 'Ads',
                name: 'ad_id',
                serverName: '',
            },
            {
                label: 'Date',
                name: 'date',
                serverName: '',
            },
        ],
        sort: {
            column: 'dimension',
            order: 'asc',
        },
        tree: {},
    },
    userOrganization: {},
};

export const NAME = 'account';

const accountDetails = function account(state = initialState, action) {
    switch (action.type) {
        case 'ACCOUNT__USER_ORGANIZATION_FETCH_SUCCESS': {
            return {
                ...state,
                userOrganization: action.payload.userOrganization,
            };
        }
        case 'ACCOUNT__FETCHING_REPORT_STATS__RESPONSE_TIMEOUT': {
            return {
                ...state,
                responseTimeout: true,
                isPivotTableLoading: false,
            };
        }
        case c.ACCOUNT__INITIALIZE: {
            const { earliestStart, timezone, organization, clientOrganizations } = action.payload;

            const initialColumns = columnsWithSpendBreakdown;

            let columns = [].concat(
                initialColumns.slice(0, 1),
                // Add custom field fields as columns
                _.map(organization.custom_fields, customField => ({
                    label: customField.name,
                    name: customField.key,
                    defaultValue: '',
                    formatType: '',
                })),
                initialColumns.slice(1)
            );

            if (!isInternalUser()) {
                columns = _.filter(columns, c => c.name !== 'billing_rate');
            }

            initKey += 1;

            return {
                ...state,
                dateRange: {
                    ...initialState.dateRange,
                    timezone,
                },
                isPivotTableLoading: true,
                isInitializing: true,
                earliestStart,
                columns,
                initKey,
                clientOrganizations,
            };
        }
        case c.ACCOUNT__LINK_QUERY__INIT: {
            // react router cannot take object array. Thats why its mapped;
            const { query } = action.payload;
            const splits = _.get(query, 'splits', { campaign_id: 'Campaigns' });
            const start = _.get(query, 'start');
            const end = _.get(query, 'end');
            const selectedClient = _.get(query, 'selectedClient');
            const campaignStatus = _.get(query, 'campaignStatus');
            const campaignSearch = _.get(query, 'campaignSearch', '');

            return {
                ...state,
                campaignStatus,
                campaignSearch,
                selectedClient,
                ...(start &&
                    end && {
                        ...state.dateRange,
                        dateRange: {
                            start,
                            end,
                        },
                    }),
                pivotTable: {
                    ...state.pivotTable,
                    splits: _.map(_.keys(splits), ky => {
                        return {
                            label: splits[ky],
                            name: ky,
                        };
                    }),
                },
            };
        }
        case c.ACCOUNT__INITIALIZE__SUCCESS: {
            const { summaryStats, pivotTableStats } = action.payload;

            const statsFound = _.keys(pivotTableStats).length > 0;

            return {
                ...state,
                pivotTableStats,
                pivotTable: statsFound
                    ? pt.generatePivotTableState(state.pivotTable, pivotTableStats)
                    : state.pivotTable,
                selectedRangeCosts: {
                    ownerCostLocal: summaryStats.owner_cost_local,
                    inventoryCost: summaryStats.inventory_cost,
                },
                isPivotTableLoading: false,
                isAccountSummaryLoading: false,
                isInitializing: false,
            };
        }
        case c.ACCOUNT__SUMMARY__DATA_REFRESH: {
            return {
                ...state,
                isAccountSummaryLoading: true,
            };
        }
        case c.ACCOUNT__SEARCH__BY_CAMPAIGN: {
            const { campaignSearch } = action.payload;

            const filters = getFilters(state, { campaignSearch });

            const rootStats = filterStatsBySplitId(state.pivotTableStats, 'campaign_id', filters);

            return {
                ...state,
                campaignSearch,
                pivotTable: pt.generatePivotTableState(state.pivotTable, rootStats),
            };
        }
        case c.ACCOUNT__SEARCH__BY_CLIENT: {
            const { selectedClient } = action.payload;

            const filters = getFilters(state, { selectedClient });

            const rootStats = filterStatsBySplitId(state.pivotTableStats, 'campaign_id', filters);

            return {
                ...state,
                selectedClient,
                pivotTable: pt.generatePivotTableState(state.pivotTable, rootStats),
            };
        }
        case c.ACCOUNT__SEARCH__BY_STATUS: {
            const { campaignStatus } = action.payload;

            const filters = getFilters(state, { campaignStatus });

            const rootStats = filterStatsBySplitId(state.pivotTableStats, 'campaign_id', filters);

            return {
                ...state,
                campaignStatus,
                pivotTable: pt.generatePivotTableState(state.pivotTable, rootStats),
            };
        }
        case c.ACCOUNT__SUMMARY__DATA_REFRESH__SUCCESS: {
            const { stats } = action.payload;

            return {
                ...state,
                selectedRangeCosts: {
                    ownerCostLocal: stats.owner_cost_local,
                    inventoryCost: stats.inventory_cost,
                },
                isAccountSummaryLoading: false,
            };
        }
        case c.ACCOUNT__PIVOT_TABLE__DATA_REFRESH: {
            return {
                ...state,
                isPivotTableLoading: true,
            };
        }
        case c.ACCOUNT__PIVOT_TABLE__DATA_REFRESH__SUCCESS: {
            const { stats } = action.payload;

            const filters = getFilters(state);

            const statsFound = _.keys(stats).length > 0;

            const rootStats = filterStatsBySplitId(stats, 'campaign_id', filters);

            return {
                ...state,
                pivotTableStats: stats,
                pivotTable: statsFound
                    ? pt.generatePivotTableState(state.pivotTable, rootStats)
                    : state.pivotTable,
                isPivotTableLoading: false,
            };
        }
        case c.ACCOUNT__PIVOT_TABLE__EXPAND_ROW: {
            return {
                ...state,
                pivotTable: pt.expandRow(state.pivotTable, action.payload.rowId),
            };
        }
        case c.ACCOUNT__PIVOT_TABLE__COLLAPSE_ROW: {
            return {
                ...state,
                pivotTable: pt.collapseRow(state.pivotTable, action.payload.rowId),
            };
        }
        case c.ACCOUNT__PIVOT_TABLE__ADD_SPLIT: {
            return {
                ...state,
                pivotTable: pt.addSplit(state.pivotTable, action.payload.dimension),
            };
        }
        case c.ACCOUNT__PIVOT_TABLE__UPDATE_SPLITS: {
            return {
                ...state,
                pivotTable: pt.updateSplits(state.pivotTable, action.payload.splits),
            };
        }
        case c.ACCOUNT__PIVOT_TABLE__REMOVE_SPLIT: {
            const isCampaignIdSplitRemoved = action.payload.dimension.name === 'campaign_id';
            return {
                ...state,
                ...(isCampaignIdSplitRemoved && {
                    campaignSearch: '',
                    selectedClient: null,
                    campaignStatus: null,
                }),
                pivotTable: pt.removeSplit(state.pivotTable, action.payload.dimension),
            };
        }
        case c.ACCOUNT__PIVOT_TABLE__SORT: {
            return {
                ...state,
                pivotTable: pt.sortColumn(state.pivotTable, action.payload.column),
            };
        }
        case c.ACCOUNT__FILTER__DATE_RANGE_FILTER: {
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    ...action.payload.dateRange,
                },
                totalForSelectedMonth: action.payload.totalForSelectedMonth,
                responseTimeout: false,
            };
        }
        case 'ACCOUNT__FILTER__CHANGE_TIMEZONE': {
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    timezone: action.payload.timezone,
                },
            };
        }
        default:
            return state;
    }
};

function filterStatsBySplitId(rootStats, splitDimension, filters) {
    if (!filters) {
        return rootStats;
    }
    const { campaignStatus, campaignSearch, selectedClient } = filters;
    const isApplyingFilters = campaignStatus || campaignSearch || selectedClient;
    const nextRoot = {
        ...rootStats,
    };

    function runInChildren(children) {
        if (!_.get(children, 'stats.length')) {
            return children;
        }

        const filteredChildren = _.map(children.stats, stat => {
            if (_.get(stat, 'stats.length')) {
                return runInChildren(stat);
            }
            return stat;
        });

        const filteredStats = _.filter(filteredChildren, stat => {
            // If there is no campaignData (e.g. ad missing in the local db), filters can not be applied
            if (isApplyingFilters && _.isEmpty(stat.attributes.campaigns)) {
                return false;
            }

            const found = _.some(stat.attributes.campaigns, campaignData => {
                const matchStatus = !campaignStatus || campaignData.status === campaignStatus;
                const matchSearch =
                    !campaignSearch ||
                    new RegExp(campaignSearch, 'gi').test(
                        `#${campaignData.name} ${campaignData.campaign_id}`
                    );
                const matchClient =
                    !selectedClient ||
                    _.some(campaignData.clients, client => {
                        return selectedClient === client.organization;
                    });

                return !matchStatus || !matchSearch || !matchClient;
            });

            if (!found) {
                return true;
            }

            return false;
        });

        return {
            ...children,
            stats: filteredStats,
        };
    }

    return runInChildren(nextRoot, 0);
}

function getFilters(state, overrides = {}) {
    const filters = _.pick(state, ['campaignStatus', 'campaignSearch', 'selectedClient']);

    return {
        ...filters,
        ...overrides,
    };
}
export default combineReducers({
    metricSelector,
    accountDetails,
});
