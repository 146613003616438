import _ from 'lodash';

export const configs = [
    {
        value: 'campaign_id',
        label: 'Campaign',
        formatValue: stat => stat.label,
        orderValue: stat => stat.label,
    },
    {
        value: 'ad_id',
        label: 'Ad',
        formatValue: stat => stat.label,
        orderValue: stat => stat.label,
    },
    {
        value: 'markup_id',
        label: 'Creative',
        formatValue: stat => stat.label,
        orderValue: stat => stat.label,
    },
    {
        value: 'gender',
        label: 'Gender',
        formatValue: stat => stat.label,
        orderValue: stat => getOrderByValue(stat),
    },
    {
        value: 'week_by_campaign_start_day',
        label: 'Week By Campaign Start Day',
        formatValue: stat => stat.label,
        orderValue: stat => stat.label,
    },
    {
        value: 'device_os',
        label: 'Device OS',
        formatValue: stat => stat.label,
        orderValue: stat => getOrderByValue(stat),
    },
    {
        value: 'channel_name',
        label: 'App Name',
        formatValue: ({ stat, channel_type }) => getValueByChannelType(stat, channel_type),
        orderValue: stat => getOrderByValue(stat),
    },
    {
        value: 'channel_type',
        label: 'Type',
        formatValue: ({ stat }) => stat.label,
        orderValue: stat => stat.label,
    },
    {
        value: 'app_store_cat',
        label: 'Category',
        formatValue: ({ stat }) => stat.label,
        orderValue: stat => getOrderByValue(stat),
    },
    {
        value: 'audience_segment',
        label: 'Audience Segment',
        formatValue: stat => stat.label,
        orderValue: stat => stat.label,
    },
    {
        value: 'audience_id',
        label: 'Audience ID',
        formatValue: stat => stat.label,
        orderValue: stat => stat.label,
    },
];

function getOrderByValue(stat) {
    const label = stat.label.toLowerCase();

    if (label.indexOf('not available') > -1) {
        return;
    }

    return label;
}

function getValueByChannelType(stat, channel_type) {
    if (channel_type === 'SITE') {
        return stat.group;
    }
    return stat.label;
}

const dimensionConfig = {};
_.each(configs, config => (dimensionConfig[config.value] = config));

export default dimensionConfig;
