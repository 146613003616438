import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import each from 'lodash/each';
import filter from 'lodash/filter';
import slice from 'lodash/slice';
import map from 'lodash/map';
import includes from 'lodash/includes';
import dropRight from 'lodash/dropRight';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import flags from 'containers/flags/service';

import {
    formatNumber_currency,
    formatNumber_percentage,
    formatNumber_whole,
    formatNumber_wholeFixed,
} from 'utils/formatting';

import { getMetricValue } from 'services/resources/stats';

import { SpaceBetweenLayout, CenterLayout, Flex } from 'widgets-v5/layout';
import { BodyText } from 'widgets-v5/typography';
import Spacer from 'widgets-v5/spacer';
import { InfoTooltip } from 'widgets-v6/tooltip';
import { MultiSelect } from 'widgets-v6/select';
import { isMetricShared } from '../../containers/campaign-sharing/helper';
import { useState } from 'react';
import { Button, Chip } from '@mui/material';

const styles = () => ({
    disclaimerContent: {
        margin: 5,
    },
    fullWidth: {
        width: '100%',
    },
    tabChip: {
        '& .MuiChip-label': {
            height: '12px',
            paddingTop: 1,
        },
    },
    showMoreBtn: {
        fontSize: '10px',
    },
    metricSelectorItem: {
        overflow: 'hidden',
    },
    metricSelectorItemLabel: {
        maxWidth: '100%',
        '& > span': {
            wordWrap: 'break-word',
            maxWidth: '90%',
        },
    },
});

function formatValue(metricValue, formatType) {
    switch (formatType) {
        case 'thousands':
            return formatNumber_whole(metricValue);

        case 'percentage':
            return formatNumber_percentage(metricValue);

        case 'dollar':
            return formatNumber_currency(metricValue);

        case 'whole-fixed':
            return formatNumber_wholeFixed(metricValue, 2);

        default:
            return metricValue;
    }
}

const METRIC_TABS = {
    Performance: 'performance',
    Video: 'video',
    Engagements: 'engagements',
    Conversions: 'conversions',
};

const MetricSelector = ({
    metricSelector_toggleVisiblity,
    isVisibleBeaconsAtLimit,
    campaignBillingEnabled,
    campaign,
    classes,
    metricSelector_toggleSubevent,
    selectedSubevents,
    totalFilteredStats,
    metricComponentsConfig,
    className,
    selectedMetrics,
}) => {
    const [selectedMetricTab, setSelectedMetricTab] = useState('performance');
    const [showMoreMetrics, setShowMoreMetrics] = useState([]);
    const newUiFlag = flags.isEnabled('en_4598_new_report_conversion_columns');
    const separateVideoMetrics = flags.isEnabled('efb_82_separate_video_metrics');

    const getColumnDataByOrder = order => {
        var metricComponentsConfigLookup = {};

        each(metricComponentsConfig, metric => {
            metricComponentsConfigLookup[metric.metricType] = metric;
        });

        return _(order)
            .filter(metricType => !!metricComponentsConfigLookup[metricType])
            .map(metricType => metricComponentsConfigLookup[metricType])
            .filter(includeMetricsCreatedBeforeCampaignStart(campaign.start))
            .map(metric => {
                let metricValue = 0;

                if (totalFilteredStats) {
                    metricValue = getMetricValue({
                        metricName: metric.metricType,
                        statRecord: totalFilteredStats || 0,
                    });
                }
                const metricValueFormatted = formatValue(metricValue, metric.formatType);

                return {
                    ...metric,
                    metricValue: metricValueFormatted,
                    metricValueRaw: metricValue,
                };
            })
            .value();
    };

    const getBeaconMetrics = (excludeFields = []) => {
        const beaconMetrics = filter(
            metricComponentsConfig,
            metric => metric.category === 'beacon' && !includes(excludeFields, metric.metricType)
        );

        var maxColumn1Items = 6;
        if (beaconMetrics.length >= 14) {
            maxColumn1Items = Math.ceil(beaconMetrics.length / 2);
        }

        const column1Metrics = slice(beaconMetrics, 0, maxColumn1Items);
        const column2Metrics = slice(beaconMetrics, maxColumn1Items);

        var toColumnItems = metric => {
            let metricValue =
                totalFilteredStats && totalFilteredStats[metric.metricType]
                    ? totalFilteredStats[metric.metricType]
                    : 0;

            const metricValueFormatted = formatValue(metricValue, metric.formatType);
            return {
                ...metric,
                metricValue: metricValueFormatted,
                metricValueRaw: metricValue,
            };
        };

        const column1 = map(column1Metrics, toColumnItems);
        const column2 = map(column2Metrics, toColumnItems);

        return [column1, column2];
    };

    const getConversionMetrics = conversions => {
        let conversionMetrics = filter(
            metricComponentsConfig,
            metric => metric.category === 'conversion'
        );

        if (conversions) {
            const eventTypeMapping = {};

            each(conversions, ({ event_name, use_click_through, use_view_through }) => {
                eventTypeMapping[`conv_total_imp_${event_name}`] = use_view_through;
                eventTypeMapping[`conv_total_click_${event_name}`] = use_click_through;
            });

            conversionMetrics = filter(conversionMetrics, ({ metricType }) => {
                if (
                    includes(metricType, 'conv_total_imp_') ||
                    includes(metricType, 'conv_total_click_')
                ) {
                    if (eventTypeMapping[metricType]) {
                        return true;
                    } else {
                        return false;
                    }
                }

                return true;
            });
        }

        var toColumnItems = metric => {
            const metricValue =
                totalFilteredStats && totalFilteredStats[metric.metricType]
                    ? totalFilteredStats[metric.metricType]
                    : 0;
            const metricValueFormatted = formatValue(metricValue, metric.formatType);
            return {
                ...metric,
                metricValue: metricValueFormatted,
                metricValueRaw: metricValue,
            };
        };
        return map(conversionMetrics, toColumnItems);
    };

    const getAdvertiserMetrics = () => {
        let currentAdvertiserColumn = null;
        return _(metricComponentsConfig)
            .filter(metric => metric.category === 'advertiser_conversion')
            .map(metric => {
                const metricValue =
                    totalFilteredStats && totalFilteredStats[metric.metricType]
                        ? totalFilteredStats[metric.metricType]
                        : 0;
                const metricValueFormatted = formatValue(metricValue, metric.formatType);
                return {
                    ...metric,
                    metricValue: metricValueFormatted,
                    metricValueRaw: metricValue,
                };
            })
            .filter(metric => {
                if (!newUiFlag) {
                    return true;
                }

                if (metric.isHeader) {
                    currentAdvertiserColumn = metric.conversionName;
                    return true;
                } else {
                    // This isn't a header column. Check if the header is selected and if so, show it.
                    return selectedMetrics.some(
                        selectedMetric => selectedMetric.indexOf(currentAdvertiserColumn) !== -1
                    );
                }
            })
            .value();
    };

    let BASIC_METRIC_SORT_ORDER_COLUMN_1 = [
        'impressions',
        'clicks',
        'ctr',
        'vcr', // Removed below if separeteVideoMetrics
        'unique_users',
        'daily_uniq',
        'average_freq',
        'owner_total_media_cost_local',
        'owner_media_cost_2_local',
        'data_cost',
        'third_party_fees',
        'owner_total_media_cost_local_ecpm',
        'owner_total_media_cost_local_ecpc',
        'owner_total_media_cost_local_ecpcv', // Removed below if separeteVideoMetrics
        'win_rate',
    ];
    if (separateVideoMetrics) {
        // Move video metrics
        BASIC_METRIC_SORT_ORDER_COLUMN_1 = _.without(
            BASIC_METRIC_SORT_ORDER_COLUMN_1,
            'vcr',
            'owner_total_media_cost_local_ecpcv'
        );
    }

    if (!campaign.isUniqueUsersDisabled) {
        BASIC_METRIC_SORT_ORDER_COLUMN_1 = filter(
            BASIC_METRIC_SORT_ORDER_COLUMN_1,
            m => m !== 'daily_uniq' && m !== 'average_freq'
        );
    }

    let BASIC_METRIC_SORT_ORDER_COLUMN_2 = [
        'revenue',
        'erpm',
        'erpc',
        'revenue_ecpcv', // Removed below if separeteVideoMetrics
    ];
    if (separateVideoMetrics) {
        // Move video metrics
        BASIC_METRIC_SORT_ORDER_COLUMN_2 = _.without(
            BASIC_METRIC_SORT_ORDER_COLUMN_2,
            'revenue_ecpcv'
        );
    }

    let VIDEO_METRIC_SORT_ORDER = separateVideoMetrics
        ? [
              'event_video_start',
              'event_video_progress_25',
              'event_video_progress_50',
              'event_video_progress_75',
              'event_video_complete',
              'vcr',
              'event_video_skip',
              'event_video_companion_view',
              'event_video_companion_click',
              'owner_total_media_cost_local_ecpcv',
              'revenue_ecpcv',
          ]
        : [];

    if (!campaignBillingEnabled) {
        BASIC_METRIC_SORT_ORDER_COLUMN_2 = dropRight(BASIC_METRIC_SORT_ORDER_COLUMN_2, 3);
    }
    const basicMetricsColumn1 = getColumnDataByOrder(BASIC_METRIC_SORT_ORDER_COLUMN_1);
    const basicMetricsColumn2 = getColumnDataByOrder(BASIC_METRIC_SORT_ORDER_COLUMN_2);

    const videoMetrics = getColumnDataByOrder(VIDEO_METRIC_SORT_ORDER);

    const [beaconMetricsColumn1, beaconMetricsColumn2] = getBeaconMetrics(VIDEO_METRIC_SORT_ORDER);
    const conversionMetricsColumn = getConversionMetrics(campaign.conversions);

    const conversionOverallColumns = filter(conversionMetricsColumn, conversion =>
        includes(conversion.metricType, 'conv_overall')
    );
    const groupedConversionOverallColumns = _.groupBy(conversionOverallColumns, 'conversionName');

    const conversionColumns = filter(
        conversionMetricsColumn,
        conversion => !includes(conversion.metricType, 'conv_overall')
    );
    const groupedConversionColumns = _.groupBy(conversionColumns, 'conversionName');

    const advertiserConversionColumns = getAdvertiserMetrics(campaign.advertiserMetrics);
    const groupedAdvertiserColumns = _.groupBy(advertiserConversionColumns, 'conversionName');

    const shouldShowConversions =
        isEmpty(groupedConversionColumns) || isEmpty(groupedAdvertiserColumns);

    const isConversionShared = isMetricShared(campaign, 'conversions_sharing_settings_flag');
    const isEngagementShared = isMetricShared(campaign, 'engagements_sharing_settings_flag');

    const commonMetricItemProps = {
        metricSelector_toggleVisiblity,
        classes,
        metricSelector_toggleSubevent,
        selectedSubevents,
        selectedMetrics,
        showMoreMetrics,
        setShowMoreMetrics,
        newUiFlag,
    };

    const conversionComponent =
        shouldShowConversions && isConversionShared ? (
            <React.Fragment>
                <Spacer type="small" />
                <Grid container alignItems="center">
                    <FormLabel component="legend">Conversions</FormLabel>
                    <InfoTooltip
                        placement="top-start"
                        title={
                            <div>
                                <div className={classes.disclaimerContent}>
                                    EngageFront pixels can track same-platform conversions and
                                    same-device conversions.
                                </div>
                            </div>
                        }
                    />
                </Grid>
                {!newUiFlag && (
                    <React.Fragment>
                        <Spacer type="x-small" />
                        <Box ml={1}>
                            {map(conversionOverallColumns, metric => (
                                <MetricSelectorItem
                                    key={metric.metricType}
                                    metric={metric}
                                    {...commonMetricItemProps}
                                />
                            ))}
                        </Box>
                    </React.Fragment>
                )}
                {newUiFlag && (
                    <MetricList
                        groupedMetricList={groupedConversionOverallColumns}
                        {...commonMetricItemProps}
                    />
                )}
                {advertiserConversionColumns.length > 0 && (
                    <React.Fragment>
                        {!newUiFlag && (
                            <React.Fragment>
                                <Spacer type="x-small" />
                                <Box ml={1}>
                                    <Grid container>
                                        <BodyText>Advertiser Pixels</BodyText>
                                    </Grid>
                                    <Spacer type="x-small" />
                                    {map(advertiserConversionColumns, metric => (
                                        <MetricSelectorItem
                                            key={metric.metricType}
                                            metric={metric}
                                            {...commonMetricItemProps}
                                        />
                                    ))}
                                </Box>
                            </React.Fragment>
                        )}
                        {newUiFlag && (
                            <MetricList
                                bodyText="Advertiser Pixels"
                                groupedMetricList={groupedAdvertiserColumns}
                                {...commonMetricItemProps}
                            />
                        )}
                    </React.Fragment>
                )}
                <Spacer type="x-small" />
                {conversionColumns.length > 0 && (
                    <React.Fragment>
                        {!newUiFlag && (
                            <Box ml={1}>
                                <Grid container>
                                    <BodyText>Campaign Pixels</BodyText>
                                </Grid>
                                <Spacer type="x-small" />
                                {map(conversionColumns, metric => (
                                    <MetricSelectorItem
                                        key={metric.metricType}
                                        metric={metric}
                                        {...commonMetricItemProps}
                                    />
                                ))}
                            </Box>
                        )}
                        {newUiFlag && (
                            <MetricList
                                bodyText="Campaign Pixels"
                                groupedMetricList={groupedConversionColumns}
                                {...commonMetricItemProps}
                            />
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        ) : (
            conversionMetricsColumn.length > 0 &&
            isConversionShared && (
                <React.Fragment>
                    <Grid container>
                        <BodyText>Conversions</BodyText>
                        <InfoTooltip
                            placement="top-start"
                            title={
                                <div>
                                    <div className={classes.disclaimerContent}>
                                        EngageFront pixels can track same-platform conversions and
                                        same-device conversions.
                                    </div>
                                </div>
                            }
                        />
                    </Grid>

                    <Spacer type="x-small" />
                    {map(conversionMetricsColumn, metric => (
                        <MetricSelectorItem
                            key={metric.metricType}
                            metric={metric}
                            {...commonMetricItemProps}
                        />
                    ))}
                </React.Fragment>
            )
        );

    // Check what tabs we should show for the user and filter them out if no data.
    const metricTabsConfig = Object.keys(METRIC_TABS)
        .filter(metric => {
            if (metric === 'Engagements') {
                return (
                    beaconMetricsColumn1.length &&
                    isEngagementShared &&
                    [...beaconMetricsColumn1, ...beaconMetricsColumn2].some(
                        metric => metric?.metricValueRaw !== 0
                    )
                );
            } else if (metric === 'Conversions') {
                return (
                    shouldShowConversions &&
                    isConversionShared &&
                    [...conversionMetricsColumn, ...advertiserConversionColumns].some(
                        metric => metric?.metricValueRaw !== 0
                    )
                );
            } else if (metric === 'Video') {
                return videoMetrics.some(metric => metric.metricValueRaw !== 0); // hide video tab if all metrics are 0
            }
            return true;
        })
        .map(metric => ({
            value: METRIC_TABS[metric],
            label: metric,
        }));

    return (
        <div className={cn('ef4-metric-selector', className)}>
            {newUiFlag && (
                <Box>
                    {map(metricTabsConfig, metricConfig => {
                        return (
                            <Box mb="10" mr="4" display="inline-block">
                                <Chip
                                    label={metricConfig.label}
                                    color={
                                        metricConfig.value === selectedMetricTab
                                            ? 'primary'
                                            : 'default'
                                    }
                                    onClick={() => setSelectedMetricTab(metricConfig.value)}
                                    className={classes.tabChip}
                                />
                            </Box>
                        );
                    })}
                </Box>
            )}
            {(!newUiFlag || selectedMetricTab === METRIC_TABS.Performance) && (
                <React.Fragment>
                    <Spacer type="small" />
                    <FormLabel component="legend">Performance</FormLabel>
                    <Spacer type="x-small" />
                    <Box ml={1}>
                        {basicMetricsColumn1.map(metric => (
                            <MetricSelectorItem
                                isVisibleBeaconsAtLimit={isVisibleBeaconsAtLimit}
                                key={metric.metricType}
                                metric={metric}
                                {...commonMetricItemProps}
                            />
                        ))}
                        {basicMetricsColumn2.map(metric => (
                            <MetricSelectorItem
                                isVisibleBeaconsAtLimit={isVisibleBeaconsAtLimit}
                                key={metric.metricType}
                                metric={metric}
                                {...commonMetricItemProps}
                            />
                        ))}
                    </Box>
                </React.Fragment>
            )}
            {(!newUiFlag || selectedMetricTab === METRIC_TABS.Video) && (
                <React.Fragment>
                    <Spacer type="small" />
                    <FormLabel component="legend">Video</FormLabel>
                    <Spacer type="x-small" />
                    <Box ml={1}>
                        {videoMetrics.map(metric => (
                            <MetricSelectorItem
                                isVisibleBeaconsAtLimit={isVisibleBeaconsAtLimit}
                                key={metric.metricType}
                                metric={metric}
                                {...commonMetricItemProps}
                            />
                        ))}
                    </Box>
                </React.Fragment>
            )}
            {(!newUiFlag || selectedMetricTab === METRIC_TABS.Engagements) &&
                beaconMetricsColumn1.length > 0 &&
                isEngagementShared && (
                    <React.Fragment>
                        <Spacer type="small" />
                        <FormLabel component="legend">Engagements</FormLabel>
                        <Spacer type="x-small" />
                        <Box ml={1}>
                            {beaconMetricsColumn1.map(metric => (
                                <MetricSelectorItem
                                    isVisibleBeaconsAtLimit={isVisibleBeaconsAtLimit}
                                    key={metric.metricType}
                                    metric={metric}
                                    {...commonMetricItemProps}
                                />
                            ))}
                            {beaconMetricsColumn2.map(metric => (
                                <MetricSelectorItem
                                    isVisibleBeaconsAtLimit={isVisibleBeaconsAtLimit}
                                    key={metric.metricType}
                                    metric={metric}
                                    {...commonMetricItemProps}
                                />
                            ))}
                        </Box>
                    </React.Fragment>
                )}
            {(!newUiFlag || selectedMetricTab === METRIC_TABS.Conversions) && conversionComponent}
        </div>
    );
};

export const MetricList = ({
    groupedMetricList,
    showMoreMetrics,
    setShowMoreMetrics,
    selectedMetrics,
    bodyText,
    classes,
    ...rest
}) => {
    const handleShowButtonClick = (conversionName, showingMore) => {
        if (showingMore) {
            setShowMoreMetrics(showMoreMetrics.filter(metric => metric !== conversionName));
        } else {
            const newShowMoreMetrics = showMoreMetrics.slice(0);
            newShowMoreMetrics.push(conversionName);
            setShowMoreMetrics(newShowMoreMetrics);
        }
    };

    return (
        <React.Fragment>
            <Spacer type="x-small" />
            <Box ml={1}>
                {bodyText && (
                    <Grid container>
                        <BodyText>{bodyText}</BodyText>
                    </Grid>
                )}
                <Spacer type="x-small" />
                {map(groupedMetricList, (metricList, conversionName) => {
                    const showingMore = showMoreMetrics.includes(conversionName);
                    const isGroupSelected = selectedMetrics.some(
                        selectedMetric => selectedMetric.indexOf(conversionName) !== -1
                    );

                    let currentHeaderColumn = null;
                    const filteredMetricList = metricList.filter(metric => {
                        if (!rest.newUiFlag) {
                            return true;
                        }

                        if (metric.isHeader) {
                            currentHeaderColumn = metric.conversionName;
                            return true;
                        } else {
                            // This isn't a header column. Check if the header is selected and if so, show it.
                            return selectedMetrics.some(
                                selectedMetric => selectedMetric.indexOf(currentHeaderColumn) !== -1
                            );
                        }
                    });

                    return (
                        <React.Fragment>
                            {map(filteredMetricList, (metric, index) => {
                                return metric.isHeader ||
                                    metric.presentationName === 'Total' ||
                                    showingMore ? (
                                    <MetricSelectorItem
                                        key={`${metric.metricType}${index}`}
                                        metric={metric}
                                        selectedMetrics={selectedMetrics}
                                        classes={classes}
                                        {...rest}
                                    />
                                ) : null;
                            })}
                            {isGroupSelected ? (
                                <Button
                                    variant="text"
                                    onClick={() =>
                                        handleShowButtonClick(conversionName, showingMore)
                                    }
                                    className={classes.showMoreBtn}
                                >
                                    {showingMore ? 'Show Less' : 'Show More'}
                                </Button>
                            ) : null}
                        </React.Fragment>
                    );
                })}
            </Box>
        </React.Fragment>
    );
};

const MetricSelectorItem = ({
    metric,
    selectedMetrics,
    metricSelector_toggleVisiblity,
    metricSelector_toggleSubevent,
    selectedSubevents,
    classes,
    newUiFlag,
}) => {
    const handleButtonClick = () => {
        metricSelector_toggleVisiblity(metric);
    };

    const handleOptionSelect = values => {
        // We need to check which value for the select was added or removed.
        const addedValue = difference(values, selectedSubevents);
        const removedValue = difference(selectedSubevents, values);

        if (!addedValue && !removedValue) {
            return;
        }

        const processValues = (values, changedValue) => {
            metricSelector_toggleSubevent({ values, changedValue });
        };

        if (addedValue.length && addedValue.length > 1) {
            addedValue.forEach(value => processValues(values, value));
            return;
        }

        if (removedValue.length && removedValue.length > 1) {
            removedValue.forEach(value => processValues(values, value));
            return;
        }

        processValues(values, addedValue[0] || removedValue[0]);
    };

    const isChecked = () => {
        if (newUiFlag && metric.isHeader) {
            // The header metric should only be checked if at least one of the sub-values are checked.
            return selectedMetrics.some(
                selectedMetric => selectedMetric.indexOf(metric.conversionName) !== -1
            );
        }

        return includes(selectedMetrics, metric.metricType);
    };

    let listComponent;
    if (metric.isMultiSelect) {
        listComponent = metric.options.length ? (
            <Flex>
                <div
                    className={`${metric.isSubCategory ? 'metric-selector__subcategory' : ''} ${
                        classes.fullWidth
                    }`}
                >
                    <MultiSelect
                        value={selectedSubevents}
                        onChange={handleOptionSelect}
                        options={metric.options}
                        placeholder={metric.presentationName}
                    />
                </div>
            </Flex>
        ) : null;
    } else {
        listComponent = (
            <SpaceBetweenLayout>
                <div
                    className={`${metric.isSubCategory ? 'metric-selector__subcategory' : ''} ${
                        classes.metricSelectorItem
                    }`}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={isChecked()}
                                onChange={handleButtonClick}
                            />
                        }
                        label={metric.presentationName}
                        className={classes.metricSelectorItemLabel}
                    />
                </div>
                <CenterLayout>{metric.metricValue}</CenterLayout>
            </SpaceBetweenLayout>
        );
    }

    return listComponent;
};

function includeMetricsCreatedBeforeCampaignStart(campaignStart) {
    return metric => {
        if (!metric.inceptionDate) {
            return true;
        }

        if (moment(metric.inceptionDate).isBefore(campaignStart)) {
            return true;
        }

        return false;
    };
}

export default withStyles(styles)(MetricSelector);
