export const creativeFormatOptions = [
    {
        value: 'standard',
        label: 'Standard',
    },
    {
        value: 'video',
        label: 'Video Interstitial',
    },
    {
        value: 'custom_html',
        label: 'Rich Media',
    },
    {
        value: 'mraid',
        label: 'MRAID Rich Media',
    },
    {
        value: 'native',
        label: 'Native',
    },
    {
        value: 'third_party_vast',
        label: '3rd Party Hosted VAST',
    },
];

export const creativeFormatOptionsVersion2 = type => {
    switch (type) {
        case 'banner':
            return [
                {
                    value: 'standard',
                    label: 'Static',
                },
                {
                    value: 'custom_html',
                    label: 'Rich Media',
                },
                {
                    value: 'mraid',
                    label: 'MRAID Rich Media',
                },
            ];
        case 'box':
            return [
                {
                    value: 'standard',
                    label: 'Static',
                },
                {
                    value: 'custom_html',
                    label: 'Rich Media',
                },
                {
                    value: 'mraid',
                    label: 'MRAID Rich Media',
                },
            ];
        case 'interstitial':
            return [
                {
                    value: 'standard',
                    label: 'Static',
                },
                {
                    value: 'custom_html',
                    label: 'Rich Media',
                },
                {
                    value: 'mraid',
                    label: 'MRAID Rich Media',
                },
            ];
        case 'expandable':
            return [
                {
                    value: 'mraid',
                    label: 'MRAID Rich Media',
                },
            ];
        case 'video':
            return [
                {
                    value: 'video',
                    label: 'Video Interstitial',
                },
                {
                    value: 'third_party_vast',
                    label: '3rd Party Hosted VAST',
                },
            ];
        default:
            return [];
    }
};

export const creativeTypeOptions = [
    {
        value: 'banner',
        label: 'Banner',
    },
    {
        value: 'box',
        label: 'Box',
    },
    {
        value: 'interstitial',
        label: 'Interstitial',
    },
    {
        value: 'expandable',
        label: 'Expandable',
    },
    {
        value: 'video',
        label: 'Video',
    },
];

export const changeSizeSelectorItemsVersion2 = value => {
    switch (value) {
        case 'banner':
            return [
                {
                    value: '320x50',
                    label: '320 x 50 (Mobile - Banner)',
                },
                {
                    value: '300x50',
                    label: '300 x 50 (Mobile - Banner)',
                },
                {
                    value: '728x90',
                    label: '728 x 90 (Tablet - Banner)',
                },
            ];
        case 'box':
            return [
                {
                    value: '300x250',
                    label: '300 x 250 (Medium Rectangle - Box)',
                },
            ];
        case 'interstitial':
            return [
                {
                    value: '320x480',
                    label: '320 x 480 (Mobile - Interstitial)',
                },
                {
                    value: '480x320',
                    label: '480 x 320 (Mobile - Interstital)',
                },
                {
                    value: '768x1024',
                    label: '768 x 1024 (Tablet - Interstital)',
                },
                {
                    value: '1024x768',
                    label: '1024 x 768 (Tablet - Interstitial)',
                },
            ];
        case 'expandable':
            return [
                {
                    value: '320x50',
                    label: '320 x 50 (Mobile - Expandable)',
                },
                {
                    value: '728x90',
                    label: '728 x 90 (Mobile - Expandable)',
                },
            ];
        case 'video':
            return [
                {
                    value: '320x480',
                    label: '320 X 480 (Mobile - Video)',
                },
                {
                    value: '480x320',
                    label: '480 X 320 (Mobile - Video)',
                },
                {
                    value: '768x1024',
                    label: '768 X 1024 (Tablet - Video)',
                },
                {
                    value: '1024x768',
                    label: '1024 X 768 (Tablet - Video)',
                },
            ];
        default:
            return [];
    }
};

export const changeSizeSelectorItems = value => {
    switch (value) {
        case 'standard':
            return [
                {
                    value: '320x50',
                    label: '320 x 50 (Mobile - Banner)',
                },
                {
                    value: '300x50',
                    label: '300 x 50 (Mobile - Banner)',
                },
                {
                    value: '320x480',
                    label: '320 x 480 (Mobile - Interstitial)',
                },
                {
                    value: '480x320',
                    label: '480 x 320 (Mobile - Interstital)',
                },
                {
                    value: '728x90',
                    label: '728 x 90 (Tablet - Banner)',
                },
                {
                    value: '768x1024',
                    label: '768 x 1024 (Tablet - Interstital)',
                },
                {
                    value: '1024x768',
                    label: '1024 x 768 (Tablet - Interstitial)',
                },
                {
                    value: '300x250',
                    label: '300 x 250 (Banner / Interstitial)',
                },
            ];
        case 'mraid':
        case 'custom_html':
            return [
                {
                    value: '320x50',
                    label: '320 x 50 (Mobile - Banner)',
                },
                {
                    value: '300x50',
                    label: '300 x 50 (Mobile - Banner)',
                },
                {
                    value: '320x480',
                    label: '320 x 480 (Mobile - Interstitial)',
                },
                {
                    value: '480x320',
                    label: '480 x 320 (Mobile - Interstital)',
                },
                {
                    value: '728x90',
                    label: '728 x 90 (Tablet - Banner)',
                },
                {
                    value: '768x1024',
                    label: '768 x 1024 (Tablet - Interstital)',
                },
                {
                    value: '1024x768',
                    label: '1024 x 768 (Tablet - Interstitial)',
                },
                {
                    value: '300x250',
                    label: '300 x 250 (Banner / Interstitial)',
                },
            ];
        case 'third_party_vast':
        case 'video':
            return [
                {
                    value: '320x480',
                    label: '320 X 480 (Mobile - Interstitial)',
                },
                {
                    value: '480x320',
                    label: '480 X 320 (Mobile - Interstitial)',
                },
                {
                    value: '768x1024',
                    label: '768 X 1024 (Tablet - Interstitial)',
                },
                {
                    value: '1024x768',
                    label: '1024 X 768 (Tablet - Interstitial)',
                },
            ];
        default:
            return [];
    }
};

export const INVENTORY_FORECAST_CACHE_ALLOW = 'allow';
export const INVENTORY_FORECAST_CACHE_IGNORE = 'ignore';
export const INVENTORY_FORECAST_CACHE_REMOVE = 'remove';

export const INVENTORY_FORECAST_CACHE_OPTIONS = [
    {
        label: 'Allow to return cached data',
        value: INVENTORY_FORECAST_CACHE_ALLOW,
    },
    {
        label: 'Ignore cached data',
        value: INVENTORY_FORECAST_CACHE_IGNORE,
    },
    {
        label: 'Remove cached data',
        value: INVENTORY_FORECAST_CACHE_REMOVE,
    },
];
